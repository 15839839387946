import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CoreSession } from 'src/app/core/core.session';
import { ModalDialogComponent } from 'src/app/shared/modal-dialog/modal-dialog.component';

@Component({
  selector: 'app-root-content',
  templateUrl: './root-content.component.html',
  styleUrls: ['./root-content.component.css']
})
export class RootContentComponent implements OnInit {
  @ViewChild('dialogModal', { static: true }) dialogModal: ModalDialogComponent;
  constructor(private coreSession: CoreSession, 
    private titleService: Title,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.coreSession.ModalDialog = this.dialogModal;
  }

}
