import { TextLanguage } from "../general/textLanguage.model";
import { ContactPersonModel } from "../supplier-models/contact-person.model";

export class ClientModel {
  clientId: number;
  clientTypeId: number;
  clientName: string;
  clientCode: string;
  clientTypeName: string;
  clientNationalId: string;
  mobile: string;
  email: string;
  address: string;
  inactive: boolean;
  descriptions: TextLanguage[];
  isChecked: boolean;
  longitude: number;
  latitude: number;

  contacts: ContactPersonModel[];
  country: string;

  contactName: string;

  isLocation: boolean;

  constructor() {
    this.clientId = -1;
    this.clientTypeId = -1;
    this.clientName = "";
    this.clientCode = "";
    this.clientTypeName = "";
    this.clientNationalId = "";
    this.mobile = "";
    this.email = "";
    this.address = "";
    this.inactive = false;
    this.descriptions = [];
    this.isChecked = false;
    this.longitude = 0.0;
    this.latitude = 0.0;
    this.contacts = [];
    this.country = '';
    this.contactName = '';
    this.isLocation = false;
  }
}
