import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CoreSession } from 'src/app/core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { RouteService } from '../route.service';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { CustomListFilter } from 'src/app/shared/model/shared-table/custom-list.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceModel } from 'src/app/shared/model/invoice-model/invoice-model';
import { FormControl, FormGroup } from '@angular/forms';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { RouteEntryComponent } from '../route-entry/route-entry.component';
import { Subject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AssignedInvoicesDialogComponent } from '../assigned-invoices-dialog/assigned-invoices-dialog.component';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.css']
})
export class RouteComponent implements OnInit {
  @ViewChild('content', { static: true }) routeEntryDialog: RouteEntryComponent;
  saveSubject: Subject<void> = new Subject<void>();
  @Output('closeDialog') closeDialog = new EventEmitter();
  showDialog = false;
  otherLanguages: any[] = [];
  showSlider = false;

  routeFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    employeeCodeName: '',
    employeeTypeId: -1,
    organizationId: -1,
    vehicleId: -1,
    divisionId: -1,
    deviceSerial: '',
    securityGroupId: -1,
    includeInactive: false,
    employeeId: -1
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_Assign',
    rowOperations: [
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_StaffName",
        key: "staffName",
        isSortable: false,
        width: "25%"
      },
      {
        title: "DESC_TotalInvoices",
        key: "totalInvoices",
        isSortable: false,
        width: "25%"
      },

      {
        title: "Desc_RemainingAmount",
        key: "remainingAmount",
        isSortable: false,
        width: "20%"
      }
    ]
  };
  sharedTableFilter: CustomListFilter = {
    searchFilter: '',
    page: 0,
    pageSize: this.coreSession.pageSize
  };
  isEditModeSelected: boolean;
  selectedObj: any;

  constructor(private coreSession: CoreSession, private modalService: NgbModal,
    private routeService: RouteService,
    private translateService: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.addActions();
    this.populateAssignedInvoiceList();
  }

  addActions() {
    if (this.coreSession.checkActivitiesAvailability('route', MenuActions.Add)) {
      this.tableData.showNewBtn = true
    }
    if (this.coreSession.checkActivitiesAvailability('route', MenuActions.Edit)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.edit,
          title: "Desc_Edit",
          icon: "fa fa-edit",
          color: "black"
        },
      );
    }
    if (this.coreSession.checkActivitiesAvailability('route', MenuActions.View)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.View,
          title: "Desc_ViewAssignedInvoices",
          icon: "fa fa-eye",
          color: "black"
        },
      );
    }
  }

  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.LblWarningCaption)).then(
          (res: DialogResult) => {
            if (res === DialogResult.Yes) {
              this.onDeleteClicked(event)
            }
          });
        break;
      case RowOperation.View:
        this.viewInvoices(event);
        break;
      case RowOperation.edit:
        this.openDialogMode(event);

    }
  }

  viewInvoices(event: any) {
    const dialogRef = this.dialog.open(AssignedInvoicesDialogComponent, {
      width: '500px',
      height: '500px',
      data: { route: event },
    });

    dialogRef.afterClosed().subscribe(result => {
      // //console.log('The dialog was closed');
    });
  }

  onDeleteClicked(event: any) {
    // //console.log(event, "delete event");
    this.routeService.deleteAssigningFromInvoice(event.object).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.populateAssignedInvoiceList();
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onSaveClicked() {
    this.saveSubject.next();
  }

  onTableFilterChanged(query: any) {
    this.sharedTableFilter = query;
    // this.populatePackGroups();
  }

  openDialogMode(event) {
    this.selectedObj = event.object;
    this.isEditModeSelected = false;
    this.showSlider = true;
  }

  openDialogToAssign() {
    this.selectedObj = null;
    this.isEditModeSelected = false;
    this.showSlider = true;
    // this.routeEntryDialog.showDialog().then(
    //   (result) => {
    //     if (result != -1) {
    //       //console.log(" > -1");
    //       // this.afterAddingSelectedCustomers(result)
    //     } else {
    //       //console.log(" <= -1");
    //       // this.closeSelectCustomersDialog();
    //     }
    //   });

    // this.modalService.open(content, { size: 'xl' });
  }



  onSaveAssignedInvoice() {
    this.populateAssignedInvoiceList();
  }

  onSavedCompleted(value: any) {
    this.onSaveAssignedInvoice();
    this.showDialog = true;
  }

  populateAssignedInvoiceList() {
    this.coreSession.ModalLoading.Show();
    this.routeFilter.employeeId = 0;
    this.routeService.getAssignedInvoices().subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = response.data.data.slice();
        this.dataSource.totalItems = response.data.totalItems;
        if (response.data.length <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }



  closeSlider() {
    this.showSlider = false;
  }






}


