import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";

@Injectable({ providedIn: 'root' })
export class GuestsService {

    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'Guests';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getNumberOfGuests() {
        const urlValue: string = this.url + '/GetNumberOfGuests';
        return this.http.get(urlValue).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }


}