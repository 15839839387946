export class FacilityGuestsModel{
    currentFacilityGuests: number;
    totalBreakfastReservedGuests: number;
    currentBreakfastReservedGuests: number;
    currentBreakfastROGuests: number;
    currentBreakfastWIGuests: number;

    totalLunchReservedGuests: number;
    currentLunchReservedGuests: number;
    currentLunchROGuests: number;
    currentLunchWIGuests: number;

    totalDinnerReservedGuests: number;
    currentDinnerReservedGuests: number;
    currentDinnerROGuests: number;
    currentDinnerWIGuests: number;

    selectedFacilityId: number;

    currentWIGuests: number;
    currentROGuests: number;
    currentBBGuests: number;
    totalBBGuests: number;

    constructor() {
        this.currentFacilityGuests = 0;
        this.totalBreakfastReservedGuests = 0;
        this.currentBreakfastReservedGuests = 0;
        this.currentBreakfastROGuests = 0;
        this.currentBreakfastWIGuests = 0;

        this.totalLunchReservedGuests = 0;
        this.currentLunchReservedGuests = 0;
        this.currentLunchROGuests = 0;
        this.currentLunchWIGuests = 0;

        this.totalDinnerReservedGuests = 0;
        this.currentDinnerReservedGuests = 0;
        this.currentDinnerROGuests = 0;
        this.currentDinnerWIGuests = 0;

        this.selectedFacilityId = -1;
        this.totalBBGuests = 0;
        this.currentBBGuests = 0;
        this.currentROGuests = 0;
        this.currentWIGuests = 0;
    }
}
