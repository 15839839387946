import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { ItemDetailsModel } from "../item-models/item-details.model";
import { OperationDetailsModel } from "./operation-details.model";

export class OperationMainModel {
    transactionId: string;
    locationId: number;
    locationOperationTypeId: number;
    warehouseId: number;
    warehouseName: string;
    warehouseOperationTypeId: number;
    warehouseOperationTypeName: string;
    noOfItems: number;
    operationStatusId: number;
    operationStatusName: string;
    createdBy: number;
    createdByName: string;
    creationDateModel: DateTimeModel;
    isVoided: boolean;

    operationDetails: OperationDetailsModel[];
    itemDetails: ItemDetailsModel[];
    reasonId: number;
    reasonDesc: string;
    supplierId: number;
    supplierName: string;
    deliveryMethod: string;

    constructor() {
        this.transactionId = '';
        this.locationId = -1;
        this.locationOperationTypeId = -1;
        this.warehouseId = -1;
        this.warehouseName = '';
        this.warehouseOperationTypeId = -1;
        this.noOfItems = 0;
        this.warehouseOperationTypeName = '';
        this.operationStatusId = -1;
        this.operationStatusName = '';
        this.createdBy = -1;
        this.createdByName = '';
        this.creationDateModel = new DateTimeModel();
        this.isVoided = false;

        this.operationDetails = [];
        this.itemDetails = [];
        this.reasonId = -1;
        this.reasonDesc = '';
        this.supplierId = -1;
        this.supplierName = '';
        this.deliveryMethod = '';
    }
}