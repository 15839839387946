import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { StudentModel } from "src/app/shared/model/student-attendance-models/student.model";

@Injectable({ providedIn: 'root' })
export class StudentsService {

    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private datePipe: DatePipe) {
        this.url = baseUrl + 'Students';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getStudentsList(filter: any) {
        const urlValue: string = this.url + '/GetStudentsList';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getRequiredLists() {
        const urlValue: string = this.url + '/GetRequiredLists';
        return this.http.get(urlValue).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getStudentInfo(nationalId?: string, studentId?: number) {
        const urlValue: string = this.url + '/GetStudentInfo';

        let params = new HttpParams();
        params = params.append('nationalId', nationalId != null ? nationalId : '');
        params = params.append('studentId', studentId != null ? studentId.toString() : '-1');

        return this.http.get(urlValue, {
            headers: this.httpOptions.headers,
            params: params
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    insertStudent(student: StudentModel, isEditMode: boolean) {
        let URLActionName: string = isEditMode ? '/UpdateStudent' : '/InsertStudent';
        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(student), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    insertStudentList(students: StudentModel[], showErrorList: boolean) {
        let URLActionName: string = '/InsertStudentList?showErrorList=' + showErrorList;
        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(students), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateStudentStatus(obj: { studentId: string, newStatusId: number, reason: string, rejectTypeId: number }) {
        const urlValue: string = this.url + '/UpdateStudentStatus';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateFlag(obj: { studentId: number, programId: number, flag: string, value: boolean }) {
        const urlValue: string = this.url + '/UpdateFlag';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    resetStudentPassword(obj: { studentId: number, nationalId: string }) {
        const urlValue: string = this.url + '/ResetStudentPassword';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getStudentRejectionTypes() {
        const urlValue: string = this.url + '/GetStudentRejectionTypes';
        return this.http.get(urlValue).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    approveAllStudents(studentIds: number[]) {
        const urlValue: string = this.url + '/ApproveAllStudents';
        return this.http.post(urlValue, JSON.stringify(studentIds), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getStudentFinishedPrograms(studentId: number) {
        const urlValue: string = this.url + '/GetStudentFinishedPrograms?studentId=' + studentId;
        return this.http.get(urlValue).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    viewStudentCertificates(studentId: number) {
        const urlValue: string = this.url + '/ViewStudentCertificates?studentId=' + studentId;
        return this.http.get(urlValue, {
            responseType: "blob",
            headers: this.httpOptions.headers,
        }).map(res => {
            var blob = new Blob([res], { type: "application/pdf" });
            return blob;
        });
    }
}