import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FacilityManagementService } from '../facility-management.service';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from 'src/app/core/core.session';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { Client } from 'src/app/shared/enum/client.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { GuestModel } from 'src/app/shared/model/guests/guest.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { RoomInfoModel } from 'src/app/shared/model/guests/room-Info.model';
import { MatDialog } from '@angular/material/dialog';
import { FacilitySelectionDialogComponent } from '../facility-selection-dialog/facility-selection-dialog.component';
import { FacilityGuestsModel } from 'src/app/shared/model/guests/FacilityGuestsModel';
import { RoomFilterDialogComponent } from '../room-filter-dialog/room-filter-dialog.component';

@Component({
  selector: 'app-facility-management-main',
  templateUrl: './facility-management-main.component.html',
  styleUrls: ['./facility-management-main.component.css']
})
export class FacilityManagementMainComponent implements OnInit {

  royalSelected: boolean = false;
  hiltonSelected: boolean = false;

  form: FormGroup;
  selectedRoomInfo: RoomInfoModel;
  selectedFacilityTypeId: number = -1;
  msg: string = '';
  type: string = '';
  guestsCountModel: FacilityGuestsModel = new FacilityGuestsModel();

  @ViewChild('roomNumber') roomNumber: ElementRef;

  constructor(private facilityManagementService: FacilityManagementService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.royalSelected = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf()
      && CoreSession.selectedClient == Client.LeRoyal.valueOf();
    this.hiltonSelected = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf()
      && CoreSession.selectedClient == Client.Hilton_Deadsea.valueOf();

    this.initForm();
    this.openFacilityTypeSelectionDialog();
    this.resetValues();
  }

  initForm(): void {
    this.form = new FormGroup({
      roomNumber: new FormControl(),
      note: new FormControl(),
    });
  }

  openFacilityTypeSelectionDialog() {
    const dialogRef = this.dialog.open(FacilitySelectionDialogComponent, {
      width: '100vh',
      maxHeight: '100vh',
      panelClass: 'custom-container',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result) {
        this.selectedFacilityTypeId = result;

        let subtitle = '';
        if (CoreSession.selectedClient == Client.Hilton_Deadsea.valueOf()) {
          subtitle = 'Hilton ';
        } else if (CoreSession.selectedClient == Client.LeRoyal.valueOf()) {
          subtitle = 'Royal ';
        }
        if (this.selectedFacilityTypeId == 1)
          this.coreSession.setPageTitle(`${subtitle}Breakfast`);
        if (this.selectedFacilityTypeId == 2)
          this.coreSession.setPageTitle(`${subtitle}Lunch`);
        if (this.selectedFacilityTypeId == 3)
          this.coreSession.setPageTitle(`${subtitle}Dinner`);

        this.roomNumber.nativeElement.focus();

        this.getNumberOfGuests();
      }
    });
  }

  getNumberOfGuests() {
    this.coreSession.ModalLoading.Show();
    this.facilityManagementService.getNumberOfGuests(this.selectedFacilityTypeId).subscribe(res => {

      this.coreSession.ModalLoading.Hide();
      if (res.status != null && res.status >= 0) {
        this.guestsCountModel = res.data;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), res.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onKeyClicked(keyNumber: number, isDelete: boolean) {
    if (isDelete) {
      this.roomNumberController
        .setValue(this.roomNumberController.value != null && this.roomNumberController.value != '' ?
          this.roomNumberController.value.toString().slice(0, -1)
          : ''
        );
    } else {
      this.roomNumberController.setValue(
        this.roomNumberController.value != null ?
          this.roomNumberController.value.toString() + keyNumber.toString()
          : keyNumber.toString()
      );
    }
  }

  onEnterClicked() {
    this.msg = '';
    this.noteController.setValue(undefined);
    if (this.roomNumberController.value != null && this.roomNumberController.value != '') {

      if (this.roomNumberController.value.toString().trim() == '8080') {

        this.selectedRoomInfo.roomNumber = 8080;
        this.selectedRoomInfo.numberOfGuests = 8;
        this.selectedRoomInfo.numberOfGuestsKids = 8;
        this.selectedRoomInfo.facilityTypeId = this.selectedFacilityTypeId;
        this.selectedRoomInfo.arrivalDate = new Date();
        this.selectedRoomInfo.departureDate = new Date();
        this.selectedRoomInfo.guestName = 'Walk In';

      } else {
        this.getRoomInfo();
      }

    } else {
      this.resetValues();
    }
  }

  getRoomInfo() {
    this.coreSession.ModalLoading.Show();
    this.facilityManagementService.getRoomInfo(+this.roomNumberController.value, this.selectedFacilityTypeId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data == -1) {
          this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_RoomNotFound'));
          this.resetValues();
        } else {
          this.selectedRoomInfo = response.data;

          if (this.selectedRoomInfo.facilityTypeId == -1) {
            this.selectedRoomInfo.numberOfGuests = 4;
            this.selectedRoomInfo.numberOfGuestsKids = 4;
            this.msg =
              `This room has no ${this.selectedFacilityTypeId == 1 ? 'breakfast' : this.selectedFacilityTypeId == 2 ? 'lunch' : 'dinner'} available or room is empty`
          }
          if (this.selectedRoomInfo.facilityTypeId > 3) {
            this.type = this.selectedRoomInfo.type != null
              ? this.selectedRoomInfo.type.toLowerCase().includes('gold')
                ? 'GOLD'
                : this.selectedRoomInfo.type.toLowerCase().includes('diamond')
                  ? 'DIAMOND'
                  : 'COMP'
              : 'COMP';
          }

          this.noteController.setValue(this.selectedRoomInfo.note);

          // debugger;
          this.selectedRoomInfo.oldNumberOfGuestsWithBreakfast = this.selectedRoomInfo.numberOfFacillityGuests;
          this.selectedRoomInfo.oldNumberOfGuestsWithoutBreakfast = this.selectedRoomInfo.numberOfFacillityGuestsWithoutBreakfast;
          this.selectedRoomInfo.oldNumberOfGuestsKidsWithBreakfast = this.selectedRoomInfo.numberOfFacillityGuestsKids;
          this.selectedRoomInfo.oldNumberOfGuestsKidsWithoutBreakfast = this.selectedRoomInfo.numberOfFacillityGuestsKidsWithoutBreakfast;
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  resetValues() {
    this.selectedRoomInfo = new RoomInfoModel();
    this.msg = '';
    this.type = '';
    this.noteController.setValue(undefined);
  }

  onAdultsSelected(index: number) {
    if (this.selectedRoomInfo.facilityTypeId > -1 || this.selectedRoomInfo.roomNumber == 8080) {
      this.selectedRoomInfo.numberOfFacillityGuests = index == 0 && this.selectedRoomInfo.numberOfFacillityGuests == 1
        ? 0 : index + 1;
    } else {
      this.selectedRoomInfo.numberOfFacillityGuestsWithoutBreakfast = index == 0 && this.selectedRoomInfo.numberOfFacillityGuestsWithoutBreakfast == 1
        ? 0 : index + 1;
    }
  }

  onKidsSelected(index: number) {
    if (this.selectedRoomInfo.facilityTypeId > -1 || this.selectedRoomInfo.roomNumber == 8080) {
      this.selectedRoomInfo.numberOfFacillityGuestsKids = index == 0 && this.selectedRoomInfo.numberOfFacillityGuestsKids == 1
        ? 0 : index + 1;
    } else {
      this.selectedRoomInfo.numberOfFacillityGuestsKidsWithoutBreakfast = index == 0 && this.selectedRoomInfo.numberOfFacillityGuestsKidsWithoutBreakfast == 1
        ? 0 : index + 1;
    }
  }

  onSaveClicked() {
    if (this.selectedRoomInfo.facilityTypeId == -1) {
      this.selectedRoomInfo.facilityTypeId = this.selectedFacilityTypeId;
    }
    this.selectedRoomInfo.note = this.noteController.value ?? '';
    this.coreSession.ModalLoading.Show();
    this.facilityManagementService.addGuest(this.selectedRoomInfo).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      this.resetValues();
      this.roomNumber.nativeElement.focus();
      if (response.status != null && response.status >= 0) {
        if (response.data == -1) {
          this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
        } else {
          this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
          this.roomNumberController.setValue(undefined);
          this.noteController.setValue(undefined);
          this.getNumberOfGuests();
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.resetValues();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  showSaveButton(): boolean {
    let isChanged: boolean = this.selectedRoomInfo.oldNumberOfGuestsWithBreakfast != this.selectedRoomInfo.numberOfFacillityGuests ||
      this.selectedRoomInfo.oldNumberOfGuestsWithoutBreakfast != this.selectedRoomInfo.numberOfFacillityGuestsWithoutBreakfast ||
      this.selectedRoomInfo.oldNumberOfGuestsKidsWithBreakfast != this.selectedRoomInfo.numberOfFacillityGuestsKids ||
      this.selectedRoomInfo.oldNumberOfGuestsKidsWithoutBreakfast != this.selectedRoomInfo.numberOfFacillityGuestsKidsWithoutBreakfast;

    return isChanged;
  }

  onFilterClicked() {
    const dialogRef = this.dialog.open(RoomFilterDialogComponent, {
      width: '33vw',
      maxHeight: '100vh',
      minHeight: '36vh',
      panelClass: 'custom-container',
      disableClose: false,
      data: {
        facilityTypeId: this.selectedFacilityTypeId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
    });

  }

  get roomNumberController() {
    return this.form.get('roomNumber');
  }

  get noteController() {
    return this.form.get('note');
  }

}
