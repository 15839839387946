import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { ItemTypeModel } from 'src/app/shared/model/item-models/item-type.model';
import { ItemService } from '../../item.service';


@Component({
  selector: 'app-item-type-entry',
  templateUrl: './item-type-entry.component.html',
  styleUrls: ['./item-type-entry.component.css']
})
export class ItemTypeEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedItemTypeData: ItemTypeModel;
  @Input() isEditMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  formData: FormData;
  preparedObj: ItemTypeModel;
  saveBtnSubscription: any;
  form: FormGroup;
  otherLanguages: any[] = [];

  nameControlProp: ITextInput = {
    formControlName: "itemTypeDescription",
    placeHolder: "Desc_ItemTypeDescription",
    label: "Desc_ItemTypeDescription",
    isRequired: true
  };

  parentItemTypeList: ItemTypeModel[] = [];
  allItemTypesList: ItemTypeModel[] = [];

  constructor(
    private itemService: ItemService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.getRequiredLists();
    this.subscribeSaveClick();
  }

  getRequiredLists() {
    this.itemService.getItemRequiredLists().subscribe(res => {

      if (res.itemTypesList != null) {
        this.allItemTypesList = res.itemTypesList.slice();
        this.parentItemTypeList = res.itemTypesList.slice().filter(t => t.parentId == -1 && t.isParent &&
          (!this.isEditMode || (this.isEditMode && t.itemTypeId != this.selectedItemTypeData.itemTypeId)));
      }

      if (this.isEditMode) {
        this.getItemTypeData();
      }

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let itemTypeDescription = this.isEditMode && this.selectedItemTypeData.itemTypeName != '' ? this.selectedItemTypeData.itemTypeName : undefined;
    let itemTypeCode = this.isEditMode && this.selectedItemTypeData.itemTypeCode != '' ? this.selectedItemTypeData.itemTypeCode : undefined;

    this.form = new FormGroup({
      itemTypeDescription: new FormControl(itemTypeDescription, Validators.required),
      itemTypeCode: new FormControl(itemTypeCode, Validators.required),
      parentId: new FormControl(undefined),
      isParent: new FormControl(false),
    });

  }

  getItemTypeData() {
    this.coreSession.ModalLoading.Show();
    this.itemService.getItemTypeData(this.selectedItemTypeData.itemTypeId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.selectedItemTypeData = response.data;

        this.parentIdControl.setValue(this.selectedItemTypeData.parentId && this.selectedItemTypeData.parentId != -1 ? this.selectedItemTypeData.parentId : undefined);
        this.isParentControl.setValue(this.selectedItemTypeData.isParent);

        this.isParentChanged();

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareObj() {
    this.preparedObj = new ItemTypeModel();
    this.preparedObj.itemTypeId = this.isEditMode ? this.selectedItemTypeData.itemTypeId : -1;
    this.preparedObj.itemTypeName = this.itemTypeDescriptionControl.value;
    this.preparedObj.itemTypeCode = this.itemTypeCodeControl.value;
    this.preparedObj.parentId = this.parentIdControl.value ? this.parentIdControl.value : -1;
    this.preparedObj.isParent = this.isParentControl.value;

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.itemTypeDescriptionControl.value
      });
    }
    descriptions.forEach((desc, i) => {
      if (desc.description == '') {
        descriptions[i].description = this.itemTypeDescriptionControl.value;
      }
    });
    this.preparedObj.descriptions = descriptions;
  }

  onSaveClicked() {
    //console.log(this.form.value);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.prepareObj();

    if (this.preparedObj != null) {
      this.coreSession.ModalLoading.Show();
      this.itemService.insertItemType(this.preparedObj, this.isEditMode).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data > -1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.preparedObj.itemTypeId = response.data;
            this.onSaveCompleted.emit(this.preparedObj);
          }
          else {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (_error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  isParentChanged() {
    if (this.isParentControl.value) {
      this.parentIdControl.setValue(undefined);
      this.parentIdControl.disable();
    } else {
      this.parentIdControl.enable();
    }
  }

  get itemTypeDescriptionControl() {
    return this.form.get('itemTypeDescription');
  }

  get itemTypeCodeControl() {
    return this.form.get('itemTypeCode');
  }

  get parentIdControl() {
    return this.form.get('parentId');
  }

  get isParentControl() {
    return this.form.get('isParent');
  }

}
