import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class LanguagesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,private sessionData: SessionDataProvider,
    private router: Router) { }
  languages = [];
  lang = 'AR';

  ngOnInit(): void {
    this.languages = this.sessionData.getSessionLanguages();
  }

  clearCache(): void {
    if ('caches' in window) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
    }
  }

  useLanguage(selectedLang: any): void {
    // this.translate.use(this.translate.currentLang === 'en' ? 'ar' : 'en');
    // // console.log(selectedLang);
    if(selectedLang == null) {
      selectedLang = 'EN';
    }
    this.translate.use(selectedLang);
    this.lang = this.lang === 'AR' ? 'EN' : 'AR'
    this.dialogRef.close();
    this.clearCache();
    window.location.replace(this.router.url);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
