import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// tslint:disable-next-line:import-blacklist
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { StaffTypeModel } from 'src/app/shared/model/staff-type-model/staff-type-model';
import { map } from 'rxjs/operators';
import { CoreSession } from 'src/app/core/core.session';
import { StaffGroupModel } from 'src/app/shared/model/staff-models/staff-group.model';
@Injectable({
  providedIn: 'root'
})

export class StaffService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Staff';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getStaffList(staffFilter: any, selectedColumns?: any) {
    const body = { staffFilter: staffFilter, staffDefinitionFlags: selectedColumns }
    const urlValue: string = this.url + '/GetStaffList';
    return this.http.post(urlValue, body, this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  getAllStaffs() {
    const urlValue: string = this.url + '/GetAllStaffs';
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  getSecurityGroupsList(filter: any) {
    const urlValue: string = this.url + '/GetSecurityGroupsList';
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  insertStaff(staff: StaffModel, isEditMode: boolean) {
    let URLActionName: string = isEditMode ? '/UpdateStaff' : '/InsertStaff';
    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertStaffType(staff: StaffTypeModel) {
    //console.log(staff)
    const urlValue: string = this.url + '/InsertStaffType';
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateStaffActiveStatus(staff: StaffModel) {
    const urlValue: string = this.url + '/UpdateStaffActiveStatus';
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateStaffTypeActiveStatus(staff: StaffTypeModel) {
    const urlValue: string = this.url + '/UpdateStaffTypeActiveStatus';
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaffTypesSharedList(employeeFilter: any) {
    const urlValue: string = this.url + '/GetStaffTypesSharedList';
    //console.log(employeeFilter, "employeeFilter");
    return this.http.post(urlValue, JSON.stringify(employeeFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaffAllRequiredLists() {
    const urlValue: string = this.url + '/GetStaffAllRequiredLists';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {

      return <any>response.data;
    }
    ));
  }

  getStaffData(selectedStaffId: number) {
    const urlValue: string = this.url + '/GetStaffData';
    return this.http.post(urlValue, JSON.stringify(selectedStaffId), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaff(staffId: any) {
    const urlValue: string = this.url + '/GetLoggidInStaff?staffId=' + staffId;
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  updateStaff(staff: StaffModel) {
    const urlValue: string = this.url + '/UpdateStaffProfile';
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertStaffList(staffList: StaffModel[], showErrorList: boolean) {
    let URLActionName: string = '/InsertStaffList?showErrorList=' + showErrorList;

    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(staffList), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaffImage(staffId: number) {
    const urlValue: string = this.url + '/GetStaffImage?staffId=' + staffId;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaffGroupList(filter: any) {
    const urlValue: string = this.url + '/GetStaffGroupList';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  updateStaffGroupActiveStatus(groupId: number, inactive: boolean) {
    const urlValue: string = this.url + `/UpdateStaffGroupActiveStatus?groupId=${groupId}&inactive=${inactive}`;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertStaffGroup(staffGroup: StaffGroupModel, isEditMode: boolean) {
    let URLActionName: string = isEditMode ? '/UpdateStaffGroup' : '/InsertStaffGroup';
    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(staffGroup), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getStaffGroupData(groupId: number) {
    const urlValue: string = this.url + '/GetStaffGroupData?groupId=' + groupId;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }
}
