import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from 'src/app/content/orders-management/orders.service';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';

@Component({
  selector: 'app-assign-dialog',
  templateUrl: './assign-dialog.component.html',
  styleUrls: ['./assign-dialog.component.css']
})
export class AssignDialogComponent implements OnInit {

  form: FormGroup;

  staffList: StaffModel[] = [];

  loadingStaffList: boolean = false;

  selectedStaff: StaffModel;

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
     private coreSession: CoreSession,
     private ordersService: OrdersService) { }


  ngOnInit(): void {
    this.initForm();
    this.getStaffList();
  }

  initForm() {
    this.form = new FormGroup({
      assignedTo: new FormControl({ value: undefined, disabled: false }, Validators.required)
    });
  }

  getStaffList() {
    this.loadingStaffList = true;
    this.ordersService.getStaffList().subscribe(res => {
      //console.log(res, 'getStaffList');

      this.loadingStaffList = false;
      this.staffList = res.staffList;

      if(this.data.assignedTo != null && this.data.assignedTo != -1) {
        this.assignedToControl.setValue(this.data.assignedTo);
      }

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onSaveClicked() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.dialogRef.close({assignedTo: this.assignedToControl.value});
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get assignedToControl() {
    return this.form.get('assignedTo');
  }

}

