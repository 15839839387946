import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { AuthService } from 'src/app/core/auth/auth.service';
import { CoreSession } from 'src/app/core/core.session';
import { LanguagesComponent } from 'src/app/shared/components/languages/languages.component';
import { PortalMode } from 'src/app/shared/enum/portal-mode.enum';
import { HotelSolution, Solution } from 'src/app/shared/enum/solution.enum';
import { ProfileComponent } from '../profiles/profile/profile.component';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { Client } from 'src/app/shared/enum/client.enum';
import { StaffService } from '../data-definition/staff/staff.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';

@Component({
  selector: 'app-root-header',
  templateUrl: './root-header.component.html',
  styleUrls: ['./root-header.component.css']
})
export class RootHeaderComponent implements OnInit {

  lang = 'AR';
  userImage: any = '';
  pageTitle = '';
  email = '';
  direction: Direction = "ltr";

  loggedInStaffName: string = "";

  showLeroyalLogo: boolean = false;
  showHiltonLogo: boolean = false;
  showKasoticLogo: boolean = false;
  showIECLogo: boolean = false;
  studentPortalSelected: boolean = false;
  delMontePortalSelected: boolean = false;

  constructor(private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private coresession: CoreSession, private authService: AuthService,
    private staffService: StaffService) {
    // //console.log(this.coresession.currentUser.staffName);
    // console.log(this.router.url);

    // if(this.router.url.endsWith(ConstantURLs.studentRegisterationURL)){
    //   this.coresession.selectedPortalMode = PortalMode.Student.valueOf();
    // }else{
    //   this.coresession.selectedPortalMode = PortalMode.Admin.valueOf();
    // }

    this.studentPortalSelected = this.coresession.selectedPortalMode == PortalMode.Student.valueOf();
    this.delMontePortalSelected = this.coresession.selectedPortalMode == PortalMode.DelMonteAuthentic.valueOf()
      || this.coresession.selectedPortalMode == PortalMode.DelMonteFake.valueOf();

    this.loggedInStaffName = this.coresession.currentUser != null ? this.coresession.currentUser.staffName :
      this.studentPortalSelected ? 'Student' : 'Admin';

    this.direction = this.translate.currentLang.toLocaleLowerCase() == 'ar' ? 'rtl' : 'ltr';
    this.translate.onLangChange.subscribe(value => {
      this.lang = this.translate.currentLang.toUpperCase();
      this.coresession.changeDir();
      this.coresession.direction.subscribe(value => { this.direction = value })
    });
  }

  ngOnInit(): void {
    this.coresession.pageTitle.subscribe(value => { this.pageTitle = value; });
    this.coresession.email.subscribe(value => this.email = this.coresession.DecryptAES(value));
    this.coresession.userImage.subscribe(value => this.userImage = value);

    if (this.coresession.currentUser != null) {
      if (this.coresession.currentUser.email != null && this.coresession.currentUser.email != '')
        this.email = this.coresession.DecryptAES(this.coresession.currentUser.email);
      else
        this.email = this.direction == 'ltr' ? 'no email found' : 'لا يوجد بريد الكتروني';
      this.getStaffImage(this.coresession.currentUser.staffId);
    }

    this.showLeroyalLogo = this.coresession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.LeRoyal.valueOf();
    this.showHiltonLogo = this.coresession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.Hilton_Deadsea.valueOf();
    this.showKasoticLogo = this.coresession.selectedSolutionId === Solution.Kasotic.valueOf();
    this.showIECLogo = this.coresession.selectedSolutionId === Solution.IEC.valueOf();
  }

  getStaffImage(staffId: number) {
    this.staffService.getStaffImage(staffId).subscribe(response => {
      if (response.status != null && response.status >= 0) {

        this.userImage = this.coresession.DecryptAES(response.data);

      } else {
        // this.coresession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      // this.coresession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }


  openProfile(): any {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '600px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // //console.log(`Dialog result: ${result}`);
    });
  }

  logout(): any {
    this.authService.logout();
  }

  changeLanguage(): any {
    const dialogRef = this.dialog.open(LanguagesComponent, {
      width: '400px',
      height: '400px',
      data: { lang: this.translate.currentLang }
    });

    dialogRef.afterClosed().subscribe(result => {
      // //console.log(`Dialog result: ${result}`);
    });
  }
}
