import { Component, OnInit, ViewChild, Inject, ElementRef, AfterViewInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { CoreSession } from '../../core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { GlobalErrors } from 'src/app/shared/enum/globalErrors';
import { OperatorForLogin } from 'src/app/shared/model/operator/operator.model';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { ModalLoadingComponent } from 'src/app/shared/components/modal-loading/modal-loading.component';
import { ModalDialogComponent } from 'src/app/shared/modal-dialog/modal-dialog.component';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';
import { GlobalTheme } from 'src/app/core/global-theme';
import { ConstantStorage } from 'src/app/core/constants/constant-stroage';
import { LanguagesComponent } from 'src/app/shared/components/languages/languages.component';
import { MatDialog } from '@angular/material/dialog';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { DatePipe, formatDate } from '@angular/common';
import { PortalMode } from 'src/app/shared/enum/portal-mode.enum';
import { Client } from 'src/app/shared/enum/client.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  //#region [DECLARATIONS]

  public loginForm: FormGroup;
  public changePasswordForm: FormGroup;
  operator: OperatorForLogin = new OperatorForLogin();
  currentYear = '';
  @ViewChild('loadingModal', { static: true }) loadingModal: ModalLoadingComponent;
  @ViewChild("dialogModal", { static: true }) dialogModal: ModalDialogComponent;
  @ViewChild('userName') userName: ElementRef;

  langList: any[];
  currentLang = '';
  deviceInfo = null;
  private timeOutDelay = 25000;
  type1: string = 'password';
  type2: string = 'password';
  type3: string = 'password';
  eyeIcon: string = 'fa fa-eye';
  slashIcon: string = 'fa fa-eye-slash';
  showKeepMeLoggedInCheck: boolean = GlobalTheme.showKeepMeLoggedInCheck;
  showChangeLanguageBtn: boolean;
  lang = this.translate.currentLang.toUpperCase();
  isKasoticSelected: boolean = false;
  isIECSelected: boolean = false;
  isTaskSelected: boolean = false;

  //#endregion

  //#region [INITIALIZATION]

  constructor(
    private loginService: LoginService,
    public coreSession: CoreSession,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private sessionDataProvider: SessionDataProvider,
    public dialog: MatDialog,
    private datepipe: DatePipe) { }

  ngOnInit() {
    this.isKasoticSelected = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf();
    this.isIECSelected = this.coreSession.selectedSolutionId === Solution.IEC.valueOf()
      && CoreSession.selectedClient == Client.IEC_Jordan.valueOf();
    this.isTaskSelected = (this.coreSession.selectedSolutionId === Solution.IEC.valueOf() ||
      this.coreSession.selectedSolutionId === Solution.TaskManagement.valueOf())
      && CoreSession.selectedClient == Client.TaskManagement.valueOf();

    this.currentYear = (new Date()).getFullYear().toString();
    this.loginForm = new FormGroup(
      {
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
        keepMeLoggedIn: new FormControl(false)
      });
    // if (this.authService.isTokenExist() && this.authService.isAuthenticated()) {
    //   this.authService.navigateToHomeScreen();
    //   return;
    // }
    this.langList = this.translate.getLangs();
    this.currentLang = this.coreSession.selectedLanguageStr;
    this.langList = this.sessionDataProvider.getSessionLanguages();
    if (this.langList.length <= 1) {
      this.showChangeLanguageBtn = false;
    } else if (this.langList.length == 2) {
      this.showChangeLanguageBtn = GlobalTheme.changeLanguageFromLogin;
      this.lang = this.langList.find(lang => lang.code.toUpperCase() != this.translate.currentLang.toUpperCase()).code.toUpperCase();
    } else {
      this.lang = this.translate.currentLang.toUpperCase();
    }
  }
  ngAfterViewInit() {
    // if (!this.isChangePassword)
    //   this.userName.nativeElement.focus();
  }
  //#endregion

  //#region [METHODS]

  // initiateChangePassForm() {
  //   this.changePasswordForm = new FormGroup(
  //     {
  //       oldPassword: new FormControl('', Validators.required),
  //       newPassword: new FormControl('', Validators.required),
  //       confirmPassword: new FormControl('', Validators.required)
  //     });
  // }

  callLoginService() {
    if (this.coreSession.selectedSolutionId == Solution.IEC.valueOf()) {
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition((position) => {
      //     const longitude = position.coords.longitude;
      //     const latitude = position.coords.latitude;

      //     this.operator.gpsLng = longitude;
      //     this.operator.gpsLat = latitude;

      //     this.operator.clientId = CoreSession.selectedClient.valueOf();
      //     this.operator.isTestMode = this.coreSession.testMode;



      //   });
      // } else {
      //   console.log("No support for geolocation")
      // }

      this.loginService.login(this.operator).subscribe(result => {
        if (result.status >= 0) {
          switch (result.data) {
            case GlobalErrors.PasswordShouldBeChanged:
              this.coreSession.showWarning(this.translate.instant(ConstantMessages.LblWarningCaption), result.message);
              break;
            case GlobalErrors.LoggedInOtherSession:
              this.dialogModal.ShowMessage(this.translate.instant(result.message), DialogMode.YesNo, this.translate.instant(ConstantMessages.LblWarningCaption))
                .then(
                  (result: DialogResult) => {
                    if (result === DialogResult.Yes) {
                      this.operator.RemoveCurrentUserSession = true;
                      this.callLoginService();
                    }
                  }
                );
              break;
            case GlobalErrors.ClientNotLicensed:
            case GlobalErrors.LicenseExpired:
              this.coreSession.showWarning(this.translate.instant(ConstantMessages.LblWarningCaption), result.message);
              break;
            default:
              if (result.data.isNearToExpired)
                this.coreSession.showWarning(
                  this.translate.instant(ConstantMessages.LblWarningCaption),
                  result.message
                );

              result.data.operatorModel.staffCode = this.coreSession.DecryptAES(result.data.operatorModel.staffCode);

              result.data.operatorModel.staffName = this.coreSession.DecryptAES(result.data.operatorModel.staffName);

              this.sessionDataProvider.GeneralConfigurations =
                result.data.configurationsList;
              this.sessionDataProvider.DivisionConfigurations =
                result.data.ConfigurationsListOnDivisionLevel;
              // this.coreSession.showPrivacypolicy = result.data.showPrivacy;
              // this.coreSession.privacyText = result.data.privacyText;
              // this.coreSession.isPrivacyLoaded = true;
              //console.log(result.data);

              this.authService.login(
                result.data.operatorModel,
                result.data.key,
                result.data.generalData,
                result.data.menuList,
                result.data.addedList,
                this.keepMeLoggedInControl.value,
                result.data.licenseData,
              );
              break;
          }
        } else {
          this.coreSession.showError(this.translate.instant(ConstantMessages.LblErrorCaption), result.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.showError(this.translate.instant(ConstantMessages.LblErrorCaption), this.translate.instant(ConstantMessages.MsgErrorHappened));
      });
    } else {

      this.operator.clientId = CoreSession.selectedClient.valueOf();
      this.operator.isTestMode = this.coreSession.testMode;

      this.loginService.login(this.operator).subscribe(result => {
        if (result.status >= 0) {
          switch (result.data) {
            case GlobalErrors.PasswordShouldBeChanged:
              this.coreSession.showWarning(this.translate.instant(ConstantMessages.LblWarningCaption), result.message);
              break;
            case GlobalErrors.LoggedInOtherSession:
              this.dialogModal.ShowMessage(this.translate.instant(result.message), DialogMode.YesNo, this.translate.instant(ConstantMessages.LblWarningCaption))
                .then(
                  (result: DialogResult) => {
                    if (result === DialogResult.Yes) {
                      this.operator.RemoveCurrentUserSession = true;
                      this.callLoginService();
                    }
                  }
                );
              break;
            case GlobalErrors.ClientNotLicensed:
            case GlobalErrors.LicenseExpired:
              this.coreSession.showWarning(this.translate.instant(ConstantMessages.LblWarningCaption), result.message);
              break;
            default:
              if (result.data.isNearToExpired)
                this.coreSession.showWarning(
                  this.translate.instant(ConstantMessages.LblWarningCaption),
                  result.message
                );

              result.data.operatorModel.staffCode = this.coreSession.DecryptAES(result.data.operatorModel.staffCode);

              result.data.operatorModel.staffName = this.coreSession.DecryptAES(result.data.operatorModel.staffName);

              this.sessionDataProvider.GeneralConfigurations =
                result.data.configurationsList;
              this.sessionDataProvider.DivisionConfigurations =
                result.data.ConfigurationsListOnDivisionLevel;
              // this.coreSession.showPrivacypolicy = result.data.showPrivacy;
              // this.coreSession.privacyText = result.data.privacyText;
              // this.coreSession.isPrivacyLoaded = true;
              //console.log(result.data);

              this.authService.login(
                result.data.operatorModel,
                result.data.key,
                result.data.generalData,
                result.data.menuList,
                result.data.addedList,
                this.keepMeLoggedInControl.value,
                result.data.licenseData,
              );
              break;
          }
        } else {
          this.coreSession.showError(this.translate.instant(ConstantMessages.LblErrorCaption), result.message);
        }
      }, (error: HttpErrorResponse) => {
        this.coreSession.showError(this.translate.instant(ConstantMessages.LblErrorCaption), this.translate.instant(ConstantMessages.MsgErrorHappened));
      });

    }

  }

  login() {
    //debugger;
    const today = new Date();
    const endDate = new Date('2024-05-07');
    const warningDate = new Date('2024-03-01');

    const todayWithoutTime = new Date(today.getTime());
    const endDateWithoutTime = new Date(endDate.getTime());

    todayWithoutTime.setUTCHours(0, 0, 0, 0);
    endDateWithoutTime.setUTCHours(0, 0, 0, 0);

    //&& (todayWithoutTime < endDateWithoutTime || this.coreSession.selectedSolutionId != Solution.Inventory.valueOf())


    if (this.loginForm.valid) {
      if (todayWithoutTime >= warningDate) {
        this.coreSession.showExpiry(this.translate.instant("License Expiry Date"), this.translate.instant("License almost expired, contact INCUBE to renew your licence before " + endDate.toDateString()));
      }
      if (todayWithoutTime >= endDateWithoutTime) {
        this.coreSession.showExpiry(this.translate.instant("License Expired"), this.translate.instant("Contact INCUBE to renew your license"));
      } else {
        this.operator = new OperatorForLogin();
        this.operator.username = this.coreSession.EncryptAES(this.username.value);
        this.operator.password = this.coreSession.EncryptAES(this.password.value);
        this.operator.languageId = this.coreSession.selectedLanguageId;
        this.operator.deviceInfo = this.deviceInfo;
        this.callLoginService();
      }
    } else {
      this.coreSession.showError(this.translate.instant(ConstantMessages.LblErrorCaption), this.translate.instant(ConstantMessages.MsgInsertUserAndPass));
    }
  }

  showError(msg) {
    this.loginForm.reset();
    this.changePasswordForm.reset();
    this.username.setValue('');
    this.password.setValue('');
  }

  onTogglePass(type: any) {
    switch (type) {
      case 1:
        if (this.type1 == 'password')
          this.type1 = 'text';
        else
          this.type1 = 'password';
        break;
      case 2:
        if (this.type2 == 'password')
          this.type2 = 'text';
        else
          this.type2 = 'password';
        break;
      case 3:
        if (this.type3 == 'password')
          this.type3 = 'text';
        else
          this.type3 = 'password';
        break;
    }
  }

  changeLanguage(): any {

    if (this.langList.length == 2) {
      this.translate.use(this.lang.toLowerCase());
      window.location.reload();
    } else {
      this.dialog.open(LanguagesComponent, {
        width: '400px',
        height: '400px',
        data: { lang: this.translate.currentLang }
      });
    }

  }

  get username() {
    return this.loginForm.get('username');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get keepMeLoggedInControl() {
    return this.loginForm.get('keepMeLoggedIn');
  }
}

