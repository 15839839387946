import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({name: 'numberOfStockDigitsRounder'})
export class NumberOfStockDigitsRounder implements PipeTransform {

  constructor(){}
  number = 2;
  transform(value): any {
    if(value){
      return formatNumber(parseFloat(value.toFixed(this.number)) , 'en' , '1.' + this.number + '-' + this.number ) ;
    }else{
      return formatNumber( 0 , 'en' , '1.' + this.number + '-' + this.number  ) ;

    }
  }
}