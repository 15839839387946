import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { DateTimeModel } from 'src/app/shared/model/dateTimeModel/date-time.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HereMapService {

  httpOptions;
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Maps';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getStudentLocations(filter : any) {
    const urlValue: string = this.url + '/GetStudentLocations';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result.data;
      }
    ));
  }

  getVanRouteCustomers(employeeId: any, fromDate: DateTimeModel) {
    const urlValue: string = this.url + '/GetVanRouteCustomers';
    if (!fromDate) {
      fromDate = null;
    }
    let filter = {
      employeeId: parseInt(employeeId),
      fromDateBeforeParse: JSON.stringify(fromDate)
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getLngAndLat(employeeId: any, fromDate: DateTimeModel) {
    const urlValue: string = this.url + '/GetLngAndLat';
    let filter = {
      employeeId: employeeId,
      fromDateBeforeParse: JSON.stringify(fromDate)
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }
  getVanTrack() {
    const urlValue: string = this.url + '/getVanTrack';

    return this.http.get<ResponseModel>(urlValue + "?EmployeeID=-1&AllEmployees='true'&LastLocation='true'&TrackDate=''&TrackTimeFrom=''&TrackTimeTo=''&ShowInactive='false'").map(response => {
      return response.data;
    });
  }

  getVehicleEmployees(organizationId) {
    const urlValue: string = this.url + '/GetVehicleEmployees';
    return this.http.post<ResponseModel>(urlValue, organizationId).map(response => {
      return response.data;
    });
  }

  getEmployeeRouteDetails(employeeId: any, fromDate: DateTimeModel) {
    const urlValue: string = this.url + '/GetEmployeeRouteDetails';
    let filter = {
      employeeId: parseInt(employeeId),
      fromDateBeforeParse: JSON.stringify(fromDate)
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getEmployeeItemDetails(employeeId: any, fromDate: DateTimeModel) {
    const urlValue: string = this.url + '/GetEmployeeItemDetails';
    let filter = {
      employeeId: parseInt(employeeId),
      fromDateBeforeParse: JSON.stringify(fromDate)
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  getVanDetails(employeeId: any) {
    const urlValue: string = this.url + '/GetVanDetails';

    let filter = {
      employeeId: parseInt(employeeId),
    }
    return this.http.post<ResponseModel>(urlValue, filter).map(response => {
      return response.data;
    });
  }

  checkHereMapsAvailability() {
    const urlValue: string = this.url + '/CheckHereMapsAvailability';
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));

  }
}
