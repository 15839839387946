import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { CoreSession } from '../../core/core.session';
import { OperatorForLogin } from "src/app/shared/model/operator/operator.model";
import { ConstantStorage } from "src/app/core/constants/constant-stroage";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url = "";
  private httpOptions;
  languageId = 1;
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string, private coreSession: CoreSession) {
    this.url = baseUrl + "Login/";
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }

  login(user: OperatorForLogin) {
    this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
    const urlValue: string = this.url + "Login?languageId=" + this.languageId;;
    return this.http
      .post(urlValue, JSON.stringify(user), this.httpOptions)
      .pipe(map(response => {
        localStorage.setItem(ConstantStorage.LanguageId, this.coreSession.selectedLanguageId.toString());
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];

        return result;
      }));
  }

  logout(staffId: number) {
    const urlValue: string = this.url + "Logout";
    return this.http.post(urlValue, staffId, this.httpOptions).pipe(map(response => {
      let result = new ResponseModel();
      result.data = response["data"];
      result.message = response["message"];
      result.status = response["status"];
      return result;
    }));
  }

  setLanguageId(languageId: number) {
    const urlValue: string =
      this.url + "SetLanguageId?languageId=" + languageId;
    return this.http.get(urlValue);
  }

  getConfigurationValue(filter: any) {
    const urlValue = this.url + "GetConfigurationValue";
    return this.http
      .get<ResponseModel>(urlValue + "?" + filter)
      .pipe(map(response => {
        return response;
      }));
  }
  changePassword(oldPassword: string, newPassword: string, userName: string) {
    this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
    const urlValue: string = this.url + "ChangePassword?languageId=" + this.languageId;;
    let operatorObj = {
      'oldPassword': oldPassword,
      'newPassword': newPassword,
      'userName': userName,

    };

    return this.http
      .post(urlValue, JSON.stringify(operatorObj), this.httpOptions)
      .pipe(map(response => {
        const result = new ResponseModel();
        result.data = response["data"];
        result.message = response["message"];
        result.status = response["status"];
        return result;
      }));
  }

  checkIfAuthenticated(batchNumber: string) {
    const urlValue: string = this.url + 'CheckIfAuthenticated?batchNumber=' + batchNumber;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

}
