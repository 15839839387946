import { TextLanguage } from "../general/textLanguage.model";

export class ItemTypeModel {
    itemTypeId: number;
    itemTypeName: string;
    isParent: boolean;
    parentId: number;

    descriptions: TextLanguage[];

    itemTypeCode: string;

    constructor() {
        this.itemTypeId = -1;
        this.itemTypeName = '';
        this.isParent = false;
        this.parentId = -1;

        this.descriptions = [];
        this.itemTypeCode = '';
    }
}