import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { ItemModel } from 'src/app/shared/model/item-models/item.model';
import { OperationDetailsModel } from 'src/app/shared/model/warehouse-operation-models/operation-details.model';
import { OperationMainModel } from 'src/app/shared/model/warehouse-operation-models/operation-main.model';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { ItemDetailsModel } from 'src/app/shared/model/item-models/item-details.model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { CalculatorDialogComponent } from '../../../shared/components/calculator-dialog/calculator-dialog.component';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { ArmoryOperationsService } from 'src/app/content/armory-operations/armory-operations.service';
import { SupplierModel } from '../../model/supplier-models/supplier.model';
import { Client } from '../../enum/client.enum';

@Component({
  selector: 'app-operation-entry',
  templateUrl: './operation-entry.component.html',
  styleUrls: ['./operation-entry.component.css']
})
export class OperationEntryComponent implements OnInit {

  // @Input() calcBtnClicked: Observable<void>;
  @Input() events: Observable<void>;
  @Input() selectedOpObj: OperationMainModel;
  @Input() isViewMode: boolean = false;
  @Input() operationTypeId: number;
  @Output() onSaveCompleted = new EventEmitter();
  @Output() showSerialsSlider = new EventEmitter<boolean>();
  saveSubject: Subject<void> = new Subject<void>();
  showItemSlider: boolean = false;

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_CreationDate',
    formControlName: 'creationDate',
    isCalendarOnly: true
  };

  showSerialSlider: boolean = false;

  saveBtnSubscription: any;
  // calcBtnSubscription: any;
  form: FormGroup;

  warehouseList: LocationModel[] = [];
  transactionId: string = '';
  suppliersList: SupplierModel[] = [];

  itemsList: ItemModel[] = [];
  reasonsList: any[] = [];
  listsLoading: boolean = false;

  selectedItem: ItemModel;
  allItemSerials: any[] = [];

  armoryOperationObj: OperationMainModel;

  weaponsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  accessoriesDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  ammunitionsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  allItemsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  allItemsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [
      {
        title: 'Desc_Add',
        operation: RowOperation.add,
        icon: 'fa fa-plus-square',
        color: 'black',
        showHideOptionPerLine: true,
        showWhenKeyValueTrue: true,
        controlKeyName: 'isSerialized'
      },
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: false,
        width: "25%"
      },
      {
        title: "Desc_ItemName",
        key: "itemName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_UOM",
        key: "itemUOM",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Qty",
        key: "quantity",
        isSortable: false,
        width: "25%",
        isEditable: true,
        numberOfDigits: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'isSerialized'
      },
    ]
  };

  weaponsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [
      {
        title: 'Desc_Add',
        operation: RowOperation.add,
        icon: 'fa fa-plus-square',
        color: 'black',
        showHideOptionPerLine: true,
        showWhenKeyValueTrue: true,
        controlKeyName: 'isSerialized'
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: false,
        width: "30%"
      },
      {
        title: "Desc_ItemName",
        key: "itemName",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Desc_Qty",
        key: "quantity",
        isSortable: false,
        width: "40%",
        isEditable: true,
        numberOfDigits: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'isSerialized'
      }
    ]
  };
  accessoriesTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [
      {
        title: 'Desc_Barcodes',
        operation: RowOperation.View,
        icon: 'fa fa-barcode',
        color: 'black'
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Desc_ItemName",
        key: "itemName",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Desc_Qty",
        key: "quantity",
        isSortable: false,
        width: "40%",
        isEditable: true,
        numberOfDigits: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'isSerialized'
      }
    ]
  };
  ammunitionsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Desc_ItemName",
        key: "itemName",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Desc_Qty",
        key: "quantity",
        isSortable: false,
        width: "40%",
        isEditable: true,
        numberOfDigits: true,
        disableInputBasedOnProperty: true,
        disableInputPropertyName: 'isSerialized'
      }
    ]
  };

  isKasotcSelected: boolean = false;
  citSelected: boolean = false;
  invSelected: boolean = false;
  invInCubeSelected: boolean = false;
  traceabilitySelected: boolean = false;

  selectedBarcode: string = '';
  selectedIndex = -1;

  constructor(private armoryOperationsService: ArmoryOperationsService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isKasotcSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf();
    this.citSelected = this.coreSession.selectedSolutionId == Solution.CIT.valueOf();
    this.invSelected = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient != Client.Inventory_INCUBE.valueOf();
    this.invInCubeSelected = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient == Client.Inventory_INCUBE.valueOf();

    this.traceabilitySelected = this.coreSession.selectedSolutionId == Solution.Traceability.valueOf();

    if (this.isViewMode) {
      this.allItemsTableData.columns[3].isEditable = false;
      this.weaponsTableData.columns[2].isEditable = false;
      this.accessoriesTableData.columns[2].isEditable = false;
      this.ammunitionsTableData.columns[2].isEditable = false;

      this.weaponsTableData.rowOperations = [
        {
          title: 'Desc_Serials',
          operation: RowOperation.View,
          icon: 'fa fa-barcode',
          color: 'black',
          showWhenKeyValueTrue: true,
          controlKeyName: 'isSerialized'
        }
      ];
      this.allItemsTableData.rowOperations = [
        {
          title: 'Desc_Serials',
          operation: RowOperation.View,
          icon: 'fa fa-barcode',
          color: 'black',
          showWhenKeyValueTrue: true,
          controlKeyName: 'isSerialized',
          showHideOptionPerLine: true,
        },
        {
          title: 'Desc_ConnectWithItem',
          operation: RowOperation.Clone,
          icon: 'fa fa-external-link',
          color: 'blue',
          showHideOptionPerLine: true,
          showWhenKeyValueTrue: true,
          controlKeyName: 'notAvailable'
        }
      ];
    }
    else {
      this.ammunitionsTableData.rowOperations = [
        {
          title: 'Desc_WeightToQty',
          operation: RowOperation.Configure,
          icon: 'fa fa-calculator',
          color: 'black'
        }
      ]
    }

    if (this.isViewMode || this.operationTypeId == 1) {

      this.accessoriesTableData.rowOperations = [
        {
          title: 'Desc_Barcodes',
          operation: RowOperation.View,
          icon: 'fa fa-barcode',
          color: 'black'
        }
      ];

    } else if (this.operationTypeId == 2 && !this.isViewMode) {
      this.accessoriesTableData.rowOperations = [
        {
          title: 'Desc_Add',
          operation: RowOperation.add,
          icon: 'fa fa-plus-square',
          color: 'black'
        }
      ];

      this.weaponsTableData.columns = [
        {
          title: "#",
          key: "itemId",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_ItemName",
          key: "itemName",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_Qty",
          key: "quantity",
          isSortable: false,
          width: "33%",
          isEditable: true,
          numberOfDigits: true,
          disableInputBasedOnProperty: true,
          disableInputPropertyName: 'isSerialized'
        },
        {
          title: "Desc_AvailableQty",
          key: "availableQty",
          isSortable: false,
          width: "33%",
          numberOfDigits: true
        }
      ];

      this.accessoriesTableData.columns = [
        {
          title: "#",
          key: "itemId",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_ItemName",
          key: "itemName",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_Qty",
          key: "quantity",
          isSortable: false,
          width: "33%",
          isEditable: true,
          numberOfDigits: true,
          disableInputBasedOnProperty: true,
          disableInputPropertyName: 'isSerialized'
        },
        {
          title: "Desc_AvailableQty",
          key: "availableQty",
          isSortable: false,
          width: "33%",
          numberOfDigits: true
        }
      ];

      this.ammunitionsTableData.columns = [
        {
          title: "#",
          key: "itemId",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_ItemName",
          key: "itemName",
          isSortable: true,
          width: "17%"
        },
        {
          title: "Desc_Qty",
          key: "quantity",
          isSortable: false,
          width: "33%",
          isEditable: true,
          numberOfDigits: true,
          disableInputBasedOnProperty: true,
          disableInputPropertyName: 'isSerialized'
        },
        {
          title: "Desc_AvailableQty",
          key: "availableQty",
          isSortable: false,
          width: "33%",
          numberOfDigits: true
        }
      ];

      this.allItemsTableData.columns = [
        {
          title: "#",
          key: "itemId",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_ItemName",
          key: "itemName",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Qty",
          key: "quantity",
          isSortable: false,
          width: "20%",
          isEditable: true,
          numberOfDigits: true,
          disableInputBasedOnProperty: true,
          disableInputPropertyName: 'isSerialized'
        },
        {
          title: "Desc_UOM",
          key: "itemUOM",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_AvailableQty",
          key: "availableQty",
          isSortable: false,
          width: "20%",
          numberOfDigits: true
        }
      ];
    }

    this.initForm();
    this.getRequiredLists();
    this.subscribeClickEvents();
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
    // this.calcBtnSubscription.unsubscribe();
  }

  onAmmunitionRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.Configure: {
        this.openCalculator(event.object);
        break;
      }
    }
  }

  initForm() {

    const transactionId = this.isViewMode ? this.selectedOpObj.transactionId : undefined;
    const warehouseId = this.isViewMode ? this.selectedOpObj.warehouseId : undefined;

    this.form = new FormGroup({
      transactionId: new FormControl({ value: transactionId, disabled: true }, Validators.required),
      warehouseId: new FormControl({ value: warehouseId, disabled: this.isKasotcSelected || this.isViewMode }, Validators.required),
      createdBy: new FormControl({ value: undefined, disabled: true }),
      creationDate: new FormControl({ value: undefined, disabled: true }),
      reason: new FormControl({ value: undefined, disabled: this.isViewMode }),
      supplier: new FormControl({ value: undefined, disabled: this.isViewMode }, []),
      deliveryMethod: new FormControl({ value: undefined, disabled: this.isViewMode })
    });

  }

  onWeaponRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.add:
        this.onAddSerialClicked(event.object);
        break;
      case RowOperation.View:
        this.onViewSerialsClicked(event.object);
        break;
    }
  }

  onItemRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.add:
        this.onAddSerialClicked(event.object);
        break;
      case RowOperation.View:
        this.onViewSerialsClicked(event.object);
        break;
      case RowOperation.Clone:
        this.onConnectItemClicked(event);
        break;
    }
  }

  onAddSerialClicked(object: any) {
    this.selectedItem = object;
    this.showSerialSlider = true;
    this.showSerialsSlider.emit(true);
  }
  onViewSerialsClicked(object: any) {
    this.selectedItem = object;
    this.showSerialSlider = true;
    this.showSerialsSlider.emit(true);
  }
  closeSerialSlider() {
    this.selectedItem = null;
    this.showSerialSlider = false;
    this.showSerialsSlider.emit(false);
  }

  onAccessoryRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.onViewSerialsClicked(event.object);
        break;
      case RowOperation.add:
        this.onAddSerialClicked(event.object);
        break;
    }
  }

  onLocationSelected() {
    //debugger;
    this.allItemSerials = this.allItemSerials.map(item => {
      return {
        ...item,
        isChecked: false
      }
    });
    if (this.warehouseIdControl.value != null) {
      this.allItemsDataSource.data = this.itemsList.slice().filter(i => i.locationId == this.warehouseIdControl.value);
      this.allItemsDataSource.pageData = this.allItemsDataSource.data;
      this.allItemsDataSource.totalItems = this.allItemsDataSource.data.length;
    }
  }

  getRequiredLists() {
    this.listsLoading = true;
    this.armoryOperationsService.getRequiredLists(this.operationTypeId).subscribe(res => {
      //console.log(res, 'getRequiredLists');
      this.listsLoading = false;
      this.warehouseList = [];
      if (this.isKasotcSelected)
        this.warehouseList.push(res.armoryList && res.armoryList.length > 0 ? res.armoryList[0] : []);
      else {
        this.warehouseList = res.armoryList ?? [];
      }
      this.itemsList = res.itemsList;
      this.reasonsList = res.reasonsList;
      this.suppliersList = res.suppliersList ?? [];

      if (this.isViewMode) {
        this.getOperationData();
      } else {
        this.warehouseIdControl.setValue(res.armoryList[0]?.locationId);
        if (this.operationTypeId == 1) {
          let items = this.itemsList.slice().filter(i => i.locationId == this.warehouseIdControl.value);
          this.allItemsDataSource.data = items.slice().map(d => {
            return {
              ...d,
              notAvailable: d.notAvailable = d.itemId == -1,
            };
          });
          this.allItemsDataSource.pageData = this.allItemsDataSource.data;
          this.allItemsDataSource.totalItems = this.allItemsDataSource.data.length;
        } else {
          if (this.warehouseIdControl.value != null) {
            this.allItemsDataSource.data = this.itemsList.slice().filter(i => i.locationId == this.warehouseIdControl.value);
            this.allItemsDataSource.pageData = this.allItemsDataSource.data;
            this.allItemsDataSource.totalItems = this.allItemsDataSource.data.length;
          }
        }

        if (this.isKasotcSelected) {

          this.weaponsDataSource.data = this.itemsList.slice().filter(item => item.itemTypeId == 1).slice();
          this.weaponsDataSource.pageData = this.weaponsDataSource.data;
          this.weaponsDataSource.totalItems = this.weaponsDataSource.data.length;

          this.accessoriesDataSource.data = this.itemsList.slice().filter(item => item.itemTypeId == 2).slice();
          this.accessoriesDataSource.pageData = this.accessoriesDataSource.data;
          this.accessoriesDataSource.totalItems = this.accessoriesDataSource.data.length;

          this.ammunitionsDataSource.data = this.itemsList.slice().filter(item => item.itemTypeId == 3).slice();
          this.ammunitionsDataSource.pageData = this.ammunitionsDataSource.data;
          this.ammunitionsDataSource.totalItems = this.ammunitionsDataSource.data.length;

        }
        this.allItemSerials = res.serialsList;
      }

    },
      (_error: HttpErrorResponse) => {
        this.listsLoading = false;
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  getOperationData() {
    this.coreSession.ModalLoading.Show();
    this.armoryOperationsService.getOperationData(this.selectedOpObj.transactionId, this.selectedOpObj.operationStatusId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.selectedOpObj = response.data;

        //console.log(this.selectedOpObj, 'selectedOpObj');

        this.supplierControl.setValue(this.selectedOpObj.supplierId != -1 ? this.selectedOpObj.supplierId : undefined);
        this.deliveryMethodControl.setValue(this.selectedOpObj.deliveryMethod != '' ? this.selectedOpObj.deliveryMethod : undefined);

        this.createdByControl.setValue(this.selectedOpObj.createdByName);
        this.creationDateControl.setValue(this.selectedOpObj.creationDateModel.date);
        this.reasonControl.setValue(this.selectedOpObj.reasonId != null && this.selectedOpObj.reasonId != -1 ? this.selectedOpObj.reasonId : undefined);

        this.allItemSerials = this.selectedOpObj.itemDetails.slice();

        this.allItemsDataSource.data = this.selectedOpObj.operationDetails.slice().map(d => {
          return {
            ...d,
            notAvailable: d.notAvailable = d.itemId == -1,
          };
        });
        this.allItemsDataSource.pageData = this.allItemsDataSource.data;
        this.allItemsDataSource.totalItems = this.allItemsDataSource.data.length;

        this.weaponsDataSource.data = this.selectedOpObj.operationDetails.slice().filter(d => d.itemTypeId == 1).slice();
        this.weaponsDataSource.pageData = this.weaponsDataSource.data;
        this.weaponsDataSource.totalItems = this.weaponsDataSource.data.length;

        this.accessoriesDataSource.data = this.selectedOpObj.operationDetails.slice().filter(d => d.itemTypeId == 2).slice();
        this.accessoriesDataSource.pageData = this.accessoriesDataSource.data;
        this.accessoriesDataSource.totalItems = this.accessoriesDataSource.data.length;

        this.ammunitionsDataSource.data = this.selectedOpObj.operationDetails.slice().filter((d, i, arr) => d.itemTypeId == 3).slice();
        this.ammunitionsDataSource.pageData = this.ammunitionsDataSource.data;
        this.ammunitionsDataSource.totalItems = this.ammunitionsDataSource.data.length;

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onValueChanged(event: any) {

    //console.log(event, 'event');

    if (event.key == 'quantity' && this.operationTypeId == 2 && event.object.quantity > event.object.availableQty) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgNoEnoughQuantity));
    }

    if (this.isKasotcSelected) {
      if (event.key == 'quantity' && this.operationTypeId == 1 && event.object.itemTypeId == 2) {//Accessory

        let diff: number = event.object.quantity - this.allItemSerials.filter(item => item.itemId == event.object.itemId).length;

        let item: ItemModel;

        if (diff > 0) {

          for (let i = 0; i < diff; i++) {
            item = new ItemModel();
            item.itemId = event.object.itemId;
            item.itemTypeId = event.object.itemTypeId;
            item.isSerialized = true;
            item.serialNumber = '';
            item.quantity = 1;
            item.barcode = this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits);

            this.allItemSerials.push(item);
          }

        } else if (diff < 0) {

          const index = this.allItemSerials.findIndex(item => item.itemId == event.object.itemId);

          this.allItemSerials.splice(index, Math.abs(diff));

        }
      }
    }

  }

  onSaveSerial() {
    this.saveSubject.next();
  }
  onSaveSerialCompleted(newSerialsList: any[]) {
    //console.log(newSerialsList, 'newSerialsList');

    if (this.operationTypeId == 1) {
      this.allItemSerials.splice(this.allItemSerials.findIndex(s => s.itemId == this.selectedItem.itemId),
        this.allItemSerials.filter(s => s.itemId == this.selectedItem.itemId).length);
      this.allItemSerials.push(...newSerialsList);
    }
    else
      this.allItemSerials = this.allItemSerials.map(item => {
        return {
          ...item,
          isChecked: item.isChecked = newSerialsList.findIndex(s => s.barcode == item.barcode) > -1 ? newSerialsList.find(s => s.barcode == item.barcode).isChecked : item.isChecked
        }
      });

    if (this.isKasotcSelected) {
      switch (this.selectedItem.itemTypeId) {
        case 1:
          this.weaponsDataSource.data.map(w => {
            if (w.itemId == this.selectedItem.itemId)
              return {
                ...w,
                quantity: w.quantity = this.operationTypeId == 1 ? newSerialsList.length : newSerialsList.filter(s => s.isChecked == true).length
              }
            else
              return w;

          });
          break;
        case 2:
          this.accessoriesDataSource.data.map(a => {
            if (a.itemId == this.selectedItem.itemId)
              return {
                ...a,
                quantity: a.quantity = newSerialsList.filter(s => s.isChecked == true).length
              }
            else
              return a;

          });
          break;
      }
    } else {
      this.allItemsDataSource.data.map(item => {
        if (item.itemId == this.selectedItem.itemId)
          return {
            ...item,
            quantity: item.quantity = this.operationTypeId == 1 ? newSerialsList.length : newSerialsList.filter(s => s.isChecked == true).length
          }
        else
          return item;

      });
    }

    this.closeSerialSlider();
  }

  onConnectItemClicked(event: any) {
    this.selectedBarcode = event.object.barcode;
    this.selectedIndex = event.index;
    this.showItemSlider = true;
    this.showSerialsSlider.emit(true);
  }

  closeItemSlider() {
    this.selectedBarcode = '';
    this.selectedIndex = -1;
    this.showItemSlider = false;
    this.showSerialsSlider.emit(false);
  }

  onSaveItem() {
    this.saveSubject.next();
  }
  onSaveItemCompleted(item: any) {
    this.coreSession.ModalLoading.Show();
    this.armoryOperationsService.updateItemId(item).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
        this.allItemsDataSource.data[this.selectedIndex].itemId = item.itemId;
        this.allItemsDataSource.data[this.selectedIndex].itemName = item.itemName;
        this.allItemsDataSource.data[this.selectedIndex].notAvailable = false;
        this.closeItemSlider();
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }


  subscribeClickEvents() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });

    // this.calcBtnSubscription = this.calcBtnClicked.subscribe(() => {
    //   this.openCalculator();
    // });
  }

  openCalculator(obj: any) {
    const dialogRef = this.dialog.open(CalculatorDialogComponent, {
      width: '330px',
      height: '410px',
      data: {
        ammunitionType: obj
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result)
        this.updateAmmunition(result);
    });
  }

  updateAmmunition(obj) {
    const indx = this.ammunitionsDataSource.data.findIndex(item => item.itemId == obj.itemId);
    this.ammunitionsDataSource.data[indx] = obj;
    this.onValueChanged({ object: obj, key: 'quantity' });
  }

  prepareObj() {

    this.armoryOperationObj = new OperationMainModel();

    this.armoryOperationObj.transactionId = '';
    this.armoryOperationObj.warehouseOperationTypeId = this.operationTypeId;
    this.armoryOperationObj.warehouseId = this.warehouseIdControl.value;
    this.armoryOperationObj.operationStatusId = this.isKasotcSelected ? 1 : 2;
    this.armoryOperationObj.reasonId = this.reasonControl.value != null ? this.reasonControl.value : -1;

    let itemDetails = this.operationTypeId == 1
      ? this.allItemSerials.slice().map(item => new ItemDetailsModel(item)).slice()
      : this.allItemSerials.slice().filter(item => item.isChecked == true).map(item => new ItemDetailsModel(item)).slice();

    itemDetails.forEach(d => {
      d.currentStatusId = this.operationTypeId == 1 ? 1 : 5;
      d.oldStatusId = this.operationTypeId == 1 ? -1 : this.allItemSerials.find(i => i.serialNumber == d.serialNumber).currentStatusId;
    });

    this.armoryOperationObj.itemDetails = itemDetails.slice();

    let detailsList: OperationDetailsModel[] = [];
    if (this.isKasotcSelected) {
      let weaponList = this.weaponsDataSource.data.slice().filter(i => +i.quantity != null && +i.quantity != 0).slice().map(item => new OperationDetailsModel(item));
      let ammunitionList = this.ammunitionsDataSource.data.slice().filter(i => +i.quantity != null && +i.quantity != 0).slice().map(item => new OperationDetailsModel(item));
      let accessoryList = this.accessoriesDataSource.data.slice().filter(i => +i.quantity != null && +i.quantity != 0).slice().map(item => new OperationDetailsModel(item));

      detailsList.push(...weaponList, ...ammunitionList, ...accessoryList);
    } else {
      let itemsList = this.allItemsDataSource.data.slice().filter(i => +i.quantity != null && +i.quantity != 0).slice().map(item => {
        let d = new OperationDetailsModel(item);
        if (item.uomConversion != null && item.uomConversion > 0) {
          d.quantity = d.quantity * item.uomConversion;
        }
        return d;
      });
      detailsList.push(...itemsList);
    }

    detailsList.map(d => {
      return {
        ...d,
        warehouseId: d.warehouseId = this.warehouseIdControl.value
      }
    });

    this.armoryOperationObj.operationDetails = detailsList.slice();

    this.armoryOperationObj.creationDateModel = {
      date: new Date().toISOString(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      hour: new Date().getHours(),
      minute: new Date().getMinutes()
    };

    this.armoryOperationObj.supplierId = this.supplierControl.value ?? -1;
    this.armoryOperationObj.deliveryMethod = this.deliveryMethodControl.value ?? '';
  }

  onSaveClicked() {
    //console.log('SaveOperationClicked');

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    if (this.operationTypeId == 2) {

      let id1 = this.weaponsDataSource.data.findIndex(w => +w.quantity > +w.availableQty);
      let id2 = this.accessoriesDataSource.data.findIndex(a => +a.quantity > +a.availableQty);
      let id3 = this.ammunitionsDataSource.data.findIndex(a => +a.quantity > +a.availableQty);
      let id4 = this.allItemsDataSource.data.findIndex(a => +a.quantity > +a.availableQty);

      if (id1 > -1 || id2 > -1 || id3 > -1 || id4 > -1) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgNoEnoughQuantity));
        return;
      }

    }

    this.prepareObj();

    if (this.armoryOperationObj != null) {
      this.coreSession.ModalLoading.Show();
      //console.log(this.armoryOperationObj, "this.armoryOperationObj");

      this.armoryOperationsService.insertArmoryOperation(this.armoryOperationObj).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data > -1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.onSaveCompleted.emit();
          } else {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  //Form Getters
  get transactionIdControl() {
    return this.form.get('transactionId');
  }
  get warehouseIdControl() {
    return this.form.get('warehouseId');
  }
  get createdByControl() {
    return this.form.get('createdBy');
  }
  get creationDateControl() {
    return this.form.get('creationDate');
  }
  get reasonControl() {
    return this.form.get('reason');
  }
  get supplierControl() {
    return this.form.get('supplier');
  }
  get deliveryMethodControl() {
    return this.form.get('deliveryMethod');
  }
}
