import { TextLanguage } from "../general/textLanguage.model";
import { ContactPersonModel } from './contact-person.model';

export class SupplierModel {
    supplierId: number;
    supplierName: string;
    supplierCode: string;
    country: string;
    city: string;
    address: string;
    inactive: boolean;
    contacts: ContactPersonModel[];

    descriptions: TextLanguage[];

    constructor() {
        this.supplierId = -1;
        this.supplierName = '';
        this.supplierCode = '';
        this.country = '';
        this.city = '';
        this.address = '';
        this.inactive = false;
        this.contacts = [];

        this.descriptions = [];
    }
}