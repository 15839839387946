import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { DynamicFormsModel } from 'src/app/shared/model/dynamic-forms-models/dynamic-forms.model';
import { DynamicFormsSectionsModel } from 'src/app/shared/model/dynamic-forms-models/dynamic-forms-sections.model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { DynamicFormHistoryService } from '../dynamic-form-history.service';
import { DynamicFormEntryType } from 'src/app/shared/enum/dynamicFormEntryType';
import { Direction } from '@angular/cdk/bidi';


@Component({
  selector: 'app-dynamic-form-inspection-detail',
  templateUrl: './dynamic-form-inspection-detail.component.html',
  styleUrls: ['./dynamic-form-inspection-detail.component.css']
})
export class DynamicFormInspectionDetailComponent implements OnInit {
  @Input() selectedDynamicFormInspection: DynamicFormsModel;
  dynamicFormInspection: DynamicFormsModel;
  dynamicFormInspectionSections: DynamicFormsSectionsModel[];

  dynamicFormMediaEntryType: number = DynamicFormEntryType.Media.valueOf();
  dynamicFormMediaImageEntryType: number = DynamicFormEntryType.Image.valueOf();

  dynamicFormDateAndTimeEntryType: number = DynamicFormEntryType.DateAndTime.valueOf();

  dynamicFormDateEntrySubType: number = DynamicFormEntryType.Date.valueOf();
  dynamicFormTimeEntrySubType: number = DynamicFormEntryType.Time.valueOf();
  dynamicFormDateTimeEntrySubType: number = DynamicFormEntryType.DateTime.valueOf();

  elements: any = [
    {id: 1, first: 'Mark', last: 'Otto', handle: '@mdo'},
    {id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat'},
    {id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter'},
  ];

  headElements = ['ID', 'First', 'Last', 'Handle'];
  selectedLanguage: string = "en";
  direction: Direction = "rtl";

  constructor(private coreSession: CoreSession,
    private dynamicFormHistoryService: DynamicFormHistoryService,
    private translateService: TranslateService) { 
      this.selectedLanguage = this.translateService.currentLang.toLocaleLowerCase(); 
      this.direction = this.selectedLanguage == 'en' ? 'ltr' : 'rtl';
    }

  ngOnInit() {
    this.populateDynamicFormsInspectionDetail();

    //console.log(this.dynamicFormMediaEntryType, "this.dynamicFormMediaEntryType");
    //console.log(this.dynamicFormMediaImageEntryType, "this.dynamicFormMediaImageEntryType");
  }

  populateDynamicFormsInspectionDetail() {
    this.coreSession.ModalLoading.Show();
    //console.log(this.selectedDynamicFormInspection, "this.selectedDynamicFormInspection");
    //console.log(this.selectedDynamicFormInspection.answerId, "this.selectedDynamicFormInspection.answerId");

    this.dynamicFormHistoryService.getDynamicFormInspectionDetail(this.selectedDynamicFormInspection.answerId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      // console.log(response);
      this.dynamicFormInspection = response.data;
      // console.log(this.dynamicFormInspection.sections, "SECTIONS");


      if (response.status != null && response.status >= 0) {
        if (!response.data) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
        this.dynamicFormInspection = response.data;
        // console.log('gg');
        
        this.dynamicFormInspectionSections = this.dynamicFormInspection.sections;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }
}
