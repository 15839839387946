import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { TextLanguage } from "../general/textLanguage.model";
import { SerialModel } from "./serial-model";

export class InvoiceModel {
    invoiceId: string;
    invoiceDateModel: DateTimeModel;
    clientId: number;
    createdBy: number;
    modifiedBy: number;
    remainingAmount: number;
    netTotal: number;
    tax: number;
    discount: number;
    quantity: number;
    invoiceTypeId: number;
    invoiceTypeDescription: string;
    clientDescription: string;
    staffDescription: string;
    isInvoiceVoided: boolean;
    staffId: number;
    status: number;
    descriptions: TextLanguage[];
    serialInvoice: SerialModel;
    assignedTo: number;
    grossTotal: number;

    constructor() {
        this.invoiceId = "";
        this.invoiceDateModel = new DateTimeModel();
        this.clientId = -1;
        this.createdBy = -1;
        this.modifiedBy = -1;
        this.remainingAmount = 0;
        this.netTotal = 0;
        this.tax = -1.0;
        this.discount = -1.0;
        this.quantity = -1.0;
        this.invoiceTypeId = -1;
        this.invoiceTypeDescription = "";
        this.clientDescription = "";
        this.staffDescription = "";
        this.isInvoiceVoided = false;
        this.staffId = -1;
        this.status = -1;
        this.descriptions = [];
        this.serialInvoice = null;
        this.assignedTo = -1;
        this.grossTotal = 0;
    }

}
