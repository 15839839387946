import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { ClientOperationModel } from 'src/app/shared/model/client-models/client-operation-model';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClientPromisesModel } from 'src/app/shared/model/client-models/client-promise-model';
import { ClientNotesModel } from 'src/app/shared/model/client-models/client-notes-model';
import { ClientsDynamicDataModel } from 'src/app/shared/model/client-models/client-dynamic-data-model';

@Injectable({
  providedIn: 'root'
})
export class ClientOperationService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'ClientOperation';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  fillClientPromises() {
    let clientPromisesDataModel: ClientPromisesModel[];
    clientPromisesDataModel = [
      {
        clientId: 1,
        promiseId: 1,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '22/4/2021',
        promiseDescription: 'Promise 1'
      },
      {
        clientId: 1,
        promiseId: 2,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '25/4/2021',
        promiseDescription: 'Promise 2'
      },
      {
        clientId: 1,
        promiseId: 3,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '26/4/2021',
        promiseDescription: 'Promise 3'
      },
      {
        clientId: 1,
        promiseId: 4,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '26/4/2021',
        promiseDescription: 'Promise 4'
      },
      {
        clientId: 1,
        promiseId: 5,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '27/4/2021',
        promiseDescription: 'Promise 5'
      },
      {
        clientId: 1,
        promiseId: 6,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '29/4/2021',
        promiseDescription: 'Promise 6'
      },
      {
        clientId: 1,
        promiseId: 7,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '30/4/2021',
        promiseDescription: 'Promise 7'
      },
      {
        clientId: 1,
        promiseId: 8,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '30/4/2021',
        promiseDescription: 'Promise 8'
      },
      {
        clientId: 1,
        promiseId: 9,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '1/5/2021',
        promiseDescription: 'Promise 9'
      },
      {
        clientId: 1,
        promiseId: 10,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '2/5/2021',
        promiseDescription: 'Promise 10'
      },
      {
        clientId: 1,
        promiseId: 11,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '3/5/2021',
        promiseDescription: 'Promise 11'
      }
    ];

    return clientPromisesDataModel;
  }

  fillClientNotes() {
    let clientNotesDataModel: ClientNotesModel[];
    clientNotesDataModel = [
      {
        clientId: 1,
        noteId: 1,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '22/4/2021',
        noteDescription: 'Note 1'
      },
      {
        clientId: 1,
        noteId: 2,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '25/4/2021',
        noteDescription: 'Note 2'
      },
      {
        clientId: 1,
        noteId: 3,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '26/4/2021',
        noteDescription: 'Note 3'
      },
      {
        clientId: 1,
        noteId: 4,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '26/4/2021',
        noteDescription: 'Note 4'
      },
      {
        clientId: 1,
        noteId: 5,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '27/4/2021',
        noteDescription: 'Note 5'
      },
      {
        clientId: 1,
        noteId: 6,
        createdBy: 1,
        createdByVal: 'Ahmad',
        creationDate: '29/4/2021',
        noteDescription: 'Note 6'
      }
    ];

    return clientNotesDataModel;
  }

  fillClientsDynamicList() {
    let clientDynamicDataModel: ClientsDynamicDataModel[];
    clientDynamicDataModel = [
      {
        clientId: 1,
        dynamicFieldId: 1,
        dynamicFieldKey: 'dynamic 1',
        dynamicFieldValue: 'dynamic val 1'
      },
      {
        clientId: 1,
        dynamicFieldId: 2,
        dynamicFieldKey: 'dynamic 2',
        dynamicFieldValue: 'dynamic val 2'
      },
      {
        clientId: 1,
        dynamicFieldId: 3,
        dynamicFieldKey: 'dynamic 3',
        dynamicFieldValue: 'dynamic val 3'
      },
      {
        clientId: 1,
        dynamicFieldId: 4,
        dynamicFieldKey: 'dynamic 4',
        dynamicFieldValue: 'dynamic val 4'
      },
      {
        clientId: 1,
        dynamicFieldId: 5,
        dynamicFieldKey: 'dynamic 5',
        dynamicFieldValue: 'dynamic val 5'
      },
      {
        clientId: 1,
        dynamicFieldId: 6,
        dynamicFieldKey: 'dynamic 6',
        dynamicFieldValue: 'dynamic val 6'
      },
      {
        clientId: 1,
        dynamicFieldId: 7,
        dynamicFieldKey: 'dynamic 7',
        dynamicFieldValue: 'dynamic val 7'
      },
      {
        clientId: 1,
        dynamicFieldId: 8,
        dynamicFieldKey: 'dynamic 8',
        dynamicFieldValue: 'dynamic val 8'
      },
      {
        clientId: 1,
        dynamicFieldId: 9,
        dynamicFieldKey: 'dynamic 9',
        dynamicFieldValue: 'dynamic val 9'
      },
      {
        clientId: 1,
        dynamicFieldId: 10,
        dynamicFieldKey: 'dynamic 10',
        dynamicFieldValue: 'dynamic val 10'
      },
      {
        clientId: 1,
        dynamicFieldId: 11,
        dynamicFieldKey: 'dynamic 11',
        dynamicFieldValue: 'dynamic val 11'
      },
      {
        clientId: 1,
        dynamicFieldId: 12,
        dynamicFieldKey: 'dynamic 12',
        dynamicFieldValue: 'dynamic val 12'
      },
      {
        clientId: 1,
        dynamicFieldId: 13,
        dynamicFieldKey: 'dynamic 13',
        dynamicFieldValue: 'dynamic val 13'
      },
      {
        clientId: 1,
        dynamicFieldId: 14,
        dynamicFieldKey: 'dynamic 14',
        dynamicFieldValue: 'dynamic val 14'
      },
      {
        clientId: 1,
        dynamicFieldId: 15,
        dynamicFieldKey: 'dynamic 15',
        dynamicFieldValue: 'dynamic val 15'
      },
      {
        clientId: 1,
        dynamicFieldId: 16,
        dynamicFieldKey: 'dynamic 16',
        dynamicFieldValue: 'dynamic val 16'
      },
      {
        clientId: 1,
        dynamicFieldId: 17,
        dynamicFieldKey: 'dynamic 17',
        dynamicFieldValue: 'dynamic val 17'
      }
    ];

    return clientDynamicDataModel;
  }

  getClientsOperationsForLoggedInUserList(clientFilter: any) {
    const urlValue: string = this.url + '/GetClientsOperationsForLoggedInUserList';
    return this.http.post(urlValue, JSON.stringify(clientFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getClientDataToStart() {
    const urlValue: string = this.url + '/GetClientDataToStart';
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  getSelectedClientData(clientId: number) {
    const urlValue: string = this.url + '/GetSelectedClientData?clientId=' + clientId;
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  updateClientOperationStatus(clientOperationModel: ClientOperationModel) {
    const urlValue: string = this.url + '/UpdateClientOperationStatus';
    return this.http.post(urlValue, JSON.stringify(clientOperationModel), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  // insertClient(client: ClientModel) {
  //   const urlValue: string = this.url + '/InsertClient';
  //   return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  // updateClientActiveStatus(client: ClientModel) {
  //   const urlValue: string = this.url + '/UpdateClientActiveStatus';
  //   return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  // getClientTypesList() {
  //   const urlValue: string = this.url + '/GetClientTypesList';
  //   return this.http.get<ResponseModel>(urlValue).map((response) => {
  //     return <any>response.data;
  //   }
  //   );
  // }
}
