export const ConstantConfigurations = {
    ApplyLPONumber : 'ApplyLPONumber',
    AllowCustomerAppLogin : 'AllowCustomerAppLogin',
    AllowSameOperatorForMultiCustomer : 'AllowSameOperatorForMultiCustomer',
    DateFormat : 'DateFormat',
    DateTimeFormat : 'DateTimeFormat',
    ForceCustomerOrganizationSelection: 'ForceCustomerOrganizationSelection',
    RouteVisitStartDay: 'RouteVisitStartDay',
    LinkItemsToWarehouse : "LinkItemsToWarehouse",
    AllowDownPaymentWhenHavePendingInvoices : "AllowDownPaymentWhenHavePendingInvoices",
    AllowEditApprovedLoadOffloadRequest : "AllowEditApprovedLoadOffloadRequest",
    AllowTripGeneration : 'AllowTripGeneration',
    AutoExecuteLoadRequestInBO : 'AutoExecuteLoadRequestInBO',
    AutoExecuteOffloadRequestInBO : 'AutoExecuteOffloadRequestInBO',
    CheckMainWHStockForLoad : 'CheckMainWHStockForLoad',
    UseDefaultExpiryAndBatchForItems : 'UseDefaultExpiryAndBatchForItems',
    EnableSplitOrder :'EnableSplitOrder',
    AllowInvoiceOrderBeforeAssignOrder :'AllowInvoiceOrderBeforeAssignOrder',
    ForceOrganizationFilterForPackGroups : 'ForceOrganizationFilterForPackGroups',
    AllowTax : "AllowTax",
    AllowRetailTaxOnItems:"AllowRetailTaxOnItems",
    PreventContinueWhenCreditLimitExceededOnOrders : 'PreventContinueWhenCreditLimitExceededOnOrders',
    PreventContinueWhenPaymentTermsExceededOnOrders : 'PreventContinueWhenPaymentTermsExceededOnOrders',
    LimitBOSalesToWarehouseStock : 'LimitBOSalesToWarehouseStock',
    AllowTaxOverFreeItems : 'AllowTaxOverFreeItems',
    ToleranceValueOverPromotionBenefit: 'ToleranceValueOverPromotionBenefit',
    NumberOfStockDigits: 'NumberOfStockDigits',
    AllowFractionsInQuantity: 'AllowFractionsInQuantity',
    NumberOfDigits: 'NumberOfDigits',
    ControlTransactionOnDivisionLevel: 'ControlTransactionOnDivisionLevel',
    AllowSelectWarehouseInPO: 'AllowSelectWarehouseInPO',
    ApplyGS1TrackAndTraceTemplate:'ApplyGS1TrackAndTraceTemplate',
    ShowDivisionSelectionOnWHTransaction : 'ShowDivisionSelectionOnWHTransaction',
    AllowEditPrice: 'AllowEditPrice',
    ShowSerialTrackingHistory: 'ShowSerialTrackingHistory',
    AllowEditTax: 'AllowEditTax',
    AllowEditDiscount: 'AllowEditDiscount',
    CalculateTaxBeforeDiscounts: 'CalculateTaxBeforeDiscounts',
    ApplyAmountDiscountPerQuantity: 'ApplyAmountDiscountPerQuantity',
    ApplyRoundingOnCalculations: 'ApplyRoundingOnCalculations',
    AllowBatchSelectionInWarehouseOperations: 'AllowBatchSelectionInWarehouseOperations',
    SuggestFullOffloadAfterLoadIn:'SuggestFullOffloadAfterLoadIn',
    BatchNumberFormat:'BatchNumberFormat',
    BlockManuallyBatchInsertion:'BlockManuallyBatchInsertion',
    ShowQuantityInLoadIn:'ShowQuantityInLoadIn',
    NumberOfWarehouseChecksInCheckIn:'NumberOfWarehouseChecksInCheckIn',
    AllowEditItemInTransfer:'AllowEditItemInTransfer',
    ApplyCreditReleaseOverCustomerOnly:'ApplyCreditReleaseOverCustomerOnly',
    CheckCustomerUniquenessEntry:'CheckCustomerUniquenessEntry',
    EnableMinMaxPriceInPriceList:'EnableMinMaxPriceInPriceList',
    ApplyNetPrice:'ApplyNetPrice',
    ForceLoadRequestForOrders: 'ForceLoadRequestForOrders',
    EnableSelectEmployeeForOrderInBO: 'EnableSelectEmployeeForOrderInBO',
    AutoDispatchOrder: 'AutoDispatchOrder',
    ApplyApprovalCycleInWeb:'ApplyApprovalCycleInWeb',
    DeliverOrdersUsingInvan: 'DeliverOrdersUsingInvan',
    TimeIntervalInEndorsementScreen:'TimeIntervalInEndorsementScreen',
    AffectVanStockInDeliveryInFO:'AffectVanStockInDeliveryInFO',
    BOInvoicing:'BOInvoicing',
    AffectMainWarehouseUponOrderBOInvoicing:'AffectMainWarehouseUponOrderBOInvoicing',
    DeliveryMode:'DeliveryMode',
    CreateComplementaryOrder:'CreateComplementaryOrder',
    InvoiceValueConsideredInPromotionBenefits:'InvoiceValueConsideredInPromotionBenefits',
    RecalculatePromotionInDelivery:'RecalculatePromotionInDelivery',
    ConsiderCurrentPromotionsInDelivery:'ConsiderCurrentPromotionsInDelivery',
    SaleRestrictionCalculationMode:'SaleRestrictionCalculationMode',
    AllowPartialDelivery:'AllowPartialDelivery',
    AllowEnterCreditDebitNoteDate:'AllowEnterCreditDebitNoteDate',
    SerialTemplateDateFormat:'SerialTemplateDateFormat',
    ForceSingleSerials:'ForceSingleSerials',
    SerialTrimEndLength:'SerialTrimEndLength',
    SerialTrimStartLength:'SerialTrimStartLength',
    EnableGeneratingSSCC:'EnableGeneratingSSCC',
    AllowBatchSelectionInDelivery:'AllowBatchSelectionInDelivery',
    EnablePushOrderNotification:'EnablePushOrderNotification',
    AllowPromotion : 'AllowPromotion',
    LinkInputVoucherToVendor:'LinkInputVoucherToVendor',
    CheckCreditLimitOnEmployeeDivisionAccount:'CheckCreditLimitOnEmployeeDivisionAccount',
    ShowSurveyAsMenu : 'ShowSurveyAsMenu',
    ForcePreviousLevelApproval:"ForcePreviousLevelApproval",
    AllowUseCreditNotesInCollection : "AllowUseCreditNotesInCollection",
    ForceUseAvailableCreditNotes :"ForceUseAvailableCreditNotes",
    LinkVehicleToAccount: 'LinkVehicleToAccount',
    CheckAvailableCreditToLoad: 'CheckAvailableCreditToLoad',
    AllowLoadOverAvailableCredit: 'AllowLoadOverAvailableCredit',
    DefaultSerialScanningType:'DefaultSerialScanningType',
    ApplyTaxTypesInPrices:'ApplyTaxTypesInPrices',
    CategoryBrandCustomerAppMode: 'CategoryBrandCustomerAppMode',
    paymentTypesForCreditCustomer:'PaymentTypesForCreditCustomer',
    allowPayingExtraAtCollection:'AllowPayingExtraAtCollection',
    UseWeightPriceInOrder:'UseWeightPriceInOrder',
    AutoCollectBOInvoices:'AutoCollectBOInvoices',
    AllowFOC:'AllowFOC',
    CheckFOCLimit:'CheckFOCLimit',
    CheckConsumerPrice:'CheckConsumerPrice',
    EmployeeKeyResponceTimeOut: 'EmployeeKeyResponceTimeOut',
    ForceSelectDocumentReasonsOnOperation: 'ForceSelectDocumentReasonsOnOperation'



};
