import { Component, OnInit, ViewChild, Input, NgModule } from '@angular/core';

import { NgbModal, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HereMapService } from '../here-maps.service';
import { CoreSession } from 'src/app/core/core.session';
import { StudentModel } from 'src/app/shared/model/student-attendance-models/student.model';

declare var H: any;

@NgModule({
  declarations: [
    ModalBasicComponent
  ],
  imports: [
    NgbModule//.forRoot()
  ]
})
@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.css']
})
export class ModalBasicComponent implements OnInit {
  private ui: any;
  private platform: any;
  private map: any;
  private studentsList: StudentModel[] = [];
  isMapVisible: boolean = false;
  closeResult: string;
  @ViewChild("content", { static: true }) content: NgbModal;

  @Input()
  public appId: any;

  @Input()
  public appCode: any;

  @Input()
  public lat: any;

  @Input()
  public lng: any;

  @Input()
  public width: any;

  @Input()
  public height: any;

  ngOnInit() {
    this.platform = new H.service.Platform({
      useCIT: true,
      app_id: this.appId,
      app_code: this.appCode,
      useHTTPS: true
    });
  }
  constructor(private modalService: NgbModal,
    private hereMapService: HereMapService,
    private coreSession: CoreSession,
    config: NgbModalConfig) { }

  public showDialog() {
    this.isMapVisible = false;
    this.modalService.open(this.content, {
      centered: true,
      size: "lg",
      backdrop: 'static'
    });

    let defaultLayers = this.platform.createDefaultLayers();

    this.map = new H.Map(
      document.getElementById("mapId"),
      defaultLayers.normal.map
    );
    window.addEventListener('resize', () => this.map.getViewPort().resize());
    let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);

    // this.modalService.open(this.content, { centered: true, size: "lg" });
    this.prepareMapVehicles();

    this.isMapVisible = true;
  }
  close() {
    this.modalService.dismissAll();
  }

  prepareMapVehicles() {
    // this.hereMapService.getStudentLocations().subscribe(result => {
    //   this.studentsList = result;
    //   (this.studentsList);
    //   if (this.studentsList && this.studentsList.length > 0) {
    //     for (let i = 0; i < this.studentsList.length; i++) {
    //       this.dropMarker({ "lat": this.studentsList[i].gpsLat, "lng": this.studentsList[i].gpsLng }, this.studentsList[i].firstName.toString());
    //     }
    //   }
    // });
  }
  private dropMarker(coordinates: any, description: string) {
    let icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/shop-2.png');
    let marker = new H.map.Marker(coordinates, { icon: icon });

    marker.setData(description);
    marker.addEventListener('pointerenter', event => {
      let bubble = new H.ui.InfoBubble(event.target.getPosition(), {
        description
      });
      this.ui.addBubble(bubble);

      (this.ui);

    }, false);
    this.map.addObject(marker);

    marker.addEventListener('pointerleave', event => {
      ("LEFT AND DATA OS : " + description);
    }, false);

    marker.addEventListener('tap', event => {
      // this.goToLink("http://localhost:55807/employee-operation/customer-map");
      // this.router.navigate(['/' + ConstantURLs.employeeOperationURL + '/' + "customer-map"]);
    }, false);
  }
}
