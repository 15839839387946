import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { Subject, Observable } from 'rxjs';
import { ClientService } from '../client.service';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { GlobalTheme } from 'src/app/core/global-theme';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { Country } from 'src/app/shared/model/general/country.model';


var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));

@Component({
  selector: 'app-clients-entry',
  templateUrl: './clients-entry.component.html',
  styleUrls: ['./clients-entry.component.css']
})
export class ClientsEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedClientData: any;
  @Input() isEditMode: boolean = false;
  @Input() selectedIndex: number = -1;
  @Output() onSaveCompleted = new EventEmitter();

  isKasoticSelected: boolean = false;

  clientObj: ClientModel;
  saveBtnSubscription: any;
  form: FormGroup;
  contactsForm: FormGroup;
  otherLanguages: any[] = [];
  clientTypesDataSource: any[];

  name: ITextInput = {
    formControlName: "clientName",
    placeHolder: "Desc_ClientName",
    label: "Desc_ClientName",
    isRequired: true
  };
  isCategoriesLoading = false;
  selectedClientTypeId: any;

  clientEntries = GlobalTheme.clientDefinitionEntries;

  contactsDataSource: SharedTableResult = {
    data: [],
    totalItems: 0
  }

  contactsTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: false,
    showSearch: true,
    columns: [
      {
        title: 'Desc_ContactName',
        key: 'contactName',
        isSortable: true,
        width: '50%',
        isEditable: true,
      },
      {
        title: 'Desc_ContactNumber',
        key: 'contactNumber',
        isSortable: true,
        width: '50%',
        isEditable: true,
        numberOfDigits: true
      }
    ],
    rowOperations: [
      {
        title: 'Desc_Delete',
        operation: RowOperation.delete,
        icon: 'fa fa-trash',
        color: 'red'
      },
    ],
    multiSelectionOperations: [],
    isMultiSelection: true,
    allowSelectAll: false,
    specificNumberOfSelections: true,
    numberOfAllowedSelections: 1,
  }

  countryList: Country[] = [];


  constructor(private clientService: ClientService, private translateService: TranslateService, private coreSession: CoreSession) { }

  ngOnInit(): void {

    this.countryList = Object.keys(countries.getNames(this.coreSession.selectedLanguageStr.toLowerCase())).map(key => {
      return {
        code: key,
        name: countries.getNames(this.coreSession.selectedLanguageStr)[key],
      }
    });

    this.isKasoticSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf() || this.coreSession.selectedSolutionId == Solution.CIT.valueOf();

    this.initForm();
    this.subscribeSaveClick();
    this.populateTypesList();


  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let clientId = this.isEditMode ? this.selectedClientData.clientId : -1;
    let clientName = this.isEditMode ? this.selectedClientData.clientName : "";
    let clientCode = this.isEditMode ? this.selectedClientData.clientCode : "";
    let clientNationalId = this.isEditMode ? this.selectedClientData.clientNationalId : "";
    let clientTypeId = this.isEditMode && this.selectedClientData && this.selectedClientData.clientTypeId && this.selectedClientData.clientTypeId > 0 ? this.selectedClientData.clientTypeId : undefined;
    let mobile = this.isEditMode ? this.selectedClientData.mobile : "";
    let email = this.isEditMode ? this.selectedClientData.email : "";
    let address = this.isEditMode ? this.selectedClientData.address : "";
    let isInactive = this.isEditMode ? this.selectedClientData.inactive : false;
    let longitude = this.isEditMode ? this.selectedClientData.longitude : 0.0;
    let latitude = this.isEditMode ? this.selectedClientData.latitude : 0.0;

    this.form = new FormGroup({
      clientId: new FormControl(clientId),
      clientName: new FormControl(clientName, Validators.required),
      clientCode: new FormControl(clientCode, Validators.required),
      clientNationalId: new FormControl(clientNationalId),
      clientTypeId: new FormControl(clientTypeId),
      mobile: new FormControl(mobile),
      email: new FormControl(email, Validators.email),
      address: new FormControl(address),
      isInactive: new FormControl(isInactive),
      longitude: new FormControl(longitude),
      latitude: new FormControl(latitude),
      country: new FormControl(undefined)
    });
    if (!this.clientEntries.clientName) {
      this.clientNameControl.clearValidators();
    }
    if (!this.clientEntries.clientCode) {
      this.clientCodeControl.clearValidators();
    }

    this.contactsForm = new FormGroup({
      contactName: new FormControl(undefined, Validators.required),
      contactNumber: new FormControl(undefined, [Validators.required, Validators.maxLength(14), Validators.pattern(/^[0-9]\d*$/)]),
    });
  }

  getClientData(selectedClient: ClientModel) {
    this.coreSession.ModalLoading.Show();
    this.clientService.getClientData(selectedClient.clientId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
        this.selectedClientData = response.data;

        this.clientIdControl.setValue(this.selectedClientData.clientId);
        this.clientNameControl.setValue(this.selectedClientData.clientName);
        this.clientCodeControl.setValue(this.selectedClientData.clientCode);
        this.clientNationalIdControl.setValue(this.selectedClientData.clientNationalId);
        this.mobileControl.setValue(this.selectedClientData.mobile);
        this.emailAddressControl.setValue(this.selectedClientData.email);
        this.addressControl.setValue(this.selectedClientData.address);
        this.clientTypeIdControl.setValue(this.selectedClientData.clientTypeId);
        this.isInactiveControl.setValue(this.selectedClientData.inactive);
        this.longitudeControl.setValue(this.selectedClientData.longitude);
        this.latitudeControl.setValue(this.selectedClientData.latitude);
        this.countryControl.setValue(this.selectedClientData.country != null && this.selectedClientData.country != '' ? this.selectedClientData.country : undefined);

        this.contactsDataSource = {
          data: this.selectedClientData.contacts.slice().map((c) => {
            return {
              ...c,
              isChecked: c.isChecked = c.isDefault != null ? c.isDefault : false
            }
          }),
          totalItems: this.selectedClientData.contacts.length
        };

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onContactRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteContact(event);
        break;
    }
  }

  onDeleteContact(event: any) {

    this.contactsDataSource.data.splice(event.index, 1);
    this.contactsDataSource.pageData = this.contactsDataSource.data;
    this.contactsDataSource.totalItems--;

  }

  onAddContact() {
    if (this.contactNumberControl.hasError('maxlength') || this.contactNumberControl.hasError('pattern')) {
      return;
    }
    if (this.contactsForm.invalid) {
      this.contactsForm.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.contactsDataSource.data.push({
      contactName: this.contactNameControl.value,
      contactNumber: this.contactNumberControl.value
    });

    this.contactsDataSource.data = this.contactsDataSource.pageData;
    this.contactsDataSource.totalItems++;

    this.contactsForm.reset();

  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareClientObj() {
    this.clientObj = new ClientModel();
    this.clientObj.clientId = +this.clientIdControl.value;
    this.clientObj.clientName = this.clientNameControl.value;
    this.clientObj.clientCode = this.clientCodeControl.value;
    this.clientObj.clientNationalId = this.clientNationalIdControl.value;
    this.clientObj.mobile = this.mobileControl.value;
    this.clientObj.email = this.emailAddressControl.value;
    this.clientObj.address = this.addressControl.value;
    this.clientObj.clientTypeId = this.clientTypeIdControl && this.clientTypeIdControl.value ? this.clientTypeIdControl.value : -1;
    this.clientObj.inactive = this.isInactiveControl && this.isInactiveControl.value ? this.isInactiveControl.value : false;
    this.clientObj.longitude = +this.longitudeControl.value;
    this.clientObj.latitude = +this.latitudeControl.value;
    this.clientObj.country = this.countryControl.value != null ? this.countryControl.value : '';

    this.clientObj.contacts = this.contactsDataSource.data.slice()
      .filter(c => c.contactName != null && c.contactName != '' && c.contactNumber != null && c.contactNumber != '')
      .map(c => {
        return {
          ...c,
          contactNumber: c.contactNumber = c.contactNumber.toString(),
          isDefault: c.isDefault = c.isChecked != null ? c.isChecked : false
        };
      });
  }

  onSaveClicked() {
    if (this.emailAddressControl.hasError('email')) {
      this.emailAddressControl.markAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgInvalidEmailAddress));
      return;
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.prepareClientObj();

    //console.log(this.clientObj);

    if (this.clientObj != null) {
      this.coreSession.ModalLoading.Show();
      this.clientService.insertClient(this.clientObj, this.isEditMode).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data > -1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.onSaveCompleted.emit();
          } else {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
          // this.router.navigate([ConstantURLs.employeeURL], { relativeTo: this.route.parent });
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  populateTypesList() {
    if (this.clientEntries.clientTypeName || this.clientEntries.clientClass) {
      this.isCategoriesLoading = true;

      this.clientService.getClientTypesList().subscribe(result => {
        //console.log(result)
        this.clientTypesDataSource = result;
        if (this.clientTypesDataSource && this.clientTypesDataSource.length > 0 && !this.isEditMode) {
          this.clientTypeIdControl.setValue(this.clientTypesDataSource[0].categoryId);
        }
        this.isCategoriesLoading = false;

        if (this.isEditMode) {
          this.getClientData(this.selectedClientData);
        }
      });

    } else {
      if (this.isEditMode) {
        this.getClientData(this.selectedClientData);
      }
    }
  }

  onclientTypesDataChanged(types: any) {
    this.selectedClientTypeId = this.clientTypeIdControl.value;

  }
  //Form Getters
  get clientIdControl() {
    return this.form.get('clientId');
  }

  get clientNameControl() {
    return this.form.get('clientName');
  }

  get clientCodeControl() {
    return this.form.get('clientCode');
  }

  get clientNationalIdControl() {
    return this.form.get('clientNationalId');
  }

  get clientTypeIdControl() {
    return this.form.get('clientTypeId');
  }

  get mobileControl() {
    return this.form.get('mobile');
  }

  get emailAddressControl() {
    return this.form.get('email');
  }

  get addressControl() {
    return this.form.get('address');
  }

  get isInactiveControl() {
    return this.form.get('isInactive');
  }

  get longitudeControl() {
    return this.form.get('longitude');
  }

  get latitudeControl() {
    return this.form.get('latitude');
  }

  get countryControl() {
    return this.form.get('country');
  }

  get contactNameControl() {
    return this.contactsForm.get('contactName');
  }

  get contactNumberControl() {
    return this.contactsForm.get('contactNumber');
  }

}
