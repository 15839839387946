import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { LectureScheduleModel } from 'src/app/shared/model/lecture-schedule-models/lecture-schedule.model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { ReportService } from '../../reports.service';

@Component({
  selector: 'app-lectures-report-list',
  templateUrl: './lectures-report-list.component.html',
  styleUrls: ['./lectures-report-list.component.css']
})
export class LecturesReportListComponent implements OnInit {

  isViewMode = false;
  isEditMode = false;
  saveSubject: Subject<void> = new Subject<void>();
  selectedObj: LectureScheduleModel;
  showSlider = false;

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Organizer",
        key: "organizerName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Program",
        key: "programName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Session",
        key: "sessionName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Date",
        key: "feedbackDate",
        isSortable: true,
        width: "25%",
      },

    ]
  };

  constructor(private coreSession: CoreSession,
    private reportsService: ReportService,
    private translateService: TranslateService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.addActions();
    this.populateReportsList();
  }

  addActions() {
    //debugger;
    // if (this.coreSession.checkActivitiesAvailability(ConstantURLs.lecturesReportURL, MenuActions.View)) {
    this.tableData.rowOperations.push(
      {
        operation: RowOperation.View,
        title: "Desc_View",
        icon: "fa fa-eye",
        color: "black"
      }
    );
    // }
  }


  //Slider Events
  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.openSliderToView(event.object);
        break;
    }
  }

  //Search
  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    this.populateReportsList();
  }

  //Open Slider Events
  openSliderToAdd() {
    this.selectedObj = null;
    this.isViewMode = false;
    this.isEditMode = false;
    this.showSlider = true;
  }
  openSliderToView(val: any) {
    this.selectedObj = val;
    this.isViewMode = true;
    this.isEditMode = false;
    this.showSlider = true;
  }
  openSliderToEdit(val: any) {
    this.selectedObj = val;
    this.isViewMode = false;
    this.isEditMode = true;
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onSaveCompleted(val: any) {
    this.closeSlider();
    this.populateReportsList();
  }

  populateReportsList() {
    this.coreSession.ModalLoading?.Show();
    this.reportsService.getLectureReportsList(this.filter).subscribe(response => {
      this.coreSession.ModalLoading?.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        response.data.data.map(d => {
          return {
            ...d,
            feedbackDate: d.feedbackDate = this.datePipe.transform(d.feedbackDate, 'YYYY-MM-dd hh:mm a')
          }
        });

        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = response.data.data.slice();
        this.dataSource.totalItems = response.data.totalItems;

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading?.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }
}
