import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CoreSession } from "src/app/core/core.session";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { InsertRouteModel } from "src/app/shared/model/route-model/route-model";

@Injectable({
    providedIn: 'root'
})
export class RouteService {
    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: String,
        private coreSession: CoreSession) {
        this.url = baseUrl + 'Route';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    insertAssignedInvoices(insertRoutes: InsertRouteModel) {
        // //console.log(insertRoutes)
        const urlValue: string = this.url + '/InsertAssignedInvoices';
        return this.http.post(urlValue, JSON.stringify(insertRoutes), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getAssignedInvoicesByFilter(routeFilter: any) {
        const urlValue: string = this.url + '/GetAssignedInvoices';
        return this.http.post(urlValue, JSON.stringify(routeFilter), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    GetAllAssignedinvoices() {
      const urlValue: string = this.url + '/GetAllAssignedinvoices';
      return this.http.get(urlValue).pipe(map(
          (response) => {
              const result = new ResponseModel();
              result.data = response['data'];
              result.message = response['message'];
              result.status = response['status'];
              return result;
          }
      ));
  }

    getAssignedInvoices() {
      const urlValue: string = this.url + '/GetAssignedInvoices';
      return this.http.get(urlValue).pipe(map(
          (response) => {
              const result = new ResponseModel();
              result.data = response['data'];
              result.message = response['message'];
              result.status = response['status'];
              return result;
          }
      ));
  }

    getInvoicesToDialog(routeFilter: any) {
        const urlValue: string = this.url + '/GetInvoicesToDialog';
        return this.http.post(urlValue, JSON.stringify(routeFilter), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status']
                return result;
            }
        ));
    }

    getStaffNameList() {
        const urlValue: string = this.url + '/GetStaffNameList';
        return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
            return <any>response.data;
        }));
    }

    deleteAssigningFromInvoice(route: InsertRouteModel) {
        const urlValue: string = this.url + '/DeleteAssigningFromInvoice';
        return this.http.post(urlValue, JSON.stringify(route), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getAssignedInvoicesStaff(staffId: any) {
      const urlValue: string = this.url + '/GetStaffInvoices?staffId=' + staffId;
      return this.http.get(urlValue).pipe(map(
          (response) => {
              const result = new ResponseModel();
              result.data = response['data'];
              result.message = response['message'];
              result.status = response['status'];
              return result;
          }
      ));
  }

  deleteRoute(invoice: any) {
    const urlValue: string = this.url + '/DeleteAssigningFromRoute';
    return this.http.post(urlValue, JSON.stringify(invoice), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }
}
