import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { JwtHelperService } from '@auth0/angular-jwt';
import * as crypto from 'crypto';
import { NavBarService } from "../content/root-nav/sidebar.service";
import { ResponseModel } from "../shared/model/api-models/api-models";
import { map } from "rxjs/operators";
import { ConstantStorage } from "./constants/constant-stroage";
import { ConstantConfigurations } from "./constants/constant-configuration";
import { ConfigurationModel } from "../shared/enum/general/configuration.model";
import 'rxjs/Rx';
import { MenuModel } from "../shared/model/menu/menu.model";
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root"
})
export class SessionDataProvider {
  private url = "";
  private sessionLanguages: any[] = [];
  public GeneralConfigurations: ConfigurationModel[];
  public DivisionConfigurations: ConfigurationModel[];
  public allMenusAvailable: MenuModel[] = [];
  public addedMenus: MenuModel[] = [];

  languageId = 1;
  public DateFormat = "dd/MM/yyyy";
  public DateTimeFormat = "dd/MM/yyyy hh:mm a";
  public SerialDateFormat = "dd/MM/yyyy";
  public TimeFormat = "hh:mm a";

  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private navBar: NavBarService) {
    this.url = baseUrl;
  }

  getSessionLanguages() {
    return this.sessionLanguages;
  }
  getActiveLanguages() {
    return this.sessionLanguages.filter(language => language.active);
  }

  loadSessionData() {
    this.loadSessionLanguages();
  }

  loadSessionLanguages() {
    const urlValue: string = this.url + "Login/GetAllLanguages";
    return new Promise((resolve, reject) => {
      this.http
        .get<ResponseModel>(urlValue)
        .pipe(map(response => {
          return response;
        }))
        .subscribe(res => {
          this.sessionLanguages = res.data;
          // this.loadSessionConfigurations();
          resolve(true);
        });
    });
  }

  getExternalURLs() {
    const urlValue = this.url + "Login/GetExternalURLs";
    return this.http
      .get<ResponseModel>(urlValue).pipe(map(response => {
        return response;
      }));
  }

  loadSessionConfigurations() {
    if (this.isAuthenticated()) {
      this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
      const urlValue: string = this.url + "Employee/FillOperatorData?languageId=" + this.languageId;
      return new Promise((resolve, reject) => {
        this.http
          .get<ResponseModel>(urlValue)
          .pipe(map(response => {
            return response;
          }))
          .subscribe(res => {
            this.GeneralConfigurations = res.data.configurationsList;
            this.DivisionConfigurations = res.data.configurationsListOnDivisionLevel;
            this.navBar.setReloadSidebar();
            if (this.getConfigurationValue(ConstantConfigurations.DateFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.DateFormat);
              this.DateFormat = format;
            }
            if (this.getConfigurationValue(ConstantConfigurations.DateTimeFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateTimeFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.DateTimeFormat);
              this.DateTimeFormat = format;
            }
            if (this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat) != null && this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
              this.SerialDateFormat = format;
            }
            resolve(true);
          });
      });
    }
  }

  loadSessionStaffData() {
    // //console.log("here im");
    var currentUser;

    if (sessionStorage.length > 0) {
      currentUser = JSON.parse(sessionStorage.getItem(ConstantStorage.CurrentUser));
    } else {
      currentUser = JSON.parse(localStorage.getItem(ConstantStorage.CurrentUser));
    }

    if (
      currentUser != null &&
      currentUser.isLoggedIn &&
      this.isAuthenticated()
    ) {


      this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
      const urlValue: string = this.url + "Staff/FillStaffData";
      return new Promise((resolve, reject) => {
        this.http.post<ResponseModel>(urlValue, this.languageId).map(response => {
          return response;
        })
          .subscribe(res => {
            if (res.status > -1) {

              this.addedMenus = res.data.addedList;
              this.allMenusAvailable = res.data.menusList;
              // //console.log(this.addedMenus, "ADDED MENUS");
              // //console.log(this.allMenusAvailable, "Menus Available");

              // this.navBar.setReloadSidebar();
              if (this.getConfigurationValue(ConstantConfigurations.DateFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateFormat);
                this.DateFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.DateTimeFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateTimeFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.DateTimeFormat);
                this.DateTimeFormat = format;
              }
              if (this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat) != null && this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat).trim() != '') {
                let format = this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
                this.SerialDateFormat = format;
              }
              resolve(true);
            }

          });
      });
    }
  }

  loadSessionConfigurationsAfterUpdateConfig() {
    if (this.isAuthenticated()) {
      this.languageId = +localStorage.getItem(ConstantStorage.LanguageId);
      const urlValue: string = this.url + "Employee/FillOperatorConfigurations";
      return new Promise((resolve, reject) => {
        this.http
          .get<ResponseModel>(urlValue)
          .pipe(map(response => {
            return response;
          }))
          .subscribe(res => {
            this.GeneralConfigurations = res.data.configurationsList;
            this.DivisionConfigurations = res.data.configurationsListOnDivisionLevel;
            if (this.getConfigurationValue(ConstantConfigurations.DateFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.DateFormat);
              this.DateFormat = format;
            }
            if (this.getConfigurationValue(ConstantConfigurations.DateTimeFormat) != null && this.getConfigurationValue(ConstantConfigurations.DateTimeFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.DateTimeFormat);
              this.DateTimeFormat = format;
            }
            if (this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat) != null && this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat).trim() != '') {
              let format = this.getConfigurationValue(ConstantConfigurations.SerialTemplateDateFormat);
              this.SerialDateFormat = format;
            }
          });
      });
    }
  }

  public getConfigurationValue(keyName: string): string {
    if (this.GeneralConfigurations) {
      const config = this.GeneralConfigurations.find(
        a => a.keyName === keyName
      );
      if (config && config.keyValue) {
        return config.keyValue;
      } else {
        return '';
      }
    } else {
      return "";
    }
  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = sessionStorage.length > 0 ? sessionStorage.getItem(ConstantStorage.TokenName) : localStorage.getItem(ConstantStorage.TokenName);
    return !jwtHelper.isTokenExpired(token);
  }

  loadMenus(securityGroupId: number) {
    const urlValue: string = this.url + "Employee/FillMenuData";
    return new Promise((resolve, reject) => {
      this.http
        .get<ResponseModel>(urlValue + '?securityGroupId=' + securityGroupId)
        .pipe(map(response => {
          return response;
        }))
        .subscribe(res => {
          resolve(true);
        });
    });
  }
}
