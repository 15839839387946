import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { CoreSession } from 'src/app/core/core.session';
import { ItemModel } from 'src/app/shared/model/item-models/item.model';

@Component({
  selector: 'app-calculator-dialog',
  templateUrl: './calculator-dialog.component.html',
  styleUrls: ['./calculator-dialog.component.css']
})
export class CalculatorDialogComponent implements OnInit {

  selectedAmmunition: ItemModel;
  form: FormGroup;
  resultQty: number = 0;

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService, private coreSession: CoreSession) { }


  ngOnInit(): void {
    this.selectedAmmunition = this.data.ammunitionType;
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      ammunitionType: new FormControl({ value: this.selectedAmmunition.itemId, disabled: false }, Validators.required),
      minWeight: new FormControl({ value: this.selectedAmmunition.minWeight, disabled: true }),
      maxWeight: new FormControl({ value: this.selectedAmmunition.maxWeight, disabled: true }),
      desiredWeight: new FormControl()
    });

    this.resultQty = 0;
    this.onWeightChange();
  }

  // onAmmunitionSelect() {
  //   if (this.ammunitionTypeControl.value != null) {
  //     const indx = this.ammunitionsList.findIndex(a => a.itemId === this.ammunitionTypeControl.value);
  //     this.minWeightControl.setValue(this.ammunitionsList[indx].minWeight);
  //     this.maxWeightControl.setValue(this.ammunitionsList[indx].maxWeight);
  //   }
  //   this.resultQty = 0;
  //   this.onWeightChange();
  // }

  onSaveClicked() {
    let resObj = JSON.parse(JSON.stringify(this.selectedAmmunition));
    resObj.quantity = this.resultQty;
    this.dialogRef.close(resObj);
  }


  onWeightChange() {
    if (this.desiredWeightControl.value && this.minWeightControl.value != 0 && this.maxWeightControl.value != 0) {
      const minQty = this.desiredWeightControl.value / this.minWeightControl.value;
      const maxQty = this.desiredWeightControl.value / this.maxWeightControl.value;

      const avgQty = (minQty + maxQty) / 2;

      this.resultQty = Math.floor(avgQty);

    } else {
      this.resultQty = 0;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get ammunitionTypeControl() {
    return this.form.get('ammunitionType');
  }
  get minWeightControl() {
    return this.form.get('minWeight');
  }
  get maxWeightControl() {
    return this.form.get('maxWeight');
  }
  get desiredWeightControl() {
    return this.form.get('desiredWeight');
  }
}

