import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export default class Validation {
  static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        // return if another validator has already found an error on the matchingControl

        return null;
      }

      // return error if validation fails

      let errors = null;
      if (control.value !== matchingControl.value) {
        errors = { mustMatch: true };
      }
      matchingControl.setErrors(errors);
      return errors;
    };
  }
}