export enum GlobalErrors {
    NotInitialized = 0,
    Success = 1,
    Error = 2,
    NoMoreRows = 3,
    SuccessWithZeroRowAffected = 4,
    NotEnoughQuantity = 5,
    PasswordNotMatch = 6,
    ValueIsMoreThanRequested = 7,
    PasswordShouldBeChanged = 8,
    LoggedInOtherSession = 9,
    SomeInvalidSerials = 10,
    AllSerialsInvalid = 11,
    DBTimeoutExpired = 12,
    DBCannotExecuteQuery = 13,
    DBQueryAlreadyOpened = 14,
    DataAlreadyExists = 15,
    VersionNotMatch = 16,
    NoDocumentSequence = 17,
    CustomerAppPath = 18,
    DevicesNotLicenced = 19,
    EmailAlreadyExists = 20,
    SerilalAlreadyExist = 21,
    UsernameAlreadyExist = 22,
    SampleTypeCodeExist = 23,
    SampleTypeOptionCodeExist = 24,
    ClientNotLicensed = 25,
    LicenseExpired = 26,
}

export enum LicenseResult {
    Licensed = 1,
    Error = 2,
    Unauthorized = 3,
    LicenseServerError = 4,
    NearToExpired = 5
}