import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientPromisesModel } from 'src/app/shared/model/client-models/client-promise-model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { ClientOperationService } from '../../client-operation.service';

@Component({
  selector: 'app-operation-promises-list',
  templateUrl: './operation-promises-list.component.html',
  styleUrls: ['./operation-promises-list.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class ClientOperationPromisesListComponent implements OnInit {
  clientPromises: ClientPromisesModel[] = [];
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: 100,
    showPaginator: false,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    SearchBoxLabel: "Desc_Search",
    showNewBtn: true,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "DESC_Promise",
        key: "promiseDescription",
        isSortable: false,
        width: "35%"
      },
      {
        title: "DESC_Date",
        key: "creationDate",
        isSortable: false,
        width: "32%"
      },
      {
        title: "DESC_Created_By",
        key: "createdByVal",
        isSortable: false,
        width: "33%"
      }
    ]
  };

  constructor(config: NgbModalConfig, private modalService: NgbModal, private clientOperationService: ClientOperationService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.clientPromises = this.clientOperationService.fillClientPromises();
    this.dataSource = {
      data: this.clientPromises,
      totalItems: this.clientPromises.length
    }
  }


  onNewClicked(content) {
    this.modalService.open(content);
  }
}
