import { ClientModel } from "../client-models/client-model";
import { DateTimeModel } from "../dateTimeModel/date-time.model";

export class RouteStationsAssignmentModel {
  id: number;
  selectedStations: ClientModel[];
  selectedStaffId: number;
  tripDate: Date;
  tripDateModel: DateTimeModel;
  teamMembers: number[];
  vehicleId: number;
  staffName: string;
  vehicleName: string;

  constructor() {
    this.id = -1;
    this.selectedStations = [];
    this.selectedStaffId = -1;
    this.tripDateModel = new DateTimeModel();
    this.teamMembers = [];
    this.vehicleId = -1;
    this.staffName = '';
    this.vehicleName = '';
  }

}
