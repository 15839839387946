export class RoomInfoModel {
    serialNumber: string;
    roomNumber: number;
    guestName: string;

    numberOfGuests: number;
    remainingNumberOfGuests: number;
    numberOfFacillityGuests: number;
    numberOfFacillityGuestsWithoutBreakfast: number;


    numberOfGuestsKids: number;
    remainingNumberOfGuestsKids: number;
    numberOfFacillityGuestsKids: number;
    numberOfFacillityGuestsKidsWithoutBreakfast: number;

    mobileNumber: string;

    arrivalDate: Date;
    departureDate: Date;
    breakfastType: string;
    company: string;
    facilityTypeId: number;

    type: string;

    oldNumberOfGuestsWithBreakfast: number;
    oldNumberOfGuestsWithoutBreakfast: number;
    oldNumberOfGuestsKidsWithBreakfast: number;
    oldNumberOfGuestsKidsWithoutBreakfast: number;

    note: string;

    constructor() {
        this.serialNumber = '';
        this.roomNumber = 0;
        this.guestName = '';
        
        this.numberOfGuests = 0;
        this.remainingNumberOfGuests = 0;
        this.numberOfFacillityGuests = 0;
        this.numberOfFacillityGuestsWithoutBreakfast = 0;


        this.numberOfGuestsKids = 0;
        this.remainingNumberOfGuestsKids = 0;
        this.numberOfFacillityGuestsKids = 0;
        this.numberOfFacillityGuestsKidsWithoutBreakfast = 0;

        this.mobileNumber = '';

        this.breakfastType = '';
        this.company = '';

        this.facilityTypeId = -1;

        this.type = '';
        this.serialNumber = '';

        this.oldNumberOfGuestsWithBreakfast = 0;
        this.oldNumberOfGuestsWithoutBreakfast = 0;
        this.oldNumberOfGuestsKidsWithBreakfast = 0;
        this.oldNumberOfGuestsKidsWithoutBreakfast = 0;

        this.note = '';
    }
}