import { Component, OnInit, ViewChild, ElementRef, NgModule, Renderer2, OnDestroy, Input } from '@angular/core';
import { HereMapService } from '../here-maps.service';
import { ModalBasicComponent } from '../modal-basic/modal-basic.component';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ConnectionService } from 'ng-connection-service';
import { StudentModel } from 'src/app/shared/model/student-attendance-models/student.model';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { Client } from 'src/app/shared/enum/client.enum';

declare var H: any;

@Component({
  selector: 'app-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.css']
})
export class HereMapComponent implements OnInit, OnDestroy {
  @Input() showSingleLocation: boolean = false;
  @Input() locationDescription: string = '';
  @Input() longitude: number = 0.0;
  @Input() latitude: number = 0.0;


  private ui: any;
  private platform: any;
  private map: any;
  private studentsList: StudentModel[] = [];
  private icon: any;
  private marker: any;
  private markersGroup: any;
  public studentDetails: any;
  public empId: any;
  public empDescription: any;
  public StudentDescription: string = "Select Student";
  public top: number;
  isStudentOnHover: boolean = false;
  isFilterOpened = false;
  filterForm: FormGroup = new FormGroup({});
  selectedEmployeeId: any;
  showSlider = false;
  showVanRouteData = false;
  @ViewChild("content") content: ModalBasicComponent;

  @ViewChild("map", { static: true })
  public mapElement: ElementRef;

  @ViewChild("studentName", { static: true }) studentNameElement: ElementRef;
  mapServiceUnavailable: boolean = false;

  isIEC: boolean = CoreSession.selectedClient == Client.IEC_Jordan;

  // The scripts below are dynamically added to HTML body to configure Here Maps Services.
  scripts = [
    { 'src': 'https://js.api.here.com/v3/3.0/mapsjs-core.js', 'type': 'text/javascript', 'charset': 'utf-8' },
    { 'src': 'https://js.api.here.com/v3/3.0/mapsjs-service.js', 'type': 'text/javascript', 'charset': 'utf-8' },
    { 'src': 'https://js.api.here.com/v3/3.0/mapsjs-places.js', 'type': 'text/javascript', 'charset': 'utf-8' },
    { 'src': 'https://js.api.here.com/v3/3.0/mapsjs-mapevents.js', 'type': 'text/javascript', 'charset': 'utf-8' },
    { 'src': 'https://js.api.here.com/v3/3.0/mapsjs-ui.js', 'type': 'text/javascript', 'charset': 'utf-8' },
  ];
  networkConnectionStateSubscription;
  resourcesLoaded: boolean = false;

  filter = {
    studentId: -1,
    organizerId: -1,
    nationalId: '',
    universityId: -1
  };

  universityList: LocationModel[] = [];
  organizersList: StaffModel[] = [];
  allStudentsList: StudentModel[] = [];

  public constructor(private hereMapService: HereMapService,
    private coreSession: CoreSession,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private connectionService: ConnectionService) {
    if (navigator.onLine) { // if internet is connected
      // var i = 0;
      // this.prepareMapServices(i);
      this.checkHereServiceAvailability();
    } else { // if no internet connection, do not load map, display network error message.
      this.mapServiceUnavailable = true;
    }
  }

  checkHereServiceAvailability() {


    this.hereMapService.checkHereMapsAvailability().subscribe(response => {
      if (response && response.status >= 0) {
        this.universityList = response.data.universityList != null ? response.data.universityList : [];
        this.organizersList = response.data.organizersList != null ? response.data.organizersList : [];
        this.allStudentsList = response.data.studentsList != null ? response.data.studentsList : [];

        this.prepareMapServices(0);
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('Error occured at "js.api.here.com"'));
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('Error occured at "js.api.here.com"'));
    });
  }

  prepareMapServices(startIdx: number) {

    var loadNextSource = true;
    this.loadScript(this.scripts[startIdx]['src'], this.scripts[startIdx]['type'], this.scripts[startIdx]['charset'], 'src' + (startIdx + 1).toString()
    ).then(() => { // loaded successfully

      //      console.log( this.scripts[startIdx]['src'], ' Loaded, H: ', H);
      if (loadNextSource) {
        if ((startIdx + 1) < this.scripts.length) {
          startIdx++;
          this.prepareMapServices(startIdx);
        } else {
          this.afterResourcesLoad();
        }
      }
    }, () => { // not loaded
      //      console.log( this.scripts[startIdx]['src'], ' Not Loaded, H: ', H);
      loadNextSource = false;
      this.mapServiceUnavailable = true;
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('A network error occured, map services are currently unavailable.'));
    });
  }

  loadScript(src: string, type: string, charset: string, id: string) {

    return new Promise((resolve, reject) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script')
        script.type = type;
        script.charset = charset;
        script.id = id;
        script.onload = resolve;
        script.onerror = reject;
        script.src = src;
        document.getElementsByTagName('body')[0].appendChild(script);
      } else {
        resolve(null);
      }
    })
  }

  afterResourcesLoad() {
    this.resourcesLoaded = true;

    this.checkMapResources().then(() => { // map source exists
      // console.log('RESOLVED');

      // setTimeout(() => {

      if (!this.mapServiceUnavailable
        && H && H != undefined && H.service && H.service != undefined
      ) {
        this.platform = new H.service.Platform({
          useCIT: true,
          app_id: "SXw9u0a2JdpTuPzuWYss",
          app_code: "Yp92GERdCKl1PHmcUnBHjw",
          useHTTPS: true
        });

        this.afterViewInit();


        // this.initFilterForm();
        if (!this.showSingleLocation)
          this.prepareStudentLocations();
        else {
          this.markersGroup = new H.map.Group();

          this.icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/pin.png');
          this.dropMarker({ "lat": this.latitude, "lng": this.longitude }, this.locationDescription.toString());

          var gg = this.markersGroup.getBounds();
          this.map.setViewBounds(this.markersGroup.getBounds());
          this.map.addObject(this.markersGroup);
        }

      } else {  // map source does not exist
        debugger;

        this.mapServiceUnavailable = true;
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('A network error occured, map services are currently unavailable.'));
      }
      // }, 1);
    }).catch((err) => {/*console.log('catch',err)*/
      debugger;
      this.mapServiceUnavailable = true;
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('A network error occured, map services are currently unavailable.'));
    });
  }

  checkMapResources() {
    var promise = new Promise<void>((resolve, reject) => {
      // setTimeout(() => {
      if (this.checkLoadedScripts()) {

        resolve();
      } else {

        reject();
      }
      // }, 1);
    });
    return promise;
  }
  public checkLoadedScripts(): boolean {
    var result = true;
    var i = 0;
    this.scripts.forEach(s => {
      if (document.getElementById('src' + (i + 1).toString())) {
        // console.log('element Exists ', document.getElementById('src'+(i + 1).toString()))
      } else {
        result = false;
      }
      i++;
    });
    return result;
  }

  public ngOnInit() {
    if (this.mapServiceUnavailable) {
      debugger;
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('A network error occured, map services are currently unavailable.'));
    }
    this.initFilterForm();
    this.subscribeToConnectionState();
    // this.coreSession.ModalLoading.Show();
    // this.platform = new H.service.Platform({
    //   useCIT: true,
    //   app_id: "SXw9u0a2JdpTuPzuWYss",
    //   app_code: "Yp92GERdCKl1PHmcUnBHjw",
    //   useHTTPS: true
    // });

    // this.initFilterForm();
    // this.prepareMapVehicles();
  }

  ngOnDestroy(): void {
    if (this.networkConnectionStateSubscription) this.networkConnectionStateSubscription.unsubscribe();
  }

  subscribeToConnectionState() {
    this.networkConnectionStateSubscription =
      this.connectionService.monitor().subscribe(isConnected => {
        // console.log('internet connection -> here - maps component')
        // this.coreSession.isInternetConnected = isConnected;
        if (isConnected) {
          // this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant('Connection Restored'));
          this.enableScreen();
        }
        else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          this.disableScreen();
        }
      });
    // this.networkConnectionStateSubscription =
    //   this.coreSession.connectionState.subscribe((isConnected) => {
    //     if(!isConnected) {
    //       this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.MsgMapsNetworkError));
    //       this.disableScreen();
    //     } else {
    //       this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.MsgConnectionRestoredRefresh));
    //       this.enableScreen();
    //     }
    //   });
  }

  disableScreen() {
    this.mapServiceUnavailable = true;
    this.resourcesLoaded = false;
    this.close();
  }
  enableScreen() {
    this.mapServiceUnavailable = false;
  }


  public afterViewInit() {
    let defaultLayers = this.platform.createDefaultLayers();
    defaultLayers.normal.map.setMin(2);

    this.map = new H.Map(
      document.getElementById("mapId"),
      defaultLayers.normal.map,
      {
        zoom: 2,
        center: { lat: "0", lng: "0" }
      }
    );
    // window.addEventListener('resize', () => this.map.getViewPort().resize());
    let behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    ("Control = ");
    (this.ui);

    this.ui.getControl('mapsettings').setVisibility(false);
    this.ui.getControl('zoom').setVisibility(false);
    this.ui.getControl('scalebar').setVisibility(false);
  }

  prepareStudentLocations() {
    this.filter = {
      studentId: this.filterForm.value.student != null ? +this.filterForm.value.student : -1,
      organizerId: this.filterForm.value.organizer != null ? +this.filterForm.value.organizer : -1,
      nationalId: this.filterForm.value.nationalId != null ? this.filterForm.value.nationalId : '',
      universityId: this.filterForm.value.university != null ? +this.filterForm.value.university : -1,
    }
    this.hereMapService.getStudentLocations(this.filter).subscribe(result => {
      this.studentsList = result;
      if (this.studentsList && this.studentsList.length > 0) {
        this.markersGroup = new H.map.Group();

        for (let i = 0; i < this.studentsList.length; i++) {
          if (this.studentsList[i].isLoggedIn) {
            if (this.coreSession.selectedSolutionId == Solution.Ambulance.valueOf())
              this.icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/ambulance-van-black.png');
            else
              this.icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/pin.png');
          }

          else {
            if (this.coreSession.selectedSolutionId == Solution.Ambulance.valueOf())
              this.icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/ambulance-van-black.png');
            else
              this.icon = new H.map.Icon(this.coreSession.baseURL + 'assets/img/pin-light.png');
          }

          this.dropMarker({ "lat": this.studentsList[i].gpsLat, "lng": this.studentsList[i].gpsLng }, this.translateService.currentLang.toLocaleLowerCase() == 'en' ? this.studentsList[i].fullNameEn.toString() : this.studentsList[i].fullNameAr.toString());
        }
        var gg = this.markersGroup.getBounds();
        this.map.setViewBounds(this.markersGroup.getBounds());
        this.map.addObject(this.markersGroup);
      }
      this.coreSession.ModalLoading.Hide();
    });
  }

  private dropMarker(coordinates: any, description: string) {
    this.marker = new H.map.Marker(coordinates, { icon: this.icon });

    this.marker.setData(description);
    this.markersGroup.addObject(this.marker);

    this.marker.addEventListener('pointerenter', event => {

      this.StudentDescription = event.target.P;
      this.isStudentOnHover = true;

      this.renderer.setStyle(this.studentNameElement.nativeElement, 'background', '#000000');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'color', 'white');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'border-radius', '10px');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'padding', '10px');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'position', 'absolute');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'z-index', '9000000');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'left', (event.originalEvent.x - 58) + 'px');
      this.renderer.setStyle(this.studentNameElement.nativeElement, 'top', (event.originalEvent.y - 40) + 'px');

    }, false);

    this.marker.addEventListener('pointerleave', event => {
      ("just left the position = ");
      this.isStudentOnHover = false;
    }, false);

    this.marker.addEventListener('tap', event => {
      this.studentDetails = event.target.P;
      let splittedDetails = this.studentDetails.toString().split('-');

      this.empId = splittedDetails[0];
      this.empDescription = splittedDetails[1];
      if (!this.mapServiceUnavailable && this.resourcesLoaded) {
        this.showVanRouteDataSlider();
      } else if (this.mapServiceUnavailable) {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('Maps Network Error'));
      } else if (!this.resourcesLoaded) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('Refresh Page'));
      }
    }, false);
  }

  showVanRouteDataSlider() {
    this.showVanRouteData = true;
    this.showSlider = true;
  }

  close() {
    this.coreSession.SetTitle('Admin Settings');
    this.showSlider = false;
    this.showVanRouteData = false;
  }

  createInfoBubble(map) {
    // Create info bubble
    // var bubble = new H.ui.InfoBubble({ lng: 13.4, lat: 52.51 }, {
    //   content: '<b>Hello World!</b>'
    // });
    // // Add info bubble to the UI:
    // this.ui.addBubble(bubble);
  }
  toggleFilter() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  onCloseFilter() {
    this.isFilterOpened = false;
  }

  onReset() {
    this.filterForm.reset();
    this.onFind();
  }

  onFind() {
    if (this.filterForm.invalid) return;
    if (this.mapServiceUnavailable) {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant('Maps Network Error'));
      return
    }
    if (!this.resourcesLoaded) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Refresh Page'));
      return
    }
    // this.selectedEmployeeId = this.filterForm.value.employeeId === null
    //   || this.filterForm.value.employeeId === "" ? -1 : this.filterForm.value.employeeId

    if (this.markersGroup)
      this.map.removeObject(this.markersGroup);
    this.prepareStudentLocations();
  }

  initFilterForm() {
    this.filterForm = new FormGroup({
      student: new FormControl(),
      organizer: new FormControl(),
      nationalId: new FormControl(),
      university: new FormControl()
    });
  }
}
