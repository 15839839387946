import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { InvoiceModel } from 'src/app/shared/model/invoice-model/invoice-model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: String,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Invoice';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  // getInvoiceList(invoiceFilter: any) {
  //   const urlValue: string = this.url + '/GetInvoiceList';
  //   //console.log(urlValue, "URL");

  //   return this.http.post(urlValue, JSON.stringify(invoiceFilter), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  getAllInvoices() {
    const urlValue: string = this.url + '/GetAllInvoices';
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  insertInvoice(invoice: InvoiceModel) {
    //console.log(invoice)
    const urlValue: string = this.url + '/InsertInvoice';
    //console.log(urlValue, "URL");
    //console.log(invoice, "invoice");
    return this.http.post(urlValue, JSON.stringify(invoice), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateInvoiceActiveStatus(invoice: InvoiceModel) {
    const urlValue: string = this.url + '/UpdateInvoiceActiveStatus';
    return this.http.post(urlValue, JSON.stringify(invoice), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }


  getInvoiceTypesList() {
    const urlValue: string = this.url + '/GetInvoiceTypesList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getInvoiceClietsList() {
    const urlValue: string = this.url + '/GetInvoiceClientsList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getInvoiceList() {
    const urlValue: string = this.url + '/GetInvoicesList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getMaxInvoiceId() {
    const urlValue: string = this.url + '/GetInvoicesMaxId';
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];

        return result;
      }
    ));
  }

  assignInvoice(invoiceId: string, assignedTo: number) {
    const urlValue: string = this.url + '/AssignInvoice?invoiceId=' + invoiceId + '&assignedTo=' + assignedTo;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

}
