export class RouteAsseginedModel {
  routeId: number;
  staffId: number;
  createdBy: number;
  totalCollected: number;
  remainingAmount: number;
  staffName: string;
  totalInvoices: number;
  status: string;
  invoiceDate: string;
  invoiceId: string;

  constructor() {
      this.routeId = -1;
      this.staffId = -1;
      this.createdBy = -1;
      this.remainingAmount = 0;
      this.staffName = '';
      this.status = '';
      this.totalCollected = 0;
      this.totalInvoices = 0;
      this.invoiceDate = '';
  }

}
