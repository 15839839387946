import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from 'src/app/core/core.session';
import { GuestsService } from '../../guests/guests.service';
import { RootHeaderComponent } from '../../root-header/root-header.component';

@Component({
  selector: 'app-facility-selection-dialog',
  templateUrl: './facility-selection-dialog.component.html',
  styleUrls: ['./facility-selection-dialog.component.css']
})
export class FacilitySelectionDialogComponent implements OnInit {

  facilityTypes: any[] = [];

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private coreSession: CoreSession,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.facilityTypes = [
      {
        id: 1,
        type: 'Breakfast',
        icon: '/assets/images/facility-management/breakfast-icon.png',
      },
      {
        id: 2,
        type: 'Lunch',
        icon: '/assets/images/facility-management/lunch-icon.png',
      },
      {
        id: 3,
        type: 'Dinner',
        icon: '/assets/images/facility-management/dinner-icon.png',
      },
    ];
  }

  onSelectFacilityType(facilityTypeId: number) {
    this.dialogRef.close(facilityTypeId);
  }

}
