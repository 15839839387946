import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders-report',
  templateUrl: './orders-report.component.html',
  styleUrls: ['./orders-report.component.css']
})
export class OrdersReportComponent implements OnInit {

  gridReportURL: string = '';

  constructor() { }

  ngOnInit(): void {
    this.gridReportURL = 'http://localhost:8080/API/Report/ShowGrid?id=';
  }

}
