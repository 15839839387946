import { TextLanguage } from "../../enum/general/textLanguage.model";
import { SecurityGroupsModel } from "../security-groups/security-groups-model";

export class StaffModel {
  staffId: number;
  staffTypeId: number
  locationId: number;
  subLocationId: number;
  staffName: string;
  staffCode: string;
  staffTypeName: string;
  staffNationalId: string;
  mobile: string;
  email: string;
  oldEmail: string;
  address: string;
  username: string;
  password: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  onHold: boolean;
  inactive: boolean;
  isOutSource: boolean;
  isUserFo: boolean;
  descriptions: TextLanguage[];
  securityGroups: SecurityGroupsModel[];
  staffImage: string;
  vehicleId: number;
  isLoggedIn: boolean;
  isSupervisor: boolean;
  supervisorId: number;
  staffNumber: string;
  locationsList: number[];
  staffGroups: number[];

  constructor() {
    this.staffId = -1;
    this.staffTypeId = -1;
    this.locationId = -1;
    this.subLocationId = -1;
    this.staffName = "";
    this.staffCode = "";
    this.staffTypeName = "";
    this.staffNationalId = "";
    this.mobile = "";
    this.email = "";
    this.address = "";
    this.username = "";
    this.password = "";
    this.confirmPassword = "";
    this.onHold = false;
    this.inactive = false;
    this.isOutSource = false;
    this.isUserFo = false;
    this.descriptions = [];
    this.securityGroups = [];
    this.staffImage = "";
    this.oldEmail = "";
    this.vehicleId = -1;
    this.isLoggedIn = false;
    this.isSupervisor = false;
    this.supervisorId = -1;
    this.staffNumber = '';
    this.locationsList = [];
    this.staffGroups = [];
  }
}
