import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { map } from 'rxjs/operators';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { SupplierModel } from 'src/app/shared/model/supplier-models/supplier.model';

@Injectable({
    providedIn: 'root'
})
export class SuppliersService {

    httpOptions;
    private url = '';
    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'Suppliers';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    insertSupplier(newSupplier: SupplierModel, isEditMode: boolean) {
        const URLActionName = isEditMode ? '/UpdateSupplier' : '/InsertSupplier'
        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(newSupplier), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    insertSupplierList(supplierList: SupplierModel[], showErrorList: boolean) {
        let URLActionName: string = '/InsertSupplierList';

        let params = new HttpParams();
        params = params.append('showErrorList', showErrorList.toString());

        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(supplierList), {
            headers: this.httpOptions.headers,
            params: params
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getSupplierData(supplierId: number) {
        const urlValue: string = this.url + '/GetSupplierData';
        return this.http.post(urlValue, supplierId, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getSuppliersList(SuppliersFilter: any) {
        const urlValue: string = this.url + '/GetSuppliersList';
        return this.http.post<ResponseModel>(urlValue, JSON.stringify(SuppliersFilter), this.httpOptions).pipe(map((response) => {
            const result = new ResponseModel();
            result.data = response['data'];
            result.message = response['message'];
            result.status = response['status'];
            return result;
        }
        ));
    }

    updateSupplierActiveStatus(supplierId: number, inactive: boolean) {
        const urlValue: string = this.url + '/UpdateSupplierActiveStatus?supplierId='+supplierId + '&inactive='+ inactive;
        return this.http.get(urlValue, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    public exportSuppliersToExcel(suppliersList: SupplierModel[]) {

        let workBook = new Workbook();
        let workSheet = workBook.addWorksheet('Locations', { properties: { defaultColWidth: 30 } });

        let row = workSheet.addRow(["sp_code", "sp_description"]);
        row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
        row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        row.height = 35;

        let sp = new SupplierModel();
        for (let i = 0; i < suppliersList.length; i++) {
            sp = suppliersList[i];
            row = workSheet.addRow(
                [
                    sp.supplierCode,
                    sp.supplierName,
                ]
            );
            row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }

        workBook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'INV_Suppliers' + '.xlsx');
        });

    }

}
