export enum messageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO'
}

export enum classMessageType {
  SUCCESS = 'success-snackbar',
  ERROR = 'error-snackbar',
  WARN = 'warning-snackbar'
}
