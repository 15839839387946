import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { InvoiceModel } from 'src/app/shared/model/invoice-model/invoice-model';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { InvoiceService } from '../invoice.service';
import { Subject, Observable } from 'rxjs';
import { SerialModel } from 'src/app/shared/model/invoice-model/serial-model';
import { Solutions } from 'src/app/shared/enum/themes-mode';

@Component({
  selector: 'app-invoice-entry',
  templateUrl: './invoice-entry.component.html',
  styleUrls: ['./invoice-entry.component.css']
})
export class InvoiceEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedInvoiceData: InvoiceModel;
  @Input() isViewMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();
  @Input() invoiceIds: string[] = [];
  // Not Collected, Fully Collected and Partially Collected
  invoiceStatusList = [
    { value: 1, lable: "Desc_NotCollected" },
    { value: 2, lable: "Desc_FullyCollected" },
    { value: 3, lable: "Desc_PartiallyCollected" }
  ];
  invoiceDataSource: any[];
  invoiceClientsDataSource: any[];

  isInvoiceLoading = false;
  selectedInvoiceId: number;


  invoiceObj: InvoiceModel;
  serialInvoiceObj: SerialModel;
  saveBtnSubscription: any;

  form: FormGroup;
  serialForm: FormGroup;

  formSubmitted = false;
  otherLanguages: any[] = [];
  name: ITextInput = {
    formControlName: "InvoiceName",
    placeHolder: "Desc_InvoiceName",
    label: "Desc_InvoiceName",
    isRequired: true
  };

  invoiceTypesList: any[] = [];
  serialInvoiceData: SerialModel;
  isTypesLoading = false;

  clientsList: any[] = [];
  isClientsLoading = false;
  public color: any = Solutions.main;
  newId: string;

  constructor(private invoiceService: InvoiceService, private coreSession: CoreSession, private translateService: TranslateService) {

  }

  ngOnInit(): void {
    //console.log(this.selectedInvoiceData);
    this.populateInvoiceTypesList();
    this.populateInvoiceClientsList();
    this.subscribeSaveClick();
    this.initForm();
    this.initserialForm();
  }

  private initserialForm() {
    let invoiceId = this.isViewMode ? this.selectedInvoiceData.serialInvoice.invoiceId : '0';
    let fromSerial = this.isViewMode ? this.selectedInvoiceData.serialInvoice.fromSerial : '';
    let SerialStatus = this.isViewMode ? this.selectedInvoiceData.serialInvoice.serialStatus : false;
    let toSerial = this.isViewMode ? this.selectedInvoiceData.serialInvoice.toSerial : '';

    this.serialForm = new FormGroup({
      invoiceId: new FormControl({ value: invoiceId, disabled: true }, [Validators.required]),
      toSerial: new FormControl({ value: toSerial, disabled: !this.isViewMode ? false : true }, [Validators.required]),
      fromSerial: new FormControl({ value: fromSerial, disabled: !this.isViewMode ? false : true }, [Validators.required]),
      serialStatus: new FormControl(SerialStatus),
    });
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  populateInvoiceTypesList() {
    this.isInvoiceLoading = true;
    this.invoiceService.getInvoiceTypesList().subscribe(result => {
      //console.log(result);
      this.invoiceDataSource = result;
      if (this.invoiceDataSource && this.invoiceDataSource.length > 0 && !this.isViewMode) {
        this.invoiceTypeIdControl.setValue(this.invoiceDataSource[0].invoiceTypeId);
      }
      this.isInvoiceLoading = false;
    });
  }
  populateInvoiceClientsList() {
    this.isClientsLoading = true;
    this.invoiceService.getInvoiceClietsList().subscribe(result => {
      this.invoiceClientsDataSource = result;
      if (this.invoiceClientsDataSource && this.invoiceClientsDataSource.length > 0 && !this.isViewMode) {
        this.invoiceClientIdControl.setValue(this.invoiceClientsDataSource[0].clientId);
      }
      this.isClientsLoading = false;
    });
  }
  initForm() {

    let invoiceId = this.isViewMode ? this.selectedInvoiceData.invoiceId : '';
    let invoiceDate = this.isViewMode ? this.selectedInvoiceData.invoiceDateModel.date : Date.now();
    let clientId = this.isViewMode && this.selectedInvoiceData
      && this.selectedInvoiceData.clientId && this.selectedInvoiceData.clientId
      > 0 ?
      this.selectedInvoiceData.clientId : undefined;
    let createdBy = this.isViewMode ? this.selectedInvoiceData.createdBy : 0;
    let modifiedBy = this.isViewMode ? this.selectedInvoiceData.modifiedBy : 0;
    let remainingAmount = this.isViewMode ? this.selectedInvoiceData.remainingAmount : 0;
    let netTotal = this.isViewMode ? this.selectedInvoiceData.netTotal : 0;
    let tax = this.isViewMode ? this.selectedInvoiceData.tax : 0;
    let discount = this.isViewMode ? this.selectedInvoiceData.discount : 0;
    let quantity = this.isViewMode ? this.selectedInvoiceData.quantity : 0;
    let invoiceTypeId = this.isViewMode && this.selectedInvoiceData
      && this.selectedInvoiceData.invoiceTypeId && this.selectedInvoiceData.invoiceTypeId
      > 0 ?
      this.selectedInvoiceData.invoiceTypeId : undefined;
    let invoiceTypeDescription = this.isViewMode ? this.selectedInvoiceData.invoiceTypeDescription : "";
    let clientDescription = this.isViewMode ? this.selectedInvoiceData.clientDescription : "";
    let status = this.isViewMode ? this.selectedInvoiceData.status : undefined;


    this.form = new FormGroup({
      invoiceId: new FormControl({ value: invoiceId, disabled: true }, [Validators.required]),
      invoiceDate: new FormControl({ value: (new Date()).toISOString().substring(0, 10), disabled: !this.isViewMode ? false : true }, [Validators.required]),
      clientId: new FormControl({ value: clientId, disabled: !this.isViewMode ? false : true }, [Validators.required]),
      createdBy: new FormControl({ value: createdBy, disabled: !this.isViewMode ? false : true }),
      modifiedBy: new FormControl({ value: modifiedBy, disabled: !this.isViewMode ? false : true }),
      remainingAmount: new FormControl({ value: netTotal, disabled: !this.isViewMode ? false : true }),
      netTotal: new FormControl({ value: netTotal, disabled: !this.isViewMode ? false : true }, Validators.required),
      tax: new FormControl({ value: tax, disabled: !this.isViewMode ? false : true }, Validators.required),
      discount: new FormControl({ value: discount, disabled: !this.isViewMode ? false : true }),
      quantity: new FormControl({ value: quantity, disabled: !this.isViewMode ? false : true }),
      invoiceTypeId: new FormControl({ value: invoiceTypeId, disabled: this.isViewMode }, [Validators.required]),
      invoiceTypeDescription: new FormControl({ value: invoiceTypeDescription, disabled: !this.isViewMode ? false : true }, [Validators.required]),
      clientDescription: new FormControl({ value: clientDescription, disabled: !this.isViewMode ? false : true }, [Validators.required]),
      status: new FormControl({ value: status, disabled: !this.isViewMode ? false : true }, [Validators.required])

    });

    if (!this.isViewMode) {
      this.getMaxInvoiceId();
    }
  }

  private getMaxInvoiceId() {
    this.invoiceService.getMaxInvoiceId().subscribe(res => {
      if (res.status > -1) {
        this.newId = res.data;
        this.invoiceIdControl.setValue(res.data);
      }
    });
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareInvoiceObj() {
    this.prepareInvoiceSerialObj();
    this.invoiceObj = new InvoiceModel();

    // let date = this.coreSession.validateDate(this.invoiceDateControl.value, 'dd-MM-yyyy');

    let dateModel = this.coreSession.getDateTimeModel(this.invoiceDateControl.value);

    this.invoiceObj.invoiceId = this.invoiceIdControl.value;
    this.invoiceObj.invoiceDateModel = dateModel;
    this.invoiceObj.clientId = this.invoiceClientIdControl && this.invoiceClientIdControl.value ? this.invoiceClientIdControl.value : -1;
    this.invoiceObj.createdBy = this.invoiceCreatedByControl.value;
    this.invoiceObj.modifiedBy = this.invoiceModifiedByControl.value;
    this.invoiceObj.remainingAmount = this.invoiceNetTotalControl.value;
    this.invoiceObj.netTotal = this.invoiceNetTotalControl.value;
    this.invoiceObj.tax = this.invoiceTaxControl.value ?? 0;
    this.invoiceObj.discount = this.invoiceDiscountControl.value ?? 0;
    this.invoiceObj.quantity = this.invoiceQuantityControl.value ?? 0;
    this.invoiceObj.invoiceTypeId = this.invoiceTypeIdControl.value ?? -1;
    //this.invoiceTypeIdControl && this.invoiceTypeIdControl.value ? this.invoiceTypeIdControl.value : 1;
    this.invoiceObj.invoiceTypeDescription = this.invoiceTypeDescriptionControl.value ?? '';
    this.invoiceObj.clientDescription = this.clientDescriptionControl.value ?? '';
    this.invoiceObj.serialInvoice = this.serialInvoiceObj;
    this.invoiceObj.status = this.statusControl.value != null ? this.statusControl.value : -1;
    this.invoiceObj.assignedTo = -1;
    this.invoiceObj.grossTotal = this.invoiceNetTotalControl.value;
  }


  prepareInvoiceSerialObj() {
    this.serialInvoiceObj = new SerialModel();
    this.serialInvoiceObj.invoiceId = this.invoiceIdControl.value;
    this.serialInvoiceObj.fromSerial = this.fromSerialControl.value != null ? this.fromSerialControl.value.toString() : '';
    this.serialInvoiceObj.toSerial = this.fromSerialControl.value != null ? this.fromSerialControl.value : '';//this.toSerialControl.value;
    this.serialInvoiceObj.serialStatus = this.invoiceSerilStatusControl.value ?? -1;
  }

  onSaveClicked() {
    this.prepareInvoiceObj();
    //console.log(this.invoiceObj);

    if (this.invoiceObj.tax <= 0 && this.invoiceObj.netTotal <= 0 && this.invoiceObj.invoiceTypeId <= -1 && this.invoiceObj.clientId <= -1) {
      this.form.markAllAsTouched();
      return this.form.invalid;
    }
    else {
      if (this.invoiceObj != null) {
        this.coreSession.ModalLoading.Show();
        this.invoiceService.insertInvoice(this.invoiceObj).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {

            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.onSaveCompleted.emit();
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
          }
        },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          }
        );
      }
    }
  }
  onInvoiceIdDataChanges(invoiceId: any) {
    this.selectedInvoiceId = +this.invoiceIdControl.value;
  }
  onInvoiceDataChanged(invoice: any) {
    this.selectedInvoiceId = this.invoiceTypeIdControl.value;

  }
  onInvoiceClientsDataChanged(invoiceClients: any) {
    this.selectedInvoiceId = this.invoiceClientIdControl.value;

  }
  onDateChoosen(invoiceDate: any) {
    this.selectedInvoiceId = this.invoiceDateControl.value;
  }


  //Form Getters
  get invoiceIdControl() {
    return this.form.get('invoiceId');
  }

  get invoiceDateControl() {
    return this.form.get("invoiceDate");
  }

  get invoiceClientIdControl() {
    return this.form.get('clientId');
  }

  get invoiceCreatedByControl() {
    return this.form.get('createdBy');
  }

  get invoiceModifiedByControl() {
    return this.form.get('modifiedBy');
  }

  get invoiceRemainingAmountControl() {
    return this.form.get('netTotal');
  }

  get invoiceNetTotalControl() {
    return this.form.get('netTotal');
  }

  get invoiceTaxControl() {
    return this.form.get('tax');
  }

  get invoiceDiscountControl() {
    return this.form.get('discount');
  }

  get invoiceQuantityControl() {
    return this.form.get('quantity');
  }

  get invoiceTypeIdControl() {
    return this.form.get('invoiceTypeId');
  }

  get invoiceTypeDescriptionControl() {
    return this.form.get('invoiceTypeDescription');
  }

  get clientDescriptionControl() {
    return this.form.get('clientDescription');
  }

  get statusControl() {
    return this.form.get('status');
  }

  //********************** Serial controls ***************************

  get toSerialControl() {
    return this.serialForm.get('toSerial');
  }

  get fromSerialControl() {
    return this.serialForm.get('fromSerial');
  }

  get invoiceSerilStatusControl() {
    return this.serialForm.get('serialStatus');
  }












}
