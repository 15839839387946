import { DateTimeModel } from "../dateTimeModel/date-time.model";

export class StudentModel {
    studentId: number;
    gender: string;
    nationalId: string;
    dateOfBirth: Date;
    dateOfBirthModel: DateTimeModel;
    address: string;
    mobileNumber: string;
    whatsappNumber: string;
    emailAddress: string;
    locationName: string;
    cardNumber: string;
    locationId: number;
    nationality: string;
    nationalityId: number;
    major: string;
    governorate: string;
    governorateId: number;
    preferableHours: HoursRangeModel[];
    preferableDays: string;
    studentStatusId: number;
    inactive: boolean;
    password: string;
    isDisabilityAvailable: boolean;
    disabilityDescription: string;
    isChronicDiseasesAvailable: boolean;
    chronicDiseasesDescription: string;
    registeredFromPortal: boolean;
    rejectionReason: string;
    uploadedFile: string;
    selectedProgramId: number;
    isPassed: boolean;
    isPreAssessmentFilled: boolean;
    isPostAssessmentFilled: boolean;
    showCertificate: boolean;
    gpsLat: number;
    gpsLng: number;
    studentFullName: string;
    selectedProgramName: string;
    age: number;
    universityNumber: string;
    creationDateModel: DateTimeModel;
    isLoggedIn: boolean;
    duplicateReason: string;
    invalidReason: string;
    certificateAvailable: boolean;
    fullNameAr: string;
    fullNameEn: string;

    constructor() {
        this.studentId = -1;
        this.nationalId = '';
        this.gender = '';
        this.address = '';
        this.mobileNumber = '';
        this.whatsappNumber = '';
        this.emailAddress = '';
        this.locationName = '';
        this.cardNumber = '';
        this.governorate = '';
        this.major = '';
        this.nationality = '';
        this.locationId = -1;
        this.preferableHours = [];
        this.preferableDays = '';
        this.chronicDiseasesDescription = '';
        this.isChronicDiseasesAvailable = false;
        this.disabilityDescription = '';
        this.isDisabilityAvailable = false;
        this.password = '';
        this.inactive = false;
        this.studentStatusId = -1;
        this.dateOfBirthModel = new DateTimeModel();
        this.registeredFromPortal = false;
        this.rejectionReason = '';
        this.uploadedFile = '';
        this.selectedProgramId = -1;
        this.isPassed = false;
        this.isPostAssessmentFilled = false;
        this.isPreAssessmentFilled = false;
        this.showCertificate = false;
        this.gpsLat = 0.0;
        this.gpsLng = 0.0;
        this.studentFullName = '';
        this.selectedProgramName = '';
        this.age = 0;
        this.universityNumber = '';
        this.creationDateModel = new DateTimeModel();
        this.nationalityId = -1;
        this.governorateId = -1;
        this.isLoggedIn = false;
        this.duplicateReason = '';
        this.invalidReason = '';
        this.certificateAvailable = false; 
        this.fullNameAr = '';
        this.fullNameEn = '';
    }
}

export class HoursRangeModel {
    rangeId: number;
    timeRange: string;
    fromTime: Date;
    toTime: Date;
    fromTimeModel: DateTimeModel;
    toTimeModel: DateTimeModel;

    constructor() {
        this.rangeId = -1;
        this.timeRange = '';
    }
}