import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { PortalMode } from 'src/app/shared/enum/portal-mode.enum';
import { LoginService } from '../../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-delmonte-home-page',
  templateUrl: './delmonte-home-page.component.html',
  styleUrls: ['./delmonte-home-page.component.css']
})
export class DelmonteHomePageComponent implements OnInit {

  portraitMode: boolean = false;
  landescapeMode: boolean = true;
  isAuthentic: boolean = true;
  isFake: boolean = false;
  date: Date;

  constructor(private coreSession: CoreSession,
    private loginService: LoginService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    let ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.portraitMode = true;
      this.landescapeMode = false;
    }
    else if (/Chrome/i.test(ua)) {
      this.portraitMode = false;
      this.landescapeMode = true;
    }
    else {
      this.portraitMode = false;
      this.landescapeMode = true;
    }

    setInterval(t => {
      if (navigator.userAgent !== ua) {
        this.uaChanged(ua, navigator.userAgent);
        ua = navigator.userAgent;
      }
    }, 1000);

    if (this.coreSession.selectedPortalMode == PortalMode.DelMonteAuthentic.valueOf()) {
      this.isAuthentic = true;
      this.isFake = false;
    }
    if (this.coreSession.selectedPortalMode == PortalMode.DelMonteFake.valueOf()) {
      this.isAuthentic = false;
      this.isFake = true;
    }
    this.date = new Date();

    this.checkIfAuthenticated();
  }

  uaChanged(prevUa, newUa) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(newUa)) {
      this.portraitMode = true;
      this.landescapeMode = false;
    }
    else if (/Chrome/i.test(newUa)) {
      this.portraitMode = false;
      this.landescapeMode = true;
    }
    else {
      this.portraitMode = false;
      this.landescapeMode = true;
    }
  }


  checkIfAuthenticated() {
    this.coreSession.ModalLoading.Show();
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        let batchNumber = params.batchNo ?? '';
        console.log(batchNumber); // price
        this.loginService.checkIfAuthenticated(batchNumber).subscribe(result => {
          if (result.status >= 0) {

            // this.coreSession.SetTitle("Login");
            this.coreSession.ModalLoading.Hide();
            this.isAuthentic = true;
            this.isFake = false;
          } else {
            this.coreSession.ModalLoading.Hide();
            this.isAuthentic = false;
            this.isFake = true;
            // this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(result.message));
          }
        }, (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
      }
      );
  }



}