import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { NavBarService } from './sidebar.service';
import { CoreSession } from 'src/app/core/core.session';
import { Router } from '@angular/router';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';
import { OperatorModel } from 'src/app/shared/model/operator/operator.model';
import { Background, Color, FontTypes, Solutions } from 'src/app/shared/enum/themes-mode';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { ConstantStorage } from 'src/app/core/constants/constant-stroage';
import { Client } from 'src/app/shared/enum/client.enum';

@Component({
  selector: 'app-root-nav',
  templateUrl: './root-nav.component.html',
  styleUrls: ['./root-nav.component.css']
})
export class RootNavComponent {
  public isMinimized = true;
  public urls;
  public CurrentUser: OperatorModel;
  // public menus: MenuModel[] = [];
  public active: false;
  collapsingArray;
  indexCounter: number;
  currenctIndex;
  open = false;
  navLinks: any[] = [];
  urlValue: string = "";
  groundValue = Solutions.main;
  public selectedIndex;
  showMenuLabels: boolean = false;
  royal: boolean = false;
  hilton: boolean = false;
  kasotic: boolean = false;
  iec: boolean = false;

  constructor(private navService: NavBarService, public coreSession: CoreSession,
    private sessionDataProvider: SessionDataProvider, private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2) {
    this.urls = ConstantURLs;
    if (sessionStorage.length > 0) {
      this.showMenuLabels = sessionStorage.getItem(ConstantStorage.ShowMenuTitles) == 'true';
      this.showMenuLabelsChanged();
    } else {
      sessionStorage.setItem(ConstantStorage.ShowMenuTitles, this.showMenuLabels.toString());
    }
  }
  ngOnInit() {
    this.royal = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.LeRoyal.valueOf();
    this.hilton = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.Hilton_Deadsea.valueOf();
    this.kasotic = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf();
    this.iec = this.coreSession.selectedSolutionId === Solution.IEC.valueOf();
    // this.prepareNavigation();

    this.CurrentUser = this.coreSession.currentUser;
    this.navService.MinimizedClicked.subscribe(
      (isMin: boolean) => {
        this.isMinimized = isMin;
      }
    );

    this.navService.ReloadSidebar.subscribe(
      () => {
        this.prepareNavigation();
      });
    this.prepareNavigation();
    this.navService.ActiveSidebar.subscribe(
      (menuUrl: string) => {

        this.navLinks.forEach(a => {
          a.isActive = false;
        });

        for (let i = 0; i < this.navLinks.length; i++) {
          if (this.navLinks[i].link.split("/")[1].includes(menuUrl)) {
            this.navLinks[i].isActive = true;
          }
        }
      }
    );
  }


  onclick(index: number) {
    this.currenctIndex = this.collapsingArray[index];
    this.collapsingArray.fill(true);
    this.collapsingArray[index] = !this.currenctIndex;
  }
  prepareNavigation() {
    // //console.log("ACTION.. START");
    this.CurrentUser = this.coreSession.currentUser;
    this.indexCounter = 0;

    this.navLinks = [];
    for (let menu of this.sessionDataProvider.allMenusAvailable) {
      // loop on web parent menus (sidebar menus)
      if (!menu.menuId.includes('-')) {
        this.indexCounter = this.indexCounter + 1;
        let index = this.sessionDataProvider.allMenusAvailable.findIndex(x => x.parentMenuId === menu.menuId);
        // data definitions && settings && ROI : no redirect on any child
        if (menu.menuId === "100" || menu.menuId === '800') {
          this.urlValue = menu.url;
        } else {
          this.urlValue = menu.url;
        }

        let newName = {
          label: menu.menuName,
          menuId: menu.menuId,
          link: '/' + this.urlValue,
          icon: menu.icon,
          index: this.indexCounter,
          isActive: false
        };
        this.navLinks.push(newName);
      }
    }
    if (this.router.url == '/') {
      this.router.navigate(['/' + this.sessionDataProvider.addedMenus[0].url]);
      this.navLinks[0].isActive = true;
      this.setSelectedMenu(0, this.navLinks[0].label);
    } else {
      for (let i = 0; i < this.navLinks.length; i++) {
        if (this.navLinks[i].link.split('/')[1].includes(this.router.url.split('/')[1])) {
          this.navLinks[i].isActive = true;
          this.setSelectedMenu(i, this.navLinks[i].label);
        }
      }
    }

  }

  showMenuLabelsChanged() {
    this.navService.setShowMenuLabels(this.showMenuLabels);
    sessionStorage.setItem(ConstantStorage.ShowMenuTitles, this.showMenuLabels.toString());
  }

  // prepareNavigation() {
  //   this.CurrentUser = this.coreSession.currentUser;
  //   this.indexCounter = 0;

  //   this.navLinks = [];


  //   let newName = {
  //     label: "Desc_DataDefinition",
  //     menuId: "100",
  //     link: '/' + "data-definition",
  //     icon: "/assets/images/sidenav/data-definition.png",
  //     index: 0,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_Staff",
  //     menuId: "200",
  //     link: '/' + "staff",
  //     icon: "/assets/images/sidenav/staff.png",
  //     index: 1,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_Client",
  //     menuId: "300",
  //     link: '/' + "client",
  //     icon: "/assets/images/sidenav/clients.png",
  //     index: 2,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_ClientOperation",
  //     menuId: "400",
  //     link: '/' + "client-operation",
  //     icon: "/assets/images/sidenav/client-operations.png",
  //     index: 3,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_Route",
  //     menuId: "500",
  //     link: '/' + "route",
  //     icon: "/assets/images/sidenav/route.png",
  //     index: 4,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_AccountReceivable",
  //     menuId: "600",
  //     link: '/' + "invoice",
  //     icon: "/assets/images/sidenav/invoices.png",
  //     index: 5,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);

  //   newName = {
  //     label: "Desc_Setting",
  //     menuId: "700",
  //     link: '/' + "setting",
  //     icon: "/assets/images/sidenav/settings.png",
  //     index: 6,
  //     isActive: false
  //   };
  //   this.navLinks.push(newName);
  //   //
  //   //
  //   for (let i = 0; i < this.navLinks.length; i++) {
  //     if (this.navLinks[i].link.split('/')[1].includes(this.router.url.split('/')[1])) {
  //       this.navLinks[i].isActive = true;
  //       this.setSelectedMenu(i,this.navLinks[i].label)
  //     }
  //   }
  // }

  setSelectedMenu(i: any, menu: any): any {
    // //console.log("SELECTED WILL CHANGE " + menu);

    this.selectedIndex = i;
    this.coreSession.pageTitle.next(menu);
  }
}
