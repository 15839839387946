import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { ItemModel } from 'src/app/shared/model/item-models/item.model';
@Injectable({
  providedIn: 'root'
})

export class LocationService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Location';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getLocationList(locationFilter: any) {
    const urlValue: string = this.url + '/GetLocationsList';
    return this.http.post(urlValue, JSON.stringify(locationFilter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  getLocationTypesList() {
    const urlValue: string = this.url + '/GetLocationTypesList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getLocationRequiredLists() {
    const urlValue: string = this.url + '/GetLocationRequiredLists';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {

      return <any>response.data;
    }
    ));
  }

  getLocationData(selectedLocation: any) {
    const urlValue: string = this.url + '/GetLocationData';
    return this.http.post(urlValue, JSON.stringify(selectedLocation), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateLocationActiveStatus(locationId: number, inactive: boolean) {
    const urlValue: string = this.url + '/UpdateLocationActiveStatus?locationId=' + locationId + '&inactive=' + inactive;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertLocation(location: LocationModel, isEditMode: boolean) {
    let URLActionName: string = isEditMode ? '/UpdateLocation' : '/InsertLocation';

    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(location), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertLocationList(locationList: LocationModel[], showErrorList: boolean) {
    let URLActionName: string = '/InsertLocationList';

    const urlValue: string = this.url + URLActionName;

    let params = new HttpParams();
    params = params.append('showErrorList', showErrorList.toString());

    return this.http.post(urlValue, JSON.stringify(locationList), {
      headers: this.httpOptions.headers,
      params: params
    }).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  public exportLocationsToExcel(locationsList: LocationModel[]) {

    let workBook = new Workbook();
    let workSheet = workBook.addWorksheet('Locations', { properties: { defaultColWidth: 30 } });

    let row = workSheet.addRow(["wh_code", "wh_description"]);
    row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
    row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    row.height = 35;

    let location = new LocationModel();
    for (let i = 0; i < locationsList.length; i++) {
      location = locationsList[i];
      row = workSheet.addRow(
        [
          location.locationCode,
          location.locationName,
        ]
      );
      row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    }

    workBook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'INV_Locations' + '.xlsx');
    });

  }
}
