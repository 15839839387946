export enum Client {
    None = 0,
    LeRoyal = 1,
    Hilton_Deadsea,
    IEC_Jordan,
    TaskManagement,
    Inventory_Dubai,
    Inventory_Kuwait,
    Inventory_Tunisia,
    Inventory_Palestine,
    Kasotc,
    Transguard,
    Inventory_INCUBE,
    Inventory_Ultra_Vision,
    Inspection_Fertilizer,
    Inventory_Jomaa_AlMajed,
    Assets_Eqbal,
    Inventory_ToyTriangle,
    Car_Rental,
    Customer_Satisfaction,
    Inspection_Tobacco,
    Assets_LSS,
    Inspection_Ambulance,
    Devastation_Inspection
}
