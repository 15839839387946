import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { CoreSession } from 'src/app/core/core.session';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';
import { InventorySolution, Solution } from 'src/app/shared/enum/solution.enum';
import { ExportMode, FilterDialogComponent, ReportType } from '../filter-dialog/filter-dialog.component';
import { Client } from 'src/app/shared/enum/client.enum';
import { ReportService } from '../reports.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports-main',
  templateUrl: './reports-main.component.html',
  styleUrls: ['./reports-main.component.css']
})
export class ReportsMainComponent implements OnInit {

  selectedSolutionId: number = -1;
  navLinks: any[];
  indexCounter: number = -1;
  showExcel: boolean = false;
  showPDF: boolean = false;

  constructor(private dialog: MatDialog,
    private coreSession: CoreSession,
    private router: Router,
    private route: ActivatedRoute,
    private sessionDataProvider: SessionDataProvider,
    private reportService: ReportService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.selectedSolutionId = this.coreSession.selectedSolutionId;
    this.showPDF = this.selectedSolutionId == Solution.Inventory.valueOf() &&
      (CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()
        || CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf());
    this.showExcel = this.selectedSolutionId == Solution.Inventory.valueOf() &&
      (CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()
        || CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()
        || CoreSession.selectedClient == Client.Inventory_Palestine.valueOf());
    this.prepareNavigation();
  }

  prepareNavigation() {
    this.navLinks = [];

    for (let menu of this.sessionDataProvider.allMenusAvailable) {
      if (menu.parentMenuId === '500') {

        this.indexCounter = this.indexCounter + 1

        let newName = {
          label: menu.menuName,
          link: menu.url,
          index: this.indexCounter,
          reportId: menu.reportId ?? -1
        };
        this.navLinks.push(newName);
        //console.log(this.navLinks, 'NavLinks');

      }
    }
  }

  onStockCountingTotalsReportSelected() {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '35%',
      height: 'auto',
      data: {
        exportMode: ExportMode.PDF,
        reportType: ReportType.StockCountingTotals.valueOf(),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result) {

      }
    });
  }

  onGroupByItemsReportSelected() {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '35%',
      height: 'auto',
      data: {
        exportMode: ExportMode.PDF,
        reportType: ReportType.StockCountingGroupedByItem.valueOf(),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result) {

      }
    });
  }

  onStockCountingSelected() {
    if (CoreSession.selectedClient == Client.Inventory_Dubai.valueOf()) {
      this.router.navigate([ConstantURLs.stockCountingReportURL], { relativeTo: this.route });
    }
    else {
      const dialogRef = this.dialog.open(FilterDialogComponent, {
        width: '35%',
        height: 'auto',
        data: {
          exportMode: ExportMode.PDF,
          reportType: ReportType.StockCountingDetails.valueOf(),
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //console.log(result, 'Dialog result');
        if (result) {

        }
      });
    }
  }
  onInventorySheetSelected() {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '35%',
      height: 'auto',
      data: {
        exportMode: ExportMode.Excel,
        reportType: ReportType.StockCountingDetails.valueOf(),
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result) {

      }
    });
  }

  goToReport(link: any) {
    if (link.reportId > -1)
      this.router.navigate(['dynamic-report', link.reportId, link.label], { relativeTo: this.route });
    else
      this.router.navigate([link.link], { relativeTo: this.route });
  }

}
