import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-layout',
  template: `
  <app-main></app-main>
  `,
  styles: [],
})
export class HomeLayoutComponent {
  constructor(private router: Router){

  }
}
