
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ConstantURLs } from "../constants/constant-URL";
import { CoreSession } from "../core.session";
import { PortalMode } from "src/app/shared/enum/portal-mode.enum";


@Injectable()
export class LoginAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private coreSession: CoreSession
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {

    if (this.coreSession.selectedPortalMode == PortalMode.Student.valueOf()) {
      this.router.navigate(["/", ConstantURLs.studentRegisterationURL]);
      return false;
    } else if (this.coreSession.selectedPortalMode == PortalMode.DelMonteAuthentic.valueOf() || this.coreSession.selectedPortalMode == PortalMode.DelMonteFake.valueOf()) {
      this.router.navigate(["/", ConstantURLs.homePageURL]);
      return false;
    } else {
      return true;
    }
  }

}
