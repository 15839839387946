import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientOperationListComponent } from './content/client-operation/client-operation-list/client-operation-list.component';
import { ClientOperationMainComponent } from './content/client-operation/client-operation-view/client-operation-main/client-operation-main.component';
import { NumberOfGuestsComponent } from './content/guests/number-of-guests/number-of-guests.component';
import { InvoiceComponent } from './content/invoice/invoice-list/invoice.component';
import { LoginComponent } from './content/login/login.component';
import { RouteStationsListComponent } from './content/route-stations/route-stations-list/route-stations-list.component';
import { RouteComponent } from './content/route/route-list/route.component';
import { AuthGuard } from './core/auth/auth.gurad';
import { ConstantURLs } from './core/constants/constant-URL';
import { HomeLayoutComponent } from './content/home/home-layout.component';
import { ReportsMainComponent } from './content/reports/reports-main/reports-main.component';
import { StudentsEntryComponent } from './content/attendance-management/students-definition/students-entry/students-entry.component';
import { LoginAuthGuard } from './core/auth/login-auth.gurad';
import { HereMapComponent } from './content/maps/here-map/here-map.component';
import { LecturesReportListComponent } from './content/reports/lectures-report/lectures-report-list/lectures-report-list.component';
import { StudentsReportComponent } from './content/reports/students-report/students-report/students-report.component';
import { OrganizersAttendanceListComponent } from './content/reports/organizers/organizers-attendance-list/organizers-attendance-list.component';
import { StudentFeedbackListComponent } from './content/reports/students-report/students-feedback-report/student-feedback-list/student-feedback-list.component';
import { StockCountingReportComponent } from './content/reports/inventory-reports/stock-counting-report/stock-counting-report.component';
import { AssetReportComponent } from './content/reports/asset-reports/asset-report/asset-report.component';
import { SessionsAttendanceReportComponent } from './content/reports/sessions-attendance-report/sessions-attendance-report.component';
import { FacilityManagementMainComponent } from './content/facility-management/facility-management-main/facility-management-main.component';
import { WeaponsSummaryReportComponent } from './content/reports/weapons-summary-report/weapons-summary-report.component';
import { OrdersReportComponent } from './content/reports/orders-report/orders-report.component';
import { InboundReportComponent } from './content/reports/inventory-reports/inbound-report/inbound-report.component';
import { OutboundReportComponent } from './content/reports/inventory-reports/outbound-report/outbound-report.component';
import { DelmonteHomePageComponent } from './content/del-monte/delmonte-home-page/delmonte-home-page.component';
import { DynamicReportComponent } from './content/reports/dynamic-report/dynamic-report.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginAuthGuard],
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: '', redirectTo: 'data-definition/home', pathMatch: 'full' },
      {
        path: "data-definition",
        loadChildren: () => import('./content/data-definition/data-definition.module').then(m => m.DataDefinitionModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: '',
        children: [
          {
            path: 'client-operation',
            component: ClientOperationListComponent,
          },
          {
            path: 'client-operation/client-operation-view/:id',
            component: ClientOperationMainComponent,
          },
        ]
      },
      {
        path: 'route',
        component: RouteComponent,
      },
      {
        path: ConstantURLs.routeStationsURL,
        component: RouteStationsListComponent,
      },
      {
        path: 'invoice',
        component: InvoiceComponent,
      },
      {
        path: ConstantURLs.documentOperationURL,
        loadChildren: () => import('./content/document-operation/document-operation.module').then(m => m.DocumentOperationModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.settingsURL,
        loadChildren: () => import('./content/settings/settings.module').then(m => m.SettingsModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.samplesURL,
        loadChildren: () => import('./content/samples/samples.module').then(m => m.SamplesModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.ticketsURL,
        loadChildren: () => import('./content/tickets/tickets.module').then(m => m.TicketsModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.armoryOperationsURL,
        loadChildren: () => import('./content/armory-operations/armory-operations.module').then(m => m.ArmoryOperationsModule)
      },
      {
        path: ConstantURLs.warehouseOperationsURL,
        loadChildren: () => import('./content/armory-operations/armory-operations.module').then(m => m.ArmoryOperationsModule)
      },
      {
        path: ConstantURLs.ordersManagementURL,
        loadChildren: () => import('./content/orders-management/orders.module').then(m => m.OrdersModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.guestsMainURL,
        component: NumberOfGuestsComponent
      },
      {
       path: ConstantURLs.locationOperationsURL,
       loadChildren: () => import('./content/location-operations/location-operations.module').then(m => m.LocationOperationsModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.vehicleOperationsURL,
        loadChildren: () => import('./content/vehicle-operations/vehicle-operations.module').then(m => m.VehicleOperationsModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.attendanceManagementURL,
        loadChildren: () => import('./content/attendance-management/attendance-management.module').then(m => m.AttendanceManagementModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.reportURL,
        children: [
          {
            path: '',
            component: ReportsMainComponent
          },
          {
            path: ConstantURLs.lecturesReportURL,
            component: LecturesReportListComponent
          },
          {
            path: ConstantURLs.studentsReportURL,
            component: StudentsReportComponent
          },
          {
            path: ConstantURLs.studentsFeedbackReportURL,
            component: StudentFeedbackListComponent
          },
          {
            path: ConstantURLs.organizersAttendanceReportURL,
            component: OrganizersAttendanceListComponent
          },
          {
            path: ConstantURLs.stockCountingReportURL,
            component: StockCountingReportComponent
          },
          {
            path: ConstantURLs.assetsReportURL,
            component: AssetReportComponent
          },
          {
            path: ConstantURLs.sessionsAttendanceReportURL,
            component: SessionsAttendanceReportComponent
          },
          {
            path: ConstantURLs.weaponsSummaryReportURL,
            component: WeaponsSummaryReportComponent
          },
          {
            path: ConstantURLs.ordersReportURL,
            component: OrdersReportComponent
          },
          {
            path: ConstantURLs.inboundReportURL,
            component: InboundReportComponent
          },
          {
            path: ConstantURLs.outboundReportURL,
            component: OutboundReportComponent
          },
          {
            path: 'dynamic-report/:id/:name',
            component: DynamicReportComponent,
          }
        ]
      },
      {
        path: ConstantURLs.lecturesURL,
        loadChildren: () => import('./content/lectures/lectures.module').then(m => m.LecturesModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.mapURL,
        component: HereMapComponent
      },
      {
        path: ConstantURLs.facilityManagementURL,
        component: FacilityManagementMainComponent
      },
      {
        path: ConstantURLs.homeURL,
        loadChildren: () => import('./content/dashboards/dashboards.module').then(m => m.DashboardsModule).catch( err => console.log('Error in loading module', err))
      },
      {
        path: ConstantURLs.farmActivitiesURL,
        loadChildren: () => import('./content/farm-activities/farm-activities.module').then(m => m.FarmActivitiesModule).catch( err => console.log('Error in loading module', err))
      },
    ]
  },
  {
    path: ConstantURLs.studentRegisterationURL,
    component: HomeLayoutComponent,
    children : [
      {
        path: '',
        component: StudentsEntryComponent
      }
    ]
  },
  {
    path: ConstantURLs.homePageURL,
    component: HomeLayoutComponent,
    children : [
      {
        path: '',
        component: DelmonteHomePageComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
