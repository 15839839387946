import { TextLanguage } from "../general/textLanguage.model";
import { InvoiceModel } from "../invoice-model/invoice-model";
import { StaffModel } from "../staff-models/staff-model";

export class InsertRouteModel {
    invoiceList: InvoiceModel[];
    staffId: number;

    constructor() {
        this.invoiceList = [];
        this.staffId = -1;
    }

}
