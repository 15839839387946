import { ItemModel } from "./item.model";
import { DateTimeModel } from "../dateTimeModel/date-time.model";

export class ItemDetailsModel {
    itemId: number;
    serialNumber: string;
    barcode: string;
    currentStatusId: number;
    oldStatusId: number;
    newStatusId: number;
    InvoiceId: string;
    invoiceStatusId: number;

    usageStatus: string;
    usageDate: string;
    usageDateModel: DateTimeModel;
    note: string;
    numberOfBulletsUsed: number;

    jafSerial: string;
    warehouseId: number;
    itemCode: string;
    parentSerial: string;

    constructor(item?: ItemModel) {

        if (item != null) {
            this.itemId = +item.itemId;
            this.serialNumber = item.serialNumber;
            this.jafSerial = item.jafSerial;
            this.barcode = item.barcode;
            this.numberOfBulletsUsed = item.numberOfBulletsUsed;
            this.usageStatus = item.usageStatus;
            this.note = item.note;
            this.itemCode = item.itemCode;
            this.parentSerial = item.parentSerial;
            if (item.usageDate)
                this.usageDate = item.usageDate;
        } else {
            this.itemId = -1;
            this.serialNumber = '';
            this.barcode = '';
            this.numberOfBulletsUsed = 0;
            this.usageStatus = '';
            this.note = '';
            this.jafSerial = '';
            this.itemCode = '';
            this.parentSerial = '';
        }
        this.currentStatusId = -1;
        this.oldStatusId = -1;
        this.newStatusId = -1;
        this.InvoiceId = '';
        this.invoiceStatusId = -1;
        this.warehouseId = -1;
    }

}