import { Directive, HostBinding, HostListener } from '@angular/core';
import { CoreSession } from 'src/app/core/core.session';
import { Solution } from '../enum/solution.enum';
import { Background, Color, FontTypes, Solutions } from '../enum/themes-mode';


@Directive({
  selector: '[appThemes]'
})
export class ThemesDirective {

  constructor(private coreSession: CoreSession) { }
  groundValue = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf() 
  ? Color.Beige.toString() : this.coreSession.selectedSolutionId === Solution.IEC.valueOf() 
  ? '#cc0000'  : Solutions.main.toString();
  colorValue = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf() ? 'black' : Color.GOLD.toString();
  fontValue = FontTypes.GOLD.toString();


  @HostBinding('style.background-color')
  backgroundColor:string = this.groundValue;

  @HostBinding('style.color')
  color:string = this.colorValue;


}
