import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsDirective } from './directives/effects.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { NgbModule, NgbCollapse, NgbDropdownMenu, NgbDropdown, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTableComponent } from './shared-table/shared-table.component';
import { OnlyNumber } from './directives/onlyNumbers/onlyNumbers.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoFocusDirective } from './directives/autoFocus.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { NumberOfDigits } from './directives/numberOfDigits.directive';
import { NumberOfStockDigits } from './directives/numberOfStockDigits.directive';
import { SingleClickDirective } from './directives/singleClick.directive';
import { SingleClickForEnterKeyDirective } from './directives/singleClickForEnterKey.directive';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { NumberOfStockDigitsRounder } from './pipes/decimal-rounder-stock.pipe';
import { NumberOfDigitsRounder } from './pipes/decimal-rounder.pipe';
import { NumberOfStockDigitsFloorRounder } from './pipes/floor-rounder-stock.pipe';
import { KeysPipe } from './pipes/keys.pipe';
// import { IgxLinearGaugeModule } from 'igniteui-angular-gauges';
import { SliderComponent } from './slider/slider.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ModalLoadingComponent } from './components/modal-loading/modal-loading.component';
import { DateTimePickerComponent } from './components/filters/date-time-picker/date-time-picker.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from './components/snackbar-message/snackbar-message.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import { ThemesDirective } from './directives/themes.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import { LanguagesComponent } from './components/languages/languages.component';
import {MatListModule} from '@angular/material/list';
import { RootNavThemeDirective } from './directives/root-nav-theme.directive';
import { MatCardModule } from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TextInputLanguagesComponent } from './components/text-input-languages/text-input-languages.component';
import { SerialEntryComponent } from './components/serial-entry/serial-entry.component';
import { CalculatorDialogComponent } from './components/calculator-dialog/calculator-dialog.component';
import { DataImportDialog } from './components/import-dialog/import-dialog.component';
import { StudentsEntryComponent } from '../content/attendance-management/students-definition/students-entry/students-entry.component';
import { MatRadioModule } from '@angular/material/radio';
import { SideFilterTemplateComponent } from './components/side-filter-template/side-filter-template.component';
import { InBoundListComponent } from './components/in-bound-list/in-bound-list.component';
import { OutBoundListComponent } from './components/out-bound-list/out-bound-list.component';
import { OperationEntryComponent } from './components/operation-entry/operation-entry.component';
import { ImportResultDialogComponent } from './components/import-result-dialog/import-result-dialog.component';

import {MatExpansionModule} from '@angular/material/expansion';
import { WarehouseOperationImportDialogComponenet } from '../content/armory-operations/import-dialog/warehouse-operation-import-dialog.component';
import { ItemEntryComponent } from '../content/data-definition/item/item-definition/item-entry/item-entry.component';
import { MatrixSelectionComponent } from './matrix-selection/matrix-selection.component';
import { DynamicFormInspectionDetailComponent } from '../content/dynamic-form-operation/dynamic-forms-history/dynamic-form-history-detail/dynamic-form-inspection-detail.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AssignDialogComponent } from './components/assign-dialog/assign-dialog.component';
import { HereMapComponent } from '../content/maps/here-map/here-map.component';
import { CategoryEntryComponent } from '../content/data-definition/category/category-entry/category-entry.component';
import { ItemTypeEntryComponent } from '../content/data-definition/item/item-type/item-type-entry/item-type-entry.component';
import { SupplierEntryComponent } from '../content/data-definition/suppliers/supplier-entry/supplier-entry.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SharedTableComponent,
    ModalDialogComponent,
    ModalLoadingComponent,
    EffectsDirective,
    FilterPipe,
    OnlyNumber,
    DropdownDirective,
    KeysPipe,
    NumberOfDigitsRounder,
    AutoFocusDirective,
    SliderComponent,
    NumberOfDigits,
    NumberOfStockDigits,
    NumberOfStockDigitsRounder,
    NumberOfStockDigitsFloorRounder,
    ImportDialogComponent,
    SingleClickDirective,
    SingleClickForEnterKeyDirective,
    TextInputLanguagesComponent,
    DateTimePickerComponent,
    SnackbarMessageComponent,
    ThemesDirective,
    LanguagesComponent,
    RootNavThemeDirective,
    SerialEntryComponent,
    CalculatorDialogComponent,
    DataImportDialog,
    StudentsEntryComponent,
    SideFilterTemplateComponent,
    InBoundListComponent,
    OutBoundListComponent,
    OperationEntryComponent,
    ImportResultDialogComponent,
    WarehouseOperationImportDialogComponenet,
    ItemEntryComponent,
    MatrixSelectionComponent,
    DynamicFormInspectionDetailComponent,
    AssignDialogComponent,
    HereMapComponent,
    CategoryEntryComponent,
    ItemTypeEntryComponent,
    SupplierEntryComponent,
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    CommonModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbModule,
    NgxSpinnerModule,
    MatInputModule,
    DragDropModule,
    // IgxLinearGaugeModule,
    TranslateModule.forChild(),
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MatListModule,
    MatCardModule,
    MatTableModule,
    NgMultiSelectDropDownModule,
    MatRadioModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatSlideToggleModule
  ],
  exports: [
    CommonModule,
    ModalDialogComponent,
    ModalLoadingComponent,
    SharedTableComponent,
    NgbTooltipModule,
    OnlyNumber,
    DropdownDirective,
    NumberOfDigitsRounder,
    AutoFocusDirective,
    FilterPipe,
    SliderComponent,
    NumberOfDigits,
    NumberOfStockDigits,
    NumberOfStockDigitsRounder,
    NumberOfStockDigitsFloorRounder,
    ImportDialogComponent,
    MatInputModule,
    MatInputModule,
    SingleClickDirective,
    SingleClickForEnterKeyDirective,
    DateTimePickerComponent,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ThemesDirective,
    MatTooltipModule,
    MatListModule,
    RootNavThemeDirective,
    MatCardModule,
    MatTableModule,
    TextInputLanguagesComponent,
    SerialEntryComponent,
    CalculatorDialogComponent,
    DataImportDialog,
    StudentsEntryComponent,
    MatRadioModule,
    SideFilterTemplateComponent,
    InBoundListComponent,
    OutBoundListComponent,
    OperationEntryComponent,
    MatExpansionModule,
    WarehouseOperationImportDialogComponenet,
    ItemEntryComponent,
    MatrixSelectionComponent,
    DynamicFormInspectionDetailComponent,
    FlexLayoutModule,
    AssignDialogComponent,
    HereMapComponent,
    CategoryEntryComponent,
    ItemTypeEntryComponent,
    SupplierEntryComponent,
  ]
  ,
  providers: [
    DatePipe
  ],
  bootstrap: []
  // entryComponents: [SharedItemsListFilterComponent]
})
export class SharedModule { }
