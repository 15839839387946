import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DynamicReportService } from './dynamic-report.service';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { ReportFilterTypes } from 'src/app/shared/enum/dynamic-report.enum';
import { ActivatedRoute } from '@angular/router';
import { Solution } from 'src/app/shared/enum/solution.enum';


@Component({
  selector: 'app-dynamic-report',
  templateUrl: './dynamic-report.component.html',
  styleUrls: ['./dynamic-report.component.css']
})
export class DynamicReportComponent implements OnInit, OnDestroy {

  entryFormGroup: FormGroup;
  isLoading = false;
  controlsList = [];
  data: string = '';

  @Input() reportId = -1;
  @Input() reportName = '';

  reportModel: any = {
    reportId: -1,
    reportName: '',
    reportFilters: []
  };

  constructor(
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private dynamicReportService: DynamicReportService,
    private route: ActivatedRoute
  ) { }
  ngOnInit() {
    if (this.reportId == -1) {
      this.reportId = +this.route.snapshot.paramMap.get('id');
      this.reportName = this.route.snapshot.paramMap.get('name');
    }
    this.entryFormGroup = new FormGroup({});
    this.getReportFilters();
  }
  initForm() {
    this.controlsList.forEach((control) => {

      control.disabled = false;
      if (control.isDateFilter || control.isDateTimeFilter) {
        var dateProperties: IDateTimePickerProperties = {
          label: control.description,
          formControlName: control.formControlName,
          isCalendarOnly: control.isDateFilter, // date only without time section
          dateValue: new Date(),
        };
        control.dateProperties = dateProperties;
        control.selectedDateModelValue = new Date();
      }
      var selectedValue = null;
      if (control.isDateFilter || control.isDateTimeFilter) {
        selectedValue = control.selectedDateModelValue.date;
      } else if (control.reportFilterTypeId === ReportFilterTypes.Lookup || control.reportFilterTypeId === ReportFilterTypes.LookUp_WithoutAll) {
        selectedValue = control.selectedValue;
      }

      this.entryFormGroup.addControl(control.formControlName,
        new FormControl({ value: selectedValue, disabled: false }, Validators.required));

    });
  }
  ngOnDestroy() {
  }
  getReportFilters() {
    // this.reportId = 1270;
    this.coreSession.ModalLoading.Show();

    this.dynamicReportService.getReportFilters(this.reportId).subscribe(
      (response) => {
        this.isLoading = true;
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          this.controlsList = response.data;
          this.initForm();
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.isLoading = true;
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }
  getChildFilterData(childControl) {
    this.coreSession.ModalLoading.Show();
    this.dynamicReportService.getChildFilterData(childControl).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          childControl.filterData = response.data;
          if (childControl.filterData && childControl.filterData.length > 0) {
            this.entryFormGroup.get(childControl.formControlName).setValue(childControl.filterData[0].bindValue);
            this.entryFormGroup.get(childControl.formControlName).updateValueAndValidity();
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }
  checkIfParentDisable(control) {
    var index = this.controlsList.findIndex(x => x.filterIndex === control.parentFilterIndex);
    if (index >= 0) {
      return this.controlsList[index].disabled;
    }
    return false;
  }
  onChangeDate(selectedControl, event) {
    selectedControl.selectedDateModelValue = event;
  }
  onChangeEnableStatus(selectedControl) {
    var isDisable = !selectedControl.disabled;
    if (isDisable) {
      selectedControl.disabled = true;
      this.entryFormGroup.get(selectedControl.formControlName).setValidators(Validators.nullValidator);
      this.entryFormGroup.get(selectedControl.formControlName).disable();
    } else {
      selectedControl.disabled = false;
      this.entryFormGroup.get(selectedControl.formControlName).enable();
      this.entryFormGroup.get(selectedControl.formControlName).setValidators(Validators.required);
    }
    this.entryFormGroup.get(selectedControl.formControlName).setValue(null);
    this.entryFormGroup.get(selectedControl.formControlName).updateValueAndValidity();

    // reflect child filters data and check all
    if (selectedControl.reportFilterTypeId === ReportFilterTypes.Lookup) {
      var index = this.controlsList.findIndex(x => x.reportFilterTypeId === ReportFilterTypes.Lookup && x.parentFilterIndex == selectedControl.filterIndex);
      if (index != -1) {
        this.onChangeEnableStatusForChild(this.controlsList[index], selectedControl);
      }
    }
  }
  onChangeEnableStatusForChild(childControl, parentControl) {
    if (parentControl.disabled) {
      childControl.disabled = true;
      this.entryFormGroup.get(childControl.formControlName).setValidators(Validators.nullValidator);
      this.entryFormGroup.get(childControl.formControlName).disable();
    } else {
      childControl.disabled = false;
      this.entryFormGroup.get(childControl.formControlName).enable();
      this.entryFormGroup.get(childControl.formControlName).setValidators(Validators.required);
    }
    this.entryFormGroup.get(childControl.formControlName).setValue(null);
    childControl.filterData = [];

    this.entryFormGroup.get(childControl.formControlName).updateValueAndValidity();
  }
  onChangeComboSelection(selectedControl) {
    var index = this.controlsList.findIndex(x => x.parentFilterIndex == selectedControl.filterIndex);
    if (index != -1) {
      var childControl = this.controlsList[index];
      if (childControl) {

        childControl.parentFilterValue = -1;
        childControl.filterData = [];
        this.entryFormGroup.get(childControl.formControlName).setValue(null);
        this.entryFormGroup.get(childControl.formControlName).updateValueAndValidity();

        if (!selectedControl.disabled) {
          var parentValue = this.entryFormGroup.get(selectedControl.formControlName).value;
          if (parentValue && parentValue != '' && parentValue != '-1' && parentValue != -1) {

            // set child as enabled when change value for parent filter
            childControl.disabled = false;
            this.entryFormGroup.get(childControl.formControlName).enable();
            this.entryFormGroup.get(childControl.formControlName).setValidators(Validators.required);

            childControl.parentFilterValue = parentValue;
            // call api to fill child control data after change parent filter selection
            this.getChildFilterData(childControl);
          }
        }
      }
    }
  }

  showData() {

    this.coreSession.markFormGroupTouched(this.entryFormGroup);
    if (this.entryFormGroup.invalid) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.controlsList.forEach(control => {

      control.selectedText = control.description;

      if (control.disabled && (control.isDateFilter || control.isDateTimeFilter)) {
        control.selectedText = '---';
        control.selectedDateModelValue = new Date();
        control.selectedDateModelValue = control.selectedDateModelValue.date;
      }
      else if (control.reportFilterTypeId == ReportFilterTypes.Lookup) {
        if (!control.disabled && this.entryFormGroup.get(control.formControlName).value) {
          var data = control.filterData.find(x => x.bindValue === this.entryFormGroup.get(control.formControlName).value)
          if (data) {
            control.selectedText = data.bindLabel;
          }
        } else {
          control.selectedText = '---';
        }
        control.selectedValue = this.entryFormGroup.get(control.formControlName).value ? this.entryFormGroup.get(control.formControlName).value : '-1';
      }
      else if (control.reportFilterTypeId == ReportFilterTypes.LookUp_WithoutAll) {
        if (control.disabled) {
          control.selectedValue = '-1';
          control.selectedText = '---';
        } else {
          if (!control.disabled && this.entryFormGroup.get(control.formControlName).value) {
            var data = control.filterData.find(x => x.bindValue === this.entryFormGroup.get(control.formControlName).value)
            if (data) {
              control.selectedText = data.bindLabel;
            }
          }
          control.selectedValue = this.entryFormGroup.get(control.formControlName).value ? this.entryFormGroup.get(control.formControlName).value : '-1';
        }
      } else if (control.reportFilterTypeId == ReportFilterTypes.Text) {
        control.selectedText = '---';
        if (!control.disabled && this.entryFormGroup.get(control.formControlName).value) {
          control.selectedText = this.entryFormGroup.get(control.formControlName).value;
        }
        control.selectedValue = this.entryFormGroup.get(control.formControlName).value ? this.entryFormGroup.get(control.formControlName).value : '';
      }
    });

    this.reportModel.reportId = this.reportId;
    this.reportModel.reportName = this.reportName;
    this.reportModel.reportFilters = this.controlsList;

    this.coreSession.ModalLoading.Show();
    this.dynamicReportService.showReportData(this.reportModel).subscribe(
      (response) => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          let url = response.data;
          window.open(url, "_blank");
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }
}
