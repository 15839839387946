export enum ImportDataTypes {
    None=0,
    Items = 1,
    Employees= 2,
    Customers = 3,
    Serials=4,
    Serials_InputVoucher=5,
    DisplayUnit = 6,
    Suppliers = 7,
    Locations = 8,
    Students = 9,
    ItemBarcodes = 10,
    Assets = 11,
    UOM = 12,
    GenerateBarcodesAsPDF = 13,
}