import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { FacilityGuestsModel } from 'src/app/shared/model/guests/FacilityGuestsModel';
import { GuestsService } from '../guests.service';
import { Solution, HotelSolution } from 'src/app/shared/enum/solution.enum';
import { Client } from 'src/app/shared/enum/client.enum';

@Component({
  selector: 'app-number-of-guests',
  templateUrl: './number-of-guests.component.html',
  styleUrls: ['./number-of-guests.component.css']
})
export class NumberOfGuestsComponent implements OnInit, OnDestroy {
  guestsCountModel: FacilityGuestsModel = new FacilityGuestsModel();
  observableRef: any;

  royalSelected: boolean = false;
  hiltonSelected: boolean = false;

  constructor(private guestsService: GuestsService,
    private coreSession: CoreSession,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.royalSelected = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.LeRoyal.valueOf();
    this.hiltonSelected = this.coreSession.selectedSolutionId === Solution.Breakfast.valueOf() && CoreSession.selectedClient == Client.Hilton_Deadsea.valueOf();

    this.getNumberOfGuests();
    this.observableRef = Observable.interval(60000)
      .subscribe(() => {
        this.getNumberOfGuests();
      });
  }

  ngOnDestroy(): void {
    this.observableRef.unsubscribe();
  }

  getNumberOfGuests() {

    this.coreSession.ModalLoading.Show();

    this.guestsService.getNumberOfGuests().subscribe(res => {

      this.coreSession.ModalLoading.Hide();
      if (res.status != null && res.status >= 0) {
        this.guestsCountModel = res.data;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), res.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );

  }

}
