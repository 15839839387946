import { NgModule, ErrorHandler } from "@angular/core";
import { AppRoutingModule } from "../app-routing.module";
import { CommonModule, DecimalPipe } from "@angular/common";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DropdownDirective } from "../shared/directives/dropdown.directive";
import { MatIconModule } from "@angular/material/icon";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { AuthGuard } from "./auth/auth.gurad";
import { AuthService } from "./auth/auth.service";
import { HttpClientInterceptor } from "./auth/auth-request";
import { AuthErrorHandler } from "./auth/auth-error-handler";
import { LoginAuthGuard } from "./auth/login-auth.gurad";


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    MatIconModule,
    NgbTooltipModule,
    NgSelectModule,
    TranslateModule.forChild() ,
  ],
  exports: [DropdownDirective],
  providers: [
    DecimalPipe,
    AuthGuard,
    LoginAuthGuard,
    AuthService,
    { provide: ErrorHandler, useClass: AuthErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
  ]
})
export class CoreModule {}
