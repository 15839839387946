import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { TextLanguage } from "../general/textLanguage.model";
import { ItemConnectionModel } from "../armory-models/item-connection.model";
export class ItemModel {
  itemId: number;
  itemName: string;
  subname: string;
  itemTypeId: number;
  itemTypeName: string;
  itemCategoryId: number;
  itemCategoryName: string;
  itemCode: string;
  itemImage: string;
  quantity: number;
  availableQty: number;
  isInsite: boolean;
  insiteQty: number;
  offsiteQty: number;
  itemConnections: ItemConnectionModel[];
  inactive: boolean;
  descriptions: TextLanguage[];
  isSerialized: boolean;
  serialNumber: string;
  barcode: string;
  minWeight: number;
  maxWeight: number;
  usageStatus: string;
  usageDate: string;
  usageDateModel: DateTimeModel;
  note: string;
  numberOfBulletsUsed: number;
  jafSerial: string;
  //Inv
  itemUOM: string;
  warehouseCode: string;
  costPrice: number;
  unitPrice: number;
  supplierCode: string;
  uomConversion: number;
  locationId: number;
  itemSkuDetails: ItemSKUDetailsModel[];
  brand: string;
  itemSubTypeName: string;
  notAvailable: boolean;
  originalInsiteQty: number;
  originalOffsiteQty: number;
  parentSerial: string;
  uomId: number;

  constructor() {
    this.itemId = -1;
    this.itemTypeId = -1;
    this.itemCode = '';
    this.itemName = '';
    this.itemTypeName = '';
    this.itemImage = '';
    this.itemCategoryId = -1;
    this.itemCategoryName = '';
    this.itemConnections = [];
    this.quantity = 0;
    this.availableQty = 0;
    this.inactive = false;
    this.descriptions = [];
    this.isSerialized = false;
    this.serialNumber = '';
    this.barcode = '';
    this.minWeight = 0;
    this.maxWeight = 0;
    this.numberOfBulletsUsed = 0;
    this.note = '';
    this.jafSerial = '';
    this.isInsite = false;
    this.insiteQty = 0;
    this.offsiteQty = 0;
    this.itemUOM = '';
    this.warehouseCode = '';
    this.costPrice = 0.0;
    this.unitPrice = 0.0;
    this.supplierCode = '';
    this.uomConversion = 1;
    this.subname = '';
    this.locationId = -1;
    this.itemSkuDetails = [];
    this.brand = '';
    this.itemSubTypeName = '';
    this.notAvailable = false;
    this.parentSerial = '';
    this.uomId = -1;
  }
}

export class ItemSKUDetailsModel {
  itemId: number;
  warehouseId: number;
  barcode: string;
  itemUOM: string;
  costPrice: number;
  unitPrice: number;
  uomConversion: number;
  warehouseName: string;
  uomId: number;

  constructor() {
    this.itemId = -1;
    this.warehouseId = -1;
    this.warehouseName = '';
    this.barcode = '';
    this.itemUOM = '';
    this.costPrice = 0.0;
    this.unitPrice = 0.0;
    this.uomConversion = 1;
    this.uomId = -1;
  }
}