import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({ name: 'numberOfStockDigitsFloorRounder' })
export class NumberOfStockDigitsFloorRounder implements PipeTransform {

  constructor() {}
  numberOfStockDigits = 2;
  transform(value): any {
    if (value) {
      var result = 0;
      if (this.numberOfStockDigits > 0) {
        var paddingNumber = + '1'.concat((String('0').repeat(this.numberOfStockDigits) + '').substr((this.numberOfStockDigits * -1), this.numberOfStockDigits));
        result = Math.floor(value * paddingNumber) / paddingNumber;
      } else {
        result = Math.floor(value);
      }
      return formatNumber(result, 'en', '1.' + this.numberOfStockDigits + '-' + this.numberOfStockDigits);
    } else {
      return formatNumber(0, 'en', '1.' + this.numberOfStockDigits + '-' + this.numberOfStockDigits);
    }
  }
}