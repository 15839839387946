import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { RoomInfoModel } from "src/app/shared/model/guests/room-Info.model";

@Injectable({ providedIn: 'root' })
export class FacilityManagementService{
    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'FacilityManagement';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getRoomInfo(roomNumber: number, selectedFacilityTpeId: number) {
        const urlValue: string = this.url + `/GetRoomInfo?roomNumber=${roomNumber}&selectedFacilityTpeId=${selectedFacilityTpeId}`;
        return this.http.get(urlValue, this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    addGuest(roomInfo: RoomInfoModel) {
        const urlValue: string = this.url + '/AddGuest';
        return this.http.post(urlValue, JSON.stringify(roomInfo), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getNumberOfGuests(facilityTypeId: number) {
        const urlValue: string = this.url + `/GetNumberOfGuests?facilityTypeId=${facilityTypeId}`;
        return this.http.get(urlValue).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getRoomsList(filter: any) {
        const urlValue: string = this.url + '/GetRoomsList';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
          map((response) => {
            let res = new ResponseModel();
            res.data = response["data"];
            res.message = response["message"];
            res.status = response["status"];
    
            return res;
          })
        );
      }
}