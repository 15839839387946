import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { CustomListFilter } from 'src/app/shared/model/shared-table/custom-list.interface';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { ClientOperationService } from '../client-operation.service';
import { Subject, Observable } from 'rxjs';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';

@Component({
  selector: 'app-client-operation-list',
  templateUrl: './client-operation-list.component.html',
  styleUrls: ['./client-operation-list.component.css']
})
export class ClientOperationListComponent implements OnInit {
  isEditModeSelected = false;
  saveSubject: Subject<void> = new Subject<void>();
  data: ClientModel[];
  selectedClientObj: ClientModel;
  selectedIndexVal: number = -1;
  showSlider = false;

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  clientFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    clientCodeName: '',
    clientTypeId: -1,
    includeInactive: false,
    clientId: -1
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_Start',
    showAddIcon: false,
    rowOperations: [

    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_ClientName",
        key: "clientNameCode",
        isSortable: false,
        width: "25%"
      },
      {
        title: "DESC_Promise",
        key: "promiseDescription",
        isSortable: false,
        width: "20%"
      },
      {
        title: "DESC_Note",
        key: "noteDescription",
        isSortable: false,
        width: "20%"
      },
      {
        title: "DESC_Status",
        key: "operationStatusDescription",
        isSortable: false,
        width: "15%"
      },
      {
        title: "DESC_Contacted_By",
        key: "address",
        isSortable: false,
        width: "20%"
      }
    ]
  };

  sharedTableFilter: CustomListFilter = {
    searchFilter: '',
    page: 0,
    pageSize: this.coreSession.pageSize
  };

  constructor(private coreSession: CoreSession, private clientOperationService: ClientOperationService, private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.populateClientsOperationList();
    this.addActions();
  }

  addActions() {
    if (this.coreSession.checkActivitiesAvailability('client-operation', MenuActions.Add)) {
      this.tableData.showNewBtn = true;
    }
    if (this.coreSession.checkActivitiesAvailability('client-operation', MenuActions.View)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.View,
          title: "View",
          icon: "fa fa-eye",
          color: "#12344d"
        }
      );
    }
    this.tableData.rowOperations.push(
      {
        operation: RowOperation.View,
        title: "View",
        icon: "fa fa-eye",
        color: "#12344d"
      }
    );
  }

  //Slider Events
  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.router.navigate(['/client-operation/client-operation-view', event.object.clientId]);
        break;
    }
  }
  //Search
  onTableFilterChanged(query: any) {
    this.sharedTableFilter = query;
    // this.populatePackGroups();
  }

  //Open Slider Events
  startClientOperations() {
    this.router.navigate(['/client-operation/client-operation-view', -1]);
  }

  closeSlider() {
    this.showSlider = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onClientSaved() {
    this.closeSlider();
    this.populateClientsOperationList();
  }

  onSaveCompleted(val: any) {
    this.onClientSaved();
  }

  populateClientsOperationList() {
    this.coreSession.ModalLoading.Show();
    this.clientFilter.clientId = 0;
    this.clientOperationService.getClientsOperationsForLoggedInUserList(this.clientFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        // if (response.data.totalItems <= 0) {
        //   this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        // }
        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = response.data.data.slice();
        this.dataSource.totalItems = response.data.totalItems;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }
}
