import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './content/login/login.component';
import { RouteComponent } from './content/route/route-list/route.component';
import { SettingsComponent } from './content/settings/settings/settings.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from "@angular/material/tabs";
import { RootNavComponent } from './content/root-nav/root-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { HomeLayoutComponent } from './content/home/home-layout.component';
import { SharedModule } from './shared/shared.module';
import { MainComponent } from './content/main/main.component';

import { RootHeaderComponent } from './content/root-header/root-header.component';
import { ProfileComponent } from "./content/profiles/profile/profile.component";
import { RootContentComponent } from './content/root-content/root-content.component';
import { InvoiceEntryComponent } from './content/invoice/invoice-entry/invoice-entry.component';
import { InvoiceComponent } from './content/invoice/invoice-list/invoice.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SessionDataProvider } from './core/session-data-provider.service';
import { ClientOperationBasicDataComponent } from './content/client-operation/client-operation-view/client-operation-basic-data/client-operation-basic-data.component';
import { ClientOperationDynamicDataComponent } from './content/client-operation/client-operation-view/client-operation-dynamic-data/client-operation-dynamic-data.component';
import { ClientOperationHistoryListComponent } from './content/client-operation/client-operation-view/client-operation-history-list/client-operation-history-list.component';
import { ClientOperationPromisesListComponent } from './content/client-operation/client-operation-view/operation-promises-list/operation-promises-list.component';
import { ClientOperationNotesListComponent } from './content/client-operation/client-operation-view/client-operation-notes-list/client-operation-notes-list.component';
import { ClientOperationMainComponent } from './content/client-operation/client-operation-view/client-operation-main/client-operation-main.component';
import { ClientOperationListComponent } from './content/client-operation/client-operation-list/client-operation-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BidiModule } from '@angular/cdk/bidi';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RouteEntryComponent } from './content/route/route-entry/route-entry.component';
import { AssignedInvoicesDialogComponent } from './content/route/assigned-invoices-dialog/assigned-invoices-dialog.component';
import { MatTreeModule } from '@angular/material/tree';
import { CoreModule } from './core/core.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClientsListComponent } from './content/data-definition/clients/clients-list/clients.component';
import { ClientsEntryComponent } from './content/data-definition/clients/clients-entry/clients-entry.component';
import { RouteStationsListComponent } from './content/route-stations/route-stations-list/route-stations-list.component';
import { NumberOfGuestsComponent } from './content/guests/number-of-guests/number-of-guests.component';
import { FilterDialogComponent } from './content/reports/filter-dialog/filter-dialog.component';
import { ReportsMainComponent } from './content/reports/reports-main/reports-main.component';
import { CommonModule } from '@angular/common';
import { LecturesReportEntryComponent } from './content/reports/lectures-report/lectures-report-entry/lectures-report-entry.component';
import { LecturesReportListComponent } from './content/reports/lectures-report/lectures-report-list/lectures-report-list.component';
import { RouteStationsEntryComponent } from './content/route-stations/route-stations-entry/route-stations-entry.component';
import { StudentsReportComponent } from './content/reports/students-report/students-report/students-report.component';
import { DynamicReportComponent } from './content/reports/dynamic-report/dynamic-report.component';
import { OrganizersAttendanceListComponent } from './content/reports/organizers/organizers-attendance-list/organizers-attendance-list.component';
import { OrganizersAttendanceViewComponent } from './content/reports/organizers/organizers-attendance-view/organizers-attendance-view.component';
import { StudentFeedbackListComponent } from './content/reports/students-report/students-feedback-report/student-feedback-list/student-feedback-list.component';
import { StudentFeedbackViewComponent } from './content/reports/students-report/students-feedback-report/student-feedback-view/student-feedback-view.component';
import { StockCountingReportComponent } from './content/reports/inventory-reports/stock-counting-report/stock-counting-report.component';
import { AssetReportComponent } from './content/reports/asset-reports/asset-report/asset-report.component';
import { SessionsAttendanceReportComponent } from './content/reports/sessions-attendance-report/sessions-attendance-report.component';
import { FacilityManagementMainComponent } from './content/facility-management/facility-management-main/facility-management-main.component';
import { FacilitySelectionDialogComponent } from './content/facility-management/facility-selection-dialog/facility-selection-dialog.component';
import { RoomFilterDialogComponent } from './content/facility-management/room-filter-dialog/room-filter-dialog.component';
import { WeaponsSummaryReportComponent } from './content/reports/weapons-summary-report/weapons-summary-report.component';
import { OrdersReportComponent } from './content/reports/orders-report/orders-report.component';
import { InboundReportComponent } from './content/reports/inventory-reports/inbound-report/inbound-report.component';
import { OutboundReportComponent } from './content/reports/inventory-reports/outbound-report/outbound-report.component';
import { DelmonteHomePageComponent } from './content/del-monte/delmonte-home-page/delmonte-home-page.component';
// import { GoogleMapsModule } from '@angular/google-maps';

export class TranslateHandler implements MissingTranslationHandler {
  private response: String;

  handle(params: MissingTranslationHandlerParams) {
    console.log('*** Missing text for:', params.key);
    return params.key.replace('Desc_', '').replace(/([A-Z])/g, ' $1').trim(); // here u can return translation
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, document.getElementsByTagName('base')[0].href + 'Resources/Lang/', '.json');
}

export function SessionDataFactory(provider: SessionDataProvider) {
  return () => provider.loadSessionLanguages();
}

export function SessionDataFactory2(provider: SessionDataProvider) {
  return () => provider.loadSessionStaffData();
}
@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginComponent,
    ClientsListComponent,
    ClientsEntryComponent,
    InvoiceComponent,
    RouteComponent,
    SettingsComponent,
    RootNavComponent,
    MainComponent,
    RootHeaderComponent,
    RootContentComponent,
    ClientOperationListComponent,
    ClientOperationMainComponent,
    ClientOperationBasicDataComponent,
    ClientOperationDynamicDataComponent,
    ClientOperationHistoryListComponent,
    ClientOperationPromisesListComponent,
    ClientOperationNotesListComponent,
    InvoiceEntryComponent,
    RouteEntryComponent,
    RouteStationsListComponent,
    ProfileComponent,
    AssignedInvoicesDialogComponent,
    NumberOfGuestsComponent,
    FilterDialogComponent,
    ReportsMainComponent,
    LecturesReportListComponent,
    LecturesReportEntryComponent,
    RouteStationsEntryComponent,
    StudentsReportComponent,
    DynamicReportComponent,
    OrganizersAttendanceListComponent,
    OrganizersAttendanceViewComponent,
    StudentFeedbackListComponent,
    StudentFeedbackViewComponent,
    StockCountingReportComponent,
    AssetReportComponent,
    SessionsAttendanceReportComponent,
    FacilityManagementMainComponent,
    FacilitySelectionDialogComponent,
    RoomFilterDialogComponent,
    WeaponsSummaryReportComponent,
    OrdersReportComponent,
    InboundReportComponent,
    OutboundReportComponent,
    DelmonteHomePageComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatCardModule,
    MatTabsModule,
    LayoutModule,
    NgSelectModule,
    HttpClientModule,
    BidiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    //   isolate: true,
    //   missingTranslationHandler: [{ provide: MissingTranslationHandler, useClass: TranslateHandler }]
    // }),
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTreeModule,
    CoreModule,
    NgMultiSelectDropDownModule,
    // GoogleMapsModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientJsonpModule
  ],
  exports: [
    TranslateModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatTreeModule,
    NgMultiSelectDropDownModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    {
      provide: APP_INITIALIZER,
      useFactory: SessionDataFactory,
      deps: [SessionDataProvider],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SessionDataFactory2,
      deps: [SessionDataProvider],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href + 'API/';
}
