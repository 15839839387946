import { Injectable, Inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FormGroup } from "@angular/forms";
import { ModalLoadingComponent } from "../shared/components/modal-loading/modal-loading.component";
import { ModalDialogComponent } from "../shared/modal-dialog/modal-dialog.component";
import { CachedGeneralData, OperatorModel } from "../shared/model/operator/operator.model";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from "../shared/components/snackbar-message/snackbar-message.component";
import { classMessageType, messageType } from "../shared/enum/snackbar/class-types";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Direction } from "@angular/cdk/bidi";
import { MenuActions } from "../shared/enum/menu-actions-enum";
import { SessionDataProvider } from "./session-data-provider.service";
import { DateTimeModel } from "../shared/model/dateTimeModel/date-time.model";

import * as CryptoJS from "crypto-js";
import { InventorySolution, HotelSolution, Solution } from "../shared/enum/solution.enum";
import { PortalMode } from "../shared/enum/portal-mode.enum";
import { Client } from "../shared/enum/client.enum";

@Injectable({ providedIn: "root" })
export class CoreSession {
  //#region [DECLARATIONS]
  public selectedSolutionId: number = Solution.DynamicForms.valueOf();
  public static selectedClient: number = Client.None.valueOf();
  public selectedPortalMode: number = PortalMode.Admin.valueOf();
  public testMode: boolean = true;

  public showImportErrorList: boolean = false;

  public generalSearchPattern: string = null;
  public hideEmployeeEntrySliderFooter = false;
  public hideDisplayUnitEntrySliderFooter = false;
  public hideCustomerEntrySliderFooter = false;
  public hideSubRecallExecutionEntrySliderFooter = false;
  public showLanguageSlider = false;
  public selectedLanguageStr = "";
  public isRTL = false;
  public selectedLanguageId = -1;
  public TitleService: Title;
  public ModalDialog: ModalDialogComponent;
  public ModalLoading: ModalLoadingComponent;
  public DefaultValue = "8zD2RB7Fl0dVbO8BMZ3TTw==";
  public DefaultBatchNumber = "1990/01/01";
  public BatchNoFormat = "yyyy/MM/dd";
  public currentUser: OperatorModel = null;
  public cachedGeneralData: CachedGeneralData;
  public pageSize = 25;
  public pageSizeOptions: number[] = [10, this.pageSize, 50, 75, 100];
  public baseCurrencyCode: string = "ABC";
  private timeOutDelay = 5000;
  public pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Home');
  public userImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public email: BehaviorSubject<string> = new BehaviorSubject<string>('no email found');
  public direction: BehaviorSubject<Direction> = new BehaviorSubject<Direction>('ltr');
  public numberOfBarcodeDigits: number = 14;

  operation1Length: number; // (/)
  operation2Length: number; // (-)
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  private readonly duration = 5000;
  baseURL = "";

  //#endregion

  //#region [DECLARATIONS]

  constructor(private _snackBar: MatSnackBar,
    private sessionDataProvider: SessionDataProvider
  ) {
    this.baseURL = document.getElementsByTagName("base")[0].href;
  }
  //#endregion

  //#region [GENERAL FUNCTIONS]


  // DEFAULT EXPIRY DATE
  public DefaultExpiryDate(): Date {
    return new Date(1990, 0, 1);
  }
  // Generic Methods to set delays
  public SetDelay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  // SET TITLE
  public SetTitle(title: string) {
    this.TitleService.setTitle("Sonic");
    // this.TitleService.setTitle('Sonic - ' + title);
  }
  // Get Array Index Based On Value
  public GetIndexForList(arrayList, key, value) {
    for (let index = 0; index < arrayList.length; index++) {
      if (arrayList[index][key] === value) {
        return index;
      }
    }
    return -1;
  }

  setPageTitle(menu: any): any {
    this.pageTitle.next(menu);
  }

  public cloneObject(originalItem: any) {
    let targetItem: any;
    targetItem = Object.assign({}, originalItem);
    return targetItem;
  }

  toQueryString(obj: any) {
    const parts = [];
    // tslint:disable-next-line:forin
    for (const property in obj) {
      const value = obj[property];
      if (value != null && value !== undefined) {
        parts.push(
          encodeURIComponent(property) + "=" + encodeURIComponent(value)
        );
      }
    }
    return parts.join("&");
  }

  //#endregion

  //#region [DIALOG NOTIFICATIONS FUNCTIONS]

  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  //#endregion
  //Expecting format dd/MM/yyyy
  parseDMY = s => {
    let [d, m, y] = s.split(/\D/);
    return new Date(y, m - 1, d);
  };

  parseStringToDate(value: any): Date | null {
    if (typeof value === "string" && value.includes("/")) {
      const str = value.split("/");
      if (str.length === 3) {
        const year = Number(str[2]);
        const month = Number(str[1]) - 1;
        const day = Number(str[0]);
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          if (
            ((!(year % 4) && year % 100) || !(year % 400)) &&
            month >= 1 &&
            month <= 12 &&
            day >= 1 &&
            day <= 31
          )
            return new Date(year, month, day);
          else return null;
        } else return null;
      } else {
        return null;
      }
    } else if (typeof value === "string" && value === "") {
      return new Date();
    }
    const timestamp = typeof value === "number" ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
  testUsedSeparator(inputText) {
    this.operation1Length = 0;
    this.operation2Length = 0;
    //Test which seperator is used '/' or '-'
    var opera1 = inputText.split("/");
    var opera2 = inputText.split("-");
    this.operation1Length = opera1.length;
    this.operation2Length = opera2.length;
    // Extract the string into month, date and year
    if (this.operation1Length > 1) {
      var pdate = inputText.split("/");
    } else if (this.operation2Length > 1) {
      var pdate = inputText.split("-");
    }
    return pdate;
  }
  validateAndReturnDate(dd, mm, yy) {
    // Create list of days of a month [assume there is no leap year by default]
    var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (mm == 1 || mm > 2) {
      if (dd > ListofDays[mm - 1]) {
        return null;
      }
    }
    if (mm == 2) {
      var lyear = false;
      if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
        lyear = true;
      }
      if (lyear == false && dd >= 29) {
        return null;
      }
      if (lyear == true && dd > 29) {
        return null;
      }
    }
    return new Date(yy, mm, dd);
  }
  validateDate(inputText, currentDateFormat) {
    let date = null;
    switch (currentDateFormat) {
      case "yyMMdd":
        var dateformat = /^\d{2}(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var yy = parseInt(inputText.substring(0, 2)) + 2000;
          var mm = parseInt(inputText.substring(2, 4)) - 1;
          var dd = parseInt(inputText.substring(4));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "ddMMyyyy":
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{4}$/;
        if (inputText.match(dateformat)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = parseInt(inputText.substring(2, 4)) - 1;
          var yy = parseInt(inputText.substring(4));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yyyyMMdd":
        var dateformat = /^\d{4}(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var yy = parseInt(inputText.substring(0, 4));
          var mm = parseInt(inputText.substring(4, 6)) - 1;
          var dd = parseInt(inputText.substring(6));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MMddyyyy":
        var dateformat = /^(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])\d{4}$/;
        if (inputText.match(dateformat)) {
          var mm = parseInt(inputText.substring(0, 2)) - 1;
          var dd = parseInt(inputText.substring(2, 4));
          var yy = parseInt(inputText.substring(4));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MMddyy":
        var dateformat = /^(0?[1-9]|1[012])(0?[1-9]|[12][0-9]|3[01])\d{2}$/;
        if (inputText.match(dateformat)) {
          var mm = parseInt(inputText.substring(0, 2)) - 1;
          var dd = parseInt(inputText.substring(2, 4));
          var yy = parseInt(inputText.substring(4)) + 2000;
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "ddMMMyyyy":
        var MONTHS = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)\d{4}$/;
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dateformat2 = /^(0?[1-9]|[12][0-9]|3[01])(Jan|Feb|Mar|Apr|May|Jun|July|Aug|Sep|Oct|Nov|Dec)\d{4}$/;
        if (inputText.match(dateformat)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = MONTHS.indexOf(inputText.substring(2, 5).toUpperCase());
          var yy = parseInt(inputText.substring(5));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else if (inputText.match(dateformat2)) {
          var dd = parseInt(inputText.substring(0, 2));
          var mm = MONTHS.indexOf(inputText.substring(2, 5).toUpperCase());
          var yy = parseInt(inputText.substring(5));
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd/MM/yyyy":
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var yy = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd-MM-yyyy":
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var yy = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yyyy-MM-dd":
        var dateformat = /^\d{4}[\-](0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var yy = parseInt(pdate[0]);
          var mm = parseInt(pdate[1]) - 1;
          var dd = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM/dd/yyyy":
        var dateformat = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM-dd-yyyy":
        var dateformat = /^(0?[1-9]|1[012])[\-](0?[1-9]|[12][0-9]|3[01])[\-]\d{4}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "MM/dd/yy":
        var dateformat = /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{2}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var mm = parseInt(pdate[0]) - 1;
          var dd = parseInt(pdate[1]);
          var yy = parseInt(pdate[2]) + 2000;
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "yy/MM/dd":
        var dateformat = /^\d{2}[\/](0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var yy = parseInt(pdate[0]) + 2000;
          var mm = parseInt(pdate[1]) - 1;
          var dd = parseInt(pdate[2]);
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
      case "dd-MMM-yy":
        var MONTHS = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\-](JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)[\-]\d{2}$/;
        var months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var dateformat2 = /^(0?[1-9]|[12][0-9]|3[01])[\-](Jan|Feb|Mar|Apr|May|Jun|July|Aug|Sep|Oct|Nov|Dec)[\-]\d{2}$/;
        if (inputText.match(dateformat)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = MONTHS.indexOf(pdate[1].toUpperCase());
          var yy = parseInt(pdate[2]) + 2000;
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else if (inputText.match(dateformat2)) {
          var pdate = this.testUsedSeparator(inputText);
          var dd = parseInt(pdate[0]);
          var mm = months.indexOf(pdate[1]);
          var yy = parseInt(pdate[2]) + 2000;
          date = this.validateAndReturnDate(dd, mm, yy);
          return date;
        } else return null;
    }
  }

  getDefaultPathAttachments() {
    return "../../../../assets/img/attachments1.png";
  }


  public GetCurrentDateAsModel() {
    const currentDate = new Date();
    const date = new DateTimeModel();
    date.year = currentDate.getFullYear();
    date.month = currentDate.getMonth() + 1;
    date.day = currentDate.getDate();
    date.date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return date;
  }

  public GetCurrentDateTimeAsModel() {
    const currentDate = new Date();
    const date = new DateTimeModel();
    date.year = currentDate.getFullYear();
    date.month = currentDate.getMonth() + 1;
    date.day = currentDate.getDate();
    date.hour = currentDate.getHours();
    date.minute = currentDate.getMinutes();
    date.date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );
    return date;
  }


  //Toast Messages And ProgressBar

  public showWarning(title: string, msg: string) {
    this._snackBar.openFromComponent(SnackbarMessageComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: [classMessageType.ERROR],
      data: {
        message: msg, title: title, snackType: messageType.WARNING
      }
    });
  }
  public showSuccess(title: string, msg: string) {
    this._snackBar.openFromComponent(SnackbarMessageComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: [classMessageType.SUCCESS],
      data: {
        message: msg, title: title, snackType: messageType.SUCCESS
      }
    });
  }
  public showInfo(title: string, msg: string) {
    this._snackBar.openFromComponent(SnackbarMessageComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: [classMessageType.SUCCESS],
      data: {
        message: msg, title: title, snackType: messageType.INFO
      }
    });
  }
  public showError(title: string, msg: string) {
    this._snackBar.openFromComponent(SnackbarMessageComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration,
      panelClass: [classMessageType.ERROR],
      data: {
        message: msg, title: title, snackType: messageType.ERROR
      }
    });
  }

  public showExpiry(title: string, msg: string) {
    this._snackBar.openFromComponent(SnackbarMessageComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.duration + 3,
      panelClass: [classMessageType.ERROR],
      data: {
        message: msg, title: title, snackType: messageType.ERROR
      }
    });
  }

  public checkImageSize(event: any) {
    const file = (event.target as HTMLInputElement).files[0];
    var img = file.size;
    var imgsize = img / 1024;
    if (imgsize >= 2084) {
      this._snackBar.openFromComponent(SnackbarMessageComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.duration,
        panelClass: [classMessageType.WARN],
        data: {
          message: "File too Big grater then 2 MB, maybe effect on the application performance!.",
          title: "Warning", snackType: messageType.WARNING
        }
      });
    }
  }

  public changeDir() {
    if (this.selectedLanguageId === 1) {
      this.direction.next("ltr");
    } else if (this.selectedLanguageId === 2) {
      this.direction.next("rtl");
    } else {
      this.direction.next("rtl");
    }
  }

  checkActivitiesAvailability(menuURL: string, action: MenuActions) {
    let index = this.sessionDataProvider.allMenusAvailable.findIndex(
      (x) => x.url === menuURL //&& !x.isParentMenu
    );
    if (index > -1) {
      if (
        action &&
        this.sessionDataProvider.allMenusAvailable[index].addedActionTypes.find(
          (x) => x.actionTypeId === action.valueOf()
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  EncryptAES(value: any) {

    if (this.selectedSolutionId === Solution.Sanad.valueOf() && value && value != "") {

      let staticKey = '0123456789123456';
      var key = CryptoJS.enc.Utf8.parse(staticKey);
      var iv = CryptoJS.enc.Utf8.parse(staticKey);

      var encrypted = CryptoJS.AES.encrypt(
        value.toString(),
        key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

      return encrypted.toString();

    }
    else {

      return value;

    }

  }

  DecryptAES(value: string) {

    if ((this.selectedSolutionId === Solution.Sanad.valueOf() || this.selectedSolutionId === Solution.MedicalPOD.valueOf())
      && value && value != "") {

      try {
        let staticKey = '0123456789123456';
        var key = CryptoJS.enc.Utf8.parse(staticKey);
        var iv = CryptoJS.enc.Utf8.parse(staticKey);

        var decrypted = CryptoJS.AES.decrypt(
          value,
          key, {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });

        let d = decrypted.toString(CryptoJS.enc.Utf8);
        if(d == null || d == '') {
          return value;
        } else {
          return d;
        }
      }
      catch(e) {
        return value;
      }

    } else {

      return value;

    }

  }
  public generateRandomAlphnumericText(length: number) {
    var result = '';
    // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var characters = 'ABCDEF0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  dataURItoBlob(dataURI: string) {
    const fileType = dataURI.split(';')[0].split(':')[1];
    const base64 = dataURI.replace(dataURI.split('base64,')[0] + 'base64,', '');
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: fileType });
    return blob;
  }

  getDateTimeModel(selectedDateValue): DateTimeModel {
    let selectedDate = new Date(selectedDateValue);
    const date = new DateTimeModel();
    (selectedDate);
    date.year = selectedDate.getFullYear();
    date.month = selectedDate.getMonth() + 1;
    date.day = selectedDate.getDate();
    date.minute = selectedDate.getMinutes();
    date.hour = selectedDate.getHours();
    date.date = selectedDate;
    return date;
  }
}
