import { TextLanguage } from "../../enum/general/textLanguage.model";
import { DateTimeModel } from "../dateTimeModel/date-time.model";
import { DynamicFormsEntriesModel } from "./dynamic-forms-entries.model";
import { DynamicFormsSectionsModel } from "./dynamic-forms-sections.model";

export class DynamicFormsModel {
  dynamicFormId: number;
  dynamicFormName: string;
  validFromDateModel: DateTimeModel;
  validToDateModel: DateTimeModel;
  // startDateModel: DateTimeModel;
  // endDateModel: DateTimeModel;
  onHold: boolean;
  isPublished: boolean;
  noOfSections: number;
  sections: DynamicFormsSectionsModel[];
  noOfEntries: number;
  entries: DynamicFormsEntriesModel[];
  descriptions: TextLanguage[];
  mainId: number;
  assetId: number;
  solutionId: number;
  inspectorName: string;
  clientName: string;
  inspectionDate: string;
  answerId: number;
  dynamicFormTypeId: string;
  hasAnswers: boolean;
  Inspectors: string;
  statusId: number;
  locations: string;

  constructor() {
    this.dynamicFormId = -1;
    this.dynamicFormName = "";
    this.validFromDateModel = new DateTimeModel();
    this.validToDateModel = new DateTimeModel();
    this.noOfSections = 0;
    this.sections = [];
    this.noOfEntries = 0;
    this.entries = [];
    this.descriptions = [];
    this.assetId = -1;
    this.mainId = -1;
    this.solutionId = -1;
    this.inspectorName = "";
    this.clientName = "";
    this.inspectionDate = "";
    this.answerId = -1;
    this.dynamicFormTypeId = "--1-";
    this.hasAnswers = false;
    this.Inspectors = "";
    this.statusId = -1;
    this.locations = '';
  }
}
