import { ItemModel } from "../item-models/item.model";

export class OperationDetailsModel {
    transactionId: string;
    locationId: number;
    itemId: number;
    warehouseId: number;
    quantity: number;
    itemTypeId: number;
    itemName: string;
    itemCode: string;
    notAvailable: boolean;
    barcode: string;
    isSerialized: boolean;
    uomId: number;

    constructor(item?: ItemModel) {
        this.transactionId = '';
        this.warehouseId = -1;
        this.locationId = -1;
        this.notAvailable = false;
        this.isSerialized = false;
        this.uomId = -1;

        if (item == null) {
            this.itemId = -1;
            this.quantity = 0;
            this.itemTypeId = -1;
            this.itemName = '';
            this.itemCode = '';
            this.barcode = '';
            this.isSerialized = false;
        } else {
            this.itemId = +item.itemId;
            this.quantity = +item.quantity;
            this.itemTypeId = +item.itemTypeId;
            this.itemName = item.itemName;
            this.itemCode = item.itemCode;
            this.barcode = item.barcode;
            this.isSerialized = item.isSerialized;
            this.uomId = item.uomId;
        }
    }
}
