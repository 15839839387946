import { TextLanguage } from "../general/textLanguage.model";

export class CategoryModel {
  categoryId: number;
  categoryTypeId: number;
  parentId: number;
  categoryName: string;
  categoryCode: string;
  categoryTypeName: string;
  categoryParentName: string;
  categoryImage: string;
  isImageChanged: boolean;
  isParent: boolean;

  inactive: boolean;
  isUsed: boolean;

  descriptions: TextLanguage[];

  constructor() {
    this.categoryId = -1;
    this.categoryTypeId = -1;
    this.categoryCode = '';
    this.categoryName = '';
    this.parentId = -1;
    this.categoryTypeName = '';
    this.categoryParentName = '';
    this.categoryImage = '';
    this.isImageChanged = false;
    this.isParent = false;
    this.isUsed = false;

    this.inactive = false;
    this.descriptions = [];
  }
}