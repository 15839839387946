import { TextLanguage } from "../../enum/general/textLanguage.model";

export class LocationModel {
  locationId: number;
  locationTypeId: number
  locationName: string;
  locationCode: string;
  locationTypeName: string;
  locationNotes: string;
  organizationId: number;
  inactive: boolean;
  descriptions: TextLanguage[];

  isParent: boolean;
  parentId: string;
  categoryId: number;
  locationBarcode: string;
  categoryName: string;
  subLocations: LocationModel[];
  idSequence: string;
  isOld: boolean;
  longitude: number;
  latitude: number;

  isLocation: boolean;
  integrationId: string;

  constructor() {
    this.locationId = -1;
    this.locationTypeId = -1;
    this.locationName = '';
    this.locationCode = '';
    this.locationTypeName = '';
    this.locationNotes = '';
    this.organizationId = -1;
    this.inactive = false;
    this.descriptions = [];

    this.isParent = false;
    this.parentId = '-1';
    this.categoryId = -1;
    this.locationBarcode = '';
    this.categoryName = '';

    this.subLocations = [];
    this.idSequence = '';
    this.isOld = false;
    this.longitude = 0.0;
    this.latitude = 0.0;

    this.isLocation = true;
    this.integrationId = '';
  }
}
