import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ImportDataTypes } from 'src/app/shared/enum/import/ImportDataTypes';
import { ItemModel, ItemSKUDetailsModel } from 'src/app/shared/model/item-models/item.model';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { LocationTypeModel } from 'src/app/shared/model/location-models/location-type.model';
import { SupplierModel } from 'src/app/shared/model/supplier-models/supplier.model';
import * as XLSX from 'xlsx';
import { LocationService } from '../../../content/data-definition/location/location.service';
import { SuppliersService } from '../../../content/data-definition/suppliers/suppliers.service';
import { ItemService } from '../../../content/data-definition/item/item.service';
import { InventorySolution, Solution } from '../../enum/solution.enum';
import { Client } from '../../enum/client.enum';
import { StaffService } from 'src/app/content/data-definition/staff/staff.service';
import { StaffModel } from '../../model/staff-models/staff-model';
import { StaffTypeModel } from '../../model/staff-type-model/staff-type-model';
import { AssetsModel } from '../../model/assets-models/assets.model';
import { AssetCategoryModel } from '../../model/assets-models/asset-category.model';
import { AssetConditionModel } from '../../model/assets-models/asset-condition.model';
import { AssetSubcategoryModel } from '../../model/assets-models/asset-subcategory.model';
import { AssetsStatusModel } from '../../model/assets-models/assets-status.model';
import { AssetsTypeModel } from '../../model/assets-models/assets-type.models';
import { AssetsService } from 'src/app/content/data-definition/assets/assets.service';
import { ConstantStorage } from 'src/app/core/constants/constant-stroage';
import { DatePipe } from '@angular/common';
import { Direction } from '@angular/cdk/bidi';
import { UOMModel } from '../../model/uom/uom.model';

@Component({
  selector: 'app-data-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.css']
})
export class DataImportDialog implements OnInit {

  form: FormGroup;

  itemsList: ItemModel[] = [];
  itemBarcodes: ItemSKUDetailsModel[] = [];
  suppliersList: SupplierModel[] = [];
  locationsList: LocationModel[] = [];
  staffList: StaffModel[] = [];
  assetList: AssetsModel[] = [];

  locationTypesList: LocationTypeModel[] = [];
  isLocationTypeLoading: boolean = false;

  staffTypes: StaffTypeModel[] = [];
  universityList: LocationModel[] = [];

  excelData: any[] = [];
  whData: any[] = [];

  mode: ImportDataTypes;

  uploadedFileName = 'Desc_NoFileUploaded';
  showLocationTypes: boolean = false;

  duplicateList: any[] = [];
  count: number = 0;
  numberOfHits: number = 1;
  numberOfItemsPerHit: number = 10000;
  insertedList: any[] = [];
  duplicatedList: any[] = [];
  failedToInsertList: any[] = [];

  formData: FormData;
  fileCunks: Blob[] = [];

  uploadedFiles: any[] = [];

  totalSize: number = 0;

  loggedInUserId: number = -1;
  dbFormattedDate: string = '';
  showImportBarcodesToggle: boolean = false;
  showImportItemUOMToggle: boolean = false;
  direction: Direction = "rtl";

  dynamicMode: boolean = false;
  uomList: UOMModel[] = [];
  isKasoticSelected: boolean = false;
  isIECSelected: boolean = false;

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private itemService: ItemService,
    private coreSession: CoreSession,
    private locationService: LocationService,
    private suppliersService: SuppliersService,
    private staffService: StaffService,
    private assetsService: AssetsService,
    private datePipe: DatePipe) { }


  ngOnInit(): void {
    this.isIECSelected = this.coreSession.selectedSolutionId == Solution.IEC.valueOf();
    this.isKasoticSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf();
    this.direction = this.translateService.currentLang.toLocaleLowerCase() == 'en' ? 'ltr' : 'rtl';

    this.mode = this.data.importMode;

    this.dynamicMode = this.mode != ImportDataTypes.GenerateBarcodesAsPDF && (CoreSession.selectedClient == Client.Inventory_Palestine.valueOf() ||
    CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf() ||
    CoreSession.selectedClient == Client.Inventory_ToyTriangle.valueOf() ||
    CoreSession.selectedClient == Client.Inventory_Jomaa_AlMajed.valueOf() ||
    CoreSession.selectedClient == Client.Inventory_INCUBE.valueOf());

    this.showLocationTypes = this.mode == ImportDataTypes.Locations && this.coreSession.selectedSolutionId != Solution.Inventory.valueOf();
    this.showImportBarcodesToggle = this.mode == ImportDataTypes.Items && (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf());
    // this.showImportItemUOMToggle = this.mode == ImportDataTypes.Items && CoreSession.selectedClient == Client.Inventory_Palestine.valueOf();
    this.initForm();

    if (this.showLocationTypes)
      this.getLocationTypes();
    if (this.mode == ImportDataTypes.Employees)
      this.getStaffRequiredLists();

    if (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()) {
      this.getItemRequiredList();
    }

    if (sessionStorage.length > 0) {
      this.loggedInUserId = JSON.parse(sessionStorage.getItem(ConstantStorage.CurrentUser)).staffId;
    } else {
      this.loggedInUserId = JSON.parse(localStorage.getItem(ConstantStorage.CurrentUser)).staffId;
    }
  }

  initForm() {
    this.form = new FormGroup({
      locationType: new FormControl(undefined, this.showLocationTypes ? Validators.required : []),
      importBarcodes: new FormControl(false),
      importItemUOM: new FormControl(false),
    });
  }

  getItemRequiredList() {
    this.itemService.getItemRequiredLists().subscribe(res => {

      this.uomList = res.uomList;

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  getStaffRequiredLists() {
    this.staffService.getStaffAllRequiredLists().subscribe(result => {

      this.staffTypes = result.staffTypeList;
      this.universityList = result.staffDepartmentList.slice().filter(loc => loc.locationTypeId == 3).slice();

    });
  }

  getLocationTypes() {
    this.isLocationTypeLoading = true;

    this.locationService.getLocationRequiredLists().subscribe(res => {

      //console.log(res);

      this.locationTypesList = res.locationTypesList;

      if (this.locationTypesList && this.locationTypesList.length > 0) {
        //this.locationTypeControl.setValue(3);
      }

      this.isLocationTypeLoading = false;

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  public readExcel(event: any) {
    if (this.excelData == null) {
      this.excelData = [];
    }
    if (this.uploadedFiles == null) {
      this.uploadedFiles = [];
    }
    if (this.whData == null) {
      this.whData = [];
    }
    const files: File[] = event.srcElement.files;
    if (files.length > 0) {
      this.coreSession.ModalLoading.Show();
      Array.from(files).forEach((file, i) => {

        if (
          file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
          || file.type.match("application/vnd.ms-excel")
          || file.type.match("text/csv")
        ) {
          this.totalSize += file.size;

          if ((file.size / (1000 * 1000)) > 20) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgLargeFileWarning));
            this.coreSession.ModalLoading.Hide();
            return;
          }

          if (this.mode == ImportDataTypes.ItemBarcodes) {
            this.uploadedFiles.push({
              fileName: file.name,
              file: file,
            });
          }
          else {

            if (((this.totalSize) / (1000 * 1000)) > 20) {
              this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgLargeFileWarning));
              this.coreSession.ModalLoading.Hide();
              return;
            }

            this.uploadedFiles.push({
              fileName: file.name,
              file: file,
            });

            this.uploadedFileName = file.name;

            const target: DataTransfer = <DataTransfer>(<unknown>event.target);

            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
              const binarystr: string = e.target.result;
              const workbook: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

              let wsname: string = workbook.SheetNames[0];

              const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

              /* save data */
              if (this.dynamicMode)
                this.excelData.push(...XLSX.utils.sheet_to_json(ws, { raw: false, blankrows: false }));
              else
                this.excelData.push(...XLSX.utils.sheet_to_json(ws, { raw: true, blankrows: false }));

              if (CoreSession.selectedClient == Client.Inventory_Palestine.valueOf() && this.mode == ImportDataTypes.Items) {
                wsname = workbook.SheetNames[1];

                const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

                /* save data */
                this.whData.push(...XLSX.utils.sheet_to_json(ws, { raw: false, blankrows: false }));
              }
              if (i == (files.length - 1))
                this.coreSession.ModalLoading.Hide();
            };
            reader.readAsBinaryString(file);
          }

        } else {
          this.coreSession.ModalLoading.Hide();
          this.uploadedFileName = "Desc_NoFileUploaded";
          this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_FileShouldBeExcel'));
          return;
        }
      });
      if (this.mode == ImportDataTypes.ItemBarcodes)
        this.coreSession.ModalLoading.Hide();
    }
  }

  onSaveClicked() {
    if (this.form.invalid || (this.excelData.length == 0 && this.uploadedFiles.length == 0)) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }
    this.coreSession.ModalLoading.Show();

    try {
      if (!this.dynamicMode &&
        this.mode != ImportDataTypes.ItemBarcodes) {
        this.prepareList();
      }
    } catch (error) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_InvalidSheet'));
      console.log(error);
      return;
    }

    if (this.mode == ImportDataTypes.UOM) {
      this.count = 0;
      let list = [];

      this.numberOfHits = Math.ceil(this.excelData.length / this.numberOfItemsPerHit);
      list = this.excelData.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);

      this.insertItemUOMList(list);

    } else if (this.mode == ImportDataTypes.Items) {
      this.count = 0;
      let list = [];
      let whListData = [];

      if (this.dynamicMode) {
        this.numberOfHits = Math.ceil(this.excelData.length / this.numberOfItemsPerHit);
        list = this.excelData.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);
        whListData = this.whData.slice();
      } else {
        this.numberOfHits = Math.ceil(this.itemsList.length / this.numberOfItemsPerHit);
        this.duplicatedList = this.duplicateList.slice();
        list = this.itemsList.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);
      }

      this.insertItemsList(list, whListData);

    } else if (this.mode == ImportDataTypes.ItemBarcodes) {
      this.dbFormattedDate = `TO_DATE('${this.datePipe.transform(new Date, 'yyyy/MM/dd')}', 'YYYY/MM/DD')`;
      this.count = 0;
      this.numberOfHits = this.uploadedFiles.length;
      if (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf())
        this.insertItemSKUDetails(this.uploadedFiles[this.count].file);
      else
        this.insertDynamicItemSKUDetails(this.uploadedFiles[this.count].file);


    } else if (this.mode == ImportDataTypes.Locations) {

      this.locationService.insertLocationList(this.locationsList, this.coreSession.showImportErrorList).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          } else {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

            let resultDuplicateList = this.duplicateList.slice();
            resultDuplicateList.push(...response.data.duplicateList.slice());

            this.dialogRef.close({
              insertedList: response.data.insertedList.slice(),
              duplicateList: resultDuplicateList.slice(),
              failedToInsertList: response.data.failedToInsertList.slice()
            });
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });

    } else if (this.mode == ImportDataTypes.Suppliers) {

      this.suppliersService.insertSupplierList(this.suppliersList, this.coreSession.showImportErrorList).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          } else {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

            let resultDuplicateList = this.duplicateList.slice();
            resultDuplicateList.push(...response.data.duplicateList.slice());

            this.dialogRef.close({
              insertedList: response.data.insertedList.slice(),
              duplicateList: resultDuplicateList.slice(),
              failedToInsertList: response.data.failedToInsertList.slice()
            });
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    } else if (this.mode == ImportDataTypes.Employees) {

      this.staffService.insertStaffList(this.staffList, this.coreSession.showImportErrorList).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          } else {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

            let resultDuplicateList = this.duplicateList.slice();
            resultDuplicateList.push(...response.data.duplicateList.slice());

            this.dialogRef.close({
              insertedList: response.data.insertedList.slice(),
              duplicateList: resultDuplicateList.slice(),
              failedToInsertList: response.data.failedToInsertList.slice()
            });
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    } else if (this.mode == ImportDataTypes.Assets) {

      this.assetsService.insertAssetsList(this.assetList, this.coreSession.showImportErrorList).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          } else {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

            let resultDuplicateList = this.duplicateList.slice();
            resultDuplicateList.push(...response.data.duplicateList.slice());

            this.dialogRef.close({
              insertedList: response.data.insertedList.slice(),
              duplicateList: resultDuplicateList.slice(),
              failedToInsertList: response.data.failedToInsertList.slice()
            });
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    } else if (this.mode == ImportDataTypes.GenerateBarcodesAsPDF) {
      this.dialogRef.close(this.itemsList);
    }

  }

  insertItemsList(itemList: any[], whListData: any[]) {
    if (this.mode == ImportDataTypes.Items && this.dynamicMode) {
      this.itemService.insertDynamicItemsList(itemList, whListData, this.coreSession.showImportErrorList).subscribe(response => {
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            this.coreSession.ModalLoading.Hide();
          } else {
            this.count++;
            if (this.count == this.numberOfHits) {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              this.dialogRef.close({
                insertedList: this.insertedList.slice(),
                duplicateList: this.duplicatedList.slice(),
                failedToInsertList: this.failedToInsertList.slice(),
                importMode: this.mode,
              });
            } else {
              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              const list = this.excelData.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);
              this.insertItemsList(list, []);
            }

          }
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    } else {
      this.itemService.insertItemsList(itemList, this.coreSession.showImportErrorList).subscribe(response => {
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          } else {
            this.count++;
            if (this.count == this.numberOfHits) {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

              this.dialogRef.close({
                insertedList: this.insertedList.slice(),
                duplicateList: this.duplicatedList.slice(),
                failedToInsertList: this.failedToInsertList.slice(),
                importMode: this.mode,
              });
            } else {
              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              const list = this.itemsList.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);
              this.insertItemsList(list, []);
            }

          }
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    }
  }

  insertItemUOMList(itemUOMList: any[]) {
    this.itemService.insertItemUOMList(itemUOMList, this.coreSession.showImportErrorList).subscribe(response => {
      if (response.status != null && response.status >= 0) {
        if (response.data == -1) {
          this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          this.coreSession.ModalLoading.Hide();
        } else {
          this.count++;
          if (this.count == this.numberOfHits) {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

            this.insertedList.push(...response.data.insertedList.slice());
            this.duplicatedList.push(...response.data.duplicateList.slice());
            this.failedToInsertList.push(...response.data.failedToInsertList.slice());

            this.dialogRef.close({
              insertedList: this.insertedList.slice(),
              duplicateList: this.duplicatedList.slice(),
              failedToInsertList: this.failedToInsertList.slice(),
              importMode: this.mode,
            });
          } else {
            this.insertedList.push(...response.data.insertedList.slice());
            this.duplicatedList.push(...response.data.duplicateList.slice());
            this.failedToInsertList.push(...response.data.failedToInsertList.slice());

            const list = this.excelData.slice(this.count * this.numberOfItemsPerHit, (this.count + 1) * this.numberOfItemsPerHit);
            this.insertItemUOMList(list);
          }
        }
      } else {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  insertItemSKUDetails(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      let wsname: string = workbook.SheetNames[0];

      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      /* save data */
      let jsonData = XLSX.utils.sheet_to_json(ws, { raw: true, blankrows: false });
      let insertStringArray: string[] = [];

      let uomInsertStringArray: string[] = [];
      let uomIndex = -1;
      let uomId = -1;

      this.itemBarcodes = [];
      if (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()) {
        jsonData.slice().forEach(
          r => {
            const item = new ItemSKUDetailsModel();

            item.itemId = r['PRB_PR_ID'] && r['PRB_PR_ID'] != null ? +r['PRB_PR_ID'] : -1;
            item.barcode = r['PRB_BARCODE'] && r['PRB_BARCODE'] != null ? r['PRB_BARCODE'].toString() : '';
            item.warehouseId = r['WH_ID'] && r['WH_ID'] != null ? +r['WH_ID'] : -1;
            item.uomConversion = r['PRB_CONVERSION'] && r['PRB_CONVERSION'] != null ? +r['PRB_CONVERSION'] : 1;
            item.itemUOM = r['PRB_TYPE'] && r['PRB_TYPE'] != null ? r['PRB_TYPE'].toString() : '';
            item.unitPrice = r['PRB_UNIT_PRICE'] && r['PRB_UNIT_PRICE'] != null ? +r['PRB_UNIT_PRICE'] : 0;
            item.costPrice = r['PRB_UNIT_COST'] && r['PRB_UNIT_COST'] != null ? +r['PRB_UNIT_COST'] : 0;

            if (this.itemBarcodes.some(d => d.itemId == item.itemId &&
              d.barcode == item.barcode && d.warehouseId == item.warehouseId)) {
              this.duplicateList.push(item);
            } else {
              this.itemBarcodes.push(item);

              // uomIndex = this.uomList.findIndex(u => u.quantity == item.uomConversion);

              // if (uomIndex > -1) {
              //   item.uomId = this.uomList[uomIndex].uomId;
              // } else {
              //   uomInsertStringArray.push(`INSERT INTO Uom(UomId, UOMCode, CreatedBy, CreationDate, ModifiedBy, ModificationDate, SolutionId, Quantity)\n` +
              //     `SELECT (Select COALESCE(max(UomId),0) + 1 from UOM Where SolutionId = ${this.coreSession.selectedSolutionId}), '${item.itemUOM}${item.uomConversion}', ${this.loggedInUserId}, ${this.dbFormattedDate}, ${this.loggedInUserId}, ${this.dbFormattedDate}, ${this.coreSession.selectedSolutionId}, ${item.uomConversion}\n` +
              //     `WHERE NOT EXISTS (SELECT uomId FROM uom WHERE Quantity = ${item.uomConversion});\n`);
              //   uomInsertStringArray.push(`INSERT INTO UOMLanguage(UomId, LanguageId, Description, SolutionId)
              //   SELECT (SELECT uomId FROM uom WHERE Quantity = ${item.uomConversion}), 1, '${item.itemUOM}${item.uomConversion}', ${this.coreSession.selectedSolutionId}
              //   On Conflict(UomId, LanguageId, SolutionId) DO NOTHING;
              //   INSERT INTO UOMLanguage(UomId, LanguageId, Description, SolutionId)
              //   SELECT (SELECT uomId FROM uom WHERE Quantity = ${item.uomConversion}), 2, '${item.itemUOM}${item.uomConversion}', ${this.coreSession.selectedSolutionId}
              //   On Conflict(UomId, LanguageId, SolutionId) DO NOTHING;`);
              // }

              insertStringArray.push("(");

              insertStringArray.push(`${item.itemId}, `);//0
              insertStringArray.push(`'${item.barcode.replace("'", "''")}', `);//1
              insertStringArray.push(`${item.warehouseId}, `);//2
              insertStringArray.push(`${item.uomConversion}, `);//3
              insertStringArray.push(`1, `);//4
              insertStringArray.push(`${item.unitPrice}, `);//5
              insertStringArray.push(`${item.costPrice}, `);//6
              insertStringArray.push(`${this.loggedInUserId}, `); //7
              insertStringArray.push(`${this.dbFormattedDate}, `);//8
              insertStringArray.push(`${this.loggedInUserId}, `); //9
              insertStringArray.push(`${this.dbFormattedDate}, `);//10
              insertStringArray.push(this.coreSession.selectedSolutionId.toString()); //11

              insertStringArray.push("),");
            }
          }
        );
      }

      let formData: FormData = new FormData();
      formData.append('itemBarcodesJSON', JSON.stringify(this.itemBarcodes));
      formData.append('insertString', insertStringArray.join(''));
      formData.append('uomInsertStringArray', uomInsertStringArray.join(''));
      formData.append('showErrorList', this.coreSession.showImportErrorList.toString());
      let lastIteration = (this.count + 1) == this.numberOfHits;
      formData.append('lastIteration', lastIteration.toString());

      // console.log(jsonData);

      this.itemService.insertItemSKUDetails(formData).subscribe(response => {
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            this.coreSession.ModalLoading.Hide();
          } else {
            this.count++;
            if (this.count == this.numberOfHits) {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              this.dialogRef.close({
                insertedList: this.insertedList.slice(),
                duplicateList: this.duplicatedList.slice(),
                failedToInsertList: this.failedToInsertList.slice(),
                importMode: this.mode,
              });
            } else {
              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              this.insertItemSKUDetails(this.uploadedFiles[this.count].file);
            }

          }
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          console.log(error);
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    };
  }

  insertDynamicItemSKUDetails(file: File) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      let wsname: string = workbook.SheetNames[0];

      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      /* save data */
      let jsonData = XLSX.utils.sheet_to_json(ws, { raw: true, blankrows: false });

      // console.log(jsonData);

      this.itemService.insertDynamicItemSKUDetails(jsonData).subscribe(response => {
        if (response.status != null && response.status >= 0) {
          if (response.data == -1) {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            this.coreSession.ModalLoading.Hide();
          } else {
            this.count++;
            if (this.count == this.numberOfHits) {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              this.dialogRef.close({
                insertedList: this.insertedList.slice(),
                duplicateList: this.duplicatedList.slice(),
                failedToInsertList: this.failedToInsertList.slice(),
                importMode: this.mode,
              });
            } else {
              this.insertedList.push(...response.data.insertedList.slice());
              this.duplicatedList.push(...response.data.duplicateList.slice());
              this.failedToInsertList.push(...response.data.failedToInsertList.slice());

              this.insertDynamicItemSKUDetails(this.uploadedFiles[this.count].file);
            }

          }
        } else {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          console.log(error);
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        });
    };
  }

  prepareList() {
    if (this.mode == ImportDataTypes.Items && CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()) {
      this.itemsList = [];
      this.excelData.slice().forEach(
        r => {
          const item = new ItemModel();

          item.itemId = -1;
          item.itemCategoryId = -1;
          item.itemName = r['pr_description'] && r['pr_description'] != null && r['pr_description'] != 'NULL' ? r['pr_description'].toString() : '';
          item.itemCode = r['pr_code'] && r['pr_code'] != null ? r['pr_code'].toString() : '';
          item.barcode = r['pr_barcode'] && r['pr_barcode'] != null ? r['pr_barcode'].toString() : '';
          item.itemUOM = r['UOM'] && r['UOM'] != null ? r['UOM'].toString() : '';
          item.inactive = false;
          item.warehouseCode = r['wh_id'] && r['wh_id'] != null ? r['wh_id'].toString() : '';
          item.costPrice = r['cost_price'] && r['cost_price'] != null ? r['cost_price'] : 0.0;
          item.unitPrice = r['unit_price'] && r['unit_price'] != null ? r['unit_price'] : 0.0
          item.supplierCode = r['pr_sup_no'] && r['pr_sup_no'] != null && r['pr_sup_no'] != 'NULL' ? r['pr_sup_no'].toString() : '';
          item.descriptions.push(...[
            {
              languageId: 1,
              description: r['pr_description'] && r['pr_description'] != null && r['pr_description'] != 'NULL' ? r['pr_description'].toString() : ''
            },
            {
              languageId: 2,
              description: r['pr_description2'] && r['pr_description2'] != null && r['pr_description2'] != 'NULL' ? r['pr_description2'].toString() : ''
            }
          ]);
          item.uomConversion = r['conversion'] && r['conversion'] != null ? r['conversion'] : 0;

          if (this.itemsList.some(d => d.itemCode == item.itemCode)) {
            this.duplicateList.push(item);
          } else {
            this.itemsList.push(item);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Items && CoreSession.selectedClient == Client.Inventory_Dubai.valueOf()) {

      this.itemsList = [];
      this.excelData.slice().forEach(
        r => {
          const item = new ItemModel();

          item.itemId = -1;
          item.itemCategoryId = -1;
          item.itemName = r['Description'] && r['Description'] != null && r['Description'] != 'NULL' ? r['Description'].toString() : '';
          item.itemCode = r['Code'] && r['Code'] != null ? r['Code'].toString() : '';
          item.barcode = r['Barcode'] && r['Barcode'] != null ? r['Barcode'].toString() : '';
          item.inactive = false;
          item.warehouseCode = r['Location'] && r['Location'] != null ? r['Location'].toString() : '';
          // item.quantity = r['Qty'] && r['Qty'] != null ? +r['Qty'] : 0;
          item.descriptions.push(...[
            {
              languageId: 1,
              description: r['Description'] && r['Description'] != null && r['Description'] != 'NULL' ? r['Description'].toString() : ''
            },
            {
              languageId: 2,
              description: r['Description'] && r['Description'] != null && r['Description'] != 'NULL' ? r['Description'].toString() : ''
            }
          ]);

          if (this.itemsList.some(d => d.itemCode == item.itemCode)) {
            this.duplicateList.push(item);
          } else {
            this.itemsList.push(item);
          }
        }
      );
    } 
    // else if (this.mode == ImportDataTypes.Items && CoreSession.selectedClient == Client.Inventory_INCUBE.valueOf()) {
    //   this.itemsList = [];
    //   this.excelData.slice().forEach(
    //     r => {
    //       const item = new ItemModel();

    //       item.itemId = -1;
    //       item.itemCategoryId = -1;
    //       item.brand = r['Brand'] && r['Brand'] != null ? r['Brand'].toString() : '';
    //       item.itemCategoryName = r['Category'] && r['Category'] != null ? r['Category'].toString() : '';
    //       item.itemTypeName = r['ItemType'] && r['ItemType'] != null ? r['ItemType'].toString() : '';
    //       item.itemSubTypeName = r['ItemSubType'] && r['ItemSubType'] != null ? r['ItemSubType'].toString() : '';
    //       item.subname = r['ShortName'] && r['ShortName'] != null ? r['ShortName'].toString() : '';
    //       item.itemCode = r['ItemNumber'] && r['ItemNumber'] != null ? r['ItemNumber'].toString() : '';
    //       item.barcode = r['ItemNumber'] && r['ItemNumber'] != null ? r['ItemNumber'].toString() : '';
    //       item.itemName = r['ItemDescription'] && r['ItemDescription'] != null && r['ItemDescription'] != 'NULL' ? r['ItemDescription'].toString() : '';
    //       item.inactive = false;
    //       item.warehouseCode = r['SiteID'] && r['SiteID'] != null ? r['SiteID'].toString() : '';
    //       // item.quantity = r['Qty'] && r['Qty'] != null ? +r['Qty'] : 0;
    //       item.isSerialized = true;
    //       item.descriptions.push(...[
    //         {
    //           languageId: 1,
    //           description: r['ItemDescription'] && r['ItemDescription'] != null && r['ItemDescription'] != 'NULL' ? r['ItemDescription'].toString() : '',
    //           subname: r['ShortName'] && r['ShortName'] != null && r['ShortName'] != 'NULL' ? r['ShortName'].toString() : ''
    //         },
    //         {
    //           languageId: 2,
    //           description: r['ItemDescription'] && r['ItemDescription'] != null && r['ItemDescription'] != 'NULL' ? r['ItemDescription'].toString() : '',
    //           subname: r['ShortName'] && r['ShortName'] != null && r['ShortName'] != 'NULL' ? r['ShortName'].toString() : ''
    //         }
    //       ]);

    //       if (this.itemsList.some(d => d.itemCode == item.itemCode)) {
    //         this.duplicateList.push(item);
    //       } else {
    //         this.itemsList.push(item);
    //       }
    //     }
    //   );
    // }
     else if (this.mode == ImportDataTypes.Items && CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()) {
      this.itemsList = [];

      this.excelData.slice().forEach(
        r => {
          const item = new ItemModel();

          item.itemId = r['PR_ID'] && r['PR_ID'] != null ? +r['PR_ID'] : -1;
          item.itemCategoryId = -1;
          item.itemCode = r['PR_CODE'] && r['PR_CODE'] != null ? r['PR_CODE'].toString() : '';
          item.itemName = r['PR_DESCREPTION'] && r['PR_DESCREPTION'] != null && r['PR_DESCREPTION'] != 'NULL' ? r['PR_DESCREPTION'].toString() : '';
          item.inactive = false;
          item.supplierCode = r['PR_SUP_NO'] && r['PR_SUP_NO'] != null ? r['PR_SUP_NO'].toString() : '';
          item.isSerialized = r['PR_IS_SERIAL_RECEIVING'] && r['PR_IS_SERIAL_RECEIVING'] != null ? r['PR_IS_SERIAL_RECEIVING'] != -1 : false;
          item.descriptions.push(...[
            {
              languageId: 1,
              description: r['PR_DESCREPTION'] && r['PR_DESCREPTION'] != null && r['PR_DESCREPTION'] != 'NULL' ? r['PR_DESCREPTION'].toString() : '',
            },
            {
              languageId: 2,
              description: r['PR_DESCREPTION2'] && r['PR_DESCREPTION2'] != null && r['PR_DESCREPTION2'] != 'NULL' ? r['PR_DESCREPTION2'].toString() : '',
            }
          ]);

          if (this.itemsList.some(d => d.itemCode == item.itemCode)) {
            this.duplicateList.push(item);
          } else {
            this.itemsList.push(item);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.ItemBarcodes && CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()) {

      this.itemBarcodes = [];
      this.excelData.slice().forEach(
        r => {
          const item = new ItemSKUDetailsModel();

          item.itemId = r['PRB_PR_ID'] && r['PRB_PR_ID'] != null ? +r['PRB_PR_ID'] : -1;
          item.barcode = r['PRB_BARCODE'] && r['PRB_BARCODE'] != null ? r['PRB_BARCODE'].toString() : '';
          item.warehouseId = r['WH_ID'] && r['WH_ID'] != null ? +r['WH_ID'] : -1;
          item.uomConversion = r['PRB_CONVERSION'] && r['PRB_CONVERSION'] != null ? +r['PRB_CONVERSION'] : 1;
          item.itemUOM = r['PRB_TYPE'] && r['PRB_TYPE'] != null ? r['PRB_TYPE'].toString() : '';
          item.unitPrice = r['PRB_UNIT_PRICE'] && r['PRB_UNIT_PRICE'] != null ? +r['PRB_UNIT_PRICE'] : 0;
          item.costPrice = r['PRB_UNIT_COST'] && r['PRB_UNIT_COST'] != null ? +r['PRB_UNIT_COST'] : 0;

          this.itemBarcodes.push(item);
        }
      );
    } else if (this.mode == ImportDataTypes.Locations && this.coreSession.selectedSolutionId == Solution.Inventory.valueOf()) {
      this.locationsList = [];
      this.excelData.slice().forEach(
        r => {
          const wh = new LocationModel();

          wh.locationId = -1;
          wh.locationTypeId = this.locationTypeControl.value;
          wh.locationName = r['wh_description'] && r['wh_description'] != null && r['wh_description'] != 'NULL' ? r['wh_description'].toString() : '';
          wh.locationCode = r['wh_code'] && r['wh_code'] != null ? r['wh_code'].toString() : '';
          wh.inactive = false;

          wh.descriptions.push(...[
            {
              languageId: 1,
              description: r['wh_description'] && r['wh_description'] != null && r['wh_description'] != 'NULL' ? r['wh_description'].toString() : ''
            },
            {
              languageId: 2,
              description: r['wh_description'] && r['wh_description'] != null && r['wh_description'] != 'NULL' ? r['wh_description'].toString() : ''
            }
          ]);

          if (this.locationsList.some(d => d.locationCode == wh.locationCode)) {
            this.duplicateList.push(wh);
          } else {
            this.locationsList.push(wh);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Locations && this.coreSession.selectedSolutionId != Solution.Inventory.valueOf()) {
      this.locationsList = [];
      this.excelData.slice().forEach(
        r => {
          const loc = new LocationModel();

          loc.locationId = -1;
          loc.locationTypeId = this.locationTypeControl.value;
          loc.locationName = r['Location Name'] && r['Location Name'] != null && r['Location Name'] != 'NULL' ? r['Location Name'].toString() : '';
          loc.locationCode = r['Location Code'] && r['Location Code'] != null ? r['Location Code'].toString() : '';
          loc.locationNotes = r['Notes'] && r['Notes'] != null ? r['Notes'].toString() : '';

          loc.inactive = false;

          loc.descriptions.push(...[
            {
              languageId: 1,
              description: r['Location Name'] && r['Location Name'] != null && r['Location Name'] != 'NULL' ? r['Location Name'].toString() : ''
            },
            {
              languageId: 2,
              description: r['Location Name'] && r['Location Name'] != null && r['Location Name'] != 'NULL' ? r['Location Name'].toString() : ''
            }
          ]);

          if (this.locationsList.some(d => d.locationCode == loc.locationCode)) {
            this.duplicateList.push(loc);
          } else {
            this.locationsList.push(loc);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Suppliers) {
      this.suppliersList = [];
      this.excelData.slice().forEach(
        r => {
          const sup = new SupplierModel();

          sup.supplierId = -1;
          sup.supplierName = r['sp_description'] && r['sp_description'] != null && r['sp_description'] != 'NULL' ? r['sp_description'].toString() : '';
          sup.supplierCode = r['sp_code'] && r['sp_code'] != null && r['sp_code'] != 'NULL' ? r['sp_code'].toString() : '';
          sup.inactive = false;
          sup.descriptions.push(...[
            {
              languageId: 1,
              description: r['sp_description'] && r['sp_description'] != null && r['sp_description'] != 'NULL' ? r['sp_description'].toString() : ''
            },
            {
              languageId: 2,
              description: r['sp_description'] && r['sp_description'] != null && r['sp_description'] != 'NULL' ? r['sp_description'].toString() : ''
            }
          ]);

          if (this.suppliersList.some(d => d.supplierCode == sup.supplierCode)) {
            this.duplicateList.push(sup);
          } else {
            this.suppliersList.push(sup);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Employees) {
      this.staffList = [];
      this.excelData.slice().forEach(
        r => {
          const staff = new StaffModel();

          staff.staffId = -1;
          staff.staffName = r['اسم المستخدم'] && r['اسم المستخدم'] != null && r['اسم المستخدم'] != 'NULL' ? r['اسم المستخدم'].toString() : '';
          if (staff.staffName == '')
            staff.staffName = r['الاسم الرباعي'] && r['الاسم الرباعي'] != null && r['الاسم الرباعي'] != 'NULL' ? r['الاسم الرباعي'].toString() : '';

          staff.staffCode = r['الرقم الوظيفي '] && r['الرقم الوظيفي '] != null && r['الرقم الوظيفي '] != 'NULL' ? r['الرقم الوظيفي '].toString() : '';
          staff.staffNationalId = r['الرقم الوطني'] && r['الرقم الوطني'] != null && r['الرقم الوطني'] != 'NULL' ? r['الرقم الوطني'].toString() : '';
          staff.mobile = r['رقم الهاتف'] && r['رقم الهاتف'] != null && r['رقم الهاتف'] != 'NULL' ? r['رقم الهاتف'].toString() : '';
          staff.staffTypeName = r['المسمى الوظيفي'] && r['المسمى الوظيفي'] != null && r['المسمى الوظيفي'] != 'NULL' ? r['المسمى الوظيفي'].toString() : '';
          if (staff.staffTypeName != '') {
            let ind = this.staffTypes.findIndex(t => t.description.trim() == staff.staffTypeName.trim());
            if (ind > -1)
              staff.staffTypeId = this.staffTypes[ind].staffTypeId;
          }
          // let universityNames: string[] = [];
          // if (r['الجامعة / الكلية'] && r['الجامعة / الكلية'] != null && r['الجامعة / الكلية'] != 'NULL') {
          //   universityNames = r['الجامعة / الكلية'].toString().split('@').map(n => n.trim());
          // }

          // if (universityNames.length > 0) {
          //   staff.locationsList = this.universityList.filter(u => universityNames.includes(u.locationName.trim())).map(u => u.locationId);
          // } else {
          // if (r['الجامعة'] && r['الجامعة'] != null && r['الجامعة'] != 'NULL') {
          //   universityNames = r['الجامعة'].toString().split('@').map(n => n.trim());
          // }

          // if (universityNames.length > 0) {
          //   staff.locationsList = this.universityList.filter(u => universityNames.includes(u.locationName.trim())).map(u => u.locationId);
          // }
          // }

          staff.inactive = false;
          staff.descriptions.push(...[
            {
              languageId: 1,
              description: staff.staffName
            },
            {
              languageId: 2,
              description: staff.staffName
            }
          ]);

          staff.username = staff.staffName;
          staff.password = '123456';

          if (this.staffList.some(d => d.staffCode == staff.staffCode)) {
            this.duplicateList.push(staff);
          } else {
            this.staffList.push(staff);
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Assets && CoreSession.selectedClient == Client.None.valueOf()) {
      let assetTypesList: AssetsTypeModel[] = [];
      let assetStatusList: AssetsStatusModel[] = [];
      let assetCategoryList: AssetCategoryModel[] = [];
      let assetSubCategoryList: AssetSubcategoryModel[] = [];
      let assetConditionList: AssetConditionModel[] = [];

      assetCategoryList = this.data.assetLists.assetCategoryList ?? [];
      assetSubCategoryList = this.data.assetLists.assetSubCategoryList ?? [];
      assetTypesList = this.data.assetLists.assetTypesList ?? [];
      assetStatusList = this.data.assetLists.assetStatusList ?? [];
      assetConditionList = this.data.assetLists.assetConditionList ?? [];

      let emptyRows = 0;
      var breakMe = false;

      this.assetList = [];
      this.excelData.slice().forEach(
        r => {
          if (breakMe == false) {
            const asset = new AssetsModel();

            asset.assetId = -1;
            asset.assetName = r['Asset Name'] && r['Asset Name'] != null && r['Asset Name'] != 'NULL' ? r['Asset Name'].toString() : '';
            asset.assetCode = r['Code'] && r['Code'] != null && r['Code'] != 'NULL' ? r['Code'].toString() : '';
            asset.purchaseCost = r['Purchase Cost'] && r['Purchase Cost'] != null && !isNaN(r['Purchase Cost']) ? +r['Purchase Cost'] : 0;
            asset.sellingCost = r['Selling Cost'] && r['Selling Cost'] != null && !isNaN(r['Selling Cost']) ? +r['Selling Cost'] : 0;
            asset.consumptionPeriod = r['Consumption Period'] && r['Consumption Period'] != null && !isNaN(r['Consumption Period']) ? +r['Consumption Period'] : 0;

            if (asset.assetName == '' && asset.assetCode == '' &&
              asset.purchaseCost == 0 && asset.sellingCost == 0 && asset.consumptionPeriod == 0) {
              emptyRows++;
              if (emptyRows >= 5) {
                breakMe = true;
              }
              return;

            }

            asset.assetTag = this.coreSession.generateRandomAlphnumericText(24);
            asset.assetType = r['Type'] && r['Type'] != null && r['Type'] != 'NULL' ? r['Type'].toString() : '';
            asset.assetStatusName = r['Status'] && r['Status'] != null && r['Status'] != 'NULL' ? r['Status'].toString() : '';
            asset.assetConditionName = r['Condition'] && r['Condition'] != null && r['Condition'] != 'NULL' ? r['Condition'].toString() : '';
            // asset.assetCategory = r['Category'] && r['Category'] != null && r['Category'] != 'NULL' ? r['Category'].toString() : '';
            // asset.assetSubCategory = r['Sub Category'] && r['Sub Category'] != null && r['Sub Category'] != 'NULL' ? r['Sub Category'].toString() : '';

            if (asset.assetType != '') {
              let ind = assetTypesList.findIndex(t => t.assetTypeName.trim() == asset.assetType.trim());
              if (ind > -1)
                asset.assetTypeId = assetTypesList[ind].assetTypeId;
            }

            if (asset.assetStatusName != '') {
              let ind = assetStatusList.findIndex(t => t.assetStatusName.trim() == asset.assetStatusName.trim());
              if (ind > -1)
                asset.assetStatusId = assetStatusList[ind].assetStatusId;
            }

            if (asset.assetConditionName != '') {
              let ind = assetConditionList.findIndex(t => t.assetConditionName.trim() == asset.assetConditionName.trim());
              if (ind > -1)
                asset.assetConditionId = assetConditionList[ind].assetConditionId;
            }

            // if (asset.assetCategory != '') {
            //   let ind = assetCategoryList.findIndex(t => t.assetCategoryName.trim() == asset.assetCategory.trim());
            //   if (ind > -1)
            //     asset.assetCategoryId = assetCategoryList[ind].assetCategoryId;
            // }

            // if (asset.assetSubCategory != '') {
            //   let ind = assetSubCategoryList.findIndex(t => t.assetSubcategoryName.trim() == asset.assetSubCategory.trim());
            //   if (ind > -1)
            //     asset.assetSubCategoryId = assetSubCategoryList[ind].assetSubcategoryId;
            // }

            asset.inactive = false;
            asset.descriptions.push(...[
              {
                languageId: 1,
                description: asset.assetName
              },
              {
                languageId: 2,
                description: asset.assetName
              }
            ]);

            if (this.assetList.some(d => d.assetCode == asset.assetCode)) {
              this.duplicateList.push(asset);
            } else {
              this.assetList.push(asset);
            }
          }
        }
      );
    } else if (this.mode == ImportDataTypes.Assets && CoreSession.selectedClient == Client.Assets_Eqbal.valueOf()) {
      let assetCategoryList: AssetCategoryModel[] = [];
      let assetSubCategoryList: AssetSubcategoryModel[] = [];
      let locationsList: LocationModel[] = [];

      assetCategoryList = this.data.assetLists.assetCategoryList ?? [];
      assetSubCategoryList = this.data.assetLists.assetSubCategoryList ?? [];
      locationsList = this.data.assetLists.locationsList ?? [];

      let emptyRows = 0;
      var breakMe = false;

      this.assetList = [];
      this.excelData.slice().forEach(
        r => {
          if (breakMe == false) {

            let qty = r['Qty'] && r['Qty'] != null && r['Qty'] != 'NULL' && !isNaN(+r['Qty']) ? +r['Qty'] : 0;
            for (let i = 0; i < qty; i++) {
              const asset = new AssetsModel();

              asset.assetId = -1;
              asset.assetName = r['Description'] && r['Description'] != null && r['Description'] != 'NULL' ? r['Description'].toString() : '';
              asset.assetLastLocationName = r['Location'] && r['Location'] != null && r['Location'] != 'NULL' ? r['Location'].toString() : '';
              asset.assetCategory = r['Item'] && r['Item'] != null && r['Item'] != 'NULL' ? r['Item'].toString() : '';
              asset.assetSubCategory = r['Sub Item'] && r['Sub Item'] != null && r['Sub Item'] != 'NULL' ? r['Sub Item'].toString() : '';
              asset.supplier = r['Supplier'] && r['Supplier'] != null && r['Supplier'] != 'NULL' ? r['Supplier'].toString() : '';
              asset.transactionId = r['Transaction#'] && r['Transaction#'] != null && r['Transaction#'] != 'NULL' ? r['Transaction#'].toString() : '';
              asset.qty = r['Qty'] && r['Qty'] != null && r['Qty'] != 'NULL' && !isNaN(+r['Qty']) ? +r['Qty'] : 0;

              if (asset.assetName == '' && asset.assetLastLocationName == '' &&
                asset.assetCategory == '' && asset.assetSubCategory == '') {
                emptyRows++;
                if (emptyRows >= 5) {
                  breakMe = true;
                }
                return;
              }

              asset.assetTag = this.coreSession.generateRandomAlphnumericText(24);

              if (asset.assetLastLocationName != '') {
                let ind = locationsList.findIndex(t => t.locationName.trim().toLowerCase() == asset.assetLastLocationName.trim().toLowerCase());
                if (ind > -1)
                  asset.lastLocationId = locationsList[ind].locationId;
              }

              if (asset.assetCategory != '') {
                let ind = assetCategoryList.findIndex(t => t.assetCategoryName.trim().toLowerCase() == asset.assetCategory.trim().toLowerCase());
                if (ind > -1)
                  asset.assetCategoryId = assetCategoryList[ind].assetCategoryId;
              }

              if (asset.assetSubCategory != '') {
                let ind = assetSubCategoryList.findIndex(t => t.assetSubcategoryName.trim().toLowerCase() == asset.assetSubCategory.trim().toLowerCase());
                if (ind > -1)
                  asset.assetSubCategoryId = assetSubCategoryList[ind].assetSubcategoryId;
              }

              asset.inactive = false;
              asset.descriptions.push(...[
                {
                  languageId: 1,
                  description: asset.assetName
                },
                {
                  languageId: 2,
                  description: asset.assetName
                }
              ]);

              this.assetList.push(asset);

            }

          }
        }
      );
    } else if (this.mode == ImportDataTypes.Assets && CoreSession.selectedClient == Client.Assets_LSS.valueOf()) {
      let locationsList: any[] = [];

      locationsList = this.data.assetLists.locationsList ?? [];

      let emptyRows = 0;
      var breakMe = false;

      let rowObj = this.excelData[0];
      const keys = Object.keys(rowObj);

      const idKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == '#');
      const assetNameKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'product description');
      const assetCodeKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'fixed asset code');
      const locationKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'part');
      const serialKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'seral no.');
      const priceKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'price');
      const qtyKey = keys.find(k => rowObj[k].toString().trim().toLowerCase() == 'qt');

      this.assetList = [];
      this.excelData.slice().forEach(
        (r, index) => {
          if (index == 0) {
            return;;
          }
          if (breakMe == false) {

            let qty = r[qtyKey] && r[qtyKey] != null && r[qtyKey] != 'NULL' && !isNaN(+r[qtyKey]) ? +r[qtyKey] : 0;
            for (let i = 0; i < qty; i++) {
              const asset = new AssetsModel();

              asset.assetId = -1;
              asset.assetName = r[assetNameKey] && r[assetNameKey] != null && r[assetNameKey] != 'NULL' ? r[assetNameKey].toString() : '';
              asset.assetCode = r[assetCodeKey] && r[assetCodeKey] != null && r[assetCodeKey] != 'NULL' ? r[assetCodeKey].toString() : '';
              asset.assetLastLocationName = r[locationKey] && r[locationKey] != null && r[locationKey] != 'NULL' ? r[locationKey].toString() : '';
              asset.assetTag = r[serialKey] && r[serialKey] != null && r[serialKey] != 'NULL' ? r[serialKey].toString() : '';
              asset.sellingCost = r[priceKey] && r[priceKey] != null && r[priceKey] != 'NULL' && !isNaN(+r[priceKey]) ? +r[priceKey] : 0;
              asset.purchaseCost = asset.sellingCost;
              asset.qty = r[qtyKey] && r[qtyKey] != null && r[qtyKey] != 'NULL' && !isNaN(+r[qtyKey]) ? +r[qtyKey] : 0;

              if (asset.assetId == -1 && asset.assetName == '' && asset.assetLastLocationName == '') {
                emptyRows++;
                if (emptyRows >= 5) {
                  breakMe = true;
                }
                return;
              }

              if (asset.assetLastLocationName != '') {
                let ind = locationsList.findIndex(t => t.locationName.trim().toLowerCase().includes(asset.assetLastLocationName.trim().toLowerCase()) ||
                  t.locationName2.trim().toLowerCase().includes(asset.assetLastLocationName.trim().toLowerCase()) ||
                  asset.assetLastLocationName.trim().toLowerCase().includes(t.locationName.trim().toLowerCase()) ||
                  asset.assetLastLocationName.trim().toLowerCase().includes(t.locationName2.trim().toLowerCase()));
                if (ind > -1)
                  asset.lastLocationId = locationsList[ind].locationId;
              }

              asset.inactive = false;
              asset.descriptions.push(...[
                {
                  languageId: 1,
                  description: asset.assetName
                },
                {
                  languageId: 2,
                  description: asset.assetName
                }
              ]);

              this.assetList.push(asset);

            }

          }
        }
      );
    } else if (this.mode == ImportDataTypes.GenerateBarcodesAsPDF) {  
      this.itemsList = [];
      this.excelData.slice().forEach(
        r => {
          const item = new ItemModel();

          item.itemName = r['Description'] && r['Description'] != null && r['Description'] != 'NULL' ? r['Description'].toString() : '';
          item.barcode = r['Barcode'] && r['Barcode'] != null ? r['Barcode'].toString() : '';

          this.itemsList.push(item);
        }
      );
    } 
  }

  onToggleImportBarcodes() {
    if (this.importBarcodesControl.value) {
      this.mode = ImportDataTypes.ItemBarcodes;
    } else {
      this.mode = ImportDataTypes.Items;
    }
  }

  onToggleImportItemUOM() {
    if (this.importItemUOMControl.value) {
      this.mode = ImportDataTypes.UOM;
    } else {
      this.mode = ImportDataTypes.Items;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get locationTypeControl() {
    return this.form.get('locationType');
  }

  get importBarcodesControl() {
    return this.form.get('importBarcodes');
  }

  get importItemUOMControl() {
    return this.form.get('importItemUOM');
  }

}
