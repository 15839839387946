import {
  Directive, HostBinding, HostListener, ElementRef,
  Renderer2,
  Input
} from '@angular/core';
import { CoreSession } from 'src/app/core/core.session';
import { Solution } from '../enum/solution.enum';
import { Background, Color, FontTypes, Solutions } from '../enum/themes-mode';

@Directive({
  selector: '[appRootNavTheme]'
})
export class RootNavThemeDirective {

  @Input() isActive: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private coreSession: CoreSession) {
  }
  groundValue = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf()
    ? Color.Beige.toString() : this.coreSession.selectedSolutionId === Solution.IEC.valueOf()
      ? '#cc0000' : Solutions.main.toString();

  // @HostBinding('style.color') color: string = this.groundValue;

  @HostListener('mouseover') mouseover() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', this.groundValue);
  }

  @HostListener('mouseout') mouseout() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', 'transparent');
  }

}
