import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CoreSession } from 'src/app/core/core.session';
import { TranslateService } from '@ngx-translate/core';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { HttpErrorResponse } from '@angular/common/http';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { MatDialog } from '@angular/material/dialog';
import { RouteStationsService } from '../route-stations.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { RouteStationsAssignmentModel } from 'src/app/shared/model/route-stations-model/route-stations-assignment-model';
import { VehicleModel } from 'src/app/shared/model/vehicle-models/vehicle-model';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { Observable } from 'rxjs';
import { DateTimeModel } from 'src/app/shared/model/dateTimeModel/date-time.model';

@Component({
  selector: 'app-route-stations-entry',
  templateUrl: './route-stations-entry.component.html',
  styleUrls: ['./route-stations-entry.component.css']
})
export class RouteStationsEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedObj: RouteStationsAssignmentModel;
  @Input() isViewMode: boolean = false;
  @Input() isOptimizeMode: boolean = false;
  @Input() isEditMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  staffDataSource: StaffModel[];
  vehicleDataSource: VehicleModel[];

  selectedStationsForAssinging: ClientModel[] = [];
  selectedStationsForUnassinging: ClientModel[] = [];

  isStaffLoading = false;
  selectedStaffId: number;

  isVehicleLoading = false;
  selectedVehicleId: number;

  assignStationsObj: RouteStationsAssignmentModel;
  unassignStationsObj: RouteStationsAssignmentModel;

  form: FormGroup;

  assignedStationsFilter = {
    selectedStaffId: -1,
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
  };

  unassignedStationsFilter = {
    selectedStaffId: -1,
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
  };

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_TripDate',
    formControlName: 'tripDate',
    isCalendarOnly: true
  };


  assignedStationsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  unassignedStationsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  assignedStationsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    isMultiSelection: true,
    specificActionWhenCheckRow: true,
    specificActionWhenCheckAll: true,
    newBtnCaption: 'Assign',
    rowOperations: [
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Location Name",
        key: "clientName",
        isSortable: true,
        width: "35%"
      },
      {
        title: "Address",
        key: "address",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Type",
        key: "clientTypeName",
        isSortable: true,
        width: "35%"
      }
    ]
  };

  unassignedStationsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: true,
    isMultiSelection: true,
    specificActionWhenCheckRow: true,
    specificActionWhenCheckAll: true,
    newBtnCaption: 'Unassign',
    rowOperations: [
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Location Name",
        key: "clientName",
        isSortable: true,
        width: "35%"
      },
      {
        title: "Address",
        key: "address",
        isSortable: true,
        width: "30%"
      },
      {
        title: "Type",
        key: "clientTypeName",
        isSortable: true,
        width: "35%"
      }
    ]
  };

  saveBtnSubscription: any;

  teamDropdownSettings = {
    singleSelection: false,
    idField: 'staffId',
    textField: 'staffName',
    selectAllText: '',
    unSelectAllText: '',
    searchPlaceholderText: '',
    allowSearchFilter: true,
    itemsShowLimit: 2,
  };

  teamMembersList: StaffModel[] = [];

  constructor(private coreSession: CoreSession, private modalService: NgbModal,
    private routeStationsService: RouteStationsService,
    private translateService: TranslateService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.teamDropdownSettings.selectAllText = this.translateService.instant(ConstantMessages.LblSelectAllCaption);
    this.teamDropdownSettings.unSelectAllText = this.translateService.instant(ConstantMessages.LblUnSelectAllCaption);
    this.teamDropdownSettings.searchPlaceholderText = this.translateService.instant(ConstantMessages.LblSearchCaption);

    this.initForm();
    this.subscribeSaveClick();
    this.populateRouteStationsAssignmentData(-1);
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    const staffId = this.isEditMode || this.isViewMode ? this.selectedObj.selectedStaffId : undefined;
    const vehicleId = this.isEditMode || this.isViewMode ? this.selectedObj.vehicleId : undefined;
    const tripDate = this.isEditMode || this.isViewMode ? this.selectedObj.tripDate : undefined;

    this.form = new FormGroup({
      staffId: new FormControl({ value: staffId, disabled: this.isViewMode }),
      vehicleId: new FormControl({ value: vehicleId, disabled: this.isViewMode }),
      tripDate: new FormControl({ value: tripDate, disabled: this.isViewMode }),
      team: new FormControl({ value: [], disabled: this.isViewMode }),
    });
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  onAssignClientRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:

        break;
      case RowOperation.View:

        break;
      case RowOperation.edit:

        break;

    }
  }

  onUnassignClientRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:

        break;
      case RowOperation.View:

        break;
      case RowOperation.edit:

        break;

    }
  }

  // onUnassignTableFilterChanged(query: any) {
  //   this.unassignedStationsFilter.customListFilter = query;
  //   //this.populateUnassignedStations();
  // }

  onAssignTableFilterChanged(query: any) {
    this.assignedStationsFilter.customListFilter = query;
    this.populateAssignedStations();
  }

  populateRouteStationsAssignmentData(selectedStaffId: number) {
    this.isStaffLoading = true;
    this.coreSession.ModalLoading.Show();
    this.routeStationsService.getRouteStationsAssignmentData(selectedStaffId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      // //console.log(response);

      if (response.status != null && response.status >= 0) {
        // const allStations = <SharedTableResult>response.data.assignedStations;
        // allStations.data.push(...response.data.unassignedStations.data.slice());
        // allStations.totalItems = allStations.data.length;

        this.assignedStationsDataSource = <SharedTableResult>response.data.assignedStations;
        this.unassignedStationsDataSource = <SharedTableResult>response.data.unassignedStations;

        console.log(this.unassignedStationsDataSource);

        if (selectedStaffId == -1) {
          this.staffDataSource = response.data.staffList;
          this.vehicleDataSource = response.data.vehiclesList;
        }

        if (this.isEditMode || this.isViewMode || this.isOptimizeMode) {
          this.onStaffDataChanged(null, this.staffControl.value);
          this.getRouteData();
        }

        if(this.isOptimizeMode){
          console.log(this.unassignedStationsDataSource.data.filter((e) => e.isChecked), "ttttt");

          this.unassignedStationsDataSource = {
            totalItems: this.unassignedStationsDataSource.data.filter((e) => e.isChecked).length,
            data: this.unassignedStationsDataSource.data.filter((e) => e.isChecked)
          }
        }

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      this.isStaffLoading = false;
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        this.isStaffLoading = false;
      }
    });
  }

  getRouteData() {
    this.coreSession.ModalLoading.Show();
    this.routeStationsService.getRouteData(this.selectedObj.id).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.selectedStationsForUnassinging = response.data.selectedStations;
        this.unassignedStationsDataSource.data.map(d => {
          return {
            ...d,
            isChecked: d.isChecked = this.selectedStationsForUnassinging.findIndex(x => x.clientId == d.clientId && x.isLocation == d.isLocation) > -1
          }
        });

        this.teamControl.setValue(this.teamMembersList.slice().filter(m => response.data.teamMembers.includes(m.staffId)));
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }

  populateAssignedStations() {
    this.coreSession.ModalLoading.Show();
    this.assignedStationsFilter.selectedStaffId = this.form.get("staffId").value;
    this.routeStationsService.getAssignedStations(this.assignedStationsFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.assignedStationsDataSource = <SharedTableResult>response.data;
        // //console.log(response);
        //debugger
        if (this.selectedStationsForAssinging && this.selectedStationsForAssinging.length > 0) {
          this.selectedStationsForAssinging.forEach((x) => {
            let index = this.assignedStationsDataSource.data.findIndex(z => z.clientId == x.clientId);
            if (index >= 0) {
              this.assignedStationsDataSource.data[index].isChecked = true;
            }
          });
        }

        if (response.data.length <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }

  populateUnassignedStations() {
    this.coreSession.ModalLoading.Show();
    this.unassignedStationsFilter.selectedStaffId = this.form.get("staffId").value;
    this.routeStationsService.getUnassignedStations(this.unassignedStationsFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.unassignedStationsDataSource = <SharedTableResult>response.data;

        if(this.isOptimizeMode){
          console.log(this.unassignedStationsDataSource.data.filter((e) => e.isChecked));

          this.unassignedStationsDataSource = {
            totalItems: this.unassignedStationsDataSource.data.filter((e) => e.isChecked).length,
            data: this.unassignedStationsDataSource.data.filter((e) => e.isChecked)
          }
        }

        // //console.log(response);
        if (this.selectedStationsForUnassinging && this.selectedStationsForUnassinging.length > 0) {
          this.selectedStationsForUnassinging.forEach((x) => {
            let index = this.unassignedStationsDataSource.data.findIndex(z => z.clientId == x.clientId);
            if (index >= 0) {
              this.unassignedStationsDataSource.data[index].isChecked = true;
            }
          });
        }
        if (response.data.length <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }

  onStaffDataChanged(selectedStaff?: StaffModel, staffId?: number) {
    //debugger;
    this.teamControl.setValue([]);

    if (selectedStaff != null || staffId != null) {
      this.selectedStaffId = selectedStaff != null ? selectedStaff.staffId : staffId;
      this.teamMembersList = this.staffDataSource.slice().filter(staff => staff.staffId != this.selectedStaffId);
    } else {
      this.selectedStaffId = -1;
      this.teamMembersList = [];
    }

    // this.populateRouteStationsAssignmentData(this.selectedStaffId);
  }

  onVehicleDataChanged(selectedVehicle: VehicleModel) {
    this.selectedVehicleId = selectedVehicle.vehicleId;
  }

  unassignButtonClicked(event: any) {
    this.unassignStationsObj = new RouteStationsAssignmentModel();
    this.unassignStationsObj.selectedStations = this.selectedStationsForAssinging;
    this.unassignStationsObj.selectedStaffId = this.selectedStaffId;
    this.unassignStationsObj.vehicleId = this.vehicleControl.value ?? -1;
    this.unassignStationsObj.teamMembers = this.teamControl.value != null && this.teamControl.value.length > 0 ?
      this.teamControl.value.map(m => m.staffId) : [];

    if (this.tripDateControl.value != null) {
      const date = new Date(this.tripDateControl.value.toString());
      const dateModel = new DateTimeModel();
      dateModel.year = date.getFullYear();
      dateModel.month = date.getMonth() + 1;
      dateModel.day = date.getDate();
      dateModel.date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      this.unassignStationsObj.tripDateModel = dateModel;
    }

    this.coreSession.ModalLoading.Show();
    this.routeStationsService.unassignStations(this.unassignStationsObj).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.selectedStationsForAssinging = [];
        this.populateRouteStationsAssignmentData(this.selectedStaffId);
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onSaveClicked() {
    debugger
    this.assignStationsObj = new RouteStationsAssignmentModel();
    this.assignStationsObj.id = this.isEditMode ? this.selectedObj.id : -1;
    this.assignStationsObj.selectedStations = this.selectedStationsForUnassinging;
    this.assignStationsObj.selectedStaffId = this.selectedStaffId;

    this.assignStationsObj.vehicleId = this.vehicleControl.value ?? -1;
    this.assignStationsObj.teamMembers = this.teamControl.value != null && this.teamControl.value.length > 0 ?
      this.teamControl.value.map(m => m.staffId) : [];

    if (this.tripDateControl.value != null) {
      const date = new Date(this.tripDateControl.value.toString());
      const dateModel = new DateTimeModel();
      dateModel.year = date.getFullYear();
      dateModel.month = date.getMonth() + 1;
      dateModel.day = date.getDate();
      dateModel.date = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      this.assignStationsObj.tripDateModel = dateModel;
    }

    this.coreSession.ModalLoading.Show();
    this.routeStationsService.assignStations(this.assignStationsObj, this.isEditMode).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        debugger;
        this.selectedStationsForUnassinging = [];
        this.onSaveCompleted.emit();
        //this.populateRouteStationsAssignmentData(this.selectedStaffId);
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onAssignedCheckRowOperation(object: any) {
    // //console.log(object, "CHECKING");

    if (object.isChecked) {
      this.selectedStationsForAssinging.push({ ...object });
    } else {
      let index = this.selectedStationsForAssinging.findIndex(a => a.clientId == object.clientId);
      this.selectedStationsForAssinging.splice(index, 1);
    }
  }

  onAssignedCheckAllOperation(checkAll: boolean) {
    if (checkAll) {
      this.selectedStationsForAssinging = this.assignedStationsDataSource.data.slice();
    } else {
      this.selectedStationsForAssinging = [];
    }
  }

  onUnassignedCheckRowOperation(object: any) {
    // //console.log(object, "CHECKING");
    if (object.isChecked) {
      this.selectedStationsForUnassinging.push({ ...object });
    } else {
      let index = this.selectedStationsForUnassinging.findIndex(a => a.clientId == object.clientId);
      this.selectedStationsForUnassinging.splice(index, 1);
    }
  }

  onUnassignedCheckAllOperation(checkAll: boolean) {
    if (checkAll) {
      this.selectedStationsForUnassinging = this.unassignedStationsDataSource.data.slice();
    } else {
      this.selectedStationsForUnassinging = [];
    }
  }

  onUnassignTableFilterChanged(event){

  }

  get staffControl() {
    return this.form.get('staffId');
  }

  get vehicleControl() {
    return this.form.get('vehicleId');
  }

  get tripDateControl() {
    return this.form.get('tripDate');
  }

  get teamControl() {
    return this.form.get('team');
  }
}
