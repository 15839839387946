import { CoreSession } from '../core.session';
import { LoginService } from '../../content/login/login.service';
import { ConstantStorage } from '../constants/constant-stroage';
import { CachedGeneralData, LicenseData, OperatorModel } from 'src/app/shared/model/operator/operator.model';
import { ConstantMessages } from '../constants/constant-message';
import { ConstantURLs } from '../constants/constant-URL';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionDataProvider } from '../session-data-provider.service';
import { MenuModel } from 'src/app/shared/model/menu/menu.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isMenuAvailable = false;
  observableRef: any;
  constructor(private router: Router,
    private coreSession: CoreSession,
    private loginService: LoginService,
    private translateService: TranslateService,
    // private staffService: StaffService,
    private sessionDataProvider: SessionDataProvider) {
  }
  checkMenuAvailability() {
    this.isMenuAvailable = true;
  }

  login(operator: OperatorModel, key: string, generalData: CachedGeneralData, menuList: MenuModel[], addedList: MenuModel[], keepMeLoggedIn: boolean,
    licenseData: LicenseData) {
    this.coreSession.currentUser = operator;
    this.coreSession.cachedGeneralData = generalData;
    this.sessionDataProvider.addedMenus = addedList;
    this.sessionDataProvider.allMenusAvailable = menuList;
    this.coreSession.currentUser.isLoggedIn = true;
    // this.loadStaff(operator.employeeId)
    // this.sessionDataProvider.addedMenus = addedList;
    // CASH DATA IN STORAGE
    sessionStorage.setItem(ConstantStorage.TokenName, key);
    sessionStorage.setItem(ConstantStorage.CurrentUser, JSON.stringify(this.coreSession.currentUser));
    sessionStorage.setItem(ConstantStorage.generalData, JSON.stringify(this.coreSession.cachedGeneralData));
    sessionStorage.setItem(ConstantStorage.KeepMeLoggedIn, keepMeLoggedIn.toString());
    sessionStorage.setItem(ConstantStorage.LicenseData, JSON.stringify(licenseData));

    if (keepMeLoggedIn) {
      localStorage.setItem(ConstantStorage.TokenName, key);
      localStorage.setItem(ConstantStorage.CurrentUser, JSON.stringify(this.coreSession.currentUser));
      localStorage.setItem(ConstantStorage.generalData, JSON.stringify(this.coreSession.cachedGeneralData));
      localStorage.setItem(ConstantStorage.KeepMeLoggedIn, keepMeLoggedIn.toString());
      localStorage.setItem(ConstantStorage.LicenseData, JSON.stringify(licenseData));
    }

    const jwtHelper = new JwtHelperService();
    let expirationDate: Date = jwtHelper.getTokenExpirationDate(key);
    let periodInMiliseconds = expirationDate.getTime() - new Date().getTime();

    this.observableRef = Observable.interval(periodInMiliseconds)
      .subscribe(() => {
        this.router.navigate(["/" + ConstantURLs.loginURL]);
      });

    this.navigateToHomeScreen();
  }

  ngOnDestroy(): void {
    if (this.observableRef != null)
      this.observableRef.unsubscribe();
  }

  navigateToHomeScreen() {
    // //console.log("nav");
    if (this.sessionDataProvider.addedMenus) {
      // //console.log(this.sessionDataProvider.addedMenus[0].url);

      this.router.navigate(['/', this.sessionDataProvider.addedMenus[0].url]);
      this.coreSession.setPageTitle(this.sessionDataProvider.addedMenus[0].menuName);
    } else {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUserHasNoAccess));
    }
  }

  logout() {
    this.coreSession.ModalLoading.Show();
    var currentUser;
    if (sessionStorage.length > 0) {
      currentUser = JSON.parse(sessionStorage.getItem(ConstantStorage.CurrentUser));
    } else {
      currentUser = JSON.parse(localStorage.getItem(ConstantStorage.CurrentUser));
    }
    this.loginService.logout(currentUser.staffId).subscribe(result => {
      if (result.status >= 0) {
        const keepMeLoggedIn = localStorage.getItem(ConstantStorage.KeepMeLoggedIn);

        if (localStorage.length > 0 && keepMeLoggedIn) {
          localStorage.removeItem(ConstantStorage.TokenName);
          localStorage.removeItem(ConstantStorage.CurrentUser);
          localStorage.removeItem(ConstantStorage.generalData);
          localStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
          localStorage.removeItem(ConstantStorage.LicenseData);
        }
        if (sessionStorage.length > 0) {
          sessionStorage.removeItem(ConstantStorage.TokenName);
          sessionStorage.removeItem(ConstantStorage.CurrentUser);
          sessionStorage.removeItem(ConstantStorage.generalData);
          sessionStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
          sessionStorage.removeItem(ConstantStorage.LicenseData);
        }
        // this.coreSession.SetTitle("Login");
        this.coreSession.ModalLoading.Hide();
        this.router.navigate(['/' + ConstantURLs.loginURL]);
      } else {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(result.message));
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    });
  }

  // public isAuthenticated(): boolean {
  //   const jwtHelper = new JwtHelperService();
  //   const token = localStorage.getItem(ConstantStorage.TokenName);
  //   return !jwtHelper.isTokenExpired(token);
  // }

  public isTokenExist(): boolean {
    const token = sessionStorage.length > 0 ? sessionStorage.getItem(ConstantStorage.TokenName) : localStorage.getItem(ConstantStorage.TokenName);
    return !(token == undefined);
  }

  private loadStaff(staffid: any) {
    // this.staffService.getStaff(staffid)
    //   .subscribe(response => {
    //     this.coreSession.userImage.next(response.staffImage);
    //     this.coreSession.email.next(response.email);
    //   });
  }
}
