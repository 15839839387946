import { DateTimeModel } from "../dateTimeModel/date-time.model";

export class OperatorModel {
    public encryptedOperatorId: string;
    public encryptedEmployeeId: string;
    public employeeId: number;
    public operatorId: number;
    public employeeName: string;
    public username: string;
    public employeeCode: string;
    public isLoggedIn: boolean;
    public email: string;
    public employeeTypeId: number;
    public employeeImage: string;
    public password: string;
    public newPassword: string;
    public staffId: number;
    public staffName: string;
    public locationId: number;
    //public imageFile:File;
    constructor() {
        this.isLoggedIn = false;
    }
}
export class OperatorForLogin {
    public username: string = '';
    public password: string = '';
    public languageId: number;
    public deviceInfo: string = '';
    public RemoveCurrentUserSession = false;
    public gpsLng: number;
    public gpsLat: number;
    public clientId: number;
    public isTestMode: boolean;

}
export class CachedGeneralData {

    public assemblyVersionNumber: string = '';
    public databaseVersion: string = '';
}
export class LicenseData {
    public clientId: number;
    public numberOfUsers: number;
    public numberOfItems: number;
    public numberOfAssets: number;
    public validToDateModel: DateTimeModel;

    constructor() {
        this.clientId = -1;
        this.numberOfUsers = 0;
        this.numberOfItems = 0;
        this.numberOfAssets = 0;
    }
}

