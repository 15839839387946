import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { CoreSession } from 'src/app/core/core.session';
import { ImportDataTypes } from '../../enum/import/ImportDataTypes';
import { SharedTableResult } from '../../model/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../model/shared-table/table-properties.interface';
import { Direction } from '@angular/cdk/bidi';

@Component({
  selector: 'app-import-result-dialog',
  templateUrl: './import-result-dialog.component.html',
  styleUrls: ['./import-result-dialog.component.css']
})
export class ImportResultDialogComponent implements OnInit {

  mode: ImportDataTypes;

  insertedDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  insertedTableProperties: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    showExtraBtn: false,
    extraBtnCaption: '',
    extraBtnIcon: '',
    showImport: false,
    importBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  duplicateDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  duplicateTableProperties: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    showExtraBtn: false,
    extraBtnCaption: '',
    extraBtnIcon: '',
    showImport: false,
    importBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  failedDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  invalidDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  failedTableProperties: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    showExtraBtn: false,
    extraBtnCaption: '',
    extraBtnIcon: '',
    showImport: false,
    importBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  invalidTableProperties: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    showExtraBtn: false,
    extraBtnCaption: '',
    extraBtnIcon: '',
    showImport: false,
    importBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  showErrorList: boolean = false;
  showinvalidList: boolean = false;
  direction: Direction = 'ltr';

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private coreSession: CoreSession,
  ) { }


  ngOnInit(): void {
    this.direction = this.translateService.currentLang.toLocaleLowerCase() == 'en' ? 'ltr' : 'rtl';
    this.mode = this.data.importMode;
    this.showErrorList = this.coreSession.showImportErrorList || this.mode == ImportDataTypes.ItemBarcodes.valueOf();
    if (this.mode == ImportDataTypes.Items.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_ItemName",
          key: "itemName",
          isSortable: true,
          width: "33%"
        },
        {
          title: "Desc_ItemCode",
          key: "itemCode",
          isSortable: true,
          width: "33%"
        },
        {
          title: "Desc_Barcode",
          key: "barcode",
          isSortable: true,
          width: "34%"
        }
      ];
    }
    if (this.mode == ImportDataTypes.Locations.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_LocationName",
          key: "locationName",
          isSortable: true,
          width: "50%"
        },
        {
          title: "Desc_LocationCode",
          key: "locationCode",
          isSortable: true,
          width: "50%"
        },
      ];
    }
    if (this.mode == ImportDataTypes.Suppliers.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_Supplier",
          key: "supplierName",
          isSortable: true,
          width: "50%"
        },
        {
          title: "Desc_SupplierCode",
          key: "supplierCode",
          isSortable: true,
          width: "50%"
        },
      ];
    }

    if (this.mode == ImportDataTypes.Employees.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_Staff",
          key: "staffName",
          isSortable: true,
          width: "50%"
        },
        {
          title: "Desc_StaffCode",
          key: "staffCode",
          isSortable: true,
          width: "50%"
        },
      ];
    }

    if (this.mode == ImportDataTypes.ItemBarcodes.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_ItemId",
          key: "itemId",
          isSortable: true,
          width: "33.33%"
        },
        {
          title: "Desc_Barcode",
          key: "barcode",
          isSortable: true,
          width: "33.33%"
        },
        {
          title: "Desc_UOM",
          key: "itemUOM",
          isSortable: true,
          width: "33.33%"
        },
      ];
    }

    if (this.mode == ImportDataTypes.Students.valueOf()) {
      this.showinvalidList= true;
      this.insertedTableProperties.columns = [
        {
          title: "Desc_Name",
          key: this.translateService.currentLang.toLocaleLowerCase() == 'en' ? "fullNameEn" : "fullNameAr",
          isSortable: true,
          width: "40%"
        },
        {
          title: "Desc_NationalId",
          key: "nationalId",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Email",
          key: "emailAddress",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_MobileNumber",
          key: "mobileNumber",
          isSortable: true,
          width: "20%"
        },

      ];

      this.duplicateTableProperties.columns = [
        {
          title: "Desc_Name",
          key: this.translateService.currentLang.toLocaleLowerCase() == 'en' ? "fullNameEn" : "fullNameAr",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_NationalId",
          key: "nationalId",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Email",
          key: "emailAddress",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_MobileNumber",
          key: "mobileNumber",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Reason",
          key: "duplicateReason",
          isSortable: true,
          width: "20%"
        }

      ];

      this.invalidTableProperties.columns = [
        {
          title: "Desc_Name",
          key: this.translateService.currentLang.toLocaleLowerCase() == 'en' ? "fullNameEn" : "fullNameAr",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_NationalId",
          key: "nationalId",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Email",
          key: "emailAddress",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_MobileNumber",
          key: "mobileNumber",
          isSortable: true,
          width: "20%"
        },
        {
          title: "Desc_Reason",
          key: "invalidReason",
          isSortable: true,
          width: "20%"
        }

      ];
    }

    if (this.mode == ImportDataTypes.Assets.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_AssetName",
          key: "assetName",
          isSortable: true,
          width: "33.3%"
        },
        {
          title: "Desc_AssetCode",
          key: "assetCode",
          isSortable: true,
          width: "33.3%"
        },
        {
          title: "Desc_AssetTag",
          key: "assetTag",
          isSortable: true,
          width: "33.3%"
        },
      ];
    }

    if (this.mode == ImportDataTypes.UOM.valueOf()) {
      this.insertedTableProperties.columns = [
        {
          title: "Desc_Barcode",
          key: "barcode",
          isSortable: true,
          width: "33.4%"
        },
        {
          title: "Desc_Warehouse",
          key: "warehouseName",
          isSortable: true,
          width: "33.3%"
        },
        {
          title: "Desc_Quantity",
          key: "uomConversion",
          isSortable: true,
          width: "33.3%"
        },
      ];
    }

    if (this.mode != ImportDataTypes.Students.valueOf()) {
      this.duplicateTableProperties.columns = this.insertedTableProperties.columns;
      this.invalidTableProperties.columns = this.insertedTableProperties.columns;
    }
    this.failedTableProperties.columns = this.insertedTableProperties.columns;

    this.insertedDataSource.data = this.data.insertedList.slice();
    this.insertedDataSource.totalItems = this.data.insertedList.length;

    this.duplicateDataSource.data = this.data.duplicateList.slice();
    this.duplicateDataSource.totalItems = this.data.duplicateList.length;

    this.failedDataSource.data = this.data.failedToInsertList.slice();
    this.failedDataSource.totalItems = this.data.failedToInsertList.length;

    if (this.data.invalidList != null && this.showinvalidList) {
      this.invalidDataSource.data = this.data.invalidList.slice();
      this.invalidDataSource.totalItems = this.data.invalidList.length;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}