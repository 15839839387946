import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { RootHeaderComponent } from '../../root-header/root-header.component';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { SettingsService } from '../settings.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-warehouse-selection',
  templateUrl: './warehouse-selection.component.html',
  styleUrls: ['./warehouse-selection.component.css']
})
export class WarehouseSelectionComponent implements OnInit {

  form: FormGroup;
  warehousesList: LocationModel[] = [];
  isWarehousesLoading: boolean = false;
  importData: any;
  selectedWarehouseName: string = '';

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private coreSession: CoreSession,
    private settingsService: SettingsService) { }

  ngOnInit(): void {
    this.initForm();
    this.getWarehouses();
  }

  initForm() {
    this.form = new FormGroup({
      warehouse: new FormControl(undefined, Validators.required)
    });
  }

  getWarehouses() {
    this.isWarehousesLoading = true;
    this.settingsService.getWarehouses().subscribe(res => {

      this.isWarehousesLoading = false;

      if (res.status >= 0) {
        this.warehousesList = res.data.warehousesList.slice();
        this.importData = res.data;
        this.selectedWarehouseName = res.data.warehouseId > -1
          ? this.warehousesList.find(wh => wh.locationId == res.data.warehouseId).locationName : '---';
      }
      else
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), res.message);

    },
      (_error: HttpErrorResponse) => {
        this.isWarehousesLoading = false;
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onSaveClicked() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.dialogRef.close(this.warehouseControl.value);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  get warehouseControl() {
    return this.form.get('warehouse');
  }

}
