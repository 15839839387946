import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { InvoiceModel } from 'src/app/shared/model/invoice-model/invoice-model';
import { InsertRouteModel } from 'src/app/shared/model/route-model/route-model';
import { CustomListFilter } from 'src/app/shared/model/shared-table/custom-list.interface';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { RouteService } from '../route.service';
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { InvoiceService } from '../../invoice/invoice.service';
import { RouteAsseginedModel } from 'src/app/shared/model/route-model/routeAssinged-model';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { Solutions } from 'src/app/shared/enum/themes-mode';



@Component({
  selector: 'app-route-entry',
  templateUrl: './route-entry.component.html',
  styleUrls: ['./route-entry.component.css']
})
export class RouteEntryComponent implements OnInit {
  @ViewChild('content', { static: true }) routeEntryDialog: any;
  @Input('saveString') saveString: string = 'Save';
  @Input('closeString') closeString: string = 'Close';
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() events: Observable<void>;
  @Input() selectedInvoiceData: RouteAsseginedModel;
  @Input() isEditMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();
  saveSubject: Subject<void> = new Subject<void>();
  selectedInvoices: any[] = [];
  // staffInvoices: any[] = [];
  staffDataSource: any[];
  saveBtnSubscription: any;
  isStaffsLoading = false;
  staffForm: FormGroup;
  otherLanguages: any[] = [];
  selectedStaffId: number;
  selectedStaffDescription: string;
  insertRoutes: InsertRouteModel;
  invoiceIds: string[] = [];
  assignedInvoices: any[] = [];
  invoicesList: any[] = [];
  public isShowunassigned = true;
  public color: any = Solutions.main;
  routeFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    employeeCodeName: '',
    employeeTypeId: -1,
    organizationId: -1,
    vehicleId: -1,
    divisionId: -1,
    deviceSerial: '',
    securityGroupId: -1,
    includeInactive: false,
    employeeId: -1
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  staffInvoices: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  columns = [
    {
      title: "Desc_InvoiceId",
      key: "invoiceId",
      isSortable: false,
      width: "20%"

    },
    {
      title: "Desc_RemainingAmount",
      key: "remainingAmount",
      isSortable: false,
      width: "20%"
    },
    {
      title: "Desc_InvoiceDate",
      key: "invoiceDate",
      isSortable: false,
      width: "20%"
    }
  ]
  tableData: ITableProperties = {
    pageSize: 25,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    isMultiSelection: true,
    specificActionWhenCheckRow: true,
    specificActionWhenCheckAll: true,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: this.columns
  };

  tableDataSelected: ITableProperties = {
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    rowOperations: [{
      operation: RowOperation.delete,
      title: "Desc_Delete",
      icon: "fa fa-trash",
      color: "red"
    }],
    columns: [
      {
        title: "Desc_InvoiceId",
        key: "invoiceId",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_RemainingAmount",
        key: "remainingAmount",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_InvoiceDate",
        key: "invoiceDate",
        isSortable: false,
        width: "20%"
      }
    ],
    pageSize: 0,
    multiSelectionOperations: []
  };
  sharedTableFilter: CustomListFilter = {
    searchFilter: '',
    page: 0,
    pageSize: 100
  };
  public AssignedInvoicesStaff: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private routeService: RouteService,
    private modalService: NgbModal,
    private invoiceServices: InvoiceService) {
    this.staffForm = formBuilder.group({
      staffId: formBuilder.control(this.selectedStaffId, [Validators.required]),
      staffDescription: formBuilder.control(this.selectedStaffDescription, [Validators.required])
    });
  }

  ngOnInit() {
    this.getAllinvoiceIds();
    this.subscribeSaveClick();
    this.selectedInvoices = [];
    this.populateStaffNameList(this.selectedInvoiceData);
  }

  private getAllinvoiceIds() {
    this.routeService.GetAllAssignedinvoices().subscribe(response => {
      if(response != null && response != undefined) {
        this.invoiceIds = response.data.map(e => e.invoiceId);
        this.assignedInvoices = response.data;
      }
      this.populateDialogInvoicesList();
    });
  }

  setDropDownValue() {
    this.staffForm.setValue({ selectedStaffDescription: '' });
  }


  onDialogTableFilterChanged(query: any) {
    this.sharedTableFilter = query;
  }

  onRowClicked(event) {
    if(this.selectedInvoices.indexOf(event) === -1) {
      this.selectedInvoices.push(event);
      this.setAssingedDataSourse();
    } else {
      let index = this.selectedInvoices.indexOf(event);
      this.selectedInvoices.splice(index,1);
      this.setAssingedDataSourse();
    }
  }

  populateDialogInvoicesList() {
    this.coreSession.ModalLoading.Show();
    this.routeFilter.employeeId = 0;
    this.invoiceServices.getInvoiceList().subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response != null) {
        if (response.lenght <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
        this.showUnassigned(response);
        if (this.selectedInvoiceData != null) {
          this.populateAssginedList(this.selectedInvoiceData.staffId);
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }


  private showUnassigned(response: any) {
    this.invoicesList = response;
    this.dataSource.pageData = response.filter(e => !this.invoiceIds.includes(e.invoiceId));
    this.dataSource.data = response.filter(e => !this.invoiceIds.includes(e.invoiceId));
    this.dataSource.totalItems = this.dataSource.data.length;
  }

  onShowchaned(){
    this.tableData.columns = [];
    if(this.isShowunassigned === false) {
      this.tableData.columns = this.columns;
      this.tableData.rowOperations.push({
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-trash",
        color: "red"
      });

      this.tableData.columns.push({
        title: "Desc_StaffName",
        key: "staffName",
        isSortable: false,
        width: "20%"
      });
    } else {
      this.tableData.rowOperations = [];
      this.columns.splice(3);
      this.tableData.columns = this.columns;
    }
    this.dataSource.pageData = []
    this.dataSource.data = []
    this.dataSource.pageData = this.isShowunassigned? this.invoicesList.filter(e => !this.invoiceIds.includes(e.invoiceId)): this.assignedInvoices
    this.dataSource.data = this.dataSource.pageData;
    this.dataSource.totalItems = this.dataSource.data.length;
    this.tableData.isMultiSelection = this.isShowunassigned;
    this.tableData.specificActionWhenCheckAll = this.isShowunassigned;
    this.tableData.specificActionWhenCheckRow = this.isShowunassigned;

  }

  populateStaffNameList(selectedStaff: RouteAsseginedModel) {
    this.routeService.getStaffNameList().subscribe(result => {
      this.staffDataSource = result;
      if (selectedStaff != null) {
        if (this.staffDataSource && this.staffDataSource.length > 0) {
          this.staffIdControl.setValue(selectedStaff.staffId);
        }
      } else {
        if (this.staffDataSource && this.staffDataSource.length > 0) {
          this.staffIdControl.setValue(this.staffDataSource[0].staffId);
        }
      }

      this.isStaffsLoading = false;
    });
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      return this.onSaveClicked();
    });
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  prepareRoutedInvoiceObj() {
    this.insertRoutes = new InsertRouteModel();
    this.insertRoutes.invoiceList = [];
    this.insertRoutes.invoiceList = this.selectedInvoices;
    this.insertRoutes.staffId = this.staffIdControl.value;
  }

  onSaveClicked() {
    this.prepareRoutedInvoiceObj();
    this.routeService.insertAssignedInvoices(this.insertRoutes).subscribe(response => {
      // //console.log(response);
      if (response != null && response.status >= 0) {
        this.onSaveCompleted.emit();
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );

  }
  onStaffDataChanged(invoice: any) {
    this.selectedStaffId = this.staffIdControl.value;
    this.populateAssginedList(this.selectedStaffId);
  }

  private populateAssginedList(staffId) {
    this.routeService.getAssignedInvoicesStaff(staffId).subscribe(response => {
      if (response != null && response.status >= 0) {
        // //console.log(response.data);

        this.selectedInvoices = [];
        this.selectedInvoices = response.data.data!= null?response.data.data: [];
        //.map(e => e.invoiceId);
        // this.selectedInvoices = this.invoicesList.filter(e => {
        //   return this.AssignedInvoicesStaff.includes(e.invoiceId);
        // });
        if(response.data.data!= null) {
          this.setAssingedDataSourse();
        }

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    });
  }

  private setAssingedDataSourse() {
    this.staffInvoices.data = this.selectedInvoices;
    this.staffInvoices.pageData = this.selectedInvoices;
    this.staffInvoices.totalItems = this.selectedInvoices.length;
  }

  onStaffDescriptionChanged(invoice: any) {
    this.selectedStaffDescription = this.staffDescriptionControl.value;
  }

  public showDialog() {
    this.populateDialogInvoicesList();
    return this.modalService.open(this.routeEntryDialog, { centered: true, size: 'xl' }).result.then(
      (result) => {
        if (result === DialogResult.Ok) {
          return "this.addedCustomersList";
        } else {
          return -1;
        }
      });
  }

  get staffIdControl() {
    return this.staffForm.get('staffId');
  }

  get staffDescriptionControl() {
    return this.staffForm.get('staffDescription');
  }

  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.LblWarningCaption)).then(
          (res: DialogResult) => {
            if (res === DialogResult.Yes) {
              this.onDeleteClicked(event)
            }
          });
        break;
    }
  }

  onDeleteClicked(event: any) {
    this.routeService.deleteRoute(event.object.invoiceId).subscribe(response => {
      if (response != null && response.status >= 0) {
        this.onSaveCompleted.emit();
        this.selectedInvoices = [];
        this.routeService.GetAllAssignedinvoices().subscribe(response => {
          this.invoiceIds = response.data.map(e => e.invoiceId);
          this.assignedInvoices = response.data;
          this.dataSource.data = [];
          this.dataSource.pageData =[];
          this.dataSource.pageData = this.assignedInvoices
          this.dataSource.data = this.dataSource.pageData;
          this.dataSource.totalItems = this.dataSource.data.length;
        });
      }
      else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
    (error: HttpErrorResponse) => {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    });
  }

}


