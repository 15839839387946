import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { ReportService } from '../../reports.service';

@Component({
  selector: 'app-lectures-report-entry',
  templateUrl: './lectures-report-entry.component.html',
  styleUrls: ['./lectures-report-entry.component.css']
})
export class LecturesReportEntryComponent implements OnInit {

  @Input() events: Observable<void>;
  @Input() selectedObj: any;
  @Input() isEditMode: boolean = false;
  @Input() isViewMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  // isLocationsLoading = false;
  // universityList: LocationModel[] = [];
  // lecturesList: LectureModel[] = [];
  // filteredLectures: LectureModel[] = [];
  // programsList: any[] = [];

  obj: any;
  saveBtnSubscription: any;
  form: FormGroup;

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'date',
    isCalendarOnly: false,
    isRange: false,
  };

  imagesList: String[] = [];

  constructor(private reportsService: ReportService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeSaveClick();
    // this.getRequiredLists();
    if (this.isEditMode || this.isViewMode) {
      this.getLectureReportDetails();
    }
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let lecture = this.isEditMode || this.isViewMode ? this.selectedObj.sessionName : undefined;
    let organizer = this.isEditMode || this.isViewMode ? this.selectedObj.organizerName : undefined;
    let date = this.isEditMode || this.isViewMode ? new Date(this.selectedObj.feedbackDate.toString()) : undefined;
    let program = this.isEditMode || this.isViewMode ? this.selectedObj.programName : undefined;
    let comments = this.isEditMode || this.isViewMode ? this.selectedObj.feedbackText : undefined;

    this.form = new FormGroup({
      lecture: new FormControl({ value: lecture, disabled: this.isViewMode }, Validators.required),
      date: new FormControl({ value: date, disabled: this.isViewMode }, Validators.required),
      program: new FormControl({ value: program, disabled: this.isViewMode }, Validators.required),
      organizer: new FormControl({ value: organizer, disabled: this.isViewMode }, Validators.required),
      comments: new FormControl({ value: comments, disabled: this.isViewMode }, Validators.required),
    });
  }

  // getRequiredLists() {
  //   this.isLocationsLoading = true;
  //   this.lecturesService.getRequiredLists().subscribe(res => {
  //     //console.log(res, 'getRequiredLists');

  //     this.universityList = res.universityList;
  //     this.lecturesList = res.lecturesList;
  //     this.programsList = res.programsList;

  //     this.isLocationsLoading = false;

  //     if (this.universityList.length > 0 && !this.isEditMode && !this.isViewMode)
  //       this.locationIdControl.setValue(this.universityList[0]?.locationId);

  //     if (this.isEditMode || this.isViewMode)
  //       this.getLectureScheduleData();

  //   },
  //     (_error: HttpErrorResponse) => {
  //       this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
  //     });
  // }

  getLectureReportDetails() {
    this.coreSession.ModalLoading.Show();
    this.reportsService.getLectureReportDetails(this.selectedObj.feedbackId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.imagesList = response.data.map(img => 'data:image/png;base64,' + img);

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onProgramSelected() {
    // this.lectureIdControl.setValue(undefined);

    // if (this.programIdControl.value != null) {
    //   this.filteredLectures = this.lecturesList.slice().filter(lec => +lec.programId == +this.programIdControl.value);
    // } else {
    //   this.filteredLectures = [];
    // }
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      // this.onSaveClicked();
    });
  }

  // prepareObj() {
  //   this.scheduleObj = new LectureScheduleModel();

  //   this.scheduleObj.lectureScheduleId = this.isEditMode ? this.selectedObj.lectureScheduleId : -1;

  //   this.scheduleObj.lectureId = +this.lectureIdControl.value;
  //   this.scheduleObj.locationId = +this.locationIdControl.value;
  //   const lectureDate = new Date(this.lectureDateControl.value.toString());
  //   const dateModel = new DateTimeModel();

  //   dateModel.year = lectureDate.getFullYear();
  //   dateModel.month = lectureDate.getMonth() + 1;
  //   dateModel.day = lectureDate.getDate();
  //   dateModel.hour = lectureDate.getHours();
  //   dateModel.minute = lectureDate.getMinutes();
  //   dateModel.date = lectureDate;

  //   this.scheduleObj.lectureDateModel = dateModel;
  //   this.scheduleObj.programId = +this.programIdControl.value;
  //   this.scheduleObj.roomNumber = +this.roomNumberControl.value;
  //   this.scheduleObj.roomCurrentCapacity = 0;//+this.roomCurrentCapacityControl.value;
  //   this.scheduleObj.roomMaximumCapacity = +this.roomMaximumCapacityControl.value;
  // }

  // onSaveClicked() {
  //   if (this.form.invalid) {
  //     this.form.markAllAsTouched();
  //     this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
  //     return;
  //   }

  //   this.prepareObj();

  //   if (this.scheduleObj != null) {
  //     this.coreSession.ModalLoading.Show();
  //     this.lecturesService.insertLectureSchedule(this.scheduleObj, this.isEditMode).subscribe(response => {
  //       this.coreSession.ModalLoading.Hide();
  //       if (response.status != null && response.status >= 0) {
  //         this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
  //         this.onSaveCompleted.emit();
  //       } else {
  //         this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
  //       }
  //     },
  //       (error: HttpErrorResponse) => {
  //         this.coreSession.ModalLoading.Hide();
  //         this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
  //       }
  //     );
  //   }
  // }

  //Form Getters
  get organizerControl() {
    return this.form.get('organizer');
  }

  get lectureControl() {
    return this.form.get('lecture');
  }

  get dateControl() {
    return this.form.get('date');
  }

  get programControl() {
    return this.form.get('program');
  }

  get commentsControl() {
    return this.form.get('comments');
  }
}
