import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[numberOfDigits]"
})
export class NumberOfDigits {
  constructor() {}

  numberOfDigits = 2;

  @HostListener("keydown", ["$event"]) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if(this.numberOfDigits === 0 && e.key === "."){
      return false;
    }
    // Prevent Multiple [.] in Numbers
    const index = event.srcElement.value.indexOf(".");
    if (e.key === "." && index > -1) {
      return false;
    }
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Check Number Of Digit Format ex--> Number of Digit is 2 not 3
    if (
      index > -1 &&
      event.srcElement.value.length - index > this.numberOfDigits
    ) {
      return false;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
}
