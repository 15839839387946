import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { DynamicFormsModel } from 'src/app/shared/model/dynamic-forms-models/dynamic-forms.model';
import { SubCategoryModel } from 'src/app/shared/model/sub-category-model/sub-category-model';
import { map } from 'rxjs/operators';
import { CategoryModel } from 'src/app/shared/model/armory-models/category.model';
@Injectable({
  providedIn: 'root'
})
export class DataDefinitionService {

  httpOptions;
  private url = '';
  private subCategoryUrl = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Category';
    this.subCategoryUrl = baseUrl + 'SubCategory';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  getDynamicFormsList(dynamicFormFilter: any) {
    const urlValue: string = this.url + '/GetDynamicFormsList';
    return this.http.post(urlValue, JSON.stringify(dynamicFormFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateDynamicFormStatus(staff: DynamicFormsModel) {
    const urlValue: string = this.url + '/UpdateDynamicFormStatus';
    return this.http.post(urlValue, JSON.stringify(staff), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  //----------------------------------CATEGORY-SERVICE---------------------------------------------------

  uploadCategoryImage(category: CategoryModel) {
    const urlValue: string = this.url + '/UploadCategoryImage';
    return this.http.post(urlValue, JSON.stringify(category), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getCategoryList(categoryFilter: any) {
    const urlValue: string = this.url + '/GetCategoryList';
    //console.log(categoryFilter, "categoryFilter");
    return this.http.post(urlValue, JSON.stringify(categoryFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getCategoryRequiredLists() {
    const urlValue: string = this.url + '/GetCategoryRequiredLists';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getCategoryData(categoryId: number) {
    const urlValue: string = this.url + '/GetCategoryData';
    return this.http.post(urlValue, JSON.stringify(categoryId), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];

        return result;
      }
    ));
  }

  getAllCategories() {
    const urlValue: string = this.url + '/GetAllCategories';
    //console.log(urlValue, 'allCategories');
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  insertCategory(category: CategoryModel, isEditMode: boolean) {
    const urlAction: string = isEditMode ? '/UpdateCategory' : '/InsertCategory'
    const urlValue: string = this.url + urlAction;
    return this.http.post(urlValue, JSON.stringify(category), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateCategoryActiveStatus(categoryId: number, inactive: boolean) {
    const urlValue: string = this.url + '/UpdateCategoryActiveStatus?categoryId=' + categoryId + '&inactive=' + inactive;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  //--------------------------------------SUB-CATEGORY-SERVICE---------------------------------------------

  getSubCategoryList(employeeFilter: any) {
    const urlValue: string = this.subCategoryUrl + '/GetSubCategoryList';
    //console.log(employeeFilter, "employeeFilter");
    return this.http.post(urlValue, JSON.stringify(employeeFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getAllSubCategories() {
    const urlValue: string = this.subCategoryUrl + '/GetAllSubCategories';
    //console.log(urlValue, 'allSubCategories');
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  insertSubCategory(subCategory: SubCategoryModel) {
    const urlValue: string = this.subCategoryUrl + '/InsertSubCategory';
    return this.http.post(urlValue, JSON.stringify(subCategory), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateSubCategoryActiveStatus(subCategory: SubCategoryModel) {
    const urlValue: string = this.subCategoryUrl + '/updateSubCategoryActiveStatus';
    return this.http.post(urlValue, JSON.stringify(subCategory), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getCategoriesList() {
    const urlValue: string = this.subCategoryUrl + '/GetCategoriesList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }
}
