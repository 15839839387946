import { Component, Inject, OnInit } from '@angular/core';
import { RootHeaderComponent } from '../../root-header/root-header.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { CoreSession } from 'src/app/core/core.session';
import { FacilityManagementService } from '../facility-management.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ConstantMessages } from 'src/app/core/constants/constant-message';

@Component({
  selector: 'app-room-filter-dialog',
  templateUrl: './room-filter-dialog.component.html',
  styleUrls: ['./room-filter-dialog.component.css']
})
export class RoomFilterDialogComponent implements OnInit {

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    facilityTypeId: this.data.facilityTypeId,
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_RoomNumber",
        key: "roomNumber",
        isSortable: false,
        width: "33%"
      },
      {
        title: "Desc_GuestName",
        key: "guestName",
        isSortable: false,
        width: "34%"
      },
      {
        title: "Desc_Company",
        key: "company",
        isSortable: false,
        width: "33%"
      },
    ]
  };

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private coreSession: CoreSession,
    private facilityManagementService: FacilityManagementService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    if (query.searchFilter != null && query.searchFilter != '')
      this.getRoomsList();
    else {
      this.dataSource.data = [];
      this.dataSource.totalItems = 0;
    }
  }

  getRoomsList() {
    this.coreSession.ModalLoading.Show();
    this.facilityManagementService.getRoomsList(this.filter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        //console.log(response, "response");
        if (response.data.totalItems <= 0) {
          this.coreSession.showInfo("", response.message);
        }
        this.dataSource.data = response.data.data;
        this.dataSource.totalItems = response.data.totalItems;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

}
