import { Direction } from '@angular/cdk/bidi';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreSession } from 'src/app/core/core.session';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';
import { ModalLoadingComponent } from 'src/app/shared/components/modal-loading/modal-loading.component';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { NavBarService } from '../root-nav/sidebar.service';
import { PortalMode } from 'src/app/shared/enum/portal-mode.enum';
import { ConstantStorage } from 'src/app/core/constants/constant-stroage';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  isMinimized = true;
  isSettingClicked = true;
  @ViewChild('loadingModal', { static: true }) loadingModal: ModalLoadingComponent;
  direction: Direction = "ltr";

  oneMenuOnly: boolean = false;
  isKasotic: boolean = false;
  delMontePortalSelected: boolean = false;
  showMenuLabels: boolean = false;

  constructor(
    private sessionDataProvider: SessionDataProvider,
    private navService: NavBarService,
    private coreSession: CoreSession, private translate: TranslateService) {
    this.translate.onLangChange.subscribe(value => {
      this.coreSession.changeDir();
      this.coreSession.direction.subscribe(value => { this.direction = value });
    });
  }

  ngOnInit() {
    this.delMontePortalSelected = this.coreSession.selectedPortalMode == PortalMode.DelMonteAuthentic.valueOf()
      || this.coreSession.selectedPortalMode == PortalMode.DelMonteFake.valueOf();
    this.coreSession.direction.subscribe(value => { this.direction = value });
    this.coreSession.ModalLoading = this.loadingModal;
    this.navService.MinimizedClicked.subscribe(
      (isMin: boolean) => {
        this.isMinimized = isMin;
      }
    );
    this.navService.SettingsClicked.subscribe(
      (setting: boolean) => {
        this.isSettingClicked = setting;
      }
    );

    this.oneMenuOnly = this.sessionDataProvider.addedMenus.length <= 1;
    this.isKasotic = this.coreSession.selectedSolutionId === Solution.Kasotic.valueOf();
    if (sessionStorage.length > 0) {
      this.showMenuLabels = sessionStorage.getItem(ConstantStorage.ShowMenuTitles) == 'true';
    }
    this.navService.ShowMenuLabelsChanged.subscribe(value => {
      this.showMenuLabels = value;
    });
  }
}
