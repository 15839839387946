import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-message',
  templateUrl: './snackbar-message.component.html',
  styleUrls: ['./snackbar-message.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarMessageComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  public snackBar: MatSnackBarRef<SnackbarMessageComponent>) { }

  ngOnInit(): void {
  }

  get getIcon() {
    switch (this.data.snackType) {
      case 'SUCCESS':
        return 'done';
      case 'ERROR':
        return 'error';
      case 'WARNING':
        return 'warning';
      case 'INFO':
        return 'info';
    }
  }

  get getIconClass() {
    switch (this.data.snackType) {
      case 'SUCCESS':
        return 'img-suc';
      case 'ERROR':
        return 'img-er';
      case 'WARNING':
        return 'img-wr';
      case 'INFO':
        return 'img-suc';
    }
  }

  get getSideDivClass() {
    switch (this.data.snackType) {
      case 'SUCCESS':
        return 'sideColor-suc';
      case 'ERROR':
        return 'sideColor-err';
      case 'WARNING':
        return 'sideColor-war';
      case 'INFO':
        return 'sideColor-suc';
    }
  }
}
