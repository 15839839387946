export class DateTimeModel {
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public date: any;
    constructor() {
    }
}
