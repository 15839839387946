import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { DateTimeModel } from "src/app/shared/model/dateTimeModel/date-time.model";
import { OrderMainModel } from "src/app/shared/model/order-models/order-main.model";

@Injectable({ providedIn: 'root' })
export class OrdersService {

    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'Orders';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getOrdersList(OrderFilter: any) {
        const urlValue: string = this.url + '/GetOrdersList';
        return this.http.post(urlValue, JSON.stringify(OrderFilter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getRequiredLists() {
        const urlValue: string = this.url + '/GetOrderRequiredLists';
        return this.http.get(urlValue).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getItemsAvailable(filter: { dateRange: DateTimeModel[], sources: number[] }) {
        const urlValue: string = this.url + '/GetItemsAvailable';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];

                return result.data;
            }
        ));
    }

    getOrderData(orderId: string) {
        const urlValue: string = this.url + '/GetOrderData';
        return this.http.post(urlValue, JSON.stringify(orderId), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    insertOrder(obj: { newOrder: OrderMainModel, oldOrder: OrderMainModel }, isEditMode: boolean) {
        let URLActionName: string = isEditMode ? '/UpdateOrder' : '/InsertOrder';
        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateOrderStatus(obj: { orderId: string, newStatusId: number}) {
        const urlValue: string = this.url + '/UpdateOrderStatus';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    assignOrder(obj: { orderId: string, assignedTo: number }) {
        const urlValue: string = this.url + '/AssignOrder';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    cancelOrder(obj: { orderId: string, note: string }) {
        const urlValue: string = this.url + '/CancelOrder';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getStaffList() {
        const urlValue: string = this.url + '/GetStaffList';
        return this.http.get(urlValue,).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getMissingItems(filter: any) {
        const urlValue: string = this.url + '/GetMissingItems';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    approveOnOrderWithMissingItems(obj: { orderId: string, orderStatusId: number , note: string, missingItemsSerialStatusId: number}) {
        const urlValue: string = this.url + '/ApproveOnOrderWithMissingItems';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    sendEmailWithMissingItems(orderId: string, orderStatusId: number, note: string, missingItemsSerialStatusId: number) {
        const urlValue: string = this.url + `/SendEmailWithMissingItems?orderId=${orderId}&orderStatusId=${orderStatusId}&note=${note}&missingItemsSerialStatusId=${missingItemsSerialStatusId}`;
        return this.http.get(urlValue, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

}