import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { RouteComponent } from '../route-list/route.component';
import { RouteService } from '../route.service';



@Component({
  selector: 'app-assigned-invoices-dialog',
  templateUrl: './assigned-invoices-dialog.component.html',
  styleUrls: ['./assigned-invoices-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class AssignedInvoicesDialogComponent implements OnInit {
  displayedColumns: string[] = ['InvoicedId', 'remainingAmount', 'invoicedDate'];
  dataSource: any;
  staffId: number = -1;
  staffName: any = '';
  constructor(private coreSession: CoreSession,public dialogRef: MatDialogRef<RouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private routeService: RouteService,private translateService: TranslateService) { }


  ngOnInit(): void {
    this.staffId = this.data.route.object.staffId;
    this.staffName = this.data.route.object.staffName;
    this.populateAssignedList(this.staffId);
  }

  populateAssignedList(staffId: any) {
    this.routeService.getAssignedInvoicesStaff(staffId).subscribe(response => {
      if (response != null && response.status >= 0) {
        this.dataSource = new MatTableDataSource(response.data.data);
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
    (error: HttpErrorResponse) => {
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
