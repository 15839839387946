import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stock-counting-report',
  templateUrl: './stock-counting-report.component.html',
  styleUrls: ['./stock-counting-report.component.css']
})
export class StockCountingReportComponent implements OnInit {

  gridReportURL: string = '';

  constructor() { }

  ngOnInit(): void {
    this.gridReportURL = 'http://localhost:8080/API/Report/ShowGrid?id=';
  }

}
