import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootHeaderComponent } from '../../root-header/root-header.component';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import Validation from './utils/validation';
import { OperatorModel } from 'src/app/shared/model/operator/operator.model';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { CoreSession } from 'src/app/core/core.session';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { ConstantStorage } from 'src/app/core/constants/constant-stroage';
import { Direction } from '@angular/cdk/bidi';
import { StaffService } from '../../data-definition/staff/staff.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {

  hidePass = true;
  hideNewPass = true;
  hideConPass = true;
  form: FormGroup;
  submitted = false;
  url = " ";
  imagePath = " ";
  staff: StaffModel;
  username: any;
  staffid: any;
  password: any;
  oldEmail: string;
  @Output() onSaveCompleted = new EventEmitter();
  direction: Direction = "rtl";

  constructor(
    public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private staffService: StaffService,
    private coresession: CoreSession,
    private translateService: TranslateService
  ) {
    this.direction = this.translateService.currentLang.toLocaleLowerCase() == 'en' ? 'ltr' : 'rtl'; 
    this.translateService.onLangChange.subscribe(value => {
      this.coresession.changeDir();
      this.coresession.direction.subscribe(value => { this.direction = value });

    })
  }



  ngOnInit(): void {
    this.createProfileForm();
    this.loadStaff();
  }

  private createProfileForm() {
    this.form = new FormGroup(
      {
        mobile: new FormControl(undefined),
        address: new FormControl(undefined),
        email: new FormControl(undefined, [Validators.email]),
        oldPassword: new FormControl(undefined),
        newPassword: new FormControl(undefined),
        confirmPassword: new FormControl(undefined),
      },
      {
        validators: Validation.mustMatch('newPassword', 'confirmPassword'),
      }

    );
  }

  private loadStaff() {
    const currentUser = JSON.parse(sessionStorage.length > 0 ? sessionStorage.getItem(ConstantStorage.CurrentUser) : localStorage.getItem(ConstantStorage.CurrentUser));
    this.staffService.getStaff(currentUser.staffId)
      .subscribe(response => {
        this.staff = response;
        this.staffid = response.staffId;
        this.oldEmail = response.email;
        this.f.mobile.setValue(this.coresession.DecryptAES(this.staff.mobile));
        this.f.address.setValue(this.coresession.DecryptAES(this.staff.address));
        this.f.email.setValue(this.coresession.DecryptAES(this.staff.email));
        this.imagePath = this.coresession.DecryptAES(this.staff.staffImage);
        this.username = this.coresession.DecryptAES(this.staff.username);
        this.coresession.userImage.next(this.imagePath);
        this.coresession.email.next(this.staff.email);
        this.password = this.coresession.DecryptAES(response.password);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getErrorMessage() {
    return 'Required felid';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    const staffModel = new StaffModel();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coresession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }
    staffModel.email = this.f.email.value != null ? this.coresession.EncryptAES(this.f.email.value) : '';
    staffModel.mobile = this.f.mobile.value != null ? this.coresession.EncryptAES(this.f.mobile.value) : '';
    staffModel.newPassword = this.f.newPassword.value != null ? this.coresession.EncryptAES(this.f.newPassword.value) : '';
    staffModel.staffImage = this.imagePath != null ? this.coresession.EncryptAES(this.imagePath) : '';
    staffModel.address = this.f.address.value != null ? this.coresession.EncryptAES(this.f.address.value ): '';
    staffModel.staffId = this.staffid != null ? this.staffid : -1;
    staffModel.password = this.password != null ? this.coresession.EncryptAES(this.password) : '';
    staffModel.oldPassword = this.f.oldPassword.value != null ? this.f.oldPassword.value : '';
    staffModel.confirmPassword = this.f.confirmPassword.value != null ? this.coresession.EncryptAES(this.f.confirmPassword.value) : '';
    staffModel.oldEmail = this.oldEmail != null ? this.oldEmail : '';
    this.staffService.updateStaff(staffModel).subscribe(response => {
      // //console.log(response);
      if (response.status != null && response.status >= 0) {
        this.coresession.userImage.next(this.coresession.DecryptAES(staffModel.staffImage));
        this.coresession.email.next(this.coresession.DecryptAES(staffModel.email));
        this.coresession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
        this.onSaveCompleted.emit();
        this.dialogRef.close();
      } else {
        this.coresession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
      }

    },
      (_error: HttpErrorResponse) => {
        this.coresession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  addFile(event: any) {
    const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.url = reader.result as string;
      this.imagePath = this.url
    }
    reader.readAsDataURL(file)
  }
}
