import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CoreSession } from '../../core/core.session';
import { Observable } from 'rxjs';
import { FormMode } from 'src/app/shared/enum/form-mode.enum';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  httpOptions;
  private url = '';
  private serviceURL = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession,
  ) {
    this.url = baseUrl + 'Settings/';
    this.serviceURL = baseUrl + 'Services/';
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "my-auth-token"
      })
    };
  }

  getMenusList(securityGroupId: number, mode: FormMode) {
    const urlValue: string = this.url + 'FillMenuData';
    let menuList = {
      securityGroupId: securityGroupId,
      mode: mode
    };
    return this.http.post<ResponseModel>(urlValue, menuList).map(response => {
      var isValid = false;
      if (response && response.data) {
        // if (encryptedEmployeeId) {
        //   if (encryptedEmployeeId === response.data.encryptedEmployeeId) {
        isValid = true;
        //   }
        // }
      }
      if (isValid) {
        return response.data;
      } else {
        return null;
      }
    });
  }

  getModuleDiscription(moduleId: number) {
    const urlValue = this.url + 'GetModuleEditableDescriptions';
    return this.http.post<ResponseModel>(urlValue, moduleId).map((response) => {
      return response;
    });
  }
  // getAllConfigurations(level: number, configFilter: string) {
  //   const urlValue: string = this.url + 'GetAllConfigurations';
  //   let configuration = {
  //     levelId: level,
  //     filter: configFilter
  //   };
  //   return this.http
  //     .post<ResponseModel>(urlValue, configuration)
  //     .map(response => {
  //       return response.data;
  //     })
  // }
  // getAllWebServices() {
  //   const urlValue: string = this.serviceURL + 'GetAllServices';
  //   return this.http.get<ResponseModel>(urlValue).map(response => {
  //     return response.data;
  //   });
  // }
  // updateConfigurations(changedConfigurations: any[]) {
  //   const urlValue = this.url + 'UpdateSystemConfigurations';
  //   return this.http
  //     .post<ResponseModel>(urlValue, changedConfigurations)
  //     .map(
  //       (response) => {
  //         return response;
  //       }
  //     );
  // }
  // updateServices(changedServices: any) {
  //   const urlValue = this.serviceURL + 'UpdateServices';
  //   return this.http
  //     .post<ResponseModel>(urlValue, changedServices)
  //     .map(
  //       (response) => {
  //         return response;
  //       }
  //     );
  // }
  updateDescriptions(descriptions: any[], moduleId: number) {
    const urlValue = this.url + 'UpdateDescriptions';
    let DescriptionsData = {
      captions: descriptions,
      moduleId: moduleId
    }
    return this.http
      .post<ResponseModel>(urlValue, DescriptionsData)
      .map(
        (response) => {
          return response;
        }
      );
  }
  // InsertLevelConfigurations(changedConfigurations: any[], level: ConfigurationLevels) {
  //   const urlValue = this.url + 'InsertLevelConfigurations';
  //   let configLevelData = {
  //     changedConfigurations: changedConfigurations,
  //     configLevel: level
  //   }
  //   return this.http
  //     .post<ResponseModel>(urlValue, configLevelData)
  //     .map(
  //       (response) => {
  //         return response;
  //       }
  //     );
  // }
  // saveSecurityGroup(securityGroupMenusAndId: any) {
  //   const urlValue: string = this.url + 'InsertSecurityGroup';
  //   return this.http.post(urlValue, JSON.stringify(securityGroupMenusAndId), this.httpOptions).map(
  //     (response) => {
  //       const result = new ResponseModel();
  //       result.data = response['data'];
  //       result.message = response['message'];
  //       result.status = response['status'];
  //       return result;
  //     }
  //   );
  // }

  // getAllAvailableSecurityGroups() {
  //   const urlValue = this.url + "GetAvailableSecurityGroupsList";
  //   return this.http.post<ResponseModel>(urlValue, this.httpOptions).map(response => {
  //     let employeeId = this.coreSession.CurrentOperator.employeeId;
  //     let decryptedData = this.secService.decryptData(response.data, employeeId);
  //     let decryptedDataModel = JSON.parse(decryptedData);
  //     response.data = decryptedDataModel;
  //     return response;
  //   });
  // }

  getDescriptionsList(securityGroupId: number) {
    // const urlValue: string = this.url + '/GetDescriptionsList'

    // return this.http.get<ResponseModel>(urlValue + '?securityGroupId=' + securityGroupId).map(response => {
    //   return response.data;
    // });
    const urlValue: string = this.url + 'GetDescriptionsList';
    return this.http.post<ResponseModel>(urlValue, securityGroupId).map(response => {
      return response.data;
    });
  }

  // deleteSecurityGroup(securityGroupId: any) {
  //   const urlValue = this.url + 'DeleteSecurityGroup';
  //   return this.http.post<ResponseModel>(urlValue, securityGroupId)
  //     .map(response => {
  //       return response;
  //     });
  // }
  getAllAvailableModules() {
    const urlValue = this.url + 'GetAvailableModules';
    return this.http.post<ResponseModel>(urlValue, this.httpOptions).map(response => {
      return response;
    });
  }
  restoreDefaultDescriptions(sonicModules: any) {
    const urlValue = this.url + 'RestoreDefaultDescriptions';
    return this.http
      .post<ResponseModel>(urlValue, sonicModules)
      .map(
        (response) => {
          return response;
        }
      );
  }
  manageLanguages(languages: any) {
    const urlValue = this.url + 'ManageLanguages';
    return this.http
      .post<ResponseModel>(urlValue, languages)
      .map(
        (response) => {
          return response;
        }
      );
  }

  uploadInvData() {
    const urlValue: string = this.url + 'UploadInventoryData';
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  downloadInvData(warehouseId: number) {
    const urlValue: string = this.url + 'DownloadInventoryData?warehouseId=' + warehouseId;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getWarehouses() {
    const urlValue: string = this.url + 'GetWarehouses';
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }
  // saveOperationLevels(operationDataList: any[]) {
  //   const urlValue = this.url + "InsertOperationLevels";
  //   return this.http.post<ResponseModel>(urlValue, operationDataList)
  //     .map(response => {
  //       return response;
  //     })
  // }
  // getOperationLevelsData() {
  //   const urlValue: string = this.url + 'getOperationLevelsData';
  //   return this.http.post<ResponseModel>(urlValue, this.httpOptions).map(response => {
  //     ;
  //     return response;
  //   });
  // }


  // getAllMenus() {
  //   const urlValue: string = this.url + 'GetAllMenus';
  //   return this.http.post<ResponseModel>(urlValue, null)
  //     .map(response => {
  //       return response;
  //     });
  // }

  getAvailableMenusForEdit() {
    const urlValue: string = this.url + 'GetAvailableMenusForEdit';
    return this.http.get<ResponseModel>(urlValue).map(response => {
      return response;
    });
  }

  // getCustomerVisitStepDetails(filter:any) {
  //   const urlValue: string = this.url + 'GetCustomerVisitStepDetails';
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // getVisitPatternData(filter:any) {
  //   const urlValue: string = this.url + 'GetVisitPatternData';
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // insertVisitPattern(visitPatternObj) {
  //   const urlValue = this.url + "InsertVisitPattern";
  //   return this.http.post<ResponseModel>(urlValue, visitPatternObj)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // updateVisitPattern(visitPatternObj) {
  //   const urlValue = this.url + "UpdateVisitPattern";
  //   return this.http.post<ResponseModel>(urlValue, visitPatternObj)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // getVisitSteps(id:number , isCustomerGroup:boolean) {
  //   const urlValue: string = this.url + 'GetVisitSteps';
  //   return this.http.get<ResponseModel>(
  //     urlValue + '?id=' + id + '&isCustomerGroup=' + isCustomerGroup
  //     ).map(response => {
  //     return response;
  //   });
  // }

  // updateStepSequence(steps:any[]) {
  //   const urlValue = this.url + "UpdateStepSequence";
  //   return this.http.post<ResponseModel>(urlValue, steps)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // deleteVisitPattern(filter:any) {
  //   const urlValue = this.url + "DeleteVisitPattern";
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // checkIfVisitPatternExists(filter:any) {
  //   const urlValue = this.url + "CheckIfVisitPatternExists";
  //   return this.http.post<ResponseModel>(urlValue, filter)
  //     .map(response => {
  //       return response;
  //   });
  // }

  // getCustomerVisitPatternsSharedTable() {
  //   const urlValue = this.url + "GetCustomerVisitPatternsSharedTable";
  //   return this.http.post<ResponseModel>(urlValue, null)
  //     .map(response => {
  //       return response;
  //   });
  // }
}
