import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { CoreSession } from "src/app/core/core.session";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";

@Injectable({
  providedIn: "root"
})
export class DynamicReportService {
  private url = "";
  constructor(private http: HttpClient, @Inject("BASE_URL") baseUrl: string,
    private coreSession: CoreSession,
    private translateService: TranslateService) {
    this.url = baseUrl + "DynamicReport";
  }

  getReportFilters(reportId) {
    const urlValue: string = this.url + "/GetReportFilters";
    return this.http.post<ResponseModel>(urlValue, reportId).map(
      response => {
        return response;
      });
  }
  getChildFilterData(childControl) {
    const urlValue: string = this.url + "/GetChildFilterData";
    return this.http.post<ResponseModel>(urlValue, childControl).map(
      response => {
        return response;
      });
  }
  showReportData(report: any) {
    const urlValue: string = this.url + '/ShowReportData';
    return this.http
      .post<ResponseModel>(urlValue, report)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  markReportAsFavorite(reportId: number) {
    const urlValue: string = this.url + '/MarkReportAsFavorite';
    return this.http
      .post<ResponseModel>(urlValue, reportId)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

  unmarkReportAsFavorite(reportId: number) {
    const urlValue: string = this.url + '/UnMarkReportAsFavorite';
    return this.http
      .post<ResponseModel>(urlValue, reportId)
      .map(response => {
        const res = new ResponseModel();
        res.data = response['data'];
        res.message = response['message'];
        res.status = response['status'];
        return res;
      });
  }

}
