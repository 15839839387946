export enum RowOperation {
    delete = 1,
    approve,
    rowClick,
    rowCheck,
    invoiceOrder,
    EmployeeLevel,
    SecurityGroupLevel,
    OrganizationLevel,
    DivisionLevel,
    CustomerLevel,
    ChannelLevel,
    CustomerGroupLevel,
    VisitPattern,
    ViewCustomers,
    Deactivate,
    Activate,
    Enable,
    Disable,
    Configure,
    View,
    Void,
    delivery,
    partialDelivery,
    Clone,
    Select,
    ManageCustomers,
    Adjust,
    Redeem,
    Close,
    Execute,
    UnSelect,
    Bounce,
    Dispatch,
    Endorse,
    collect,
    Verify,
    edit,
    Reverse,
    Print,
    add,
    Confirm,
    Cancel,
    ExportToExcel,
    SendNotification,
    SendEmail,
    reject,
    Show,
    Hide,
    Reset,
    Duplicate,
    ShowHistory,
    ShowCertificate,
    ShowPostAssessment,
    HideCertificate,
    HidePostAssessment,
    ViewCertificates,
}

export enum MultiSelectionOperation {
    delete = 1,
    approve,
    collect,
    deactivate,
    activate,
}
