import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { AssetsModel } from 'src/app/shared/model/assets-models/assets.model';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  httpOptions;
  private url = '';
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Assets';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  insertAssetsData(assetsModel: AssetsModel, isEditMode: boolean) {
    const URLActionName = isEditMode ? '/UpdateAsset' : '/InsertAsset'
    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(assetsModel), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getAssetData(assetId: number) {
    const urlValue: string = this.url + '/GetAssetData';
    return this.http.post(urlValue, assetId, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getAssetsList(assetsFilter: any) {
    const urlValue: string = this.url + '/GetAssetsList';
    return this.http.post<ResponseModel>(urlValue, JSON.stringify(assetsFilter), this.httpOptions).pipe(map((response) => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    }
    ));
  }

  updateAssetActiveStatus(assetId: number) {
    const urlValue: string = this.url + '/UpdateAssetActiveStatus';
    return this.http.post(urlValue, assetId, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getAssetRequiredLists() {
    const urlValue: string = this.url + '/GetAssetRequiredLists';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getAssetsListByParent(parentAssetsId: number) {
    const urlValue: string = this.url + `/GetAssetsListByParent?parentAssetsId=${parentAssetsId}`;
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getAssetHistory(assetId: number) {
    const urlValue: string = this.url + '/GetAssetHistory?assetId=' + assetId;
    return this.http.get<ResponseModel>(urlValue, this.httpOptions).pipe(map((response) => {
      const result = new ResponseModel();
      result.data = response['data'];
      result.message = response['message'];
      result.status = response['status'];
      return result;
    }
    ));
  }

  insertAssetsList(assetList: AssetsModel[], showErrorList: boolean) {
    let URLActionName: string = '/InsertAssetsList?showErrorList=' + showErrorList;

    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(assetList), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  generateBarcodesAsPDF(barcodes: any[]) {
    let URLActionName: string = '/GenerateBarcodesAsPDF';
    const urlValue: string = this.url + URLActionName;

    return this.http.post(urlValue, JSON.stringify(barcodes),
        {
            responseType: "blob",
            headers: this.httpOptions.headers,
        }
    ).map(res => {
        var blob = new Blob([res], { type: "application/pdf" });
        return blob;
    });
}
}
