import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ArmoryOperationsService } from 'src/app/content/armory-operations/armory-operations.service';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { RowOperation } from '../../enum/shared-table-operation.enum';
import { Solution } from '../../enum/solution.enum';
import { ItemModel } from '../../model/item-models/item.model';
import { IDateTimePickerProperties } from '../../model/dateTimeModel/date-time-properties.interface';
import { SharedTableResult } from '../../model/shared-table/shared-table-result.interface';
import { ITableProperties } from '../../model/shared-table/table-properties.interface';

@Component({
  selector: 'app-serial-entry',
  templateUrl: './serial-entry.component.html',
  styleUrls: ['./serial-entry.component.css']
})
export class SerialEntryComponent implements OnInit, OnDestroy {

  @Input() inboundOperation: boolean = false;
  @Input() isViewMode: boolean = false;
  @Input() selectedItem: ItemModel;
  @Input() allItemSerials: any[] = [];
  @Input() events: Observable<void>;
  @Input() warehouseId: number = -1;
  @Output() onSaveCompleted = new EventEmitter<ItemModel[]>();
  @ViewChild("nav") navBarRef;
  isKasotcSelected: boolean = false;
  traceabilitySelected: boolean = false;

  newSerialsList: ItemModel[] = [];
  allSerials: any[] = [];

  form: FormGroup;

  disableInputTextIfNotChecked: boolean = false;

  showDuplicateTab: boolean = false;

  saveBtnSubscription: Subscription;

  tab_desc: string;
  editObj: ItemModel = null;

  parentSerials: string[] = [];

  serialsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  factoryDuplicatesDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  }

  JAFDuplicatesDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  }

  serialsTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: []
  };

  factoryDuplicatesTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_FactorySerial",
        key: "serialNumber",
        isSortable: true,
        width: "100%",
      }
    ]
  }

  JAFDuplicatesTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_JAFSerial",
        key: "jafSerial",
        isSortable: true,
        width: "100%",
      }
    ]
  }

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_UsageDate',
    formControlName: 'usageDate',
    isCalendarOnly: true
  };

  constructor(private coreSession: CoreSession, private translateService: TranslateService, private armoryOperationsService: ArmoryOperationsService) { }

  ngOnInit(): void {
    this.isKasotcSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf();
    this.traceabilitySelected = this.coreSession.selectedSolutionId == Solution.Traceability.valueOf();
    // //console.log(this.selectedItem, 'selectedItem');
    // //console.log(this.allItemSerials, 'allItemSerials');

    if (!this.isKasotcSelected) {
      this.factoryDuplicatesTableProp.columns[0].title = 'Desc_SerialNumber';
    }
    if ((this.selectedItem.itemTypeId == 1 || !this.isKasotcSelected) && !this.isViewMode && this.inboundOperation) {
      this.serialsTableProp.rowOperations = [
        {
          operation: RowOperation.edit,
          title: "Desc_Edit",
          icon: "fa fa-pencil",
          color: "#12344d",
          showHideOptionPerLine: true,
          showWhenKeyValueFalse: true,
          controlKeyName: 'isUsed',
        },
        {
          operation: RowOperation.delete,
          title: "Desc_Delete",
          icon: "fa fa-trash",
          color: "#f1685e",
          showHideOptionPerLine: true,
          showWhenKeyValueFalse: true,
          controlKeyName: 'isUsed',
        }
      ]
    }

    this.serialsTableProp.rowOperations.push({
      operation: RowOperation.Print,
      title: "Desc_PrintBarcode",
      icon: 'fa fa-print',
      color: '#12344d'
    });

    if (this.selectedItem.itemTypeId == 2 && this.isKasotcSelected) {
      this.tab_desc = 'Desc_Barcodes';
      this.serialsTableProp.columns = [
        {
          title: 'Desc_Barcode',
          key: 'barcode',
          isSortable: true,
          width: '100%'
        }
      ];
      this.serialsTableProp.showNewBtn = true;
      this.serialsTableProp.newBtnCaption = 'Desc_PrintAll';
    } else {
      this.tab_desc = 'Desc_Serials';
      if (this.isKasotcSelected) {
        this.serialsTableProp.columns = [
          {
            title: "Desc_FactorySerial",
            key: "serialNumber",
            isSortable: true,
            width: "33%"
          },
          {
            title: "Desc_JAFSerial",
            key: "jafSerial",
            isSortable: true,
            width: "33%"
          },
          {
            title: 'Desc_Barcode',
            key: 'barcode',
            isSortable: true,
            width: '34%'
          }
        ]
      } else {
        if (this.traceabilitySelected) {
          this.serialsTableProp.columns = [
            {
              title: "Desc_SerialNumber",
              key: "serialNumber",
              isSortable: true,
              width: "50%"
            },
            {
              title: 'Desc_ParentSerial',
              key: 'parentSerial',
              isSortable: true,
              width: '50%'
            }
          ];
        } else {
          this.serialsTableProp.columns = [
            {
              title: "Desc_SerialNumber",
              key: "serialNumber",
              isSortable: true,
              width: "50%"
            },
            // {
            //   title: 'Desc_Barcode',
            //   key: 'barcode',
            //   isSortable: true,
            //   width: '50%'
            // }
          ];
        }

      }

    }

    if (!this.inboundOperation && !this.isViewMode) {
      this.serialsTableProp.isMultiSelection = true;
      this.serialsTableProp.allowSelectAll = false;
      this.serialsTableProp.specificNumberOfSelections = true;
      this.serialsTableProp.numberOfAllowedSelections = this.selectedItem.availableQty;
      this.serialsTableProp.specificActionWhenCheckRow = true;
    }

    this.subscribeSaveClick();
    this.initForm();

    this.allSerials = this.allItemSerials.slice();
    this.newSerialsList = this.allItemSerials.slice().filter(item => item.itemId == this.selectedItem.itemId && (this.inboundOperation || item.warehouseId == this.warehouseId)).slice();

    this.serialsDataSource.data = this.newSerialsList.slice();
    this.serialsDataSource.totalItems = this.serialsDataSource.data.length;
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    this.form = new FormGroup({
      factorySerial: new FormControl(undefined, Validators.required),
      serialsQty: new FormControl(),
      usageStatus: new FormControl(),
      numberOfBulletsUsed: new FormControl(),
      usageDate: new FormControl(),
      note: new FormControl(),
      jafSerial: new FormControl(undefined, this.isKasotcSelected ? Validators.required : []),
      parentSerial: new FormControl(undefined),
    });
  }

  onSerialRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteSerial(event.object.serialNumber, event.index);
        break;
      case RowOperation.edit:
        this.onEditSerialClicked(event);
        break;
      case RowOperation.Print:
        this.onPrintClicked(event);
        break;
    }
  }

  onPrintClicked(event: any) {
    const barcodes = [event.object.barcode];

    this.coreSession.ModalLoading.Show();
    this.armoryOperationsService.generateBarcodesAsPDF(barcodes).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      //debugger;
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, "_blank");
      this.coreSession.ModalLoading.Hide();
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onPrintAllClicked() {
    const barcodes = this.serialsDataSource.data.slice().map(obj => obj.barcode);

    this.coreSession.ModalLoading.Show();

    this.armoryOperationsService.generateBarcodesAsPDF(barcodes).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      //debugger;
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, "_blank");
      this.coreSession.ModalLoading.Hide();
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  onEditSerialClicked(event: any) {
    this.navBarRef.select(1);

    this.editObj = event.object;

    this.factorySerialControl.setValue(event.object.serialNumber);
    this.jafSerialControl.setValue(event.object.jafSerial);
    this.usageStatusControl.setValue(event.object.usageStatus);
    this.usageDateControl.setValue(event.object.usageDate);
    this.numberOfBulletsUsedControl.setValue(event.object.numberOfBulletsUsed);
    this.noteControl.setValue(event.object.note);
  }

  onDeleteSerial(serialNumber: string, index: number) {
    const allIndex = this.allSerials.findIndex(s => s.serialNumber == serialNumber);
    const newIndex = this.newSerialsList.findIndex(s => s.serialNumber == serialNumber);

    if (allIndex > -1)
      this.allSerials.splice(allIndex, 1);
    if (newIndex > -1)
      this.newSerialsList.splice(newIndex, 1);

    this.serialsDataSource.data.splice(index, 1);
    this.serialsDataSource.totalItems--;
  }

  onBtnClicked() {
    if (this.editObj == null) {
      this.onAddSerial();
    } else {
      this.onEditSerial();
    }
  }

  onAddSerial() {
    if (this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf()) {
      if (this.factoryEqualJAF()) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_FactoryEqualJAFSerial'));
        return;
      }
      if (this.factoryAlreadyExists()) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_FactorySerialEntered'));
        return;
      }
      if (this.jafAlreadyExists()) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_JAFSerialEntered'));
        return;
      }
      if (this.sameFactoryDifferentItem()) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_SameFactoryDifferentItem'));
        return;
      }
      if (this.sameJAFDifferentItem()) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_SameJAFDifferentItem'));
        return;
      }
    } else {
      let invalid: boolean = this.allSerials.findIndex(s =>
        s.serialNumber == this.factorySerialControl.value
        && this.selectedItem.itemId == s.itemId && this.editObj?.serialNumber != s.serialNumber) > -1;
      if(invalid) {
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_SerialAlreadyExist'));
        return;
      }
    }


    this.navBarRef.select(1);

    if (this.serialsQtyControl && +this.serialsQtyControl.value > 0) {
      for (let i = 0; i < +this.serialsQtyControl.value; i++) {
        let item = new ItemModel();
        item.itemId = this.selectedItem.itemId;
        item.itemTypeId = this.selectedItem.itemTypeId;
        item.isSerialized = true;
        item.serialNumber = this.coreSession.generateRandomAlphnumericText(14);
        item.jafSerial = this.jafSerialControl.value ?? '';
        item.quantity = 1;
        item.barcode = this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits);
        item.usageStatus = this.usageStatusControl.value ? this.usageStatusControl.value : '';
        if (this.usageDateControl.value) item.usageDate = this.usageDateControl.value;
        item.numberOfBulletsUsed = this.numberOfBulletsUsedControl.value ? this.numberOfBulletsUsedControl.value : 0;
        item.note = this.noteControl.value ? this.noteControl.value : '';
        item.parentSerial = this.parentSerialControl.value ?? '';

        this.allSerials.push(item);
        this.newSerialsList.push(item);
        if (item.parentSerial == '') {
          this.parentSerials.push(item.serialNumber);
        } else {
          let index = this.serialsDataSource.data.findIndex(s => s.serialNumber == item.parentSerial);
          if (index > -1) {
            this.serialsDataSource.data[index].isUsed = true;
          }
        }

        this.serialsDataSource.data.push(item);
        this.serialsDataSource.totalItems++;
      }
    } else {
      let item = new ItemModel();
      item.itemId = this.selectedItem.itemId;
      item.itemTypeId = this.selectedItem.itemTypeId;
      item.isSerialized = true;
      item.serialNumber = this.factorySerialControl.value;
      item.jafSerial = this.jafSerialControl.value ?? '';
      item.quantity = 1;
      item.barcode = this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits);
      item.usageStatus = this.usageStatusControl.value ? this.usageStatusControl.value : '';
      if (this.usageDateControl.value) item.usageDate = this.usageDateControl.value;
      item.numberOfBulletsUsed = this.numberOfBulletsUsedControl.value ? this.numberOfBulletsUsedControl.value : 0;
      item.note = this.noteControl.value ? this.noteControl.value : '';
      item.parentSerial = this.parentSerialControl.value ?? '';

      this.allSerials.push(item);
      this.newSerialsList.push(item);
      if (item.parentSerial == '') {
        this.parentSerials.push(item.serialNumber);
      } else {
        let index = this.serialsDataSource.data.findIndex(s => s.serialNumber == item.parentSerial);
        if (index > -1) {
          this.serialsDataSource.data[index].isUsed = true;
        }
      }

      this.serialsDataSource.data.push(item);
      this.serialsDataSource.totalItems++;
    }
    this.form.reset();
  }

  onEditSerial() {
    if (this.factoryEqualJAF()) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_FactoryEqualJAFSerial'));
      return;
    }
    if (this.factoryAlreadyExists()) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_FactorySerialEntered'));
      return;
    }
    if (this.jafAlreadyExists()) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_JAFSerialEntered'));
      return;
    }
    if (this.sameFactoryDifferentItem()) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_SameFactoryDifferentItem'));
      return;
    }
    if (this.sameJAFDifferentItem()) {
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant('Desc_SameJAFDifferentItem'));
      return;
    }

    const allIndex = this.allSerials.findIndex(s => s.serialNumber == this.editObj.serialNumber);
    const newIndex = this.newSerialsList.findIndex(s => s.serialNumber == this.editObj.serialNumber);
    const srcIndex = this.serialsDataSource.data.findIndex(s => s.serialNumber == this.editObj.serialNumber);

    let item = new ItemModel();
    item.itemId = this.selectedItem.itemId;
    item.itemTypeId = this.selectedItem.itemTypeId;
    item.isSerialized = true;
    item.serialNumber = this.factorySerialControl.value;
    item.jafSerial = this.jafSerialControl.value ?? '';
    item.quantity = 1;
    item.barcode = this.editObj.barcode;
    item.usageStatus = this.usageStatusControl.value;
    item.usageDate = this.usageDateControl.value;
    item.numberOfBulletsUsed = this.numberOfBulletsUsedControl.value;
    item.note = this.noteControl.value;

    this.allSerials[allIndex] = item;
    this.newSerialsList[newIndex] = item;

    this.serialsDataSource.data[srcIndex] = item;

    this.form.reset();
    this.editObj = null;
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  onSaveClicked() {

    // //console.log(this.newSerialsList, 'SaveSerialClicked');

    if (this.inboundOperation) {
      this.coreSession.ModalLoading.Show();
      this.armoryOperationsService.checkSerialsUniqueness(this.newSerialsList).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        //debugger;
        if (response.status != null && response.status >= 0) {
          if (response.data == 1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.onSaveCompleted.emit(this.newSerialsList);
          } else {
            this.showDuplicatedSerials(response.data.factoryDuplicates, response.data.jafDuplicates);
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    } else {
      const newSerialsList = this.serialsDataSource.data.slice().map(item => {
        return {
          ...item,
          itemId: item.itemId = this.selectedItem.itemId,
          itemTypeId: item.itemTypeId = this.selectedItem.itemTypeId,
          isSerialized: item.isSerialized = true,
          serialNumber: item.serialNumber = item.serialNumber,
          quantity: item.quantity = 1
        }
      });

      this.onSaveCompleted.emit(newSerialsList);
    }

  }

  showDuplicatedSerials(factoryDuplicates: any[], JAFDuplicates: any[]) {
    //debugger;
    this.showDuplicateTab = true;

    this.factoryDuplicatesDataSource.data = factoryDuplicates.slice().map(s => { return { serialNumber: s } });
    this.factoryDuplicatesDataSource.totalItems = factoryDuplicates.length;

    let index = -1;
    factoryDuplicates.slice().forEach(s => {
      index = this.serialsDataSource.data.findIndex(g => g.serialNumber == s);
      if (index > -1)
        this.onDeleteSerial(s, index);
    });

    if (this.isKasotcSelected) {
      this.JAFDuplicatesDataSource.data = JAFDuplicates.slice().map(s => { return { jafSerial: s } });
      this.JAFDuplicatesDataSource.totalItems = JAFDuplicates.length;

      JAFDuplicates.slice().forEach(jafSerial => {
        index = this.serialsDataSource.data.findIndex(s => s.jafSerial == jafSerial);
        if (index > -1) {
          const allIndex = this.allSerials.findIndex(s => s.jafSerial == jafSerial);
          const newIndex = this.newSerialsList.findIndex(s => s.jafSerial == jafSerial);

          if (allIndex > -1)
            this.allSerials.splice(allIndex, 1);
          if (newIndex > -1)
            this.newSerialsList.splice(newIndex, 1);

          this.serialsDataSource.data.splice(index, 1);
          this.serialsDataSource.totalItems--;
        }
      });
    }

    if (factoryDuplicates.length > 0)
      this.navBarRef.select(2);
    else {
      if (this.isKasotcSelected)
        this.navBarRef.select(3);
    }

  }

  // serialExists(): boolean {
  //   return this.factorySerialControl.value == this.jafSerialControl.value || (this.allSerials.findIndex(s => (s.serialNumber == this.factorySerialControl.value || s.jafSerial == this.factorySerialControl.value ||
  //     s.serialNumber == this.jafSerialControl.value || s.jafSerial == this.jafSerialControl.value)
  //     && this.editObj?.serialNumber != s.serialNumber) > -1);
  // }

  factoryEqualJAF(): boolean {
    return (this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf()) && (this.factorySerialControl.value == this.jafSerialControl.value);
  }

  factoryAlreadyExists(): boolean {
    return this.allSerials.findIndex(s =>
      (s.serialNumber == this.factorySerialControl.value || s.jafSerial == this.factorySerialControl.value)
      && this.selectedItem.itemId == s.itemId && this.editObj?.serialNumber != s.serialNumber) > -1;
  }

  jafAlreadyExists(): boolean {
    return this.allSerials.findIndex(s =>
      (s.serialNumber == this.jafSerialControl.value || s.jafSerial == this.jafSerialControl.value)
      && this.selectedItem.itemId == s.itemId && this.editObj?.serialNumber != s.serialNumber) > -1;
  }

  sameFactoryDifferentItem(): boolean {
    return this.allSerials.findIndex(s =>
      (s.serialNumber == this.factorySerialControl.value || s.jafSerial == this.factorySerialControl.value)
      && this.selectedItem.itemId != s.itemId && this.editObj?.serialNumber != s.serialNumber) > -1;
  }

  sameJAFDifferentItem(): boolean {
    return this.allSerials.findIndex(s =>
      (s.serialNumber == this.jafSerialControl.value || s.jafSerial == this.jafSerialControl.value)
      && this.selectedItem.itemId != s.itemId && this.editObj?.serialNumber != s.serialNumber) > -1;
  }

  get factorySerialControl() {
    return this.form.get('factorySerial');
  }
  get serialsQtyControl() {
    return this.form.get('serialsQty');
  }
  get jafSerialControl() {
    return this.form.get('jafSerial');
  }
  get usageStatusControl() {
    return this.form.get('usageStatus');
  }
  get usageDateControl() {
    return this.form.get('usageDate');
  }
  get noteControl() {
    return this.form.get('note');
  }
  get numberOfBulletsUsedControl() {
    return this.form.get('numberOfBulletsUsed');
  }
  get parentSerialControl() {
    return this.form.get('parentSerial');
  }

}
