import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RootHeaderComponent } from 'src/app/content/root-header/root-header.component';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import * as XLSX from 'xlsx';
import { ArmoryOperationsService } from '../armory-operations.service';
import { OperationMainModel } from 'src/app/shared/model/warehouse-operation-models/operation-main.model';
import { OperationDetailsModel } from 'src/app/shared/model/warehouse-operation-models/operation-details.model';
import { ItemDetailsModel } from 'src/app/shared/model/item-models/item-details.model';


@Component({
  selector: 'app-warehouse-operation-import-dialog',
  templateUrl: './warehouse-operation-import-dialog.component.html',
  styleUrls: ['./warehouse-operation-import-dialog.component.css']
})
export class WarehouseOperationImportDialogComponenet implements OnInit {

  form: FormGroup;

  locationsList: LocationModel[] = [];
  isLocationsLoading: boolean = false;

  excelData: any[] = [];

  uploadedFileName = 'Desc_NoFileUploaded';

  warehouseOperation: OperationMainModel = new OperationMainModel();

  constructor(public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private whOperationsService: ArmoryOperationsService,
    private coreSession: CoreSession,) { }


  ngOnInit(): void {
    this.initForm();
    this.getWarehouses();
  }

  initForm() {
    this.form = new FormGroup({
      warehouse: new FormControl(undefined, Validators.required),
    });
  }

  getWarehouses() {
    this.isLocationsLoading = true;

    this.whOperationsService.getRequiredLists(this.data.operationTypeId).subscribe(res => {

      //console.log(res);

      this.locationsList = res.armoryList ?? [];

      if (this.locationsList && this.locationsList.length > 0) {
        this.warehouseControl.setValue(this.locationsList[0].locationId);
      }

      this.isLocationsLoading = false;

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  public readExcel(event: any) {
    const files = event.srcElement.files;
    if (files.length > 0) {
      const file: File = files[0];

      if (
        file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        || file.type.match("application/vnd.ms-excel")
        || file.type.match("text/csv")
      ) {

        this.uploadedFileName = file.name;

        const target: DataTransfer = <DataTransfer>(<unknown>event.target);
        if (target.files.length !== 1) {
          throw new Error('Cannot use multiple files');
        }
        this.uploadedFileName = target.files[0].name;
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {

          const binarystr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

          let wsname: string = wb.SheetNames[0];
          // if (this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
          //   this.coreSession.selectedInventorySolution == InventorySolution.Dubai.valueOf()) {
          //     wsname = 'Sheet1';
          // }
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          this.excelData = XLSX.utils.sheet_to_json(ws);

        };
      } else {
        this.uploadedFileName = "Desc_NoFileUploaded";
        throw new Error('File should be an excel sheet');
      }

    }
  }

  onSaveClicked() {
    if (this.form.invalid || this.excelData.length == 0) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.prepareObj();

    this.coreSession.ModalLoading.Show();

    this.whOperationsService.insertArmoryOperation(this.warehouseOperation).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data == -1) {
          this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
        } else {
          this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));

          this.dialogRef.close(1);
        }
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });

  }

  prepareObj() {
    this.warehouseOperation = new OperationMainModel();

    this.warehouseOperation.transactionId = '';
    this.warehouseOperation.warehouseOperationTypeId = this.data.operationTypeId;
    this.warehouseOperation.warehouseId = this.warehouseControl.value;
    this.warehouseOperation.operationStatusId = 1;
    this.warehouseOperation.reasonId = -1;
    this.warehouseOperation.creationDateModel = {
      date: new Date().toISOString(),
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      hour: new Date().getHours(),
      minute: new Date().getMinutes()
    };

    this.excelData.slice().forEach(
      r => {
        const operationDetailsObj = new OperationDetailsModel();

        operationDetailsObj.itemId = -1;
        operationDetailsObj.itemCode = r['ItemNumber'] && r['ItemNumber'] != null ? r['ItemNumber'].toString() : '';
        operationDetailsObj.quantity = 1;
        operationDetailsObj.warehouseId = this.warehouseControl.value;

        let index = this.warehouseOperation.operationDetails.findIndex(d => d.itemCode == operationDetailsObj.itemCode);
        if(index > -1) {
          this.warehouseOperation.operationDetails[index].quantity++;
        } else {
          this.warehouseOperation.operationDetails.push(operationDetailsObj);
        }

        const serialDetailsObj = new ItemDetailsModel();
        
        serialDetailsObj.itemId = -1;
        serialDetailsObj.itemCode = r['ItemNumber'] && r['ItemNumber'] != null ? r['ItemNumber'].toString() : '';
        serialDetailsObj.serialNumber = r['SerialNumber'] && r['SerialNumber'] != null ? r['SerialNumber'].toString() : '';
        serialDetailsObj.currentStatusId = this.data.operationTypeId == 1 ? 1 : 5;
        serialDetailsObj.oldStatusId = this.data.operationTypeId == 1 ? -1 : 1;

        this.warehouseOperation.itemDetails.push(serialDetailsObj);
      }
    );

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get warehouseControl() {
    return this.form.get('warehouse');
  }

}