import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weapons-summary-report',
  templateUrl: './weapons-summary-report.component.html',
  styleUrls: ['./weapons-summary-report.component.css']
})
export class WeaponsSummaryReportComponent implements OnInit {

  gridReportURL: string = '';

  constructor() { }

  ngOnInit(): void {
    this.gridReportURL = 'http://localhost:8080/API/Report/ShowGrid?id=';
  }

}
