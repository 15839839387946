import { Component, OnInit } from '@angular/core';
import { ClientsDynamicDataModel } from 'src/app/shared/model/client-models/client-dynamic-data-model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { ClientOperationService } from '../../client-operation.service';

@Component({
  selector: 'app-client-operation-dynamic-data',
  templateUrl: './client-operation-dynamic-data.component.html',
  styleUrls: ['./client-operation-dynamic-data.component.css']
})
export class ClientOperationDynamicDataComponent implements OnInit {
  clientDynamicList: ClientsDynamicDataModel[] = [];
  clientDynamicObj: ClientsDynamicDataModel = new ClientsDynamicDataModel();

  dataSource1List: ClientsDynamicDataModel[] = [];
  dataSource2List: ClientsDynamicDataModel[] = [];

  tableData: ITableProperties = {
    pageSize: 100,
    showPaginator: false,
    showSearch: false,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Field_Name",
        key: "fieldName",
        isSortable: false,
        width: "50%"
      },
      {
        title: "Desc_Field_Value",
        key: "fieldValue",
        isSortable: false,
        width: "50%"
      }
    ]
  };


  constructor(private clientOperationService: ClientOperationService) { }

  ngOnInit(): void {
    this.fillClientDynamicData();
  }

  fillClientDynamicData() {
    let counter = 0;
    this.clientDynamicList = this.clientOperationService.fillClientsDynamicList();

    if (this.clientDynamicList && this.clientDynamicList.length > 0) {
      this.clientDynamicList.forEach(x => {
        if (counter % 2 == 0) {
          this.dataSource1List.push(x);
        } else {
          this.dataSource2List.push(x);
        }
        counter++;
      });
    }
  }
}
