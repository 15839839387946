import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CoreSession } from "src/app/core/core.session";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { InsertRouteModel } from "src/app/shared/model/route-model/route-model";
import { RouteStationsAssignmentModel } from "src/app/shared/model/route-stations-model/route-stations-assignment-model";

@Injectable({
  providedIn: 'root'
})
export class RouteStationsService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: String,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'RouteStations';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getRoutesList(filter: any) {
    const urlValue: string = this.url + '/GetRoutesList';
    return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  getRouteStationsAssignmentData(selectedStaffId: number) {
    // //console.log(selectedStaffId, "STAFF ID");

    const urlValue: string = this.url + '/GetRouteStationsAssignmentData?selectedStaffId=' + selectedStaffId;
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return response;
    }
    ));
  }

  getAssignedStations(stationsFilter: any) {
    const urlValue: string = this.url + '/GetAssignedStations';
    return this.http.post(urlValue, JSON.stringify(stationsFilter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      })
    );
  }

  getRouteData(id: number) {
    const urlValue: string = this.url + '/GetRouteData?id=' + id;
    return this.http.get(urlValue, this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      })
    );
  }

  getUnassignedStations(stationsFilter: any) {
    const urlValue: string = this.url + '/GetUnassignedStations';
    return this.http.post(urlValue, JSON.stringify(stationsFilter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      })
    );
  }

  unassignStations(routeStationsAssignment: RouteStationsAssignmentModel) {
    const urlValue: string = this.url + '/UnassignStations';
    return this.http.post(urlValue, JSON.stringify(routeStationsAssignment), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  assignStations(routeStationsAssignment: RouteStationsAssignmentModel, isEditMode: boolean) {
    const urlAction = isEditMode ? '/UpdateRouteStations' : '/AssignStations';
    const urlValue: string = this.url + urlAction;
    return this.http.post(urlValue, JSON.stringify(routeStationsAssignment), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  deleteRouteData(id: number) {
    const urlValue: string = this.url + '/DeleteRouteData?id=' + id;
    return this.http.get(urlValue, this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];
        return res;
      })
    );
  }
}