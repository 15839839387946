export enum ReportFilterTypes {
    Date = 1,
    Lookup = 2,
    LookUp_WithoutAll = 3,
    Date_Min = 4,
    Date_Max = 5,
    Text = 6,
    NumericDate = 7,
    NumericDate_Min = 8,
    NumericDate_Max = 9,
    DateTime_Min = 10,
    DateTime_Max = 100
}
export enum ReportFilterCaption {
    Date = 0,
    Lookup,
    Text,
    NumericDate
} 
