export const ConstantURLs = {
  searchResultURL: "search-result",
  homeUrl: "home",
  home2Url: "home2",
  //Sidebar urls
  dataDefinitionURL: "data-definition",

  dashboardURL: "dashboard",
  reportURL: "report",
  settingsURL: "setting",
  documentOperationURL: "document-operation",
  routeStationsURL: "route-stations",
  routeAssignmentListURL: "route-assignment-list",
  //Data Definitions urls
  staffURL: "staff",
  productUrl: "product",
  dynamicFormMainURL: "dynamic-form",
  vehicleListURL: "vehicle-list",
  locationListURL: "location-list",
  locationTypeListURL: "location-type-list",
  staffListURL: "staff-list",
  //Document Operations
  parcelCreationURL: "parcel-creation",
  parcelMovementURL: "parcel-movement",
  parcelDispatchURL: "parcel-dispatch",
  parcelCheckInURL: "parcel-check-in",
  parcelCheckOutURL: "parcel-checkout",
  parcelTrackingURL: "parcel-tracking",
  parcelCancellationURL: "parcel-cancellation",
  documentTrackingURL: "document-tracking",
  //Dynamic Form Operations
  dynamicFormListURL: "dynamic-form-list",
  lookUpListURL: "look-up-list",
  inspectionsListURL: "inspections-list",
  dynamicFormOperationURL: "dynamic-form-operation",

  samplesURL: "samples",
  sampleListURL: "sample-list",
  sampleTypeListURL: "sample-type-list",
  //Settings
  securityGroupsURL: "security-group-privilege",
  staffPrivilegeURL: 'staff-privilege',
  emailConfigurationsURL: 'email-configurations',

  loginURL: "login",
  noMenusURL: "no_menus",

  ticketsURL: "tickets",
  ticketListURL: "tickets-history-list",

  armoryListURL: "armory-list",
  categoryURL: "category",
  itemsDefinitionURL: 'items-definition',
  itemListURL: "item-list",
  taskListURL: "task-list",
  itemTypeListURL: "item-type-list",

  //Armory Operations
  armoryOperationsURL: "armory-operations",
  inBoundOperationsURL: "inbound-operations",
  outBoundOperationsURL: "outbound-operations",
  approvedInboundURL: "approved-inbound",
  approvedOutboundURL: "approved-outbound",
  executedInboundURL: "executed-inbound",
  executedOutboundURL: "executed-outbound",
  warehouseOperationsURL: "warehouse-operations",
  rejectedInboundURL: "rejected-inbound",
  rejectedOutboundURL: "rejected-outbound",


  //Orders Management
  ordersManagementURL: "orders-management",
  pendingOrdersURL: "pending-orders",
  approvedOrdersURL: "approved-orders",
  assignedOrdersURL: "assigned-orders",
  ordersHistoryURL: "orders-history",
  activeOrdersURL: "active-orders",
  changeRequestsURL: "change-requests",
  missingItemsURL: "missing-items",
  cancelledOrdersURL: "cancelled-orders",

  //Guests
  guestsMainURL: 'number-of-guests',
  guestsDataURL: 'number-of-guests-data',
  guestsURL: 'guests',

  suppliersListURL: 'suppliers-list',

  //Stock Counting
  locationOperationsURL: 'location-operations',
  stockCountingURL: 'stock-counting',
  stockCountingHistoryURL: 'stock-counting-history',

  //Vehicle Operations
  vehicleOperationsURL: "vehicle-operations",
  vehicleAssignmentURL: "vehicle-assignment",
  vehicleReAssignmentURL: "vehicle-reasignment",
  vehicleMaintenanceURL: "vehicle-maintenance",
  vehicleRetirementURL: "vehicle-retirement",
  vehicleHistoryURL: "vehicle-history",

  //Attendance Management
  attendanceManagementURL: 'attendance-management',
  dailyAttendanceURL: 'daily-attendance',
  pendingStudents: 'pending-students',
  approvedStudentsURL: 'approved-students',
  rejectedStudentsURL: 'rejected-students',
  deactivatedStudentsURL: 'deactivated-students',
  documentsURL: 'documents',
  studentRegisterationURL: 'student-registration',

  uomDefinitionURL: 'uom-definition',

  //Lectures
  lecturesURL: 'lectures',
  lecturesScheduleURL: 'lectures-schedule',
  pendingAttendeesURL: 'pending-attendees',
  lecturesReportURL: 'lectures-report',
  lectureDefinitionURL: 'lecture-definition',
  programDefinitionURL: 'program-defintion',

  mapURL: 'map',
  studentsReportURL: 'students-report',
  studentsFeedbackReportURL: 'students-feedback-report',
  organizersAttendanceReportURL: 'organizers-attendance-report',
  stockCountingReportURL: 'stock-counting-report',
  assetsReportURL: 'assets-report',
  sessionsAttendanceReportURL: 'sessions-attendance-report',
  weaponsSummaryReportURL: 'weapons-summary',
  ordersReportURL: 'orders-report',
  inboundReportURL: 'inbound-report',
  outboundReportURL: 'outbound-report',

  timeSlotsURL: 'time-slots',

  facilityManagementURL: 'facility-management',

  homeURL: 'home',
  weaponsDashboardURL: 'weapons-dashboard',
  orderssDashboardURL: 'orders-dashboard',

  farmActivitiesURL: "farm-activities",
  pruningInspectionURL: 'pruning-inspections',
  irrigationInspectionURL: 'irrigation-inspections',
  fertilizationInspectionURL: 'fertilization-inspections',
  pestTreatmentInspectionURL: 'pest-treatment-inspections',
  visualInspectionURL: 'visual-inspections',
  plowingInspectionURL: 'plowing-inspections',
  plantingInspectionURL: 'planting-inspections',
  schedulingInspectionURL: 'scheduling-inspections',
  harvestInspectionURL: 'harvest-inspections',
  dispatchInspectionURL: 'dispatch-inspections',
  processInspectionURL: 'process-inspections',

  homePageURL: 'home-page',

  ordersURL: 'orders',
  ordersListURL: 'orders-list',

  staffGroupsURL: 'staff-groups',
};
