import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { DataDefinitionService } from '../../data-definition.service';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryModel } from 'src/app/shared/model/armory-models/category.model';
import { CategoryTypeModel } from 'src/app/shared/model/armory-models/category-type.model';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { Solution } from 'src/app/shared/enum/solution.enum';


@Component({
  selector: 'app-category-entry',
  templateUrl: './category-entry.component.html',
  styleUrls: ['./category-entry.component.css']
})
export class CategoryEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedCategoryData: CategoryModel;
  @Input() isEditMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  isKasotcSelected: boolean = false;
  formData: FormData;
  selectedCategoryImage: File;
  categoryObj: CategoryModel;
  saveBtnSubscription: any;
  form: FormGroup;
  otherLanguages: any[] = [];
  name: ITextInput = {
    formControlName: "categoryName",
    placeHolder: "Desc_CategoryName",
    label: "Desc_CategoryName",
    isRequired: true
  };

  defaultImg = '';
  imagePath: string;

  parentCategoriesList: CategoryModel[] = [];
  categoryTypesList: CategoryTypeModel[] = [];

  isUsed: boolean = false;

  constructor(
    private categoryService: DataDefinitionService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.defaultImg = this.coreSession.selectedLanguageId == 1 ? '../../../../../assets/img/add-image.jpg' : '../../../../../assets/img/add-image-ar.jpg'

    if(this.isEditMode) {
      this.isUsed = this.selectedCategoryData.isUsed;
    }

    this.initForm();
    this.getCategoryRequiredLists();
    this.subscribeSaveClick();

    this.isKasotcSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf()  || this.coreSession.selectedSolutionId == Solution.CIT.valueOf();
  }

  getCategoryRequiredLists() {
    //debugger
    this.categoryService.getCategoryRequiredLists().subscribe(res => {
      //console.log(res, 'GetCategoryRequiredLists');

      this.parentCategoriesList = res.parentCategoriesList;
      if(this.isEditMode) {
        this.parentCategoriesList = this.parentCategoriesList.filter(c => c.categoryId != this.selectedCategoryData.categoryId)
      }
      this.categoryTypesList = res.categoryTypesList;
//debugger
      if (this.isEditMode) {
        this.getCategoryData();
      }

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  addFile(event: any) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.get('categoryImage').updateValueAndValidity();
    this.coreSession.checkImageSize(event);
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {

      this.imagePath = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let categoryId = this.isEditMode ? this.selectedCategoryData.categoryId : -1;
    let categoryName = this.isEditMode ? this.selectedCategoryData.categoryName : "";
    let categoryCode = this.isEditMode ? this.selectedCategoryData.categoryCode : "";
    let inactive = this.isEditMode ? this.selectedCategoryData.inactive : false;

    this.form = new FormGroup({
      categoryId: new FormControl(categoryId, Validators.required),
      categoryName: new FormControl(categoryName, Validators.required),
      categoryCode: new FormControl(categoryCode, Validators.required),
      categoryTypeId: new FormControl(undefined, this.isKasotcSelected ? Validators.required : []),
      categoryParentId: new FormControl(undefined),
      inactive: new FormControl(inactive),
      isParent: new FormControl(false),
      categoryImage: new FormControl()
    });

    this.imagePath = this.defaultImg;

  }

  onIsParentChange() {
    //debugger;
    if(this.isEditMode && !this.isParentControl.value && this.isUsed) {
      this.coreSession.showWarning(
        this.translateService.instant(ConstantMessages.LblWarningCaption),
        this.translateService.instant('Desc_DeleteUsedCategoryWarning')
      );
    }
    if(this.isParentControl.value) {
      this.categoryParentIdControl.setValue(undefined);
      this.categoryParentIdControl.disable();
    } else {
      this.categoryParentIdControl.enable();
    }
  }

  isParentChanged() {
    if(this.isParentControl.value) {
      this.categoryParentIdControl.setValue(undefined);
      this.categoryParentIdControl.disable();
    } else {
      this.categoryParentIdControl.enable();
    }
  }

  getCategoryData() {
    this.coreSession.ModalLoading.Show();
    this.categoryService.getCategoryData(this.selectedCategoryData.categoryId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.selectedCategoryData = response.data;

        //console.log(this.selectedCategoryData, 'selectedCategoryData');

        this.categoryTypeIdControl.setValue(this.selectedCategoryData.categoryTypeId);
        this.categoryParentIdControl.setValue(this.selectedCategoryData.parentId && this.selectedCategoryData.parentId != -1 ? this.selectedCategoryData.parentId : undefined);
        this.imagePath = this.selectedCategoryData.categoryImage && this.selectedCategoryData.categoryImage != '' ? this.selectedCategoryData.categoryImage : this.defaultImg;
        this.inactiveControl.setValue(this.selectedCategoryData.inactive);
        this.isParentControl.setValue(this.selectedCategoryData.isParent);

        this.isParentChanged();

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  clearImage() {
    this.imagePath = this.defaultImg;
  }

  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareCategoryObj() {
    this.categoryObj = new CategoryModel();
    this.categoryObj.categoryId = +this.categoryIdControl.value;
    this.categoryObj.categoryName = this.categoryNameControl.value;
    this.categoryObj.inactive = this.inactiveControl.value;
    this.categoryObj.categoryImage = this.imagePath != this.defaultImg ? this.imagePath : '';
    this.categoryObj.categoryCode = this.categoryCodeControl.value;
    this.categoryObj.categoryTypeId = this.categoryTypeIdControl.value != null ? this.categoryTypeIdControl.value : -1;
    this.categoryObj.parentId = this.categoryParentIdControl.value ? this.categoryParentIdControl.value : -1;
    this.categoryObj.isParent = this.isParentControl.value;

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.categoryNameControl.value
      });
    }
    descriptions.forEach((desc, i) => {
      if (desc.description == '') {
        descriptions[i].description = this.categoryNameControl.value;
      }
    });
    this.categoryObj.descriptions = descriptions;
  }

  onSaveClicked() {
    //console.log(this.form.value);

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.prepareCategoryObj();

    if (this.categoryObj != null) {
      //console.log(this.categoryObj, 'preparedObj');
      this.coreSession.ModalLoading.Show();
      this.categoryService.insertCategory(this.categoryObj, this.isEditMode).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data > -1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.categoryObj.categoryId = response.data;
            this.onSaveCompleted.emit(this.categoryObj);
          }
          else {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (_error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  get categoryIdControl() {
    return this.form.get('categoryId');
  }

  get categoryNameControl() {
    return this.form.get('categoryName');
  }

  get categoryCodeControl() {
    return this.form.get('categoryCode');
  }

  get categoryTypeIdControl() {
    return this.form.get('categoryTypeId');
  }

  get categoryParentIdControl() {
    return this.form.get('categoryParentId');
  }

  get isParentControl() {
    return this.form.get('isParent');
  }

  get inactiveControl() {
    return this.form.get('inactive');
  }

  get categoryImageControl() {
    return this.form.get('categoryImage');
  }

}
