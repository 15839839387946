import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from 'src/app/shared/model/dateTimeModel/date-time.model';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.css'],
})
export class DateTimePickerComponent implements OnInit, OnChanges {
  @Input() hideLabel = false;
  @Input() dateTimeProperties: IDateTimePickerProperties;
  @Input() parentForm: FormGroup;
  @Input() selectCurrentDate: boolean = false;
  @Input() setFirstLastDaysInDateRange: boolean = false;
  @Input() error: boolean  = false;

  @Output() dateSelected = new EventEmitter();
  @Input() isRequired = false;
  dateTimeRange: Date[];
  selectedDateValue;
  startAt = new Date();
  pickerType = 'both';
  selectMode = 'single';

  constructor() {
  }

  ngOnChanges() {
    if (
      this.dateTimeProperties.dateValue &&
      this.dateTimeProperties.dateValue.date
    ) {
      this.parentForm
        .get(this.dateTimeProperties.formControlName)
        .setValue(this.dateTimeProperties.dateValue.date);
      this.selectedDateValue = this.dateTimeProperties.dateValue.date;
    }

    if (this.dateTimeProperties.isCalendarOnly) {
      this.pickerType = 'calendar';
    } else if (this.dateTimeProperties.isTimerOnly) {
      this.pickerType = 'timer';
    }
    if (this.dateTimeProperties.isRange) {
      this.selectMode = 'range';
    }
  }
  ngOnInit() {
    if (this.parentForm.get(this.dateTimeProperties.formControlName).validator != null) {
      const validator = this.parentForm.get(this.dateTimeProperties.formControlName).validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
    if (this.dateTimeProperties.isCalendarOnly) {
      this.pickerType = 'calendar';
    } else if (this.dateTimeProperties.isTimerOnly) {
      this.pickerType = 'timer';
    }
    if (this.dateTimeProperties.isRange) {
      this.selectMode = 'range';
    }
    if (
      this.dateTimeProperties.dateValue &&
      this.dateTimeProperties.dateValue.date
    ) {
      this.parentForm
        .get(this.dateTimeProperties.formControlName)
        .setValue(this.dateTimeProperties.dateValue.date);
      this.selectedDateValue = this.dateTimeProperties.dateValue.date;
    }

    if (this.dateTimeProperties.min && this.dateTimeProperties.isCalendarOnly) {
      this.dateTimeProperties.min.setDate(this.dateTimeProperties.min.getDate());
      this.dateTimeProperties.min.setHours(0);
      this.dateTimeProperties.min.setMinutes(0);
      this.dateTimeProperties.min.setSeconds(0);
    }
    if (this.dateTimeProperties.isCalendarOnly && this.selectCurrentDate) {
      var date = new Date();
      this.parentForm.get(this.dateTimeProperties.formControlName).setValue(date);
      this.dateSelected.emit(this.getDateTimeModel(date));
    }
  }
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  onDateSelected(event) {
    if (!this.dateTimeProperties.isRange) {
      this.selectedDateValue = this.parentForm.get(this.dateTimeProperties.formControlName).value;
      if (this.selectedDateValue) {
        let date = new DateTimeModel();
        date = this.getDateTimeModel(this.selectedDateValue);
        this.dateSelected.emit(date);
      }
    } else {
      if (this.setFirstLastDaysInDateRange) {
        this.dateTimeRange = this.parentForm.get(this.dateTimeProperties.formControlName).value;
        if (this.dateTimeRange && this.dateTimeRange.length == 2) {
          this.dateTimeRange[0].setDate(1);
          this.dateTimeRange[1].setDate(this.daysInMonth(this.dateTimeRange[1].getMonth() + 1, this.dateTimeRange[1].getFullYear()));
          this.parentForm.get(this.dateTimeProperties.formControlName).setValue(this.dateTimeRange);
        }
      }
      this.dateTimeRange = this.parentForm.get(this.dateTimeProperties.formControlName).value;
      if (this.dateTimeRange) {
        let fromDate = new DateTimeModel();
        let toDate = new DateTimeModel();
        const selectedRange: DateTimeModel[] = [];

        fromDate = this.getDateTimeModel(this.dateTimeRange[0]);
        selectedRange.push(fromDate);
        if (this.dateTimeRange[1]) {
          toDate = this.getDateTimeModel(this.dateTimeRange[1]);
          selectedRange.push(toDate);
        }
        this.dateSelected.emit(selectedRange);
      }

    }

  }
  checkDate(tt) {
    if (tt && tt.code) {
      if (tt.code === 'Backspace') {
        this.selectedDateValue = '';
        this.parentForm
          .get(this.dateTimeProperties.formControlName)
          .setValue(this.selectedDateValue);
      } else if (tt.code === 'Tab') {
        return true;
      }
    }
    return false;
  }

  getDateTimeModel(selectedDateValue): DateTimeModel {
    let selectedDate = new Date(selectedDateValue);
    const date = new DateTimeModel();
    (selectedDate);
    date.year = selectedDate.getFullYear();
    date.month = selectedDate.getMonth() + 1;
    date.day = selectedDate.getDate();
    date.minute = selectedDate.getMinutes();
    date.hour = selectedDate.getHours();
    date.date = selectedDate;
    return date;
  }
  //  getRangeDateTimeModel(selectedDateValue): DateTimeModel {
  //    const date = new DateTimeModel();
  //    date.year = selectedDateValue.getFullYear();
  //    date.month = selectedDateValue.getMonth() + 1;
  //    date.day = selectedDateValue.getDate();
  //    date.date = selectedDateValue.setDate(selectedDateValue.getDate() + 1);
  //    return date ;
  //  }

  change() {
    this.dateSelected.emit();
  }
}
