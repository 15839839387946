import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ClientOperationStatus } from 'src/app/shared/enum/client-operation-status.enum';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { ClientOperationModel } from 'src/app/shared/model/client-models/client-operation-model';
import { ClientOperationService } from '../../client-operation.service';

@Component({
  selector: 'app-client-operation-main',
  templateUrl: './client-operation-main.component.html',
  styleUrls: ['./client-operation-main.component.css']
})
export class ClientOperationMainComponent implements OnInit {

  clientId: number = -1;
  clientOperationModel: ClientOperationModel;
  selectedClient: ClientModel;
  selectedClientOperationModel: ClientOperationModel;

  constructor(private activatedRoute: ActivatedRoute,
    private clientOperationService: ClientOperationService,
    private coreSession: CoreSession,
    private translateService: TranslateService) {
    this.activatedRoute.params.subscribe(params => {
      this.clientId = params['id'];
    });
  }

  ngOnInit(): void {
    if (this.clientId == -1) {
      this.startClientOperation();
    } else {
      this.fillSelectedClientData();
    }
  }

  fillSelectedClientData() {
    this.clientOperationService.getSelectedClientData(this.clientId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response && response.clientId > 0) {
        this.selectedClient = response;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    });
  }

  startClientOperation() {
    this.coreSession.ModalLoading.Show();
    this.clientOperationService.getClientDataToStart().subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response && response.clientBasicData && response.clientBasicData.clientId > 0) {
        this.selectedClientOperationModel = response;
        this.selectedClient = response.clientBasicData;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    });
  }

  onProcessedClicked() {
    this.selectedClientOperationModel.operationStatusId = ClientOperationStatus.Processed.valueOf();
    this.clientOperationService.updateClientOperationStatus(this.selectedClientOperationModel).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
        this.startClientOperation();
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onNotProcessedClicked() {
    this.selectedClientOperationModel.operationStatusId = ClientOperationStatus.NotProcessed.valueOf();
    this.clientOperationService.updateClientOperationStatus(this.selectedClientOperationModel).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onReportIssueClicked() {

  }
}
