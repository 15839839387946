import { element } from 'protractor';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CssSelector } from '@angular/compiler';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITextInput } from '../../model/shared-table/custom-list.interface';
import { TextLanguage } from '../../enum/general/textLanguage.model';
import { CoreSession } from 'src/app/core/core.session';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';

@Component({
  selector: 'app-text-input-languages',
  templateUrl: './text-input-languages.component.html',
  styleUrls: ['./text-input-languages.component.css']
})
export class TextInputLanguagesComponent implements OnInit {
  //#region [DECLARATIONS=]

  @Output() onTextChanged = new EventEmitter();
  @Output() onFocusIn = new EventEmitter()
  @Input() hideLabel = false;
  @Input('parentForm') parentForm: FormGroup;
  @Input('controlProperties') controlProperties: ITextInput;
  @Input('oldValues') oldValues: TextLanguage[] = [];
  @Input('disabled') disabled: boolean = false;
  @Output('textLanguagesSubmitted') textLanguagesSubmitted = new EventEmitter();
  @Input('height') minHeight: number = 125;
  @Input('maximumHeight') maxHeight: number = 200;
  @Input('prepareLanguagesListInOnChange') prepareLanguagesListInOnChange:boolean=true;
  @ViewChild("insertNewLanguage", { static: true }) insertNewLanguage: NgbModal;

  isLanguagesCollapsed = true;
  otherLanguages: TextLanguage[] = [];
  selectedLanguage: TextLanguage[] = [] ;
  textLanguagesForm: FormGroup;
  isEditMood = false;
  direction : any ;

  //#endregion

  //#region [CONSTRUCTOR]

  constructor(
    private coreSession: CoreSession,
    private sessionDataProvider: SessionDataProvider,
    private fb: FormBuilder,
    private modalService: NgbModal) {
  }
  ngOnInit() {
    this.direction = this.coreSession.isRTL ;
  }
  ngOnChanges() {
    if (this.oldValues) {
      this.isEditMood = true;
    }
    this.sessionDataProvider.getActiveLanguages().map(language => {
      if (language.languageId != this.coreSession.selectedLanguageId &&
        this.otherLanguages.filter(a => a.languageId == language.languageId).length <= 0) {
        this.otherLanguages.push({ ...language });
      }else if (language.languageId == this.coreSession.selectedLanguageId &&
        this.selectedLanguage.filter(a => a.languageId == language.languageId).length <= 0) {
          this.selectedLanguage.push({ ...language });
      }
    });
    this.textLanguagesForm = this.createGroup();
    if(this.prepareLanguagesListInOnChange)
    this.prepareLanguagesList();

  }

  //#endregion

  //#region [METHODS]

  createGroup() {
    const group = this.fb.group({});
    this.otherLanguages.forEach(language => {
      let value = '';
      if (this.isEditMood) {
        if (this.oldValues.filter(old => old.languageId === language.languageId).length > 0) {
          value = this.oldValues.filter(old => old.languageId === language.languageId)[0].description;
        }
      }
      group.addControl(language.languageId.toString(), this.fb.control({value: value, disabled: this.disabled}));
    });
    return group;
  }

  openAddLanguages() {
    this.isLanguagesCollapsed = !this.isLanguagesCollapsed;
   //this.modalService.open(this.insertNewLanguage, { centered: false,windowClass: "modal-height", size : 'sm'}).result.then();
  }

  prepareLanguagesList() {
    const mapped = Object.keys(this.textLanguagesForm.value).map(key => ({
      languageId: +key,
      description: this.textLanguagesForm.value[key]
    }));
    this.textLanguagesSubmitted.emit(mapped);
  }

  //#endregion

  //#region [EVENTS]

  onAddClicked() {
   this.isLanguagesCollapsed = true;
    if (this.disabled) return ;
    this.prepareLanguagesList();
   // this.modalService.dismissAll();
  }
  dismiss(){
    this.isLanguagesCollapsed = !this.isLanguagesCollapsed;
  }
  onFocusOutEvent(event: any){
    if(event && event.target && event.target.value && event.target.value != "" && this.controlProperties.addLabelInTextField){
      let insertedData = this.parentForm.get(this.controlProperties.formControlName).value;
      this.parentForm.get(this.controlProperties.formControlName).setValue(this.controlProperties.label + ": " + insertedData);
    }
 }
 onFocusInEvent(event: any){
   
  if(event && event.target && event.target.value && event.target.value != "" && this.controlProperties.addLabelInTextField){
    let insertedData = this.parentForm.get(this.controlProperties.formControlName).value;
    this.parentForm.get(this.controlProperties.formControlName).setValue(insertedData.replace(this.controlProperties.label + ": ", ""));
  }
  this.onFocusIn.emit(event);
}
  //#endregion
}
