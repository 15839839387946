import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { InvoiceModel } from 'src/app/shared/model/invoice-model/invoice-model';
import { CustomListFilter } from 'src/app/shared/model/shared-table/custom-list.interface';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { InvoiceService } from '../invoice.service';
import { Subject, Observable } from 'rxjs';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';
import { AssignDialogComponent } from 'src/app/shared/components/assign-dialog/assign-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  saveSubject: Subject<void> = new Subject<void>();
  isViewModeSelected = false;
  showSlider = false;
  data: InvoiceModel[];
  selectedInvoiceObj: InvoiceModel;
  invoicesIds: string[] = [];

  invoiceFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    employeeCodeName: '',
    employeeTypeId: -1,
    organizationId: -1,
    vehicleId: -1,
    divisionId: -1,
    deviceSerial: '',
    securityGroupId: -1,
    includeInactive: false,
    employeeId: -1
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_InvoiceId",
        key: "invoiceId",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_InvoiceDate",
        key: "invoiceDate",
        isSortable: false,
        width: "20%",
      },
      {
        title: "Desc_InvoiceType",
        key: "invoiceTypeDescription",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_ClientName",
        key: "clientDescription",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_NetTotal",
        key: "netTotal",
        isSortable: false,
        width: "20%"
      },
      // {
      //   title: "Desc_Tax",
      //   key: "tax",
      //   isSortable: false,
      //   width: "12.5%"
      // },
      // {
      //   title: "Desc_Discount",
      //   key: "discount",
      //   isSortable: false,
      //   width: "12.5%"
      // },
      // {
      //   title: "DESC_Status",
      //   key: "isInvoiceVoided",
      //   isSortable: false,
      //   width: "12.5%",
      // },
    ]
  };
  sharedTableFilter: CustomListFilter = {
    searchFilter: '',
    page: 0,
    pageSize: this.coreSession.pageSize
  };


  constructor(private coreSession: CoreSession, private invoiceService: InvoiceService, private translateService: TranslateService,
    private dialog: MatDialog, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.addActions();
    this.populateInvoiceList();
  }

  addActions() {
    if (this.coreSession.checkActivitiesAvailability('invoice', MenuActions.Add)) {
      this.tableData.showNewBtn = true
    }
    if (this.coreSession.checkActivitiesAvailability('invoice', MenuActions.Void)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.Void,
          title: "Void",
          icon: "fa fa-close",
          color: "#f1685e"
        },
      );
    }
    if (this.coreSession.checkActivitiesAvailability('invoice', MenuActions.View)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.View,
          title: "View",
          icon: "fa fa-eye",
          color: "#12344d"
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability('invoice', MenuActions.Adjust)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.Adjust,
          title: "Desc_Assign",
          icon: "fa fa-user-plus",
          color: "black"
        }
      );
    }
  }

  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.openSliderToView(event.object);
        break;
      case RowOperation.Void:
        this.onVoidClicked(event);
        break;
      case RowOperation.Adjust:
        this.onAssignClicked(event.object);
        break;
    }
  }

  onTableFilterChanged(query: any) {
    this.sharedTableFilter = query;
    // this.populatePackGroups();
  }

  onVoidClicked(event: any) {
    //console.log(event, "void event");
    this.invoiceService.updateInvoiceActiveStatus(event.object).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
        this.populateInvoiceList();
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onAssignClicked(object: any) {
    const dialogRef = this.dialog.open(AssignDialogComponent, {
      width: '350px',
      height: '250px',
      data: {
        assignedTo: object.assignedTo ?? -1,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result != null && result != -1) {
        this.coreSession.ModalLoading.Show();
        this.invoiceService.assignInvoice(object.invoiceId, result.assignedTo).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
            this.onSaveCompleted(1);
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
          }
        },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          });
      }
    });

  }


  openSliderToAdd(val: any) {
    this.selectedInvoiceObj = null;
    this.isViewModeSelected = false;
    this.showSlider = true;
  }
  openSliderToView(val: any) {
    this.selectedInvoiceObj = val;
    this.isViewModeSelected = true;
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
  }

  onSaveClick() {
    this.saveSubject.next();

  }

  onInvoiceSaved() {
    this.closeSlider();
    this.populateInvoiceList();
  }

  onSaveCompleted(val: any) {
    this.onInvoiceSaved();
  }

  populateInvoiceList() {
    this.coreSession.ModalLoading.Show();
    this.invoiceFilter.employeeId = 0;
    this.invoiceService.getInvoiceList().subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response != null) {
        if (response.length <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
        this.dataSource.data = response.map(
          r => {
            return {
              ...r,
              invoiceDate: r.invoiceDate = this.datePipe.transform(r.invoiceDate, 'dd-MM-yyyy'),
            }
          }
        );
        this.dataSource.pageData = response;
        this.dataSource.totalItems = this.dataSource.data.length;
        this.invoicesIds = this.dataSource.data.map(inv => inv.invoiceId);

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    });
  }
}
