import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { ClientsDynamicDataModel } from 'src/app/shared/model/client-models/client-dynamic-data-model';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { ClientNotesModel } from 'src/app/shared/model/client-models/client-notes-model';
import { ClientPromisesModel } from 'src/app/shared/model/client-models/client-promise-model';
import { ClientTypeModel } from 'src/app/shared/model/client-type-model/client-type-model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clientBasicData: ClientModel[];

  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
    private coreSession: CoreSession) {
    this.url = baseUrl + 'Client';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getSelectedIndexClient(index: number) {
    if (this.clientBasicData && this.clientBasicData[index]) {
      return this.clientBasicData[index];
    } else {
      return new ClientModel();
    }
  }

  getClientsList(clientFilter: any) {
    const urlValue: string = this.url + '/GetClientsList';
    return this.http.post(urlValue, JSON.stringify(clientFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }


  getAllClients() {
    const urlValue: string = this.url + '/GetAllClients';
    return this.http.get<ResponseModel>(urlValue).pipe(map(response => {
      return response.data;
    }));
  }

  insertClient(client: ClientModel, isEditMode: boolean) {
    let URLActionName: string =  isEditMode ? '/UpdateClient' : '/InsertClient';

    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertClientType(client: ClientTypeModel) {
    const urlValue: string = this.url + '/InsertClientType';
    return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateClientActiveStatus(client: ClientModel) {
    const urlValue: string = this.url + '/UpdateClientActiveStatus';
    return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  updateClientTypeActiveStatus(client: ClientTypeModel) {
    const urlValue: string = this.url + '/UpdateClientTypeActiveStatus';
    return this.http.post(urlValue, JSON.stringify(client), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getClientTypesSharedList(clientFilter: any){
    const urlValue: string = this.url + '/GetClientTypesSharedList';
    return this.http.post(urlValue, JSON.stringify(clientFilter), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getClientTypesList() {
    const urlValue: string = this.url + '/GetClientTypesList';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  getClientData(selectedStaffId: number) {
    const urlValue: string = this.url + '/GetClientData';
    return this.http.post(urlValue, JSON.stringify(selectedStaffId), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }
}
