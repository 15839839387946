import { Component, OnInit, Input, Output, EventEmitter, OnChanges, TemplateRef, ViewChild, DoCheck } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { ImportDialogComponent } from '../import-dialog/import-dialog.component';
import { CdkDragDrop, moveItemInArray, CdkDragRelease } from '@angular/cdk/drag-drop';
// import { ImportDataTypes } from '../enum/import/ImportDataTypes';
import { SharedTableResult } from '../model/shared-table/shared-table-result.interface';
import { ITableProperties } from '../model/shared-table/table-properties.interface';
import { CustomListFilter } from '../model/shared-table/custom-list.interface';
import { CoreSession } from 'src/app/core/core.session';
import { DataTypes } from '../enum/data-types.enum';
import { MultiSelectionOperation, RowOperation } from '../enum/shared-table-operation.enum';

@Component({
  selector: 'app-shared-table',
  templateUrl: './shared-table.component.html',
  styleUrls: ['./shared-table.component.css']
})
export class SharedTableComponent implements OnInit, OnChanges, DoCheck {

  @ViewChild('import', { static: false }) import: ImportDialogComponent;
  //#region [Outputs]
  @Output()
  tableFilterChanged = new EventEmitter();

  @Output()
  newBtnClicked = new EventEmitter();

  @Output()
  onImportCompleted = new EventEmitter();

  @Output()
  extraBtnClicked = new EventEmitter();

  @Output()
  importBtnClicked = new EventEmitter();

  @Output()
  exportBtnClicked = new EventEmitter();

  @Output()
  plusMinusBtnClicked = new EventEmitter();

  @Output()
  activateAllBtnClicked = new EventEmitter();

  @Output()
  deactivateAllBtnClicked = new EventEmitter();

  @Output()
  rowOperation = new EventEmitter();

  @Output()
  multiSelectionOperation = new EventEmitter();

  @Output()
  checkAllOperation = new EventEmitter();

  @Output()
  checkRowOperation = new EventEmitter();

  @Output()
  onValueChanged = new EventEmitter();

  @Output()
  onValueChange = new EventEmitter();

  @Output()
  onDragDrop = new EventEmitter();

  //#endregion
  //#region [Inputs]
  @Input() currentId = '';
  @ViewChild("fileImportInput", { static: true }) fileImportInput: any;
  // @Input() dataType: ImportDataTypes;
  @Input() multiSelectDataSource: any[] = [];
  @Input() multiSelectDropdownSettings = {};
  @Input() displayMultiTextSettings = {};
  @Input('filterBody') filterBody: TemplateRef<any>;
  @Input() multipleBooleans = false;
  @Input() rowButton = false;
  @Input() disabled = false;
  @Input() dataLoaded = false;
  @Input() oddEvenRowColor = true;
  @Input()
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  @Input() listOfDataSources: SharedTableResult[] = [];
  @Input() increaseFilterBodyWidth: boolean = false;
  @Input() searchText;
  @Input() disableInputTextIfNotChecked: boolean = false;
  @Input() onlyNumber: boolean = true;
  @Input()
  TableProperties: ITableProperties = {
    pageSizeOptions: this.coreSession.pageSizeOptions,
    pageSize: 1,
    showPaginator: false,
    showSearch: false,
    isOnline: false,
    showSearchBtn: false,
    newBtnCaption: 'Desc_New',
    importBtnCaption: 'Desc_Import',
    extraBtnCaption: 'Extra',
    extraBtnIcon: 'fa fa-download',
    SearchBoxLabel: 'Desc_EnterToSearch',
    specificActionWhenCheckAll: false,
    specificActionWhenCheckRow: false,
    showOptionButtonsSeparated: false,
    showAllButtons: true,
    showEditButton: true,
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [],
    allowSelectAll: true,
    showExport: false,
    exportBtnCaption: '',
    showActivateAllBtn: false,
    activateAllBtnCaption: 'Desc_ActivateAll',
    showDeactivateAllBtn: false,
    deactivateAllBtnCaption: 'Desc_DeactivateAll',
  };
  //#endregion

  //#region [DECLARATIONS]
  query: CustomListFilter = {
    page: null,
    pageSize: null
  };
  pageIndex = 0;
  textAlign = '';
  minWith = '0';
  paddingButtons = '1';
  singlDataSourceAsArray: SharedTableResult[] = [];
  isSelectAll = false;
  selectedRowsList: any[] = [];
  cloneDataSourceItems: any[] = [];
  originalDataSourceCount: number = 0;
  isFilterOpend = false;
  resetPaginator = true;
  rowSelected: any = -1;
  maxNumberOfDigitsLength: string = '';
  maxNumberOfStockDigitsLength: string = '';
  numberOfDigits: number = 0;
  numberOfStockDigits: number = 0;
  filtering: boolean = false;
  numberOfButtons: number = 0;
  actionsOpened: boolean = false;
  //#endregion

  //#region [Life Cycle Hooks]
  constructor(public coreSession: CoreSession) { }
  pageSizeOptions = [];
  ngOnInit() {
    this.textAlign = this.coreSession.isRTL ? 'left' : 'right';
    if (this.currentId && this.currentId != '') {
      this.currentId = '_' + this.currentId;
    }
    if (this.TableProperties.pageSizeOptions && this.TableProperties.pageSizeOptions.length > 0) {
      this.pageSizeOptions = this.TableProperties.pageSizeOptions;
    }
    else {
      this.pageSizeOptions = this.coreSession.pageSizeOptions;
    }
    if (!this.TableProperties.pageSize || !this.pageSizeOptions.includes(this.TableProperties.pageSize)) {
      this.TableProperties.pageSize = this.coreSession.pageSize;
    }
    if (this.TableProperties.openFilterDirectly) {
      this.isFilterOpend = true;
    }
    if (!this.TableProperties.newBtnCaption) {
      this.TableProperties.newBtnCaption = 'Desc_New';
    }
    if (!this.TableProperties.importBtnCaption) {
      this.TableProperties.importBtnCaption = 'Desc_Import';
    }
    if (!this.TableProperties.SearchBoxLabel) {
      this.TableProperties.SearchBoxLabel = 'Desc_EnterToSearch';
    }
    if (!this.TableProperties.activateAllBtnCaption) {
      this.TableProperties.activateAllBtnCaption = 'Desc_ActivateAll';
    }
    if (!this.TableProperties.deactivateAllBtnCaption) {
      this.TableProperties.deactivateAllBtnCaption = 'Desc_DeactivateAll';
    }
    if(this.TableProperties.showSearchBtn) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showNewBtn) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showImport) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showExport) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showExtraBtn) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showActivateAllBtn) {
      this.numberOfButtons++;
    }
    if(this.TableProperties.showDeactivateAllBtn) {
      this.numberOfButtons++;
    }
    this.numberOfButtons += this.TableProperties.multiSelectionOperations.length;
    // items List represents original items in one page, and on move to onother page it will change
    if (this.dataSource) {
      if (this.TableProperties.showPaginator && this.dataSource.data.length > this.TableProperties.pageSize) {
        this.dataSource.pageData = this.dataSource.data.slice(0, this.TableProperties.pageSize);
      } else {
        this.dataSource.pageData = this.dataSource.data;
      }
    }
    this.query.searchFilter = this.searchText;
    if (this.dataSource && this.dataSource.data) {
      let index = this.dataSource.data.findIndex(d => d.selected == true);
      if (index > -1) {
        this.rowSelected = index;
      }
    }
    // //Until fixing multi selection design
    // this.TableProperties.multiSelectionOperations=[];
    // this.TableProperties.isMultiSelection=false;
    this.numberOfDigits = 2;
    this.maxNumberOfDigitsLength = 12 + this.numberOfDigits + "";
    this.numberOfStockDigits = 2;
    this.maxNumberOfStockDigitsLength = 9 + this.numberOfStockDigits + "";
  }


  ngOnChanges() {
    //Until fixing multi selection design
    // this.TableProperties.multiSelectionOperations=[];
    // this.TableProperties.isMultiSelection=false;
    let currentPage = this.pageIndex;
    this.cloneDataSourceItems = this.dataSource.data;

    if (this.TableProperties.isMultiSelection) {
      //this.dataSource.data.forEach(a => (a.isChecked = false));
      this.isSelectAll = false;
      const count = this.dataSource.data.filter(a => a.isChecked === true).length;
      if (count === this.dataSource.data.length && count > 0) {
        this.isSelectAll = true;
      }
    }
    if (this.dataSource && this.dataSource.data)
      this.dataSource.data.forEach(a => (a.enableEditMode = false));
    if (this.listOfDataSources.length === 0) {
      this.singlDataSourceAsArray = [];
      this.singlDataSourceAsArray.push(this.dataSource);
    }
    if (this.dataSource && this.dataSource.data) {
      if (this.TableProperties.showPaginator && this.dataSource.data.length > this.TableProperties.pageSize) {
        this.dataSource.pageData = this.dataSource.data.slice(0, this.TableProperties.pageSize);
      } else {
        this.dataSource.pageData = this.dataSource.data;
      }

      if (!this.query.page || this.query.page == 0)
        this.pageIndex = 0;
    }


    if (this.originalDataSourceCount > this.dataSource.totalItems) {
      let index = currentPage;
      let totalPages = this.dataSource.totalItems / this.TableProperties.pageSize;

      totalPages = Math.ceil(totalPages);
      if (index >= totalPages - 1) {
        index = totalPages;
        index = Math.ceil(index);
        if (index - 1 > -1) {
          index = index - 1;
        }
      }
      this.pageIndex = index;

      if (this.TableProperties.isOnline) {
        this.query.page = this.pageIndex;
        this.query.pageSize = this.TableProperties.pageSize;
        this.tableFilterChanged.emit(this.query);
      } else {
        let startIndex = (this.pageIndex * this.TableProperties.pageSize) + 1;
        let endIndex = (this.pageIndex + 1) * this.TableProperties.pageSize;
        this.dataSource.pageData = this.dataSource.data.slice(startIndex - 1, endIndex);
      }
    }
    this.originalDataSourceCount = this.dataSource.totalItems;
    this.CustomizationShredTable();
  }

  ngDoCheck() {
    if (!this.filtering)
      this.cloneDataSourceItems = this.dataSource.data;
    if (this.dataSource) {
      if (this.TableProperties.showPaginator && this.dataSource.data.length > this.TableProperties.pageSize) {
        this.dataSource.pageData = this.dataSource.data.slice((this.pageIndex * this.TableProperties.pageSize), (this.pageIndex + 1) * this.TableProperties.pageSize);
      } else {
        this.dataSource.pageData = this.dataSource.data;
      }
    }
  }

  //#endregion

  //#region [Filters]
  applaySearchFilter() {

    if (this.TableProperties.isOnline) {
      this.query.page = 0;
      this.query.pageSize = this.TableProperties.pageSize;
      this.tableFilterChanged.emit(this.query);
      if (this.TableProperties.clearSearchBoxAfterFinish) {
        this.query.searchFilter = '';
      }
    } else {
      this.localFilter();
      if (this.dataSource) {
        this.dataSource.totalItems = this.dataSource.data.length;
        if (this.dataSource.data.length > this.TableProperties.pageSize) {
          this.dataSource.pageData = this.dataSource.data.slice(0, this.TableProperties.pageSize);
        } else {
          this.dataSource.pageData = this.dataSource.data;
        }
      }
    }
    this.pageIndex = 0;
  }

  localFilter() {
    if (!this.query.searchFilter) {
      this.query.searchFilter = '';
    }
    (this.query);
    if (
      this.query.searchFilter.trim().length === 0 ||
      this.query.searchFilter.trim() === '' ||
      this.query.searchFilter.trim().length === null
    ) {
      this.filtering = false;
      this.dataSource.data = this.cloneDataSourceItems.slice();
      return;
    } else {
      this.filtering = true;
    }
    // if(!this.cloneDataSourceItems || this.cloneDataSourceItems.length==0){
    //   this.cloneDataSourceItems = this.dataSource.data;
    //}
    const TempArray = [];
    for (const row of this.cloneDataSourceItems) {
      for (const key of Object.keys(row)) {
        if (
          (row[key] + '')
            .toLowerCase()
            .includes(this.query.searchFilter.toLowerCase().trim())
        ) {
          TempArray.push(row);
          break;
        }
      }
    }
    this.dataSource.data = TempArray.slice();
  }
  //#endregion

  //#region [Sorting]
  sortBy(columnName: string) {
    if (this.query.sortBy === columnName) {
      this.query.isSortAscending = !this.query.isSortAscending;
    } else {
      this.query.sortBy = columnName;
      this.query.isSortAscending = true;
    }

    if (this.TableProperties.isOnline) {
      this.query.page = 0;
      this.query.pageSize = this.TableProperties.pageSize;
      this.tableFilterChanged.emit(this.query);
    } else {
      // Offline Sorting
      if (this.listOfDataSources.length === 0) {
        this.offlineSort(columnName, this.query.isSortAscending);
        if (this.dataSource) {
          this.dataSource.totalItems = this.dataSource.data.length;
          if (this.dataSource.data.length > this.TableProperties.pageSize) {
            this.dataSource.pageData = this.dataSource.data.slice(0, this.TableProperties.pageSize);
          } else {
            this.dataSource.pageData = this.dataSource.data;
          }

        }
      }

    }
    this.pageIndex = 0;
  }
  offlineSort<T>(propName, isSortAscending): void {
    this.singlDataSourceAsArray[0].data.sort((a, b) => {
      if (isSortAscending) {
        if (isNaN(a[propName])) {
          if (a[propName].toString().toLowerCase() > b[propName].toString().toLowerCase())
            return -1;
          if (a[propName].toString().toLowerCase() < b[propName].toString().toLowerCase())
            return 1;
        } else {
          if (a[propName] > b[propName])
            return -1;
          if (a[propName] < b[propName])
            return 1;
        }
        return 0;
      }
      else {
        if (isNaN(a[propName])) {
          if (a[propName].toString().toLowerCase() < b[propName].toString().toLowerCase())
            return -1;
          if (a[propName].toString().toLowerCase() > b[propName].toString().toLowerCase())
            return 1;
        } else {
          if (a[propName] < b[propName])
            return -1;
          if (a[propName] > b[propName])
            return 1;
        }
        return 0;
      }
    });
  }
  onToggleModule(item: any, key: any) {
    (item);
    let event = null;
    if (this.multipleBooleans) {
      if (!item.booleanValue && item.mandatory) {
        item.mandatory = false;
      }
      event = {
        object: item,
        key: key
      };
    } else {
      item[key] = item.booleanValue;
      event = {
        object: item,
        key: key
      };
    }
    this.onValueChanged.emit(event);
  }
  onTextChanged(item: any, key: any) {
    (item);
    let event = {
      object: item,
      key: key
    }
    this.onValueChanged.emit(event);
  }

  onTextChange(item: any, key: any) {
    (item);
    let event = {
      object: item,
      key: key
    }
    this.onValueChange.emit(event);
  }

  onMultiselectDropdownChanged(item: any, key: any) {
    (item);
    let event = {
      object: item,
      key: key
    }
    this.onValueChanged.emit(event);
  }


  onChangePage(pageEvent: PageEvent) {
    if (this.TableProperties.pageSize != pageEvent.pageSize) {
      this.pageIndex = 0;
      pageEvent.pageIndex = 0;
      this.TableProperties.pageSize = pageEvent.pageSize
    }
    if (this.TableProperties.isOnline) {
      this.query.page = pageEvent.pageIndex;
      this.query.pageSize = pageEvent.pageSize;
      this.tableFilterChanged.emit(this.query);
    } else {
      let startIndex = (pageEvent.pageIndex * this.TableProperties.pageSize) + 1;
      let endIndex = (pageEvent.pageIndex + 1) * this.TableProperties.pageSize;
      this.dataSource.pageData = this.dataSource.data.slice(startIndex - 1, endIndex);
    }
    this.pageIndex = pageEvent.pageIndex;
  }
  //#endregion

  //#region [ClickEvents and MultiSelections]
  onRowOperationClicked(item: any, selectedOperation: any, currentIndex: number) {
    const event = {
      object: item,
      index: currentIndex,
      operation: selectedOperation.operation
    };
    if (selectedOperation.operation == this.rowOperations.Select.valueOf()) {
      if (currentIndex == this.rowSelected) {
        this.dataSource.data[this.rowSelected].selected = false;
        this.rowSelected = -1;
      }
      else {
        if (this.rowSelected >= 0) {
          this.dataSource.data[this.rowSelected].selected = false;
        }
        this.rowSelected = currentIndex;
        this.dataSource.data[currentIndex].selected = true;
      }
    }

    this.onClickedAnyButton(item, currentIndex, selectedOperation.operation);
  }


  onClickedAnyButton(item: any, currentIndex: number, selectedOperation: number) {
    const event = {
      object: item,
      index: currentIndex,
      operation: selectedOperation
    };
    this.rowOperation.emit(event);
  }

  onRowChecked(item: any) {
    item.isChecked = !item.isChecked;
    var count = this.dataSource.data.filter(a => a.isChecked === false).length;
    if (count > 0) {
      this.isSelectAll = false;
    }
    count = this.dataSource.data.filter(a => a.isChecked === true).length;
    if (count === this.dataSource.data.length && count > 0) {
      this.isSelectAll = true;
    }
    if (this.TableProperties.specificActionWhenCheckRow) {
      this.checkRowOperation.emit(item);
    }
  }

  onSelectAll() {
    this.isSelectAll = !this.isSelectAll;
    this.dataSource.data.forEach(o => {
      if (o.languageId !== 1 && o.languageId !== 2) {
        o.isChecked = this.isSelectAll;
      }
    });
    if (this.TableProperties.specificActionWhenCheckAll) {
      this.checkAllOperation.emit(this.isSelectAll);
    }
  }

  openFilter() {
    this.isFilterOpend = !this.isFilterOpend;
    if(this.isFilterOpend) {
      this.actionsOpened = false;
    }
  }
  onCloseFilter() {
    this.isFilterOpend = false;
  }
  onNewClicked() {
    this.newBtnClicked.emit();
  }
  onImportClicked() {
    //this.openImportDialog();
    this.importBtnClicked.emit();
  }
  onExtraBtnClicked() {
    this.extraBtnClicked.emit();
  }
  onExportClicked() {
    this.exportBtnClicked.emit();
  }
  onPlusMinusBtnClicked(item: any, key: any) {
    (item);
    let event = {
      object: item,
      key: key
    }
    this.plusMinusBtnClicked.emit(event);
  }
  onActivateAllClicked() {
    this.activateAllBtnClicked.emit();
  }
  onDeactivateAllClicked() {
    this.deactivateAllBtnClicked.emit();
  }

  openImportDialog() {
    // this.import.showDialog(this.dataType).then(res => {
    //   if (res == -1) {
    //     return false;
    //   }
    //   else if (res == 1) {
    //     //this.onImportCompleted.emit();
    //   }
    // });
  }
  onImportDialogCompleted() {
    this.onImportCompleted.emit();
  }
  onMultiSelectionOperationClicked(selectedOperation: any) {
    const rows = this.findSelectedRows();
    if (rows.length > 0) {
      const event = {
        object: rows,
        operation: selectedOperation.operation
      };
      this.multiSelectionOperation.emit(event);
    } else {
    }
  }


  findSelectedRows(): any[] {
    return this.dataSource.data.filter(a => a.isChecked === true);
  }
  isBooleanValue(valueType: number) {
    return valueType === DataTypes.boolean.valueOf();
  }
  isStringValue(valueType: number) {
    return valueType === DataTypes.String.valueOf();
  }
  isNumberValue(valueType: number) {
    return valueType === DataTypes.number.valueOf();
  }
  isButtonValue(valueType: number) {
    return valueType === DataTypes.Button.valueOf();
  }
  //#endregion

  //#region [Getters]
  get rowOperations() {
    return RowOperation;
  }

  get selectionOperations() {
    return MultiSelectionOperation;
  }
  undoChanges(changedProperty: any, changedObject: any) {
    let languageID = changedProperty.substr(changedProperty.length - 1);
    const index = this.dataSource.data.findIndex
      (i => i.DescriptionKey === changedObject.DescriptionKey);
    this.dataSource.data[index]['DescriptionValue' + languageID] = this.dataSource.data[index]['DescriptionBeforeEdit' + languageID];
  }
  //#endregion
  dropRow(event: CdkDragDrop<SharedTableResult, any>) {
    if (this.TableProperties.isAllowDragDrop) {
      moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
      this.onDragDrop.emit(event);
    }
  }

  CustomizationShredTable() {
    // let alreadyExistEditIcon = this.TableProperties.rowOperations.find(x => x.operation == 33) != undefined ? true : false;
    if (this.TableProperties.rowOperations != undefined) {
      let existDeleteIcon = this.TableProperties.rowOperations.find(x => x.title == 'Desc_Delete') != undefined ? true : false;


      if (existDeleteIcon) {
        this.TableProperties.rowOperations.find(x => x.title == 'Desc_Delete').icon = 'fa fa-trash-o';
      }


      this.TableProperties.rowOperations.sort((a, b) => b.operation - a.operation);
      this.minWith = (this.TableProperties.rowOperations.length * 33) + 'px';
      //this.paddingButtons = ((this.TableProperties.rowOperations.length * 33) >= 99 ? 16 : 7) + 'px';
    }
  }

  onKeyPress(isInt: boolean, event: any) {
    if (isInt) {
      event = event || window.event;
      var key = event.keyCode || event.which;
      // key = String.fromCharCode(key);
      if ((key >= 48 && key <= 57) || key === 8) {
        // Allow the input
      } else {
        // Prevent the input of any other characters, including the decimal point
        event.preventDefault();
      }
    }
  }

  openActions() {
    this.actionsOpened = true; 
  }
  closeActions() {
    this.actionsOpened = false;
  }

}
