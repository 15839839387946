import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { DateTimeModel } from 'src/app/shared/model/dateTimeModel/date-time.model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { ReportService } from '../../reports.service';

@Component({
  selector: 'app-organizers-attendance-list',
  templateUrl: './organizers-attendance-list.component.html',
  styleUrls: ['./organizers-attendance-list.component.css']
})
export class OrganizersAttendanceListComponent implements OnInit {

  isViewMode = false;
  saveSubject: Subject<void> = new Subject<void>();
  selectedObj: any;
  showSlider = false;
  filterForm: FormGroup;
  staffList: StaffModel[] = [];

  dateRange: DateTimeModel[] = [];
  dateRangeProperties: IDateTimePickerProperties = {
    label: 'Desc_FromTo',
    formControlName: 'dateRange',
    isCalendarOnly: true,
    isRange: true
  };

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    staffId: -1,
    fromDateModel: null,
    toDateModel: null,
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: true,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Organizer",
        key: "organizerName",
        isSortable: true,
        width: "33%"
      },
      {
        title: "Desc_Time",
        key: "time",
        isSortable: true,
        width: "34%"
      },
      {
        title: "Desc_Type",
        key: "typeName",
        isSortable: true,
        width: "33%"
      },
    ]
  };

  constructor(private coreSession: CoreSession,
    private service: ReportService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.addActions();
    this.getRequiredLists();
  }

  initForm() {
    this.filterForm = new FormGroup({
      staffId: new FormControl(),
      dateRange: new FormControl(),
    });
  }

  addActions() {
    this.tableData.rowOperations.push({
      operation: RowOperation.View,
      title: "Desc_View",
      icon: "fa fa-eye",
      color: "#12344d"
    });
  }

  getRequiredLists() {
    this.coreSession.ModalLoading.Show();
    this.service.getStaffList().subscribe(result => {
      // console.log(result, "result");
      this.coreSession.ModalLoading.Hide();

      this.staffList = result.staffList ?? [];

      this.populateAttendanceList();

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  ngOnDestroy(): void {
    this.coreSession.setPageTitle("Organizers Attendance Report");
  }

  //Slider Events
  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.openSliderToView(event.object);
        break;
    }
  }
  //Search
  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    this.populateAttendanceList();
  }

  //Open Slider Events
  openSliderToView(val: any) {
    this.selectedObj = val;
    this.isViewMode = true;
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
    this.isViewMode = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onSaveCompleted(val: any) {
    this.closeSlider();
    this.populateAttendanceList();
  }

  onDateRangeSelected(dateSelected: any[]) {
    if (dateSelected)
      this.dateRange = dateSelected;
    else
      this.dateRange = [];
  }

  onFindClicked() {
    this.filter.staffId = this.staffIdControl.value != null ? this.staffIdControl.value : -1;
    this.filter.fromDateModel = this.dateRangeControl.value != null && this.dateRange.length > 0 ? this.dateRange[0] : null;
    this.filter.toDateModel = this.dateRangeControl.value != null && this.dateRange.length > 0 ? this.dateRange[1] : null;

    this.populateAttendanceList();
  }

  populateAttendanceList() {
    this.coreSession.ModalLoading.Show();
    this.service.getOrganizersAttendanceList(this.filter).subscribe(response => {

      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }
        response.data.data.map(d => {
          return {
            ...d,
            time: d.time = this.datePipe.transform(d.time, 'YYYY-MM-dd hh:mm a')
          }
        });

        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = this.dataSource.data.slice();
        this.dataSource.totalItems = response.data.totalItems;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  get staffIdControl() {
    return this.filterForm.get('staffId');
  }
  get dateRangeControl() {
    return this.filterForm.get('dateRange');
  }

}