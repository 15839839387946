import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ResponseModel } from 'src/app/shared/model/api-models/api-models';
import { ItemModel } from 'src/app/shared/model/item-models/item.model';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import { ItemTypeModel } from 'src/app/shared/model/item-models/item-type.model';
@Injectable({
  providedIn: 'root'
})

export class ItemService {
  private url = '';
  httpOptions;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl + 'Item';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
  }

  getItemList(ItemFilter: any) {
    const urlValue: string = this.url + '/GetItemList';
    return this.http.post(urlValue, JSON.stringify(ItemFilter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  getItemData(ItemId: any) {
    const urlValue: string = this.url + '/GetItemData';
    return this.http.post(urlValue, JSON.stringify(ItemId), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];

        return result;
      }
    ));
  }

  updateItemActiveStatus(obj: {itemIds: number[], inactive: boolean}) {
    const urlValue: string = this.url + '/UpdateItemActiveStatus';
    return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertItem(Item: ItemModel, isEditMode: boolean) {
    let URLActionName: string = isEditMode ? '/UpdateItem' : '/InsertItem';

    const urlValue: string = this.url + URLActionName;
    return this.http.post(urlValue, JSON.stringify(Item), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertDynamicItemsList(itemsList: any[], warehouses: any[], showErrorList: boolean) {
    let URLActionName: string = '/InsertDynamicItemsList';
    const urlValue: string = this.url + URLActionName;

    let params = new HttpParams();
    params = params.append('showErrorList', showErrorList.toString());

    let jsonData = {
      items: itemsList,
      warehouses: warehouses,
    }

    return this.http.post(urlValue, JSON.stringify(jsonData), {
      headers: this.httpOptions.headers,
      params: params
    }).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertItemsList(itemsList: any[], showErrorList: boolean) {
    let URLActionName: string = '/InsertItemsList';
    const urlValue: string = this.url + URLActionName;

    let params = new HttpParams();
    params = params.append('showErrorList', showErrorList.toString());

    return this.http.post(urlValue, JSON.stringify(itemsList), {
      headers: this.httpOptions.headers,
      params: params
    }).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertItemSKUDetails(formData) {
    let URLActionName: string = '/InsertItemSKUDetails';
    const urlValue: string = this.url + URLActionName;

    return this.http.post(urlValue, formData).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertDynamicItemSKUDetails(dataList) {
    let URLActionName: string = '/InsertDynamicItemSKUDetails';
    const urlValue: string = this.url + URLActionName;

    return this.http.post(urlValue, dataList).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  insertItemUOMList(itemUOMList: any[], showErrorList: boolean) {
    let URLActionName: string = '/InsertItemUOMList';
    const urlValue: string = this.url + URLActionName;

    let params = new HttpParams();
    params = params.append('showErrorList', showErrorList.toString());

    return this.http.post(urlValue, JSON.stringify(itemUOMList), {
      headers: this.httpOptions.headers,
      params: params
    }).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getItemRequiredLists() {
    const urlValue: string = this.url + '/GetItemRequiredLists';
    return this.http.get<ResponseModel>(urlValue).pipe(map((response) => {
      return <any>response.data;
    }
    ));
  }

  public exportItemsToExcel(itemsList: ItemModel[]) {

    let workBook = new Workbook();
    let workSheet = workBook.addWorksheet('Details', { properties: { defaultColWidth: 27 } });

    let row = workSheet.addRow(["pr_code", "pr_barcode", "conversion", "UOM", "wh_id", "cost_price", "unit_price", "pr_description", "pr_description2", "pr_sup_no"]);
    row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
    row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    row.height = 35;

    let item = new ItemModel();
    for (let i = 0; i < itemsList.length; i++) {
      item = itemsList[i];
      row = workSheet.addRow(
        [
          item.itemCode,
          item.barcode,
          item.uomConversion,
          item.itemUOM,
          item.warehouseCode,
          item.costPrice,
          item.unitPrice,
          item.itemName,
          item.subname,
          item.supplierCode,
        ]
      );
      row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    }

    workBook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, 'INV_Products' + '.xlsx');
    });

  }

  insertItemType(itemType: ItemTypeModel, isEditMode: boolean) {
    const urlAction: string = isEditMode ? '/UpdateItemType' : '/InsertItemType'
    const urlValue: string = this.url + urlAction;
    return this.http.post(urlValue, JSON.stringify(itemType), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }

  getItemTypeData(ItemId: any) {
    const urlValue: string = this.url + '/GetItemTypeData';
    return this.http.post(urlValue, JSON.stringify(ItemId), this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];

        return result;
      }
    ));
  }

  getItemTypesList(ItemFilter: any) {
    const urlValue: string = this.url + '/GetItemTypesList';
    return this.http.post(urlValue, JSON.stringify(ItemFilter), this.httpOptions).pipe(
      map((response) => {
        let res = new ResponseModel();
        res.data = response["data"];
        res.message = response["message"];
        res.status = response["status"];

        return res;
      })
    );
  }

  generateBarcodesAsPDF(barcodes: any[]) {
    let URLActionName: string = '/GenerateBarcodesAsPDF';
    const urlValue: string = this.url + URLActionName;

    return this.http.post(urlValue, JSON.stringify(barcodes),
      {
        responseType: "blob",
        headers: this.httpOptions.headers,
      }
    ).map(res => {
      var blob = new Blob([res], { type: "application/pdf" });
      return blob;
    });
  }

  updateItemTypeActiveStatus(itemTypeId: number, inactive: boolean) {
    const urlValue: string = this.url + '/UpdateItemTypeActiveStatus?itemTypeId=' + itemTypeId + '&inactive=' + inactive;
    return this.http.get(urlValue, this.httpOptions).pipe(map(
      (response) => {
        const result = new ResponseModel();
        result.data = response['data'];
        result.message = response['message'];
        result.status = response['status'];
        return result;
      }
    ));
  }
  
}
