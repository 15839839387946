import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class GlobalTheme {

    //Login
    public static mainLogo: string;
    public static showSeconderyLogo: boolean;
    public static changeLanguageFromLogin: boolean = true;
    public static showKeepMeLoggedInCheck: boolean = true;


    //Staff Definition
    //Columns List:
    public static staffDefinitionColumns = {
        staffName: true,
        staffCode: false,
        staffNumber: false,
        staffNationalId: true,
        mobile: true,
        staffTypeName: true,
        email: true,
        address: true,
        username: true
    }
    //Entry Fields:
    public static staffDefinitionEntries = {
        staffName: true,
        staffCode: true,
        staffType: true,
        staffNationalId: true,
        mobile: true,
        email: true,
        address: true,
        securityGroups: true,
        location: false,
        vehicle: false,
        username: true,
        password: true,
        confirmPassword: true,
        onHold: false,
        inactive: true,
        isOutSource: false,
        isSupervisor: true,
        supervisor: true,
        isUserFo: true,
        subLocation: false,
        staffGroups: false,
        multipleLocations: false,
    }

    //Client Definition
    //Columns List:
    public static clientDefinitionColumns = {
        clientName: true,
        clientCode: false,
        clientTypeName: false,
        clientNationalId: false,
        contactName: false,
        mobile: true,
        email: true,
        address: true,
        longitude: false,
        latitude: false,
    }
    //Entry Fields:
    public static clientDefinitionEntries = {
        clientName: true,
        clientCode: false,
        clientClass: false,
        clientTypeName: false,
        clientNationalId: false,
        mobile: true,
        email: true,
        address: true,
        longitude: true,
        latitude: true
    }



}
