import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ArmoryOperationsService } from 'src/app/content/armory-operations/armory-operations.service';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { OperationMainModel } from 'src/app/shared/model/warehouse-operation-models/operation-main.model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';

@Component({
  selector: 'app-out-bound-list',
  templateUrl: './out-bound-list.component.html',
  styleUrls: ['./out-bound-list.component.css']
})
export class OutBoundListComponent implements OnInit {

  isViewMode = false;
  saveSubject: Subject<void> = new Subject<void>();
  selectedOpObj: OperationMainModel;
  showSlider = false;
  // calculatorSubject: Subject<void> = new Subject<void>();

  showSerialsSlider: boolean = false;

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    typeId: 2,
    statusId: 1
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      
    ]
  };

  isKasotcSelected: boolean = false;

  constructor(private coreSession: CoreSession,
    private armoryOperationsService: ArmoryOperationsService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.isKasotcSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf();
    if (this.isKasotcSelected) {
      this.filter.statusId = 1;
      this.tableData.columns.push(
        {
          title: "Desc_TransactionId",
          key: "transactionId",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_ArmoryName",
          key: "warehouseName",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_#Items",
          key: "noOfItems",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_Status",
          key: "operationStatusName",
          isSortable: true,
          width: "25%"
        }
      );
    } else {
      this.filter.statusId = -1;
      this.tableData.columns.push(
        {
          title: "Desc_TransactionId",
          key: "transactionId",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_Warehouse",
          key: "warehouseName",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_Supplier",
          key: "supplierName",
          isSortable: true,
          width: "25%"
        },
        {
          title: "Desc_Status",
          key: "operationStatusName",
          isSortable: true,
          width: "25%"
        }
      );
    }
    this.addActions();
    this.populateOperationList();
  }

  ngOnDestroy(): void {
  }

  addActions() {
    this.tableData.rowOperations.push(
      {
        operation: RowOperation.View,
        title: "Desc_View",
        icon: "fa fa-eye",
        color: "black"
      }
    );
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.outBoundOperationsURL, MenuActions.Approve)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.approve,
          title: "Desc_Approve",
          icon: "fa fa-check",
          color: "#056E01"
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.outBoundOperationsURL, MenuActions.Add)) {
      this.tableData.showNewBtn = true;
    }
    // if (this.coreSession.checkActivitiesAvailability(ConstantURLs.outBoundOperationsURL, MenuActions.Void)) {
    //   this.tableData.rowOperations.push(
    //     {
    //       operation: RowOperation.Void,
    //       title: "Desc_Void",
    //       icon: "fa fa-trash",
    //       color: "#f1685e",
    //       showWhenKeyValueFalse: true,
    //       controlKeyName: 'isVoided'
    //     });
    // }
  }

  //Slider Events
  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.openSliderToView(event.object);
        break;
      case RowOperation.Void:
        this.onVoidClicked(event);
        break;
      case RowOperation.approve:
        this.onApproveClicked(event);
        break;
    }
  }
  //Search
  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    this.populateOperationList();
  }
  //Approve
  onApproveClicked(event: any) {
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant('Desc_ApproveOperation') + ' ?',
      DialogMode.YesNo,
      this.translateService.instant('Desc_Approve')
    ).then(
      (res: DialogResult) => {

        if (res === DialogResult.Yes) {
          this.armoryOperationsService.updateOperationStatusAsApproved(event.object.transactionId).subscribe(response => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
              this.populateOperationList();
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
            }
          },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
            });
        }
      }
    );
  }
  //Void
  onVoidClicked(event: any) {
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(ConstantMessages.MsgDeleteConfirmation),
      DialogMode.YesNo,
      this.translateService.instant(ConstantMessages.LblDelete)
    ).then(
      (res: DialogResult) => {

        if (res === DialogResult.Yes) {
          this.armoryOperationsService.updateOperationStatusAsVoided(event.object.transactionId).subscribe(response => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
              this.populateOperationList();
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
            }
          },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
            });
        }
      }
    );
  }

  //Open Slider Events
  openSliderToAdd() {
    this.selectedOpObj = null;
    this.isViewMode = false;
    this.showSlider = true;
  }
  openSliderToView(val: any) {
    this.selectedOpObj = val;
    this.isViewMode = true;
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onSaveCompleted(val: any) {
    this.closeSlider();
    this.populateOperationList();
  }

  populateOperationList() {
    this.coreSession.ModalLoading.Show();
    this.armoryOperationsService.getOperationList(this.filter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = response.data.data.slice();
        this.dataSource.totalItems = response.data.totalItems;

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }


  // onCalcClicked() {
  //   this.calculatorSubject.next();
  // }

}
