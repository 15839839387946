import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { RouteStationsAssignmentModel } from 'src/app/shared/model/route-stations-model/route-stations-assignment-model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { RouteStationsService } from '../route-stations.service';

@Component({
  selector: 'app-route-stations-list',
  templateUrl: './route-stations-list.component.html',
  styleUrls: ['./route-stations-list.component.css']
})
export class RouteStationsListComponent implements OnInit {

  isViewMode = false;
  isOptimizeMode = false;
  isEditMode = false;
  saveSubject: Subject<void> = new Subject<void>();
  selectedObj: RouteStationsAssignmentModel;
  showSlider = false;

  filter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
  };

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Staff",
        key: "staffName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Vehicle",
        key: "vehicleName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Date",
        key: "tripDate",
        isSortable: true,
        width: "25%",
        isDate: true
      },
      {
        title: "Desc_#Locations",
        key: "noOfClients",
        isSortable: true,
        width: "25%"
      }
    ]
  };

  constructor(private coreSession: CoreSession,
    private routeStationsService: RouteStationsService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.addActions();
    this.populateRoutesList();
  }

  addActions() {
    //debugger;
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.routeAssignmentListURL, MenuActions.Edit)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.edit,
          title: "Desc_Edit",
          icon: "fa fa-pencil",
          color: "#12344d",
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.routeAssignmentListURL, MenuActions.Adjust)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.Adjust,
          title: "Optimize Route",
          icon: "fa fa-road",
          color: "#12344d",
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.routeAssignmentListURL, MenuActions.View)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.View,
          title: "Desc_View",
          icon: "fa fa-eye",
          color: "black"
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.routeAssignmentListURL, MenuActions.Delete)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.delete,
          title: "Desc_Delete",
          icon: "fa fa-trash",
          color: "#f1685e",
          showHideOptionPerLine: true,
          showWhenKeyValueFalse: true,
          controlKeyName: 'isExpired',
        }
      );
    }
    if (this.coreSession.checkActivitiesAvailability(ConstantURLs.routeAssignmentListURL, MenuActions.Add)) {
      this.tableData.showNewBtn = true;
    }
  }


  //Slider Events
  onRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.View:
        this.openSliderToView(event.object);
        break;
      case RowOperation.edit:
        this.openSliderToEdit(event.object);
        break;

      case RowOperation.Adjust:
        this.openSliderToAdjust(event.object);
        break;
      case RowOperation.delete:
        this.deleteSelectedRoute(event.object);
        break;
    }
  }

  deleteSelectedRoute(obj: any) {
    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(ConstantMessages.MsgDeleteConfirmation),
      DialogMode.YesNo,
      this.translateService.instant(ConstantMessages.LblDelete)
    ).then(
      (res: DialogResult) => {

        if (res === DialogResult.Yes) {
          this.coreSession.ModalLoading.Show();
          this.routeStationsService.deleteRouteData(this.selectedObj.id).subscribe(response => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgDeletedSuccessfully));
              this.populateRoutesList();
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
            }
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
            }
          });
        }
      }
    );

  }


  //Search
  onTableFilterChanged(query: any) {
    this.filter.customListFilter = query;
    this.populateRoutesList();
  }

  //Open Slider Events
  openSliderToAdd() {
    this.selectedObj = null;
    this.isViewMode = false;
    this.isOptimizeMode = false;
    this.isEditMode = false;
    this.showSlider = true;
  }
  openSliderToView(val: any) {
    this.selectedObj = val;
    this.isViewMode = true;
    this.isOptimizeMode = false;
    this.isEditMode = false;
    this.showSlider = true;
  }
  openSliderToEdit(val: any) {
    this.selectedObj = val;
    this.isViewMode = false;
    this.isOptimizeMode = false;
    this.isEditMode = true;
    this.showSlider = true;
  }
  openSliderToAdjust(val: any) {
    this.selectedObj = val;
    this.isViewMode = false;
    this.isOptimizeMode = true;
    this.isEditMode = true;
    this.showSlider = true;
  }
  closeSlider() {
    this.showSlider = false;
    this.isViewMode = false;
    this.isEditMode = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onSaveCompleted() {
    this.closeSlider();
    this.populateRoutesList();
  }

  populateRoutesList() {
    this.coreSession.ModalLoading?.Show();
    this.routeStationsService.getRoutesList(this.filter).subscribe(response => {
      this.coreSession.ModalLoading?.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.dataSource.data = response.data.data.slice().map(d => {
          return {
            ...d,
            isExpired: d.isExpired = new Date(d.tripDate.toString()) <= new Date()
          }
        });
        this.dataSource.pageData = this.dataSource.data.slice();
        this.dataSource.totalItems = response.data.totalItems;

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading?.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

}
