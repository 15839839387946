import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { ConstantURLs } from 'src/app/core/constants/constant-URL';
import { CoreSession } from 'src/app/core/core.session';
import { SessionDataProvider } from 'src/app/core/session-data-provider.service';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { SettingsService } from '../settings.service';
import { Client } from 'src/app/shared/enum/client.enum';
import { WarehouseSelectionComponent } from '../warehouse-selection-dialog/warehouse-selection.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  SettingsModules: any[] = [];

  invKuwaitSelected: boolean = false;

  constructor(
    public coreSession: CoreSession,
    private router: Router,
    private sessionDataProvider: SessionDataProvider,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private translateService: TranslateService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.prepareSettingsModules();
    this.invKuwaitSelected = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf();
  }

  prepareSettingsModules() {
    // //console.log(this.sessionDataProvider.allMenusAvailable.filter(x => x.menuId != undefined && x.parentMenuId === '800'),'settings menus')
    this.SettingsModules = [];
    for (let menu of this.sessionDataProvider.allMenusAvailable.filter(x => x.menuId != undefined && x.parentMenuId === '800')) {
      // //console.log(menu);

      let settingsMenu = {
        title: menu.menuName,
        image: menu.icon,
        link: menu.url,
        menuId: menu.menuId,
        color: menu.color,
        cardBgColor: menu.cardBgColor,
        labelBgColor: menu.labelBgColor,
      };
      this.SettingsModules.push(settingsMenu);
    }
  }

  navigateToSelectedMenu(menu: any) {
    this.router.navigate(['/' + ConstantURLs.settingsURL + '/' + menu.link]);
    this.coreSession.setPageTitle(menu.title);
    // //console.log(menu);

  }

  onUploadClicked() {
    this.coreSession.ModalLoading.Show();
    this.settingsService.uploadInvData().subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onDownloadClicked() {
    const dialogRef = this.dialog.open(WarehouseSelectionComponent, {
      width: '350px',
      maxHeight: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log(result, 'Dialog result');
      if (result) {
        this.coreSession.ModalLoading.Show();
        this.settingsService.downloadInvData(result).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
          }
        },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          });
      }
    });

  }
}
