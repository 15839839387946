export enum Background {
  GOLD = '#FAC001',
  GRAY = '#8E8E8B'
}

export enum Color {
  GOLD = '#fff',
  GRAY = 'white',
  Beige = '#d6cab4',
  Green = '#3e5b3d'
}

export enum FontTypes {
  GOLD = '#FAC001',
  GRAY = '#8E8E8B'
}

export enum Solutions {
  collection = ' #8f7fa3',
  donation = '#fdd835',
  dynamicForms = ' #FFE9C8',
  inventory = '#FAC001',
  room = '#F5E8C7',
  elevator = '#f5a962',
  extinguisher = '#ffffff',
  incubeForm = ' #FFE9C8',
  franchise = ' #f0f1f5',
  pod = ' #0090AB',
  ambulance = '#b52d5a',
  main = '#d10023',
}

