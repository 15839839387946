import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { Observable, Subject } from 'rxjs';
import { ItemService } from '../../item.service';
import { ItemModel, ItemSKUDetailsModel } from 'src/app/shared/model/item-models/item.model';
import { ItemTypeModel } from 'src/app/shared/model/item-models/item-type.model';
import { CategoryModel } from 'src/app/shared/model/armory-models/category.model';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { SupplierModel } from 'src/app/shared/model/supplier-models/supplier.model';
import { UOMModel } from 'src/app/shared/model/uom/uom.model';
import { Client } from 'src/app/shared/enum/client.enum';

@Component({
  selector: 'app-item-entry',
  templateUrl: './item-entry.component.html',
  styleUrls: ['./item-entry.component.css']
})
export class ItemEntryComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedItemObj: ItemModel;
  @Input() isEditMode: boolean = false;
  @Input() allItemsList: ItemModel[] = [];
  @Output() onSaveCompleted = new EventEmitter();
  @Input() selectedBarcode: string = '';

  disableBarcode: boolean = false;

  isKasotcSelected: boolean = false;
  itemObj: ItemModel;
  editItemObj: ItemModel;
  saveBtnSubscription: any;
  form: FormGroup;
  otherLanguages: any[] = [];
  subnameOtherLanguages: any[] = [];
  allItemsListCopy: any[] = [];
  newItemsList: ItemModel[] = [];

  suppliersList: SupplierModel[] = [];
  warehousesList: LocationModel[] = [];
  uomList: UOMModel[] = [];

  defaultImg = '';
  itemImageBase64: string;

  weaponsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  accessoriesDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };
  ammunitionsDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  weaponsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_Add',
    rowOperations: [
      {
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d"
      },
      {
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-trash",
        color: "#f1685e"
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Name",
        key: "itemName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Subname",
        key: "subname",
        isSortable: false,
        width: "25%"
      },
      {
        title: "Desc_Category",
        key: "itemCategoryName",
        isSortable: false,
        width: "25%"
      }
    ]
  };
  accessoriesTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_Add',
    rowOperations: [
      {
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d"
      },
      {
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-trash",
        color: "#f1685e"
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Name",
        key: "itemName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Subname",
        key: "subname",
        isSortable: false,
        width: "25%"
      },
      {
        title: "Desc_Category",
        key: "itemCategoryName",
        isSortable: false,
        width: "25%",
      }
    ]
  };
  ammunitionsTableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_Add',
    rowOperations: [
      {
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d"
      },
      {
        operation: RowOperation.delete,
        title: "Desc_Delete",
        icon: "fa fa-trash",
        color: "#f1685e"
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "#",
        key: "itemId",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Name",
        key: "itemName",
        isSortable: true,
        width: "25%"
      },
      {
        title: "Desc_Subname",
        key: "subname",
        isSortable: false,
        width: "25%"
      },
      {
        title: "Desc_Category",
        key: "itemCategoryName",
        isSortable: false,
        width: "25%"
      }
    ]
  };

  itemNameProperties: ITextInput = {
    formControlName: "itemName",
    placeHolder: "Desc_ItemName",
    label: "Desc_ItemName",
    isRequired: true
  };

  subnameProperties: ITextInput = {
    formControlName: "subname",
    placeHolder: "Desc_Subname",
    label: "Desc_Subname",
    isRequired: true
  };

  labelsText = {
    nameLabel: 'Desc_ItemName',
    codeLabel: 'Desc_ItemCode',
    categoryLabel: 'Desc_ItemCategory'
  }

  itemCategoriesList: CategoryModel[] = [];
  itemTypesList: ItemTypeModel[] = [];
  parentTypesList: ItemTypeModel[] = [];
  subTypesList: ItemTypeModel[] = [];

  weaponsList: ItemModel[] = [];
  ammunitionsList: ItemModel[] = [];
  accssoriesList: ItemModel[] = [];

  dropdownSettings = {
    singleSelection: false,
    idField: 'itemId',
    textField: 'itemName',
    selectAllText: this.translateService.instant(ConstantMessages.LblSelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.LblUnSelectAllCaption),
    searchPlaceholderText: this.translateService.instant(ConstantMessages.LblSearchCaption),
    allowSearchFilter: true,
    itemsShowLimit: 3,
    noDataAvailablePlaceholderText: this.translateService.instant('Desc_NoDataFound')
  };

  subnameDescriptions: any[] = [];

  isCITSelected: boolean = false;

  warehouseDropdownSettings = {
    singleSelection: false,
    idField: 'locationId',
    textField: 'locationName',
    selectAllText: this.translateService.instant(ConstantMessages.LblSelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.LblUnSelectAllCaption),
    searchPlaceholderText: this.translateService.instant(ConstantMessages.LblSearchCaption),
    allowSearchFilter: true,
    itemsShowLimit: 2,
  };

  clientId: number = -1;

  invIncubeSelected: boolean = false;
  invGeneralSelected: boolean = false;

  detailsTableDataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  detailsTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: '',
    showExtraBtn: false,
    extraBtnCaption: '',
    extraBtnIcon: '',
    showImport: false,
    importBtnCaption: '',
    rowOperations: [
      {
        title: 'Desc_Delete',
        operation: RowOperation.delete,
        icon: 'fa fa-trash',
        color: 'red'
      },
      {
        operation: RowOperation.Print,
        title: "Desc_PrintBarcode",
        icon: 'fa fa-print',
        color: '#12344d'
      }
    ],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_Barcode",
        key: "barcode",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_Warehouse",
        key: "warehouseName",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_UnitPrice",
        key: "unitPrice",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_CostPrice",
        key: "costPrice",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_UOM",
        key: "itemUOM",
        isSortable: true,
        width: "20%"
      },
    ]
  };

  palestine: boolean = false;
  uomDropdownSettings = {
    singleSelection: false,
    idField: 'uomId',
    textField: 'uomName',
    selectAllText: this.translateService.instant(ConstantMessages.LblSelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.LblUnSelectAllCaption),
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: '',
    itemsShowLimit: 1,
  };

  @Output() openInternalSlider = new EventEmitter<boolean>();
  showCategorySlider: boolean = false;
  categorySaveSubject: Subject<void> = new Subject<void>();
  showItemTypeSlider: boolean = false;
  itemTypeSaveSubject: Subject<void> = new Subject<void>();
  showSupplierSlider: boolean = false;
  supplierSaveSubject: Subject<void> = new Subject<void>();

  constructor(private itemService: ItemService,
    private coreSession: CoreSession,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.isKasotcSelected = this.coreSession.selectedSolutionId == Solution.Kasotic.valueOf();
    this.isCITSelected = this.coreSession.selectedSolutionId == Solution.CIT.valueOf();
    this.invIncubeSelected = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient == Client.Inventory_INCUBE.valueOf();
    this.invGeneralSelected = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient != Client.Inventory_INCUBE.valueOf();
    this.palestine = this.coreSession.selectedSolutionId == Solution.Inventory.valueOf() &&
      CoreSession.selectedClient == Client.Inventory_Palestine.valueOf();

    this.clientId = CoreSession.selectedClient.valueOf();

    if (this.invIncubeSelected) {
      this.subnameProperties.label = 'Desc_ShortName';
      this.subnameProperties.placeHolder = 'Desc_ShortName';
      this.labelsText.codeLabel = 'Desc_ItemNumber';
    }

    this.defaultImg = this.coreSession.selectedLanguageId == 1 ? '../../../../../assets/img/add-image.jpg' : '../../../../../assets/img/add-image-ar.jpg'
    this.itemImageBase64 = this.defaultImg;
    this.allItemsListCopy = this.allItemsList.slice();

    this.initForm();
    this.getItemRequiredLists();
    this.subscribeSaveClick();

    if (this.selectedBarcode != null && this.selectedBarcode != '') {
      this.itemBarcodeControl.setValue(this.selectedBarcode);
    } else {
      this.itemBarcodeControl.setValue(this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits));
    }
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {

    let itemName = this.isEditMode ? this.selectedItemObj.itemName : undefined;
    let itemCode = this.isEditMode && this.selectedItemObj.itemCode != null ? this.selectedItemObj.itemCode : undefined;
    let subname = this.isEditMode && this.selectedItemObj.subname != null ? this.selectedItemObj.subname : undefined;
    let itemTypeId = this.isEditMode && this.selectedItemObj.itemTypeId != -1 ? this.selectedItemObj.itemTypeId : undefined;
    let itemCategoryId = this.isEditMode && this.selectedItemObj.itemCategoryId != -1 ? this.selectedItemObj.itemCategoryId : undefined;

    this.form = new FormGroup({
      itemName: new FormControl(itemName, Validators.required),
      itemCode: new FormControl(itemCode),
      subname: new FormControl(subname),
      itemTypeId: new FormControl(itemTypeId),
      itemCategoryId: new FormControl(itemCategoryId, this.isKasotcSelected ? Validators.required : []),
      itemImage: new FormControl(),
      inactive: new FormControl(false),
      connectedWeapons: new FormControl(),
      connectedAmmunitions: new FormControl(),
      connectedAccessories: new FormControl(),
      isSerialized: new FormControl({ value: false, disabled: this.isKasotcSelected }),
      minWeight: new FormControl(undefined, (this.isKasotcSelected && !this.isEditMode) || (this.isKasotcSelected && this.isEditMode && this.selectedItemObj.itemTypeId == 3) ? Validators.required : []),
      maxWeight: new FormControl(undefined, (this.isKasotcSelected && !this.isEditMode) || (this.isKasotcSelected && this.isEditMode && this.selectedItemObj.itemTypeId == 3) ? Validators.required : []),
      costPrice: new FormControl(),
      unitPrice: new FormControl(),
      multiWarehouses: new FormControl(),
      supplier: new FormControl(),
      uomConversion: new FormControl(),
      uom: new FormControl(),
      itemBarcode: new FormControl({ value: undefined, disabled: this.disableBarcode || (this.selectedBarcode != null && this.selectedBarcode != '') }),
      brand: new FormControl(),
      itemSubType: new FormControl(),
      warehouse: new FormControl(),
    });

    //console.log(this.form, 'initForm');


    if (this.isKasotcSelected) {
      this.subnameControl.setValidators(Validators.required);
    } else {
      this.itemCodeControl.setValidators(Validators.required);
    }

    if (this.isEditMode) {

      switch (itemTypeId) {
        case 1:
          this.accssoriesList = this.allItemsListCopy.slice().filter(item => item.itemTypeId == 2 && item.inactive == false);
          this.ammunitionsList = this.allItemsListCopy.slice().filter(item => item.itemTypeId == 3 && item.inactive == false);
          break;
        default:
          this.weaponsList = this.allItemsListCopy.slice().filter(item => item.itemTypeId == 1 && item.inactive == false);
      }

    }
  }

  onItemRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.edit:
        this.onEditItemClicked(event);
        break;
      case RowOperation.delete:
        this.onDeleteItemClicked(event);
        break;
    }
  }

  onEditItemClicked(event: any) {

    this.editItemObj = event.object;
    let connectedWeapons = [];
    let connectedAccessories = [];
    let connectedAmmunitions = [];

    switch (event.object.itemTypeId) {
      case 1:
        this.weaponsTableData.newBtnCaption = 'Desc_Edit';
        for (let i = 0; i < this.newItemsList.length; i++) {
          connectedAccessories.push(...this.allItemsListCopy.slice().filter(item => item.inactive == false && this.newItemsList[i].itemConnections.findIndex(c => c.weaponId == event.object.itemId && c.accessoryId == item.itemId) > -1));
        }
        for (let i = 0; i < this.newItemsList.length; i++) {
          connectedAmmunitions.push(...this.allItemsListCopy.slice().filter(item => item.inactive == false && this.newItemsList[i].itemConnections.findIndex(c => c.weaponId == event.object.itemId && c.ammunitionId == item.itemId) > -1));
        }
        break;
      case 2:
        this.accessoriesTableData.newBtnCaption = 'Desc_Edit';
        for (let i = 0; i < this.newItemsList.length; i++) {
          connectedWeapons.push(...this.allItemsListCopy.slice().filter(item => item.inactive == false && this.newItemsList[i].itemConnections.findIndex(c => c.accessoryId == event.object.itemId && c.weaponId == item.itemId) > -1));
        }
        break;
      case 3:
        this.ammunitionsTableData.newBtnCaption = 'Desc_Edit';
        for (let i = 0; i < this.newItemsList.length; i++) {
          connectedWeapons.push(...this.allItemsListCopy.slice().filter(item => item.inactive == false && this.newItemsList[i].itemConnections.findIndex(c => c.ammunitionId == event.object.itemId && c.weaponId == item.itemId) > -1));
        }
        break;
    }

    this.itemNameControl.setValue(event.object.itemName);
    this.subnameControl.setValue(event.object.subname);
    this.itemCodeControl.setValue(event.object.itemCode);
    this.itemCategoryIdControl.setValue(event.object.itemCategoryId);
    this.itemImageBase64 = event.object.itemImage != '' ? event.object.itemImage : this.defaultImg;
    this.inactiveControl.setValue(event.object.inactive);
    this.isSerializedControl.setValue(event.object.isSerialized);
    this.minWeightControl.setValue(event.object.minWeight);
    this.maxWeightControl.setValue(event.object.maxWeight);

    this.connectedAccessoriesControl.setValue(connectedAccessories);
    this.connectedAmmunitionsControl.setValue(connectedAmmunitions);
    this.connectedWeaponsControl.setValue(connectedWeapons);

  }

  onAddItemDetails() {
    if (this.warehouseControl.value == null || this.itemBarcodeControl.value == null ||
      this.uomControl.value == null || this.uomControl.value.length == 0) {
      this.warehouseControl.markAsTouched();
      this.itemBarcodeControl.markAsTouched();
      this.unitPriceControl.markAsTouched();
      this.costPriceControl.markAsTouched();
      this.uomControl.markAsTouched();
      this.uomConversionControl.markAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    if (this.palestine) {
      for (let wh = 0; wh < this.warehouseControl.value.length; wh++) {

        for (let i = 0; i < this.uomControl.value.length; i++) {
          let itemDetail = new ItemSKUDetailsModel();
          itemDetail.itemId = this.isEditMode ? this.selectedItemObj.itemId : -1;
          itemDetail.warehouseId = this.warehouseControl.value[wh].locationId;
          itemDetail.barcode = this.itemBarcodeControl.value != null ? this.itemBarcodeControl.value.toString() : '';
          itemDetail.costPrice = this.costPriceControl.value != null ? +this.costPriceControl.value : 0;
          itemDetail.unitPrice = this.unitPriceControl.value != null ? +this.unitPriceControl.value : 0;
          itemDetail.itemUOM = this.uomControl.value[i].uomName;
          itemDetail.uomConversion = this.uomConversionControl.value != null ? this.uomConversionControl.value : 1;
          itemDetail.warehouseName = this.warehousesList.find(wh => wh.locationId == itemDetail.warehouseId).locationName;
          itemDetail.uomId = this.uomControl.value[i].uomId;

          this.detailsTableDataSource.data.push(itemDetail);
          this.detailsTableDataSource.data = this.detailsTableDataSource.pageData;
          this.detailsTableDataSource.totalItems++;
        }

      }

    } else {
      for (let wh = 0; wh < this.warehouseControl.value.length; wh++) {

        let itemDetail = new ItemSKUDetailsModel();
        itemDetail.itemId = this.isEditMode ? this.selectedItemObj.itemId : -1;
        itemDetail.warehouseId = this.warehouseControl.value[wh].locationId;
        itemDetail.barcode = this.itemBarcodeControl.value != null ? this.itemBarcodeControl.value.toString() : '';
        itemDetail.costPrice = this.costPriceControl.value != null ? +this.costPriceControl.value : 0;
        itemDetail.unitPrice = this.unitPriceControl.value != null ? +this.unitPriceControl.value : 0;
        itemDetail.itemUOM = this.uomControl.value != null ? this.uomList.find(u => u.uomId == this.uomControl.value)?.uomName : '';
        itemDetail.uomConversion = this.uomConversionControl.value != null ? this.uomConversionControl.value : 1;
        itemDetail.warehouseName = this.warehousesList.find(wh => wh.locationId == itemDetail.warehouseId).locationName;
        itemDetail.uomId = this.uomControl.value != null ? this.uomControl.value : -1;

        this.detailsTableDataSource.data.push(itemDetail);
        this.detailsTableDataSource.data = this.detailsTableDataSource.pageData;
        this.detailsTableDataSource.totalItems++;

      }
    }

    this.warehouseControl.setValue(undefined);
    this.itemBarcodeControl.setValue(undefined);
    this.unitPriceControl.setValue(undefined);
    this.costPriceControl.setValue(undefined);
    this.uomControl.setValue(undefined);
    this.uomConversionControl.setValue(undefined);
    this.itemBarcodeControl.setValue(this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits));

  }

  onItemDetailsRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteItemDetail(event);
        break;
      case RowOperation.Print:
        this.onPrintClicked(event.object.barcode);
        break;
    }
  }

  onDeleteItemDetail(event: any) {
    this.detailsTableDataSource.data.splice(event.index, 1);
    this.detailsTableDataSource.pageData = this.detailsTableDataSource.data;
    this.detailsTableDataSource.totalItems--;
  }

  onDeleteItemClicked(event: any) {
    this.coreSession.ModalDialog.ShowMessage(this.translateService.instant(ConstantMessages.MsgDeleteConfirmation), DialogMode.YesNo, this.translateService.instant(ConstantMessages.LblDelete)).then(
      (res: DialogResult) => {
        if (res === DialogResult.Yes) {
          this.deleteItem(event);
        }
      });
  }

  deleteItem(event: any) {

    const index1 = this.newItemsList.findIndex(i => i.itemId == event.object.itemId);
    const index2 = this.allItemsListCopy.findIndex(i => i.itemId == event.object.itemId);

    this.newItemsList.splice(index1, 1);
    this.allItemsListCopy.splice(index2, 1);


    switch (event.object.itemTypeId) {
      case 1:
        this.weaponsDataSource.data.splice(event.index);
        this.weaponsDataSource.totalItems--;
        this.deleteWeaponConnections(event.object.itemId)

        break;
      case 2:
        this.accessoriesDataSource.data.splice(event.index);
        this.accessoriesDataSource.totalItems--;
        this.deleteAccessoryConnections(event.object.itemId);
        break;
      case 3:
        this.ammunitionsDataSource.data.splice(event.index);
        this.ammunitionsDataSource.totalItems--;
        this.deleteAmmunitionConnections(event.object.itemId);
        break;
    }

  }

  deleteWeaponConnections(itemId: number) {
    let indx = -1;

    for (let i = 0; i < this.newItemsList.length; i++) {
      indx = this.newItemsList[i].itemConnections.findIndex(c => c.weaponId == itemId);
      if (indx > -1) {
        this.newItemsList[i].itemConnections.splice(indx);
      }
    }
    for (let i = 0; i < this.allItemsListCopy.length; i++) {
      indx = this.allItemsListCopy[i].itemConnections.findIndex(c => c.weaponId == itemId);
      if (indx > -1) {
        this.allItemsListCopy[i].itemConnections.splice(indx);
      }
    }
  }
  deleteAccessoryConnections(itemId: number) {
    let indx = -1;

    for (let i = 0; i < this.newItemsList.length; i++) {
      indx = this.newItemsList[i].itemConnections.findIndex(c => c.accessoryId == itemId);
      if (indx > -1) {
        this.newItemsList[i].itemConnections.splice(indx);
      }
    }
    for (let i = 0; i < this.allItemsListCopy.length; i++) {
      indx = this.allItemsListCopy[i].itemConnections.findIndex(c => c.accessoryId == itemId);
      if (indx > -1) {
        this.allItemsListCopy[i].itemConnections.splice(indx);
      }
    }
  }
  deleteAmmunitionConnections(itemId: number) {
    let indx = -1;

    for (let i = 0; i < this.newItemsList.length; i++) {
      indx = this.newItemsList[i].itemConnections.findIndex(c => c.ammunitionId == itemId);
      if (indx > -1) {
        this.newItemsList[i].itemConnections.splice(indx);
      }
    }
    for (let i = 0; i < this.allItemsListCopy.length; i++) {
      indx = this.allItemsListCopy[i].itemConnections.findIndex(c => c.ammunitionId == itemId);
      if (indx > -1) {
        this.allItemsListCopy[i].itemConnections.splice(indx);
      }
    }
  }

  onWeaponBtnClicked() {
    if (this.editItemObj == null) {
      this.onAddWeapon();
    } else {
      this.onEditWeapon();
    }
  }
  onAccessoryBtnClicked() {
    if (this.editItemObj == null) {
      this.onAddAccessory();
    } else {
      this.onEditAccessory();
    }
  }
  onAmmunitionBtnClicked() {
    if (this.editItemObj == null) {
      this.onAddAmmunition();
    } else {
      this.onEditAmmunition();
    }
  }

  onAddWeapon() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.prepareItemObj();
    this.weaponsDataSource.data.push(this.itemObj);
    this.weaponsDataSource.totalItems++;

    this.allItemsListCopy.push(this.itemObj);
    this.newItemsList.push(this.itemObj);

    this.onItemTypeSelected(1);

    this.otherLanguages = [];
    this.subnameOtherLanguages = [];

  }
  onAddAccessory() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.prepareItemObj();
    this.accessoriesDataSource.data.push(this.itemObj);
    this.accessoriesDataSource.totalItems++;

    this.allItemsListCopy.push(this.itemObj);
    this.newItemsList.push(this.itemObj);

    this.onItemTypeSelected(2);

    this.otherLanguages = [];
    this.subnameOtherLanguages = [];

  }
  onAddAmmunition() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.prepareItemObj();
    this.ammunitionsDataSource.data.push(this.itemObj);
    this.ammunitionsDataSource.totalItems++;

    this.allItemsListCopy.push(this.itemObj);
    this.newItemsList.push(this.itemObj);

    this.onItemTypeSelected(3);

    this.otherLanguages = [];
    this.subnameOtherLanguages = [];
  }

  onEditWeapon() {

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.itemObj = new ItemModel();

    this.itemObj.itemCode = this.itemCodeControl.value;
    this.itemObj.itemName = this.itemNameControl.value;
    this.itemObj.subname = this.subnameControl.value;
    this.itemObj.itemTypeId = this.itemTypeIdControl.value;
    this.itemObj.itemId = this.editItemObj.itemId;

    this.itemObj.itemCategoryId = this.itemCategoryIdControl.value;
    this.itemObj.itemCategoryName = this.itemCategoriesList.find(c => c.categoryId == this.itemCategoryIdControl.value).categoryName;
    this.itemObj.itemImage = this.itemImageBase64 != this.defaultImg ? this.itemImageBase64 : '';
    this.itemObj.inactive = this.inactiveControl.value;
    this.itemObj.isSerialized = this.isSerializedControl.value;
    this.itemObj.minWeight = 0;
    this.itemObj.maxWeight = 0;

    let connectedAccessories = [];
    let connectedAmmunitions = [];

    this.connectedAccessoriesControl.value?.forEach(item => {
      connectedAccessories.push({
        itemConnectionId: -1,
        weaponId: this.itemObj.itemId,
        accessoryId: item.itemId,
        ammunitionId: -1
      });
    });

    this.connectedAmmunitionsControl.value?.forEach(item => {
      connectedAmmunitions.push({
        itemConnectionId: -1,
        weaponId: this.itemObj.itemId,
        accessoryId: -1,
        ammunitionId: item.itemId
      });
    });


    this.itemObj.itemConnections.push(...connectedAccessories, ...connectedAmmunitions);

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.itemNameControl.value,
      });
    }
    let subnameDescriptions = this.subnameOtherLanguages.slice();
    if (subnameDescriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      subnameDescriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        subname: this.subnameControl.value,
      });
    }

    this.itemObj.descriptions = descriptions.map((desc) => {
      const subname = subnameDescriptions.find((subname) => subname.languageId == desc.languageId);
      return { ...desc, ...subname };
    });

    this.deleteWeaponConnections(this.editItemObj.itemId);

    const tableIndx = this.weaponsDataSource.data.findIndex(w => w.itemId == this.editItemObj.itemId);
    const allIndx = this.allItemsListCopy.findIndex(w => w.itemId == this.editItemObj.itemId);
    const newIndx = this.newItemsList.findIndex(w => w.itemId == this.editItemObj.itemId);

    this.weaponsDataSource.data[tableIndx] = this.itemObj;
    this.allItemsListCopy[allIndx] = this.itemObj;
    this.newItemsList[newIndx] = this.itemObj;

    this.onItemTypeSelected(1);
    this.otherLanguages = [];
    this.subnameOtherLanguages = [];

  }
  onEditAccessory() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.itemObj = new ItemModel();

    this.itemObj.itemCode = this.itemCodeControl.value;
    this.itemObj.itemName = this.itemNameControl.value;
    this.itemObj.subname = this.subnameControl.value;
    this.itemObj.itemTypeId = this.itemTypeIdControl.value;
    this.itemObj.itemId = this.editItemObj.itemId;

    this.itemObj.itemCategoryId = this.itemCategoryIdControl.value;
    this.itemObj.itemCategoryName = this.itemCategoriesList.find(c => c.categoryId == this.itemCategoryIdControl.value).categoryName;
    this.itemObj.itemImage = this.itemImageBase64 != this.defaultImg ? this.itemImageBase64 : '';
    this.itemObj.inactive = this.inactiveControl.value;
    this.itemObj.isSerialized = this.isSerializedControl.value;
    this.itemObj.minWeight = 0;
    this.itemObj.maxWeight = 0;

    let connectedWeapons = [];

    this.connectedWeaponsControl.value?.forEach(item => {
      connectedWeapons.push({
        itemConnectionId: -1,
        weaponId: item.itemId,
        accessoryId: this.itemObj.itemId,
        ammunitionId: -1
      });
    });

    this.itemObj.itemConnections.push(...connectedWeapons);

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.itemNameControl.value,
      });
    }
    let subnameDescriptions = this.subnameOtherLanguages.slice();
    if (subnameDescriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      subnameDescriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        subname: this.subnameControl.value,
      });
    }

    this.itemObj.descriptions = descriptions.map((desc) => {
      const subname = subnameDescriptions.find((subname) => subname.languageId == desc.languageId);
      return { ...desc, ...subname };
    });

    this.deleteAccessoryConnections(this.editItemObj.itemId);

    const tableIndx = this.accessoriesDataSource.data.findIndex(w => w.itemId == this.editItemObj.itemId);
    const allIndx = this.allItemsListCopy.findIndex(w => w.itemId == this.editItemObj.itemId);
    const newIndx = this.newItemsList.findIndex(w => w.itemId == this.editItemObj.itemId);

    this.accessoriesDataSource.data[tableIndx] = this.itemObj;
    this.allItemsListCopy[allIndx] = this.itemObj;
    this.newItemsList[newIndx] = this.itemObj;

    this.onItemTypeSelected(2);
    this.otherLanguages = [];
    this.subnameOtherLanguages = [];

  }
  onEditAmmunition() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    // if (this.isCodeExist()) {
    //   this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgUniqueCode));
    //   return;
    // }

    this.itemObj = new ItemModel();

    this.itemObj.itemCode = this.itemCodeControl.value;
    this.itemObj.itemName = this.itemNameControl.value;
    this.itemObj.subname = this.subnameControl.value;
    this.itemObj.itemTypeId = this.itemTypeIdControl.value;
    this.itemObj.itemId = this.editItemObj.itemId;

    this.itemObj.itemCategoryId = this.itemCategoryIdControl.value;
    this.itemObj.itemCategoryName = this.itemCategoriesList.find(c => c.categoryId == this.itemCategoryIdControl.value).categoryName;
    this.itemObj.itemImage = this.itemImageBase64 != this.defaultImg ? this.itemImageBase64 : '';
    this.itemObj.inactive = this.inactiveControl.value;
    this.itemObj.isSerialized = this.isSerializedControl.value;
    this.itemObj.minWeight = this.minWeightControl.value;
    this.itemObj.maxWeight = this.maxWeightControl.value;

    let connectedWeapons = [];

    this.connectedWeaponsControl.value?.forEach(item => {
      connectedWeapons.push({
        itemConnectionId: -1,
        weaponId: item.itemId,
        accessoryId: -1,
        ammunitionId: this.itemObj.itemId
      });
    });

    this.itemObj.itemConnections.push(...connectedWeapons);

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.itemNameControl.value,
      });
    }
    let subnameDescriptions = this.subnameOtherLanguages.slice();
    if (subnameDescriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      subnameDescriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        subname: this.subnameControl.value,
      });
    }

    this.itemObj.descriptions = descriptions.map((desc) => {
      const subname = subnameDescriptions.find((subname) => subname.languageId == desc.languageId);
      return { ...desc, ...subname };
    });

    this.deleteAmmunitionConnections(this.editItemObj.itemId);

    const tableIndx = this.ammunitionsDataSource.data.findIndex(w => w.itemId == this.editItemObj.itemId);
    const allIndx = this.allItemsListCopy.findIndex(w => w.itemId == this.editItemObj.itemId);
    const newIndx = this.newItemsList.findIndex(w => w.itemId == this.editItemObj.itemId);

    this.ammunitionsDataSource.data[tableIndx] = this.itemObj;
    this.allItemsListCopy[allIndx] = this.itemObj;
    this.newItemsList[newIndx] = this.itemObj;

    this.onItemTypeSelected(3);
    this.otherLanguages = [];
    this.subnameOtherLanguages = [];

  }

  isCodeExist() {
    return this.allItemsListCopy.findIndex(i => i.inactive == false && i.itemCode == this.itemCodeControl.value && this.editItemObj?.itemId != i.itemId) > -1;
  }

  getItemRequiredLists() {
    this.itemService.getItemRequiredLists().subscribe(res => {
      // console.log(res, 'getItemRequiredLists');

      this.itemCategoriesList = res.itemCategoriesList;
      this.suppliersList = res.suppliersList;
      this.warehousesList = res.warehousesList;
      this.uomList = res.uomList;
      if (res.itemTypesList != null) {
        this.itemTypesList = res.itemTypesList.slice();
        this.parentTypesList = res.itemTypesList.slice().filter(type => type.parentId == -1);
        // this.subTypesList = res.itemTypesList.slice().filter(type => type.parentId != -1);
      }
      //debugger
      if (!this.isEditMode && this.itemTypesList && this.itemTypesList.length > 0) {
        if (this.isKasotcSelected) {
          this.itemTypeIdControl.setValue(this.itemTypesList[0].itemTypeId);
          this.onItemTypeSelected(this.itemTypesList[0].itemTypeId);
        }
      }

      if (this.isEditMode) {
        if (this.isKasotcSelected)
          this.onItemTypeSelected(this.selectedItemObj.itemTypeId);
        this.getItemData();
      }

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onParentTypeSelected() {
    this.itemSubTypeControl.setValue(undefined);
    if (this.itemTypeIdControl.value != null) {
      this.subTypesList = this.itemTypesList.slice().filter(type => type.parentId == this.itemTypeIdControl.value);
    } else {
      this.subTypesList = [];
    }
  }

  getItemData() {
    this.coreSession.ModalLoading.Show();
    this.itemService.getItemData(this.selectedItemObj.itemId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.selectedItemObj = response.data;

        // console.log(this.selectedItemObj, 'selectedItemObj');

        this.itemImageBase64 = this.selectedItemObj.itemImage && this.selectedItemObj.itemImage != '' ? this.selectedItemObj.itemImage : this.defaultImg;
        this.inactiveControl.setValue(this.selectedItemObj.inactive);
        this.isSerializedControl.setValue(this.selectedItemObj.isSerialized);
        this.minWeightControl.setValue(this.selectedItemObj.minWeight);
        this.maxWeightControl.setValue(this.selectedItemObj.maxWeight);


        //this.warehouseControl.setValue(this.selectedItemObj.warehouseCode != '' ? this.selectedItemObj.warehouseCode : undefined);
        this.supplierControl.setValue(this.selectedItemObj.supplierCode != '' ? this.selectedItemObj.supplierCode : undefined);

        this.itemBarcodeControl.setValue(this.selectedItemObj.barcode != '' ? this.selectedItemObj.barcode : undefined);

        this.connectedAccessoriesControl.setValue(this.itemTypeIdControl.value == 1 ? this.allItemsListCopy.slice().filter(item => item.inactive == false && this.selectedItemObj.itemConnections.findIndex(itemComb => itemComb.accessoryId == item.itemId) > -1) : []);
        this.connectedAmmunitionsControl.setValue(this.itemTypeIdControl.value == 1 ? this.allItemsListCopy.slice().filter(item => item.inactive == false && this.selectedItemObj.itemConnections.findIndex(itemComb => itemComb.ammunitionId == item.itemId) > -1) : []);
        this.connectedWeaponsControl.setValue(this.itemTypeIdControl.value == 2 || this.itemTypeIdControl.value == 3 ? this.allItemsListCopy.slice().filter(item => item.inactive == false && this.selectedItemObj.itemConnections.findIndex(itemComb => itemComb.weaponId == item.itemId) > -1) : []);

        this.subnameDescriptions = this.selectedItemObj.descriptions.slice().map(({ languageId: languageId, subname: description }) => ({ languageId, description }));

        this.detailsTableDataSource.data = this.selectedItemObj.itemSkuDetails.slice();
        this.detailsTableDataSource.totalItems = this.detailsTableDataSource.data.length;

        this.brandControl.setValue(this.selectedItemObj.brand != '' ? this.selectedItemObj.brand : undefined);

        // if (CoreSession.selectedClient == Client.Inventory_INCUBE.valueOf()) {
        //   let selectedItemType = this.itemTypesList.find(t => t.itemTypeId == this.selectedItemObj.itemTypeId);
        //   let parentId = selectedItemType != null ? selectedItemType.parentId : -1;
        //   this.itemTypeIdControl.setValue(this.selectedItemObj.itemTypeId != -1 ? this.parentTypesList.find(type => type.itemTypeId == parentId)?.itemTypeId : undefined);
        //   this.onParentTypeSelected();
        //   this.itemSubTypeControl.setValue(this.selectedItemObj.itemTypeId != -1 ? this.selectedItemObj.itemTypeId : undefined);
        //   this.multiWarehousesControl.setValue(this.selectedItemObj.itemSkuDetails != null
        //     ? this.warehousesList.slice().filter(wh => this.selectedItemObj.itemSkuDetails.some(d => d.warehouseId == wh.locationId))
        //     : []);

        //   // this.uomControl.setValue(this.selectedItemObj.itemUOM != '' ? this.selectedItemObj.itemUOM : undefined);
        //   this.uomConversionControl.setValue(this.selectedItemObj.uomConversion);
        //   this.costPriceControl.setValue(this.selectedItemObj.costPrice);
        //   this.unitPriceControl.setValue(this.selectedItemObj.unitPrice);
        // }
        this.itemBarcodeControl.setValue(this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits));

      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onItemTypeSelected(itemTypeId: number) {
    //debugger;
    if (this.isKasotcSelected && !this.isEditMode) {
      this.form.reset({
        inactive: false,
        itemTypeId: itemTypeId
      });
      this.clearImage();
      this.weaponsTableData.newBtnCaption = 'Desc_Add';
      this.accessoriesTableData.newBtnCaption = 'Desc_Add';
      this.ammunitionsTableData.newBtnCaption = 'Desc_Add';
      this.editItemObj = null;
    }
    this.connectedWeaponsControl.setValue(undefined);
    this.connectedAccessoriesControl.setValue(undefined);
    this.connectedAmmunitionsControl.setValue(undefined);

    switch (itemTypeId) {
      case 1:
        this.accssoriesList = this.allItemsListCopy.slice().filter(item => item.inactive == false && item.itemTypeId == 2 && item.itemId != this.selectedItemObj?.itemId);
        this.ammunitionsList = this.allItemsListCopy.slice().filter(item => item.inactive == false && item.itemTypeId == 3 && item.itemId != this.selectedItemObj?.itemId);
        this.weaponsList = [];
        this.labelsText.nameLabel = 'Desc_WeaponName';
        this.labelsText.codeLabel = 'Desc_WeaponCode';
        this.labelsText.categoryLabel = 'Desc_WeaponCategory';
        this.isSerializedControl.setValue(true);
        this.minWeightControl.setValue(0);
        this.maxWeightControl.setValue(0);
        break;
      case 2:
        this.weaponsList = this.allItemsListCopy.slice().filter(item => item.inactive == false && item.itemTypeId == 1 && item.itemId != this.selectedItemObj?.itemId);
        this.accssoriesList = [];
        this.ammunitionsList = [];
        this.labelsText.nameLabel = 'Desc_AccessoryName';
        this.labelsText.codeLabel = 'Desc_AccessoryCode';
        this.labelsText.categoryLabel = 'Desc_AccessoryCategory';
        this.isSerializedControl.setValue(false);
        this.minWeightControl.setValue(0);
        this.maxWeightControl.setValue(0);
        break;
      case 3:
        this.weaponsList = this.allItemsListCopy.slice().filter(item => item.inactive == false && item.itemTypeId == 1 && item.itemId != this.selectedItemObj?.itemId);
        this.accssoriesList = [];
        this.ammunitionsList = [];
        this.labelsText.nameLabel = 'Desc_AmmunitionName';
        this.labelsText.codeLabel = 'Desc_AmmunitionCode';
        this.labelsText.categoryLabel = 'Desc_AmmunitionCategory';
        this.isSerializedControl.setValue(false);
        break;
      default:
        this.weaponsList = [];
        this.accssoriesList = [];
        this.ammunitionsList = [];
        this.labelsText.nameLabel = 'Desc_ItemName';
        this.labelsText.codeLabel = 'Desc_ItemCode';
        this.labelsText.categoryLabel = 'Desc_ItemCategory';
        this.isSerializedControl.setValue(false);
        this.minWeightControl.setValue(undefined);
        this.maxWeightControl.setValue(undefined);
    }

    this.itemNameProperties.label = this.labelsText.nameLabel;
    this.itemNameProperties.placeHolder = this.labelsText.nameLabel;

  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  onSubnameTextSubmitted(event) {
    this.subnameOtherLanguages = event.map(({ languageId: languageId, description: subname }) => ({ languageId, subname }));
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareItemObj() {

    // debugger;

    this.itemObj = new ItemModel();

    this.itemObj.itemCode = this.itemCodeControl.value != null ? this.itemCodeControl.value : '';
    this.itemObj.itemName = this.itemNameControl.value;
    this.itemObj.subname = this.subnameControl.value != null ? this.subnameControl.value : '';
    this.itemObj.itemTypeId = this.itemTypeIdControl.value != null ? this.itemTypeIdControl.value : -1;
    this.itemObj.itemId = this.isEditMode ? this.selectedItemObj.itemId :
      !this.isKasotcSelected && !this.isEditMode ? -1 :
        this.allItemsListCopy.length > 0 ? Math.max(...this.allItemsListCopy.map(i => i.itemId)) + 1 : 1;

    this.itemObj.itemCategoryId = this.itemCategoryIdControl.value != null ? this.itemCategoryIdControl.value : -1;
    this.itemObj.itemCategoryName = this.itemCategoryIdControl.value != null ? this.itemCategoriesList.find(c => c.categoryId == this.itemCategoryIdControl.value).categoryName : '';
    this.itemObj.itemImage = this.itemImageBase64 != this.defaultImg ? this.itemImageBase64 : '';
    this.itemObj.inactive = this.inactiveControl.value;
    this.itemObj.isSerialized = this.isSerializedControl.value;
    this.itemObj.minWeight = this.minWeightControl.value != null ? this.minWeightControl.value : 0;
    this.itemObj.maxWeight = this.maxWeightControl.value != null ? this.maxWeightControl.value : 0;

    this.itemObj.itemUOM = '';
    this.itemObj.uomConversion = this.uomConversionControl.value != null ? this.uomConversionControl.value : 1;
    this.itemObj.costPrice = this.costPriceControl.value != null ? this.costPriceControl.value : 0.0;
    this.itemObj.unitPrice = this.unitPriceControl.value != null ? this.unitPriceControl.value : 0.0;
    this.itemObj.supplierCode = this.supplierControl.value != null ? this.supplierControl.value : '';
    //this.itemObj.warehouseCode = this.warehouseControl.value != null ? this.warehouseControl.value : '';
    this.itemObj.barcode = this.itemBarcodeControl.value != null ? this.itemBarcodeControl.value.toString() : '';

    let connectedAccessories = [];
    let connectedAmmunitions = [];
    let connectedWeapons = [];

    if (this.itemTypeIdControl.value == 1) {
      this.connectedAccessoriesControl.value?.forEach(item => {
        connectedAccessories.push({
          itemConnectionId: -1,
          weaponId: this.itemObj.itemId,
          accessoryId: item.itemId,
          ammunitionId: -1
        });
      });

      this.connectedAmmunitionsControl.value?.forEach(item => {
        connectedAmmunitions.push({
          itemConnectionId: -1,
          weaponId: this.itemObj.itemId,
          accessoryId: -1,
          ammunitionId: item.itemId
        });
      });

    }
    else {
      this.connectedWeaponsControl.value?.forEach(item => {
        connectedWeapons.push({
          itemConnectionId: -1,
          weaponId: item.itemId,
          accessoryId: this.itemObj.itemTypeId == 2 ? this.itemObj.itemId : -1,
          ammunitionId: this.itemObj.itemTypeId == 3 ? this.itemObj.itemId : -1
        });
      });
    }

    this.itemObj.itemConnections.push(...connectedAccessories, ...connectedAmmunitions, ...connectedWeapons);

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.itemNameControl.value,
      });
    }
    descriptions.forEach((desc, i) => {
      if (desc.description == '') {
        descriptions[i].description = this.itemNameControl.value;
      }
    });
    let subnameDescriptions = this.subnameOtherLanguages.slice();
    if (subnameDescriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      subnameDescriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        subname: this.subnameControl.value != null ? this.subnameControl.value : '',
      });
    }

    this.itemObj.descriptions = subnameDescriptions.length == 0 ? descriptions.slice() : descriptions.slice().map((desc) => {
      const subname = subnameDescriptions.find((subname) => subname.languageId == desc.languageId);
      return { ...desc, ...subname };
    });

    //console.log(this.itemObj, 'itemObj');

    this.itemObj.brand = this.brandControl.value ?? '';
    this.itemObj.itemSubTypeName = this.itemSubTypeControl.value ?? '';

    this.itemObj.itemSkuDetails = this.multiWarehousesControl.value != null ?
      this.multiWarehousesControl.value.map(wh => {
        return {
          itemId: this.itemObj.itemId,
          warehouseId: wh.locationId,
          barcode: this.itemBarcodeControl.value != null ? this.itemBarcodeControl.value : '',
          itemUOM: '',
          costPrice: 0,
          unitPrice: 0,
          uomConversion: 1,
          warehouseName: '',
          uomId: -1,
        }
      }) : [];

    if (this.itemObj.itemSkuDetails.length == 0)
      this.itemObj.itemSkuDetails = this.detailsTableDataSource.data.slice();

  }

  onSaveClicked() {
    if (!this.isKasotcSelected || this.isEditMode) {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
        this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
        return;
      }

      this.prepareItemObj();

      if (this.itemObj != null) {
        this.coreSession.ModalLoading.Show();
        //console.log(this.itemObj, "ItemObj");

        this.itemService.insertItem(this.itemObj, this.isEditMode).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data == -1) {
              this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            } else {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
              this.onSaveCompleted.emit(response.data);
            }
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
          }
        },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          }
        );
      }
    } else {

      if (this.newItemsList != null && this.newItemsList.length > 0) {

        this.coreSession.ModalLoading.Show();
        //console.log(this.newItemsList, "NewItemsList");

        this.itemService.insertItemsList(this.newItemsList, false).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data == -1) {
              this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            } else {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
              this.onSaveCompleted.emit();
            }
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
          }
        },
          (error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          }
        );

      }
    }
  }

  imgFileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {

      this.coreSession.checkImageSize(fileInput);

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {

          const imgBase64Path = e.target.result;
          this.itemImageBase64 = imgBase64Path;

        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  clearImage() {
    this.itemImageBase64 = this.defaultImg;
  }

  onPrintClicked(barcode: any) {
    this.coreSession.ModalLoading.Show();
    this.itemService.generateBarcodesAsPDF([barcode]).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL, "_blank");
      this.coreSession.ModalLoading.Hide();
    },
      (error: HttpErrorResponse) => {
        this.coreSession.ModalLoading.Hide();
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      }
    );
  }

  //New Category
  onAddCategoryClicked() {
    this.showCategorySlider = true;
    this.openInternalSlider.emit(true);
  }
  closeCategorySlider() {
    this.showCategorySlider = false;
    this.openInternalSlider.emit(false);
  }
  onSaveCategory() {
    this.categorySaveSubject.next();
  }
  onSaveCategoryCompleted(newCategory: any) {
    this.closeCategorySlider();
    this.itemCategoriesList = [...this.itemCategoriesList, newCategory];
  }

  //New ItemType
  onAddItemTypeClicked() {
    this.showItemTypeSlider = true;
    this.openInternalSlider.emit(true);
  }
  closeItemTypeSlider() {
    this.showItemTypeSlider = false;
    this.openInternalSlider.emit(false);
  }
  onSaveItemType() {
    this.itemTypeSaveSubject.next();
  }
  onSaveItemTypeCompleted(newItemType: any) {
    this.closeItemTypeSlider();
    this.itemTypesList = [...this.itemTypesList, newItemType];
  }

  //New Supplier
  onAddSupplierClicked() {
    this.showSupplierSlider = true;
    this.openInternalSlider.emit(true);
  }
  closeSupplierSlider() {
    this.showSupplierSlider = false;
    this.openInternalSlider.emit(false);
  }
  onSaveSupplier() {
    this.supplierSaveSubject.next();
  }
  onSaveSupplierCompleted(newSupplier: any) {
    this.closeSupplierSlider();
    this.suppliersList = [...this.suppliersList, newSupplier];
  }

  onGenerateBarcodeClicked() {
    this.itemBarcodeControl.setValue(this.coreSession.generateRandomAlphnumericText(this.coreSession.numberOfBarcodeDigits));
  }

  //Form Getters
  get itemNameControl() {
    return this.form.get('itemName');
  }

  get itemCodeControl() {
    return this.form.get('itemCode');
  }

  get itemTypeIdControl() {
    return this.form.get('itemTypeId');
  }

  get subnameControl() {
    return this.form.get('subname');
  }

  get itemCategoryIdControl() {
    return this.form.get('itemCategoryId');
  }

  get itemImageControl() {
    return this.form.get('itemImage');
  }

  get inactiveControl() {
    return this.form.get('inactive');
  }

  get isSerializedControl() {
    return this.form.get('isSerialized');
  }

  get connectedWeaponsControl() {
    return this.form.get('connectedWeapons');
  }

  get connectedAccessoriesControl() {
    return this.form.get('connectedAccessories');
  }

  get connectedAmmunitionsControl() {
    return this.form.get('connectedAmmunitions');
  }

  get minWeightControl() {
    return this.form.get('minWeight');
  }
  get maxWeightControl() {
    return this.form.get('maxWeight');
  }

  get uomControl() {
    return this.form.get('uom');
  }
  get uomConversionControl() {
    return this.form.get('uomConversion');
  }
  get costPriceControl() {
    return this.form.get('costPrice');
  }
  get unitPriceControl() {
    return this.form.get('unitPrice');
  }
  get warehouseControl() {
    return this.form.get('warehouse');
  }
  get multiWarehousesControl() {
    return this.form.get('multiWarehouses');
  }
  get supplierControl() {
    return this.form.get('supplier');
  }
  get itemBarcodeControl() {
    return this.form.get('itemBarcode');
  }

  get brandControl() {
    return this.form.get('brand');
  }
  get itemSubTypeControl() {
    return this.form.get('itemSubType');
  }

}

