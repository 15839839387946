export enum Solution {
  Collection = 0,
  Donation = 1,
  DynamicForms = 2,
  Inventory = 3,
  Room = 4,
  Elevator = 5,
  Extinguisher = 6,
  InCubeForm = 7, //similar to dynamic forms but special for InCube Surveys
  Franchise = 8,
  PoD = 9,
  Ambulance = 10,
  AmbulanceInventory = 11,
  Sanad = 12,
  AssetsManagement = 13,
  MinistryOfEnergyForms = 14,
  MinistryOfInteriorTickets = 15,
  MedicalPOD = 16,
  Breakfast = 17,
  IEC = 18,
  TaskManagement = 19,
  Kasotic = 20,
  UNKNOWN = 21,
  CIT = 22,
  Traceability = 23,
}

export enum HotelSolution{
  Royal = 0,
  Hilton = 1,
}

export enum InventorySolution{
  Other = 0,
  Dubai = 1,
  Yasmeen = 3,
}
