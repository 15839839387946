
export class ClientsDynamicDataModel {
    clientId: number;
    dynamicFieldId: number;
    dynamicFieldKey: string;
    dynamicFieldValue: string;
    constructor() {
        this.clientId = -1;
        this.dynamicFieldId = -1;
        this.dynamicFieldKey = "";
        this.dynamicFieldValue = "";
    }
}
