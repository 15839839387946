import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @Input('bodyTemplate') bodyTemplate: TemplateRef<any>;
  @Input('width') width: string;
  @Input('showFooter') showFooter: boolean = true;
  @Input('saveString') saveString: string = 'Desc_Save';
  @Input('saveButtonIcon') saveButtonIcon:string='fa fa-floppy-o';
  @Input('showHeader') showHeader: boolean = false;
  @Input('headerString') headerString: string = '';
  @Input ('hasInternalFooter') hasInternalFooter=false;
  @Output('closeSlider') closeSlider = new EventEmitter();
  @Output('saveClicked') saveClicked = new EventEmitter();
  @Input() showIcon: boolean = false;
  @Input() iconClass: string = '';
  @Output() iconClicked = new EventEmitter<void>();
  @Input() showHeaderButton: boolean = false;
  @Output('headerBtnClicked') headerBtnClicked = new EventEmitter();
  @Input('headerBtnCaption') headerBtnCaption: string = '';
  @Input('headerBtnIcon') headerBtnIcon:string = '';

  lang: string = 'ltr';

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang.toLocaleLowerCase();
  }

  onCloseSlider() {
    this.closeSlider.emit();
  }

  onSaveClicked() {
    this.saveClicked.emit();
  }

  onIconClicked() {
    this.iconClicked.emit();
  }

  onHeaderBtnClicked() {
    this.headerBtnClicked.emit();
  }

}
