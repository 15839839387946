import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNotesModel } from 'src/app/shared/model/client-models/client-notes-model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { ClientOperationService } from '../../client-operation.service';

@Component({
  selector: 'app-client-operation-notes-list',
  templateUrl: './client-operation-notes-list.component.html',
  styleUrls: ['./client-operation-notes-list.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class ClientOperationNotesListComponent implements OnInit {

  clientNotes: ClientNotesModel[] = [];
  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: 100,
    showPaginator: false,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    SearchBoxLabel:"Desc_Search",
    showNewBtn: true,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "DESC_Note",
        key: "noteDescription",
        isSortable: false,
        width: "35%"
      },
      {
        title: "DESC_Date",
        key: "creationDate",
        isSortable: false,
        width: "32%"
      }
      ,
      {
        title: "DESC_Created_By",
        key: "createdByVal",
        isSortable: false,
        width: "33%"
      }
    ]
  };

  constructor(config: NgbModalConfig, private modalService: NgbModal, private clientOperationService: ClientOperationService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit(): void {
    this.clientNotes = this.clientOperationService.fillClientNotes();
    this.dataSource = {
      data: this.clientNotes,
      totalItems: this.clientNotes.length
    }
  }

  onNewClicked(content){
    this.modalService.open(content);
  }
}
