import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { ItemModel } from "src/app/shared/model/item-models/item.model";

@Injectable({ providedIn: 'root' })
export class ArmoryOperationsService {

    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.url = baseUrl + 'ArmoryOperation';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getInBoundOperationList(ArmoryOperationFilter: any) {
        const urlValue: string = this.url + '/GetInBoundOperationList';
        return this.http.post(urlValue, JSON.stringify(ArmoryOperationFilter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getOutBoundOperationList(ArmoryOperationFilter: any) {
        const urlValue: string = this.url + '/GetOutBoundOperationList';
        return this.http.post(urlValue, JSON.stringify(ArmoryOperationFilter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getOperationList(ArmoryOperationFilter: any) {
        const urlValue: string = this.url + '/GetOperationList';
        return this.http.post(urlValue, JSON.stringify(ArmoryOperationFilter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getRequiredLists(operationTypeId: number) {
        const urlValue: string = this.url + '/GetArmoryOperationRequiredLists';
        return this.http.post(urlValue, JSON.stringify(operationTypeId), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getOperationData(transactionId: string, statusId: number) {
        const urlValue: string = this.url + `/GetArmoryOperationData?transactionId=${transactionId}&statusId=${statusId}`;
        return this.http.get(urlValue, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    insertArmoryOperation(ArmoryOperation: any) {
        let URLActionName: string = '/InsertArmoryOperation';
        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(ArmoryOperation), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateOperationStatusAsVoided(transactionId: any) {
        const urlValue: string = this.url + '/UpdateOperationStatusAsVoided';
        return this.http.post(urlValue, JSON.stringify(transactionId), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateOperationStatusAsApproved(transactionId: any) {
        const urlValue: string = this.url + '/UpdateOperationStatusAsApproved';
        return this.http.post(urlValue, JSON.stringify(transactionId), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateOperationStatusAsExecuted(obj: any) {
        const urlValue: string = this.url + '/UpdateOperationStatusAsExecuted';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    updateItemId(item: ItemModel) {
        let URLActionName: string = '/UpdateItemId';

        const urlValue: string = this.url + URLActionName;
        return this.http.post(urlValue, JSON.stringify(item), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    checkSerialsUniqueness(itemsList: ItemModel[]) {
        let URLActionName: string = '/CheckSerialsUniqueness';
        const urlValue: string = this.url + URLActionName;

        return this.http.post(urlValue, JSON.stringify(itemsList), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    generateBarcodesAsPDF(barcodes: any[]) {
        let URLActionName: string = '/GenerateBarcodesAsPDF';
        const urlValue: string = this.url + URLActionName;

        return this.http.post(urlValue, JSON.stringify(barcodes),
            {
                responseType: "blob",
                headers: this.httpOptions.headers,
            }
        ).map(res => {
            var blob = new Blob([res], { type: "application/pdf" });
            return blob;
        });
    }

}