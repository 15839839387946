import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outbound-report',
  templateUrl: './outbound-report.component.html',
  styleUrls: ['./outbound-report.component.css']
})
export class OutboundReportComponent implements OnInit {

  gridReportURL: string = '';

  constructor() { }

  ngOnInit(): void {
    this.gridReportURL = 'http://localhost:8080/API/Report/ShowGrid?id=';
  }

}
