import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CoreSession } from 'src/app/core/core.session';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { ReportService } from '../../../reports.service';

@Component({
  selector: 'app-student-feedback-view',
  templateUrl: './student-feedback-view.component.html',
  styleUrls: ['./student-feedback-view.component.css']
})
export class StudentFeedbackViewComponent implements OnInit {

  @Input() events: Observable<void>;
  @Input() selectedObj: any;
  @Input() isViewMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  obj: any;
  saveBtnSubscription: any;
  form: FormGroup;

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'date',
    isCalendarOnly: false,
    isRange: false,
  };

  constructor(private reportsService: ReportService,
    private coreSession: CoreSession,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.subscribeSaveClick();
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let lecture = this.isViewMode ? this.selectedObj.sessionName : undefined;
    let student = this.isViewMode ? this.selectedObj.studentName : undefined;
    let date = this.isViewMode ? new Date(this.selectedObj.feedbackDate.toString()) : undefined;
    let program = this.isViewMode ? this.selectedObj.programName : undefined;
    let notes = this.isViewMode ? this.selectedObj.feedbackText : undefined;

    this.form = new FormGroup({
      lecture: new FormControl({ value: lecture, disabled: this.isViewMode }, Validators.required),
      date: new FormControl({ value: date, disabled: this.isViewMode }, Validators.required),
      program: new FormControl({ value: program, disabled: this.isViewMode }, Validators.required),
      student: new FormControl({ value: student, disabled: this.isViewMode }, Validators.required),
      notes: new FormControl({ value: notes, disabled: this.isViewMode }, Validators.required),
    });
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      // this.onSaveClicked();
    });
  }

  //Form Getters
  get studentControl() {
    return this.form.get('student');
  }

  get lectureControl() {
    return this.form.get('lecture');
  }

  get dateControl() {
    return this.form.get('date');
  }

  get programControl() {
    return this.form.get('program');
  }

  get notesControl() {
    return this.form.get('notes');
  }
}
