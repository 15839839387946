export enum DynamicFormEntryType {
    Number = 1,
    Integer = 2,
    Decimal = 3,
    Text = 3,
    SingleLine = 4,
    MultiLine = 5,
    Selection = 7,                                                   
    SingleSelection = 8,
    MultiSelection = 9,
    YesAndNo = 10,
    DateAndTime = 11,
    Date = 12,
    Time = 13,
    Media = 14,
    Image = 15,
    Video = 16,
    Audio = 17,
    DateTime = 18,
}

export enum LicenseResult {
    Licensed = 1,
    Error = 2,
    Unauthorized = 3,
    LicenseServerError = 4,
    NearToExpired = 5
}