import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { ItemModel } from 'src/app/shared/model/item-models/item.model';
import { IDateTimePickerProperties } from 'src/app/shared/model/dateTimeModel/date-time-properties.interface';
import { LocationModel } from 'src/app/shared/model/location-models/location-model';
import { StaffModel } from 'src/app/shared/model/staff-models/staff-model';
import { SupplierModel } from 'src/app/shared/model/supplier-models/supplier.model';
import { RootHeaderComponent } from '../../root-header/root-header.component';
import { ReportService } from '../reports.service';
import { Direction } from '@angular/cdk/bidi';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { Client } from 'src/app/shared/enum/client.enum';
import { StaffGroupModel } from 'src/app/shared/model/staff-models/staff-group.model';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { StockCountingHistoryModel } from 'src/app/shared/model/stock-counting/stock-counting-history.model';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnInit {

  selectedAmmunition: ItemModel;
  form: FormGroup;

  warehousesList: LocationModel[] = [];
  suppliersList: SupplierModel[] = [];
  staffList: StaffModel[] = [];
  allStaffList: StaffModel[] = [];
  staffGroupsList: StaffGroupModel[] = [];
  stockCountingHistoryList: StockCountingHistoryModel[] = [];

  dateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'date',
    isCalendarOnly: true,
  };

  fromToDateProperties: IDateTimePickerProperties = {
    label: 'Desc_Date',
    formControlName: 'fromToDate',
    isCalendarOnly: true,
    isRange: true,
  };

  direction: Direction = 'ltr';
  pdfSelected: boolean = false;
  showStaffGroup: boolean = false;
  reportType = ReportType.StockCountingDetails.valueOf();

  groupsDropdownSettings = {
    singleSelection: false,
    idField: 'groupId',
    textField: 'groupName',
    selectAllText: this.translateService.instant(ConstantMessages.LblSelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.LblUnSelectAllCaption),
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: this.translateService.instant(ConstantMessages.LblSearchCaption),
    itemsShowLimit: 2,
  };

  employeeDropdownSettings = {
    singleSelection: false,
    idField: 'staffId',
    textField: 'staffName',
    selectAllText: this.translateService.instant(ConstantMessages.LblSelectAllCaption),
    unSelectAllText: this.translateService.instant(ConstantMessages.LblUnSelectAllCaption),
    position: 'top',
    allowSearchFilter: true,
    searchPlaceholderText: this.translateService.instant(ConstantMessages.LblSearchCaption),
    itemsShowLimit: 2,
  };

  constructor(
    private reportService: ReportService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<RootHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private coreSession: CoreSession) { }


  ngOnInit(): void {
    this.direction = this.translateService.currentLang.toLocaleLowerCase() == 'en' ? 'ltr' : 'rtl';
    this.showStaffGroup = CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf() ||
      CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf();
    this.pdfSelected = this.data.exportMode == ExportMode.PDF;
    this.reportType = this.data.reportType;
    this.initForm();
    this.getReportFilterLists();
  }

  initForm() {
    this.form = new FormGroup({
      date: new FormControl(new Date(), this.pdfSelected && this.reportType == ReportType.StockCountingDetails.valueOf() ? Validators.required : []),
      warehouse: new FormControl(undefined, this.pdfSelected && this.reportType == ReportType.StockCountingDetails.valueOf() ? Validators.required : []),
      supplier: new FormControl(),
      employee: new FormControl(undefined, this.pdfSelected && this.reportType == ReportType.StockCountingDetails.valueOf() ? Validators.required : []),
      getLastReport: new FormControl(false),
      staffGroup: new FormControl(),
      orientation: new FormControl('1'),
      fromToDate: new FormControl([new Date(), new Date()]),
      stockCountingHistory: new FormControl(undefined, this.pdfSelected && this.reportType == ReportType.StockCountingDetails.valueOf() ? Validators.required : []),
    });
  }

  onGetLastReportChange() {
    if (this.getLastReportCheckControl.value) {
      this.dateControl.setValue(undefined);
      this.employeeControl.setValue(undefined);
      this.employeeSelected();
      this.dateControl.disable();
      this.employeeControl.disable();
      this.staffGroupControl.setValue(undefined);
      this.staffGroupControl.disable();
      this.staffList = this.allStaffList.slice();

      this.stockCountingHistoryControl.setValue(undefined);
      this.stockCountingHistoryControl.disable();

      if (this.pdfSelected) {
        this.dateControl.clearValidators();
        this.dateControl.updateValueAndValidity();

        this.employeeControl.clearValidators();
        this.employeeControl.updateValueAndValidity();

        this.stockCountingHistoryControl.clearValidators();
        this.stockCountingHistoryControl.updateValueAndValidity();
      }

    } else {
      this.dateControl.enable();
      this.employeeControl.enable();
      this.staffGroupControl.enable();
      this.stockCountingHistoryControl.enable();

      if (this.pdfSelected) {
        this.dateControl.setValidators([Validators.required]);
        this.dateControl.updateValueAndValidity();

        this.employeeControl.setValidators([Validators.required]);
        this.employeeControl.updateValueAndValidity();

        this.stockCountingHistoryControl.setValidators([Validators.required]);
        this.stockCountingHistoryControl.updateValueAndValidity();
      }
    }
  }

  dateSelected(date: any) {
    if (this.pdfSelected) {
      if (this.dateControl.value != null && this.warehouseControl.value != null && this.employeeControl.value != null) {
        this.coreSession.ModalLoading.Show();
        this.reportService.getAvailableStockCountingList({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
        }).subscribe(res => {
          this.coreSession.ModalLoading.Hide();
          if (res.status > -1) {
            this.stockCountingHistoryList = res.data ?? [];
            if (this.stockCountingHistoryList.length == 1) {
              this.stockCountingHistoryControl.setValue(this.stockCountingHistoryList[0].historyId);
            } else {
              this.stockCountingHistoryControl.setValue(undefined);
            }
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          }
        },
          (_error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          },
        );
      } else {
        this.stockCountingHistoryList = [];
      }
    }
  }

  warehouseSelected() {
    if (this.pdfSelected) {
      if (this.dateControl.value != null && this.warehouseControl.value != null && this.employeeControl.value != null) {
        this.coreSession.ModalLoading.Show();
        this.reportService.getAvailableStockCountingList({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
        }).subscribe(res => {
          this.coreSession.ModalLoading.Hide();

          if (res.status > -1) {
            this.stockCountingHistoryList = res.data ?? [];
            if (this.stockCountingHistoryList.length == 1) {
              this.stockCountingHistoryControl.setValue(this.stockCountingHistoryList[0].historyId);
            } else {
              this.stockCountingHistoryControl.setValue(undefined);
            }
          }
        },
          (_error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          },
        );
      } else {
        this.stockCountingHistoryList = [];
      }
    }
  }

  employeeSelected() {
    if (this.pdfSelected) {
      if (this.dateControl.value != null && this.warehouseControl.value != null && this.employeeControl.value != null) {
        this.coreSession.ModalLoading.Show();
        this.reportService.getAvailableStockCountingList({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
        }).subscribe(res => {
          this.coreSession.ModalLoading.Hide();

          if (res.status > -1) {
            this.stockCountingHistoryList = res.data ?? [];
            if (this.stockCountingHistoryList.length == 1) {
              this.stockCountingHistoryControl.setValue(this.stockCountingHistoryList[0].historyId);
            } else {
              this.stockCountingHistoryControl.setValue(undefined);
            }
          }
        },
          (_error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          },
        );
      } else {
        this.stockCountingHistoryList = [];
        this.stockCountingHistoryControl.setValue(undefined);
      }
    }
  }

  supplierSelected() {
    if (this.pdfSelected) {
      if (this.dateControl.value != null && this.warehouseControl.value != null && this.employeeControl.value != null) {
        this.coreSession.ModalLoading.Show();
        this.reportService.getAvailableStockCountingList({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
        }).subscribe(res => {
          this.coreSession.ModalLoading.Hide();

          if (res.status > -1) {
            this.stockCountingHistoryList = res.data ?? [];
            if (this.stockCountingHistoryList.length == 1) {
              this.stockCountingHistoryControl.setValue(this.stockCountingHistoryList[0].historyId);
            } else {
              this.stockCountingHistoryControl.setValue(undefined);
            }
          }
        },
          (_error: HttpErrorResponse) => {
            this.coreSession.ModalLoading.Hide();
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          },
        );
      } else {
        this.stockCountingHistoryList = [];
        this.stockCountingHistoryControl.setValue(undefined);
      }
    }
  }

  onSelectStaffGroup() {
    if (this.staffGroupControl.value != null) {
      this.employeeControl.setValue(undefined);
      this.employeeSelected();
      this.staffList = this.allStaffList.slice().filter(s => s.staffGroups.includes(this.staffGroupControl.value));
    } else {
      this.staffList = this.allStaffList.slice();
    }
  }

  getReportFilterLists() {
    this.reportService.getReportFilterLists().subscribe(res => {
      // console.log(res, 'getReportFilterLists');

      this.suppliersList = res.suppliersList;
      this.warehousesList = res.warehousesList;
      this.staffList = res.staffList.slice();
      this.allStaffList = res.staffList.slice();
      this.staffGroupsList = res.staffGroupsList ?? [];

    },
      (_error: HttpErrorResponse) => {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
      });
  }

  onExportClicked() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }
    this.coreSession.ModalLoading.Show();
    if (this.reportType == ReportType.StockCountingDetails.valueOf()) {
      if (this.data.exportMode == ExportMode.PDF) {
        this.reportService.exportStockCountingReport({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
          staffName: this.employeeControl.value != null ? this.staffList.find(st => st.staffId == this.employeeControl.value).staffName : '',
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          warehouseCode: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationCode : '',
          getLastReportFlag: this.getLastReportCheckControl.value,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
          staffGroupName: this.staffGroupControl.value != null ? this.staffGroupsList.find(g => g.groupId == this.staffGroupControl.value).groupName : '',
          orientation: +this.orientationControl.value,
          stockCountingHistoryId: this.stockCountingHistoryControl.value != null ? this.stockCountingHistoryControl.value : -1,
        }).subscribe(
          success => {
            const fileURL = URL.createObjectURL(success);
            window.open(fileURL, "_blank");
            this.coreSession.ModalLoading.Hide();
            this.dialogRef.close();
          },
          err => {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
            this.coreSession.ModalLoading.Hide();
          }
        );
      } else {
        this.reportService.getAllStockCountingHistoryDetails({
          date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
          staffIds: this.employeeControl.value != null ? this.employeeControl.value.map(g => g.staffId) : [],
          staffName: this.employeeControl.value != null ? this.employeeControl.value.map(g => g.staffName).join(',') : '',
          supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
          warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
          warehouseCode: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationCode : '',
          getLastReportFlag: this.getLastReportCheckControl.value,
          staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
          staffGroupName: this.staffGroupControl.value != null ? this.staffGroupsList.find(g => g.groupId == this.staffGroupControl.value).groupName : '',
        }).subscribe(response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data.length <= 0) {
              this.coreSession.showInfo(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            } else {
              this.reportService.exportStockCountingDetailsToExcel(response.data);
            }
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
          }
        }, (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
        );
      }
    } else if (this.reportType == ReportType.StockCountingTotals.valueOf()) {
      this.reportService.exportStaffGroupsStockCountingReport({
        date: this.dateControl.value != null ? this.datePipe.transform(this.dateControl.value, "dd-MM-yyyy") : '',
        supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
        warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
        warehouseCode: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationCode : '',
        warehouseName: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationName : '',
        staffGroups: this.staffGroupControl.value != null ? this.staffGroupControl.value.map(g => g.groupId) : [],
        orientation: +this.orientationControl.value,
      }).subscribe(
        success => {
          const fileURL = URL.createObjectURL(success);
          window.open(fileURL, "_blank");
          this.coreSession.ModalLoading.Hide();
          this.dialogRef.close();
        },
        err => {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          this.coreSession.ModalLoading.Hide();
        }
      );
    } else if (this.reportType == ReportType.StockCountingGroupedByItem.valueOf()) {
      this.reportService.exportStockCountingReportGroupedByItem({
        fromDate: this.fromToDateControl.value != null ? this.datePipe.transform(this.fromToDateControl.value[0], "dd-MM-yyyy") : '',
        toDate: this.fromToDateControl.value != null ? this.datePipe.transform(this.fromToDateControl.value[1], "dd-MM-yyyy") : '',
        supplierCode: this.supplierControl.value != null ? this.supplierControl.value : '',
        warehouseId: this.warehouseControl.value != null ? this.warehouseControl.value : -1,
        warehouseCode: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationCode : '',
        warehouseName: this.warehouseControl.value != null ? this.warehousesList.find(wh => wh.locationId == this.warehouseControl.value).locationName : '',
        staffGroupId: this.staffGroupControl.value != null ? this.staffGroupControl.value : -1,
        staffGroupName: this.staffGroupControl.value != null ? this.staffGroupsList.find(g => g.groupId == this.staffGroupControl.value).groupName : '',
        orientation: +this.orientationControl.value,
        staffId: this.employeeControl.value != null ? this.employeeControl.value : -1,
        staffName: this.employeeControl.value != null ? this.staffList.find(st => st.staffId == this.employeeControl.value).staffName : '',
      }).subscribe(
        response => {
          this.coreSession.ModalLoading.Hide();
          if (response.status != null && response.status >= 0) {
            if (response.data.length <= 0) {
              this.coreSession.showInfo(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
            } else {
              this.reportService.exportStockCountingDetailsToExcel(response.data, true);
            }
          } else {
            this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
          }
        }, (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  get dateControl() {
    return this.form.get('date');
  }
  get warehouseControl() {
    return this.form.get('warehouse');
  }
  get supplierControl() {
    return this.form.get('supplier');
  }
  get employeeControl() {
    return this.form.get('employee');
  }
  get getLastReportCheckControl() {
    return this.form.get('getLastReport');
  }
  get staffGroupControl() {
    return this.form.get('staffGroup');
  }
  get orientationControl() {
    return this.form.get('orientation');
  }
  get fromToDateControl() {
    return this.form.get('fromToDate');
  }
  get stockCountingHistoryControl() {
    return this.form.get('stockCountingHistory');
  }
}

export enum ExportMode {
  PDF = 1,
  Excel = 2,
}

export enum ReportType {
  StockCountingDetails = 1,
  StockCountingTotals = 2,
  StockCountingGroupedByItem = 3,
}
