import { Component, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { ImportDataTypes } from '../enum/import/ImportDataTypes';
import { CoreSession } from 'src/app/core/core.session';
import { ResponseModel } from '../model/api-models/api-models';
import { DialogResult } from '../enum/dialog-result.enum';
import { ImportService } from '../services/import.service';

@Component({
    selector: 'app-import-dialog',
    templateUrl: './import-dialog.component.html',
    styleUrls: ['./import-dialog.component.css']
})
export class ImportDialogComponent implements OnInit {

    //#region [DECLARATIONS]

    @Output() onImportCompleted = new EventEmitter();
    dataType: ImportDataTypes;
    ExcelFile;
    uploadedFileName = 'Desc_No_File_Uploaded';
    sampleName = '';
    hideImportButton: boolean = true;
    showResult: boolean = false;
    title = '';
    excelFile;
    @ViewChild('content', { static: true }) content: any;
    form: FormGroup;
    importHeader: string = 'header';
    importNote1: string = 'Desc_Import_note1';
    importedRecordsCount: string = '';
    notImportedRecordsCount: string = '';
    //#endregion

    //#region [INITIALIZED]

    constructor(private modalService: NgbModal,
        public coreSession: CoreSession) {
    }
    ngOnInit() {
        this.initForm();
    }
    initForm() {
        this.form = new FormGroup({
        });
    }
    //#endregion

    //#region [EVENTS]

    onUploadExcelFile(file) {
        this.hideImportButton = false;
        let This = this;
        let files = file.srcElement.files;
        if (files.length > 0) {
            let file: File = files[0];
            if (
                file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            ) {
                // this.coreSession.ModalLoading.Show();
                This.uploadedFileName = file.name;
                let formData: FormData = new FormData();
                formData.append('file', file, file.name);
                formData.append('importDataTypeId', JSON.stringify(this.dataType.valueOf()));
                this.ExcelFile = formData;
                let oFReader = new FileReader();
                oFReader.onloadend = function () {
                    This.hideImportButton = false;
                };
                oFReader.readAsText(file);
                // this.coreSession.ModalLoading.Hide();
            }
            else {
                // this.coreSession.ModalLoading.Hide();
                this.uploadedFileName = "Desc_No_File_Uploaded";
            }
        }
    }
    onImportClicked(modal) {
        this.callImportAPI(this.ExcelFile, modal);
    }
    onSampleClicked() {
        switch (this.dataType) {
            case ImportDataTypes.Items:
                this.sampleName = 'ItemsTemplate.xlsx';
                break;
            case ImportDataTypes.Employees:
                this.sampleName = 'EmployeesTemplate.xlsx';
                break;
            case ImportDataTypes.Customers:
                this.sampleName = 'CustomersTemplate.xlsx';
                break;
            case ImportDataTypes.DisplayUnit:
                this.sampleName = 'DisplayUnitTemplate.xlsx';
                break;
        }
    }
    public showDialog(importDataType: ImportDataTypes) {
        this.ExcelFile = new FormData();
        this.uploadedFileName = "Desc_No_File_Uploaded";
        this.showResult = false;
        this.importedRecordsCount = '';
        this.notImportedRecordsCount = '';
        this.dataType = importDataType;
        switch (this.dataType) {
            case ImportDataTypes.Items:
                this.title = 'Desc_Items';
                break;
            case ImportDataTypes.Employees:
                this.title = 'Desc_Employees';
                break;
            case ImportDataTypes.Customers:
                this.title = 'Desc_Customers';
                break;
            case ImportDataTypes.DisplayUnit:
                this.title = 'Desc_Display_Unit';
                break;
        }
        this.importHeader = "Desc_Import + this.title";

        return this.modalService.open(this.content, { centered: true }).result.then(
            (result) => {
                if (result === DialogResult.Ok) {
                    return 1;
                } else {
                    return -1;
                }
            });
    }
    //#endregion

    //#region [METHODS]

    callImportAPI(excelFile, modal) {
        // this.coreSession.ModalLoading.Show();
        // this.importService.importFiles(excelFile).subscribe((response: ResponseModel) => {
        //     // this.coreSession.ModalLoading.Hide();
        //     if (response.status != null && response.status >= 0) {
        //         if (response.data.isValid) {
        //             this.hideImportButton = true;
        //             this.showResult = true;
        //             this.importedRecordsCount = "Desc_Imported_Records_Count";
        //             this.notImportedRecordsCount = "Desc_Not_Imported_Records_Count";
        //             // this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.SuccessCaption), this.translateService.instant(ConstantMessages.Desc_Imported_Successfully));
        //             this.onImportCompleted.emit();
        //         }
        //         else {
        //             // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.Desc_Excel_File_Schema_Not_Match));
        //         }
        //     }
        //     else {
        //         // this.coreSession.ModalLoading.Hide();
        //         // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), response.message);
        //     }
        // }, (error) => {
        //     // this.coreSession.ModalLoading.Hide();
        //     // this.coreSession.showError(this.translateService.instant(ConstantMessages.ErrorCaption), this.translateService.instant(ConstantMessages.ErrorHappened));
        // });
    }
    //#endregion
}
