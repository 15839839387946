import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { ITextInput } from 'src/app/shared/model/shared-table/custom-list.interface';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { SupplierModel } from 'src/app/shared/model/supplier-models/supplier.model';
import { SuppliersService } from '../suppliers.service';

@Component({
  selector: 'app-supplier-entry',
  templateUrl: './supplier-entry.component.html',
  styleUrls: ['./supplier-entry.component.css']
})
export class SupplierEntryComponent implements OnInit {

  @Input() events: Observable<void>;
  @Input() selectedSupplierData: SupplierModel;
  @Input() isEditMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  supplierObj: SupplierModel;
  saveBtnSubscription: any;
  form: FormGroup;
  contactsForm: FormGroup;
  otherLanguages: any[] = [];

  supplierNameProp: ITextInput = {
    formControlName: "supplierName",
    placeHolder: "Desc_SupplierName",
    label: "Desc_SupplierName",
    isRequired: true
  };

  supplierCode: ITextInput = {
    formControlName: "supplierCode",
    placeHolder: "Desc_supplierCode",
    label: "Desc_supplierCode",
    isRequired: true
  };

  contactsDataSource: SharedTableResult = {
    data: [],
    totalItems: 0
  }

  contactsTableProp: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    isOnline: false,
    showSearch: true,
    columns: [
      {
        title: 'Desc_ContactName',
        key: 'contactName',
        isSortable: true,
        width: '50%',
        isEditable: true,
      },
      {
        title: 'Desc_ContactNumber',
        key: 'contactNumber',
        isSortable: true,
        width: '50%',
        isEditable: true,
        numberOfDigits: true,
        intValue: true,
      }
    ],
    rowOperations: [
      {
        title: 'Desc_Delete',
        operation: RowOperation.delete,
        icon: 'fa fa-trash',
        color: 'red'
      },
    ],
    multiSelectionOperations: []
  }

  constructor(private suppliersService: SuppliersService,
    private coreSession: CoreSession,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.initForm();
    if (this.isEditMode) {
      this.getSupplierData();
    }
    this.subscribeSaveClick();
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  initForm() {
    let supplierId = this.isEditMode ? this.selectedSupplierData.supplierId : -1;
    let country = this.isEditMode ? this.selectedSupplierData.country : undefined;
    let supplierName = this.isEditMode ? this.selectedSupplierData.supplierName : undefined;
    let supplierCode = this.isEditMode ? this.selectedSupplierData.supplierCode : undefined;
    let city = this.isEditMode ? this.selectedSupplierData.city : undefined;
    let inactive = this.isEditMode ? this.selectedSupplierData.inactive : false;

    this.form = new FormGroup({
      supplierId: new FormControl(supplierId),
      supplierName: new FormControl(supplierName, Validators.required),
      supplierCode: new FormControl(supplierCode, Validators.required),
      country: new FormControl(country),
      city: new FormControl(city),
      address: new FormControl(undefined),
      inactive: new FormControl(inactive),
    });

    this.contactsForm = new FormGroup({
      contactName: new FormControl(undefined, Validators.required),
      contactNumber: new FormControl(undefined, [Validators.required, Validators.maxLength(14)]),
    });

  }

  onContactRowOperation(event: any) {
    switch (event.operation) {
      case RowOperation.delete:
        this.onDeleteContact(event);
        break;
    }
  }

  onDeleteContact(event: any) {

    this.contactsDataSource.data.splice(event.index, 1);
    this.contactsDataSource.pageData = this.contactsDataSource.data;
    this.contactsDataSource.totalItems--;

  }

  onAddContact() {
    if (this.contactsForm.invalid) {
      this.contactsForm.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.contactsDataSource.data.push({
      contactName: this.contactNameControl.value,
      contactNumber: this.contactNumberControl.value
    });

    this.contactsDataSource.data = this.contactsDataSource.pageData;
    this.contactsDataSource.totalItems++;

    this.contactsForm.reset();

  }

  getSupplierData() {
    this.coreSession.ModalLoading.Show();
    this.suppliersService.getSupplierData(this.selectedSupplierData.supplierId).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        if (response.data.totalItems <= 0) {
          this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        }

        this.selectedSupplierData = response.data;

        this.supplierNameControl.setValue(this.selectedSupplierData.supplierName);
        this.supplierCodeControl.setValue(this.selectedSupplierData.supplierCode);
        this.countryControl.setValue(this.selectedSupplierData.country);
        this.cityControl.setValue(this.selectedSupplierData.city);
        this.addressControl.setValue(this.selectedSupplierData.address);
        this.inactiveControl.setValue(this.selectedSupplierData.inactive);

        this.contactsDataSource = {
          data: this.selectedSupplierData.contacts.slice(),
          totalItems: this.selectedSupplierData.contacts.length
        };

        //console.log(this.selectedSupplierData, 'selectedSupplierData');


      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }

  onOtherLanguagesTextSubmitted(event) {
    this.otherLanguages = event;
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
      this.onSaveClicked();
    });
  }

  prepareSupplierObj() {
    this.supplierObj = new SupplierModel();

    this.supplierObj.supplierId = this.isEditMode ? +this.supplierIdControl.value : -1;
    this.supplierObj.country = this.countryControl.value != undefined ? this.countryControl.value : '';
    this.supplierObj.supplierName = this.supplierNameControl.value != undefined ? this.supplierNameControl.value : '';
    this.supplierObj.supplierCode = this.supplierCodeControl.value != undefined ? this.supplierCodeControl.value : '';
    this.supplierObj.city = this.cityControl.value != undefined ? this.cityControl.value : '';
    this.supplierObj.inactive = this.inactiveControl && this.inactiveControl.value ? this.inactiveControl.value : false;
    this.supplierObj.address = this.addressControl.value != undefined ? this.addressControl.value : '';

    this.supplierObj.contacts = this.contactsDataSource.data.slice()
      .filter(c => c.contactName != null && c.contactName != '' && c.contactNumber != null && c.contactNumber != '')
      .map(c => {
        return {
          ...c,
          contactNumber: c.contactNumber = c.contactNumber.toString()
        };
      });

    let descriptions = this.otherLanguages.slice();
    if (descriptions.find(x => x.languageId === this.coreSession.selectedLanguageId) == null) {
      descriptions.push({
        languageId: this.coreSession.selectedLanguageId,
        description: this.supplierNameControl.value
      });
    }
    descriptions.forEach((desc, i) => {
      if (desc.description == '') {
        descriptions[i].description = this.supplierNameControl.value;
      }
    });
    descriptions.map(
      description => {
        return {
          ...description,
          description: description.description = description.description
        };
      }
    );
    this.supplierObj.descriptions = descriptions;

  }

  onSaveClicked() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(ConstantMessages.MsgFillMandatory));
      return;
    }

    this.prepareSupplierObj();
    //console.log(this.supplierObj);


    if (this.supplierObj != null) {
      this.coreSession.ModalLoading.Show();
      //console.log(this.supplierObj, "supplierObj");

      this.suppliersService.insertSupplier(this.supplierObj, this.isEditMode).subscribe(response => {
        this.coreSession.ModalLoading.Hide();
        if (response.status != null && response.status >= 0) {
          if (response.data > -1) {
            this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgSavedSuccessfuly));
            this.supplierObj.supplierId = response.data;
            this.onSaveCompleted.emit(this.supplierObj);
          } else {
            this.coreSession.showWarning(this.translateService.instant(ConstantMessages.LblWarningCaption), this.translateService.instant(response.message));
          }
        } else {
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(response.message));
        }
      },
        (error: HttpErrorResponse) => {
          this.coreSession.ModalLoading.Hide();
          this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
        }
      );
    }
  }

  onKeyPress(event: any) {
    event = event || window.event;
    var key = event.keyCode || event.which;
    if ((key >= 48 && key <= 57) || key === 8) {
      // Allow the input
    } else {
      // Prevent the input of any other characters, including the decimal point
      event.preventDefault();
    }
  }

  //Form Getters
  get supplierIdControl() {
    return this.form.get('supplierId');
  }
  get supplierNameControl() {
    return this.form.get('supplierName');
  }
  get supplierCodeControl() {
    return this.form.get('supplierCode');
  }
  get countryControl() {
    return this.form.get('country');
  }
  get cityControl() {
    return this.form.get('city');
  }
  get addressControl() {
    return this.form.get('address');
  }
  get inactiveControl() {
    return this.form.get('inactive');
  }

  get contactNameControl() {
    return this.contactsForm.get('contactName');
  }

  get contactNumberControl() {
    return this.contactsForm.get('contactNumber');
  }

}

