import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-matrix-selection',
  templateUrl: './matrix-selection.component.html',
  styleUrls: ['./matrix-selection.component.css']
})
export class MatrixSelectionComponent implements OnInit, OnChanges {

  @Input('columns') columns = [];
  @Input('rows') rows = [];
  @Input('initialValues') initialValues = [];
  @Input('allDisabled') allDisabled = false;
  @Input('disableList') disableList = [];
  @Output('onChangeInputValue') onChangeInputValue = new EventEmitter();

  resultSet = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resultSet = [];
    if (changes.initialValues) {
      this.resultSet = this.initialValues;
    }
  }

  getId(rowIndex: number, colIndex: number): string {
    return (rowIndex.toString() + "," + colIndex.toString());
  }

  getValue(rowIndex: number, colIndex: number): boolean {
    return this.resultSet.some(s => s.rowIndex == rowIndex && s.colIndex == colIndex);
  }

  onChangeValue(rowIndex: number, colIndex: number, newValue: boolean) {
    if (newValue) {
      // this.resultSet = this.resultSet.slice().filter(s => s.rowIndex != rowIndex);
      this.resultSet.push({
        rowIndex: rowIndex,
        colIndex: colIndex,
      });
    } else {
      this.resultSet = this.resultSet.slice().filter(s => !(s.rowIndex == rowIndex && s.colIndex == colIndex));
    }
    this.onChangeInputValue.emit(this.resultSet);
  }

  checkIfDisabled(rowIndex: number, colIndex: number): boolean {
    return this.allDisabled || this.disableList.some(l => l.rowIndex == rowIndex && l.colIndex == colIndex);
  }

}
