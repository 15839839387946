import {
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthGuard } from "./auth.gurad";
import "rxjs/add/operator/catch";
import { of } from "rxjs";
import { ConstantStorage } from "../constants/constant-stroage";
import { ConstantURLs } from "../constants/constant-URL";
import { CoreSession } from "../core.session";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  public constructor(private router: Router, private authGuard: AuthGuard) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = sessionStorage.length > 0 ? sessionStorage.getItem(ConstantStorage.TokenName) : localStorage.getItem(ConstantStorage.TokenName);
    let languageId = localStorage.getItem(ConstantStorage.LanguageId);
    let clientId = CoreSession.selectedClient.valueOf();

    if(!languageId){
      languageId = "2" ;
    }

    let authReq = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + token)
      .set("LanguageId", languageId)
      .set("ClientId", clientId.toString()),
      reportProgress: true
    });

    return next.handle(authReq).catch(x => this.handleAuthError(x));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      localStorage.removeItem(ConstantStorage.TokenName);
      localStorage.removeItem(ConstantStorage.CurrentUser);
      localStorage.removeItem(ConstantStorage.generalData);
      localStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
      localStorage.removeItem(ConstantStorage.LicenseData);

      sessionStorage.removeItem(ConstantStorage.TokenName);
      sessionStorage.removeItem(ConstantStorage.CurrentUser);
      sessionStorage.removeItem(ConstantStorage.generalData);
      sessionStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
      sessionStorage.removeItem(ConstantStorage.LicenseData);

      this.router.navigate(["/" + ConstantURLs.loginURL]);
      //navigate /delete cookies or whatever
      // this.router.navigateByUrl(`/login`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return Observable.of(err.message);
    }
    return Observable.throw(err);
  }
}
