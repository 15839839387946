export const ConstantStorage = {
    TokenName: 'TokenKey',
    CurrentUser: 'CurrentUser',
    generalData:'GeneralData',
    LanguageId: 'LanguageId',
    LastAction: "LastAction",
    KeepMeLoggedIn: 'KeepMeLoggedIn',
    ClientId: 'ClientId',
    LicenseData: 'LicenseData',
    ShowMenuTitles: 'ShowMenuTitles',
}

