import { Component, ElementRef, EventEmitter, Injectable, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { DialogMode } from '../enum/dialog-mode.enum';
import { DialogResult } from '../enum/dialog-result.enum';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.css'],
  // encapsulation: ViewEncapsulation.None
})
@Injectable()
export class ModalDialogComponent {

  //#region [DECLARATIONS]

  showYesNoButton = true;
  showOkButton = true;
  YesResult: DialogResult = DialogResult.Yes;
  NoResult: DialogResult = DialogResult.No;
  OkResult: DialogResult = DialogResult.Ok;
  @ViewChild('content', { static: true }) content: any;
  // Default Value
  confirmation = '';
  body = ' ';
  isList: boolean = false;
  msgList: string[];
  //#endregion

  //#region [INITIALIZED]

  constructor(private modalService: NgbModal,
    private translateService: TranslateService) {
  }

  //#endregion

  //#region [METHODS]

  public ShowMessage(bodyMsg: string, dialogMode: DialogMode, title?: string) {
    this.isList = false;
    this.body = bodyMsg;
    if (title) {
      this.confirmation = title;
    } else {
      this.confirmation = this.translateService.instant(ConstantMessages.LblWarningCaption);
    }
    switch (dialogMode) {
      case DialogMode.YesNo:
        this.showYesNoButton = true;
        this.showOkButton = false;
        break;
      case DialogMode.Ok:
        this.showOkButton = true;
        this.showYesNoButton = false;
        break;
      default:
        this.showOkButton = false;
        this.showYesNoButton = false;
        break;
    }
    // centered: true
    return this.modalService.open(this.content, { centered: true}).result.then(
      (result) => {
        return result;
      },
      (resaon) => {
        return DialogResult.None;
      }
    );
  }
  public ShowMessageList(mainMsg: string, list: string[], dialogMode: DialogMode, title?: string) {
    this.body = mainMsg;
    this.isList = true;
    this.msgList = list;

    if (title) {
      this.confirmation = title;
    } else {
      this.confirmation = this.translateService.instant(ConstantMessages.LblWarningCaption);
    }
    switch (dialogMode) {
      case DialogMode.YesNo:
        this.showYesNoButton = true;
        this.showOkButton = false;
        break;
      case DialogMode.Ok:
        this.showOkButton = true;
        this.showYesNoButton = false;
        break;
      default:
        this.showOkButton = false;
        this.showYesNoButton = false;
        break;
    }
    // centered: true
    return this.modalService.open(this.content, { centered: true }).result.then(
      (result) => {
        return result;
      },
      (resaon) => {
        return DialogResult.None;
      }
    );
  }
  /* private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return `with: ${reason}`;
      }
  } */

  //#endregion
}
