import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantMessages } from 'src/app/core/constants/constant-message';
import { CoreSession } from 'src/app/core/core.session';
import { RowOperation } from 'src/app/shared/enum/shared-table-operation.enum';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { CustomListFilter } from 'src/app/shared/model/shared-table/custom-list.interface';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';
import { Subject, Observable } from 'rxjs';
import { ClientService } from '../client.service';
import { GlobalTheme } from 'src/app/core/global-theme';
import { Solution } from 'src/app/shared/enum/solution.enum';
import { MenuActions } from 'src/app/shared/enum/menu-actions-enum';
import { DialogMode } from 'src/app/shared/enum/dialog-mode.enum';
import { DialogResult } from 'src/app/shared/enum/dialog-result.enum';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsListComponent implements OnInit, OnDestroy {

  isEditModeSelected = false;
  saveSubject: Subject<void> = new Subject<void>();
  data: ClientModel[];
  selectedClientObj: ClientModel;
  selectedIndexVal: number = -1;
  showSlider = false;

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  clientFilter = {
    customListFilter: {
      searchFilter: '',
      page: 0,
      pageSize: this.coreSession.pageSize
    },
    clientTypeId: -1,
    includeInactive: false,
  };

  tableData: ITableProperties = {
    pageSize: this.coreSession.pageSize,
    showPaginator: true,
    showSearch: true,
    isOnline: true,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "Desc_ClientName",
        key: "clientName",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_ClientCode",
        key: "clientCode",
        isSortable: true,
        width: "20%"
      },
      {
        title: "Desc_PhoneNumber",
        key: "mobile",
        isSortable: false,
        width: "20%"
      },
      {
        title: "Desc_Email",
        key: "email",
        isSortable: true,
        width: "20%"
      }
      ,
      {
        title: "Desc_Address",
        key: "address",
        isSortable: true,
        width: "20%"
      }
    ]
  };

  sharedTableFilter: CustomListFilter = {
    searchFilter: '',
    page: 0,
    pageSize: this.coreSession.pageSize
  };

  constructor(private coreSession: CoreSession, private clientService: ClientService, private router: Router, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.addActions();
    this.tableData.columns = [];

    for (const column in GlobalTheme.clientDefinitionColumns) {
      const v = GlobalTheme.clientDefinitionColumns[column];
      if (v) {
        this.tableData.columns.push({
          title: 'Desc_' + column[0].toLocaleUpperCase() + column.slice(1),
          key: column,
          isSortable: this.coreSession.selectedSolutionId == Solution.Sanad.valueOf() ? false : true,
        });
      }
    }
    this.tableData.columns.forEach(col => {
      col.width = (100 / this.tableData.columns.length).toString() + '%';
    });
    this.populateClientList();
  }

  ngOnDestroy(): void {
    this.coreSession.setPageTitle(this.translateService.currentLang.toLocaleLowerCase() == 'en' ? "Data Definition" :
      'تعريف البيانات');
  }

  addActions() {
    if (this.coreSession.checkActivitiesAvailability('client-list', MenuActions.Add)) {
      this.tableData.showNewBtn = true;
    }
    if (this.coreSession.checkActivitiesAvailability('client-list', MenuActions.Edit)) {
      this.tableData.rowOperations.push({
        operation: RowOperation.edit,
        title: "Desc_Edit",
        icon: "fa fa-pencil",
        color: "#12344d"
      });
    }
    if (this.coreSession.checkActivitiesAvailability('client-list', MenuActions.Delete)) {
      this.tableData.rowOperations.push(
        {
          operation: RowOperation.delete,
          title: "Desc_Delete",
          icon: "fa fa-trash",
          color: "#f1685e"
        });
    }
  }

  //Slider Events
  onRowOperation(event: any) {
    //console.log(event, "client event");

    switch (event.operation) {
      case RowOperation.edit:
        this.openSliderToEdit(event);
        break;
      case RowOperation.delete:
        this.onDeleteClicked(event);
        break;
      // case RowOperation.View:
      //   this.router.navigate(['/client-operation/client-operation-view', event.index]);
      //   break;
    }
  }
  //Search
  onTableFilterChanged(query: any) {
    this.clientFilter.customListFilter = query;
    this.populateClientList();
  }
  //Delete
  onDeleteClicked(event: any) {

    this.coreSession.ModalDialog.ShowMessage(
      this.translateService.instant(ConstantMessages.MsgDeleteConfirmation),
      DialogMode.YesNo,
      this.translateService.instant(ConstantMessages.LblDelete)
    ).then(
      (res: DialogResult) => {

        if (res === DialogResult.Yes) {

          this.clientService.updateClientActiveStatus(event.object).subscribe(response => {
            this.coreSession.ModalLoading.Hide();
            if (response.status != null && response.status >= 0) {
              this.coreSession.showSuccess(this.translateService.instant(ConstantMessages.LblSuccessCaption), this.translateService.instant(ConstantMessages.MsgUpdatedSuccessfuly));
              this.populateClientList();
            } else {
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
            }
          },
            (error: HttpErrorResponse) => {
              this.coreSession.ModalLoading.Hide();
              this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
            });
        }
      }
    );
  }
  //Open Slider Events
  openSliderToAdd() {
    this.isEditModeSelected = false;
    this.showSlider = true;
  }

  openSliderToEdit(val: any) {
    this.selectedClientObj = val.object;
    this.selectedIndexVal = val.index;
    this.isEditModeSelected = true;
    this.showSlider = true;
  }

  closeSlider() {
    this.showSlider = false;
  }

  //Saving
  onSaveClick() {
    this.saveSubject.next();
  }

  onClientSaved() {
    this.closeSlider();
    this.populateClientList();
  }

  onSaveCompleted(val: any) {
    this.onClientSaved();
  }

  populateClientList() {
    this.coreSession.ModalLoading.Show();
    this.clientService.getClientsList(this.clientFilter).subscribe(response => {
      this.coreSession.ModalLoading.Hide();
      if (response.status != null && response.status >= 0) {
        //console.log(response, "response");

        // if (response.data.totalItems <= 0) {
        //   this.coreSession.ModalDialog.ShowMessage(response.message, DialogMode.Ok);
        // }
        this.dataSource.data = response.data.data.slice();
        this.dataSource.pageData = response.data.data.slice();
        this.dataSource.totalItems = response.data.totalItems;
      } else {
        this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), response.message);
      }
    }, (error: HttpErrorResponse) => {
      this.coreSession.ModalLoading.Hide();
      this.coreSession.showError(this.translateService.instant(ConstantMessages.LblErrorCaption), this.translateService.instant(ConstantMessages.MsgErrorHappened));
    }
    );
  }
}
