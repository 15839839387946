import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ResponseModel } from "src/app/shared/model/api-models/api-models";
import { Workbook } from 'exceljs';
import FileSaver from 'file-saver';
import { StockCountingDetailsModel } from "src/app/shared/model/stock-counting/stock-counting-details.model";
import { DatePipe } from "@angular/common";
import { CoreSession } from "src/app/core/core.session";
import { Client } from "src/app/shared/enum/client.enum";

@Injectable({ providedIn: 'root' })
export class ReportService {

    private url = '';
    httpOptions;

    constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string,
        private datePipe: DatePipe) {
        this.url = baseUrl + 'Report';
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'my-auth-token'
            })
        };
    }

    getReportFilterLists() {
        const urlValue: string = this.url + '/GetReportFilterLists';
        return this.http.get(urlValue, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getAvailableStockCountingList(obj: {
        date: any, staffId: number, supplierCode: string, warehouseId: number,
        staffGroupId: number,
    }) {
        const urlValue: string = this.url + '/GetAvailableStockCountingHistoryList';
        return this.http.post(urlValue, JSON.stringify(obj), this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    exportStockCountingReport(obj: {
        date: any, staffId: number, staffName: string, supplierCode: string, warehouseId: number, warehouseCode: string, getLastReportFlag: boolean,
        staffGroupId: number, staffGroupName: string, orientation: number,
        stockCountingHistoryId: number,
    }) {
        const urlValue: string = this.url + '/ExportStockCountingReport';

        return this.http.post(urlValue, JSON.stringify(obj), {
            responseType: "blob",
            headers: this.httpOptions.headers,
        }).map(res => {
            var blob = new Blob([res], { type: "application/pdf" });
            return blob;
        });
    }

    exportStaffGroupsStockCountingReport(obj: { date: any, warehouseId: number, warehouseCode: string, warehouseName: string, supplierCode: string, staffGroups: number[], orientation: number }) {
        const urlValue: string = this.url + '/ExportStaffGroupsStockCountingReport';

        return this.http.post(urlValue,
            JSON.stringify(obj),
            {
                responseType: "blob",
                headers: this.httpOptions.headers,
            }).map(res => {
                var blob = new Blob([res], { type: "application/pdf" });
                return blob;
            });
    }

    exportStockCountingReportGroupedByItem(obj: {
        fromDate: any, toDate: any, warehouseId: number, warehouseCode: string, warehouseName: string,
        supplierCode: string, staffGroupId: number, staffGroupName: string, orientation: number,
        staffId: number, staffName: string
    }) {
        const urlValue: string = this.url + '/ExportStockCountingReportGroupedByItem';

        return this.http.post(urlValue, JSON.stringify(obj), {
            headers: this.httpOptions.headers,
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getLectureReportDetails(feedbackId: number) {
        const urlValue: string = this.url + '/GetLectureReportDetails';

        let params = new HttpParams();
        params = params.append('feedbackId', feedbackId.toString());

        return this.http.get(urlValue, {
            headers: this.httpOptions.headers,
            params: params
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getLectureReportsList(filter: any) {
        const urlValue: string = this.url + '/GetLectureReportsList';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getStudentsFeedbackList(filter: any) {
        const urlValue: string = this.url + '/GetStudentsFeedbackList';
        return this.http.post(urlValue, JSON.stringify(filter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getStudentFeedbackDetails(feedbackId: number) {
        const urlValue: string = this.url + '/GetStudentFeedbackDetails';

        let params = new HttpParams();
        params = params.append('feedbackId', feedbackId.toString());

        return this.http.get(urlValue, {
            headers: this.httpOptions.headers,
            params: params
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    getOrganizersAttendanceList(LectureFilter: any) {
        const urlValue: string = this.url + '/GetOrganizersAttendanceList';
        return this.http.post(urlValue, JSON.stringify(LectureFilter), this.httpOptions).pipe(
            map((response) => {
                let res = new ResponseModel();
                res.data = response["data"];
                res.message = response["message"];
                res.status = response["status"];

                return res;
            })
        );
    }

    getStaffList() {
        const urlValue: string = this.url + '/GetStaffList';
        return this.http.get(urlValue, this.httpOptions).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result.data;
            }
        ));
    }

    getAllStockCountingHistoryDetails(obj: {
        date: any, staffIds: number[], staffName: string, supplierCode: string, warehouseId: number, warehouseCode: string, getLastReportFlag: boolean,
        staffGroupId: number, staffGroupName: string
    }) {
        const urlValue: string = this.url + '/GetAllStockCountingHistoryDetails';

        return this.http.post(urlValue, JSON.stringify(obj), {
            headers: this.httpOptions.headers,
        }).pipe(map(
            (response) => {
                const result = new ResponseModel();
                result.data = response['data'];
                result.message = response['message'];
                result.status = response['status'];
                return result;
            }
        ));
    }

    public exportStockCountingDetailsToExcel(stockCountingDetails: StockCountingDetailsModel[], sumReport?: boolean) {

        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Sheet1', { properties: { defaultColWidth: 16 } });

        let titleRow = worksheet.addRow(["", "", "", "Stock Counting"]);
        titleRow.font = { name: 'Calibri', family: 4, size: 14, bold: true };
        titleRow.border = {
            top: { style: 'thin', color: { argb: '00000000' } },
            left: { style: 'thin', color: { argb: '00000000' } },
            bottom: { style: 'thin', color: { argb: '00000000' } },
            right: { style: 'thin', color: { argb: '00000000' } }
        };

        titleRow.eachCell((cell, number) => {

            if (number == 4) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFd9e1f2' },
                };
            }

            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

        });

        worksheet.mergeCells('D1:F1');

        // let columns: Columns = new Columns();
        let headerRow: any[] = [];
        if (CoreSession.selectedClient == Client.Inventory_Palestine.valueOf()) {
            headerRow = ['Stock Counting Id', 'Item code', 'Item Description', 'UOM', 'Barcode', 'Physical Qty/PCS', 'Physical Qty/UOM', 'Location', 'Unit Price', 'Cost Price', 'Staff/ Checked By'];
        }
        if (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()
            || CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()) {
            if (!sumReport)
                headerRow = ['Stock Counting Id', 'Item code', 'Item Description', 'UOM', 'Barcode', 'Physical Qty/PCS', 'Location', 'Unit Price', 'Total Unit', 'Cost Price', 'Total Cost', 'Staff/ Checked By', 'Staff group'];
            else
                headerRow = ['Stock Counting Id', 'Item code', 'Item Description', 'Barcode', 'Physical Qty/PCS', 'Location', 'Store ID', 'Unit Price', 'Cost Price', 'INV Date'];
        }
        else {
            headerRow = ['Stock Counting Id', 'Item code', 'Item Description', 'UOM', 'Barcode', 'Physical Qty/PCS', 'Location', 'Unit Price', 'Cost Price', 'Staff/ Checked By'];
        }
        if (CoreSession.selectedClient == Client.Inventory_ToyTriangle.valueOf()) {
            headerRow[5] = 'Warehouse';
            headerRow.push('Location');
            headerRow.push('Item Available');
        }

        let row = worksheet.addRow(headerRow);
        row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
        row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        row.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFffff00' }
        };
        row.border = {
            top: { style: 'thin', color: { argb: '00000000' } },
            left: { style: 'thin', color: { argb: '00000000' } },
            bottom: { style: 'thin', color: { argb: '00000000' } },
            right: { style: 'thin', color: { argb: '00000000' } }
        };

        row.height = 35;
        let totalUnitPrice = 0;
        let totalCostPrice = 0;
        for (let i = 0; i < stockCountingDetails.length; i++) {
            if (CoreSession.selectedClient == Client.Inventory_Palestine.valueOf()) {
                row = worksheet.addRow(
                    [
                        stockCountingDetails[i].stockCountingId,
                        stockCountingDetails[i].itemCode,
                        stockCountingDetails[i].itemName,
                        stockCountingDetails[i].itemUOM,
                        stockCountingDetails[i].itemBarcode,
                        truncateToDecimalPlaces(stockCountingDetails[i].itemAvailableStockQuantity, 3),
                        (stockCountingDetails[i].uomConversion > 0 ? (stockCountingDetails[i].itemAvailableStockQuantity / stockCountingDetails[i].uomConversion) : stockCountingDetails[i].itemAvailableStockQuantity),
                        stockCountingDetails[i].locationName,
                        truncateToDecimalPlaces(stockCountingDetails[i].unitPrice, 3),
                        truncateToDecimalPlaces(stockCountingDetails[i].costPrice, 3),
                        stockCountingDetails[i].staffName,
                    ]
                );
            } else if (CoreSession.selectedClient == Client.Inventory_Ultra_Vision.valueOf()
                || CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()) {

                let cols = [];
                cols.push(stockCountingDetails[i].stockCountingId);
                cols.push(stockCountingDetails[i].itemCode);
                cols.push(stockCountingDetails[i].itemName);
                if (!sumReport)
                    cols.push(stockCountingDetails[i].itemUOM);
                cols.push(stockCountingDetails[i].itemBarcode);
                cols.push(stockCountingDetails[i].itemAvailableStockQuantity.toFixed(3));
                cols.push(stockCountingDetails[i].locationName);
                if (sumReport)
                    cols.push(stockCountingDetails[i].warehouseCode);
                cols.push(stockCountingDetails[i].unitPrice.toFixed(3));
                if (!sumReport)
                    cols.push((stockCountingDetails[i].unitPrice * stockCountingDetails[i].itemAvailableStockQuantity).toFixed(3));
                cols.push(stockCountingDetails[i].costPrice.toFixed(3));
                if (sumReport)
                    cols.push(stockCountingDetails[i].stockCountingDate);
                if (!sumReport)
                    cols.push((stockCountingDetails[i].costPrice * stockCountingDetails[i].itemAvailableStockQuantity).toFixed(3));
                if (!sumReport)
                    cols.push(stockCountingDetails[i].staffName);
                if (!sumReport)
                    cols.push(stockCountingDetails[i].staffGroupName);

                row = worksheet.addRow(
                    cols
                );

                totalUnitPrice += (stockCountingDetails[i].unitPrice * stockCountingDetails[i].itemAvailableStockQuantity);
                totalCostPrice += (stockCountingDetails[i].costPrice * stockCountingDetails[i].itemAvailableStockQuantity);

            } else if (CoreSession.selectedClient == Client.Inventory_ToyTriangle.valueOf()) {
                row = worksheet.addRow(
                    [
                        stockCountingDetails[i].stockCountingId,
                        stockCountingDetails[i].itemCode,
                        stockCountingDetails[i].itemName,
                        stockCountingDetails[i].itemUOM,
                        stockCountingDetails[i].itemBarcode,
                        truncateToDecimalPlaces(stockCountingDetails[i].itemAvailableStockQuantity, 3),
                        stockCountingDetails[i].locationName,
                        truncateToDecimalPlaces(stockCountingDetails[i].unitPrice, 3),
                        truncateToDecimalPlaces(stockCountingDetails[i].costPrice, 3),
                        stockCountingDetails[i].staffName,
                        stockCountingDetails[i].itemLocation,
                        stockCountingDetails[i].itemAvailable,
                    ]
                );
            } else {
                row = worksheet.addRow(
                    [
                        stockCountingDetails[i].stockCountingId,
                        stockCountingDetails[i].itemCode,
                        stockCountingDetails[i].itemName,
                        stockCountingDetails[i].itemUOM,
                        stockCountingDetails[i].itemBarcode,
                        truncateToDecimalPlaces(stockCountingDetails[i].itemAvailableStockQuantity, 3),
                        stockCountingDetails[i].locationName,
                        truncateToDecimalPlaces(stockCountingDetails[i].unitPrice, 3),
                        truncateToDecimalPlaces(stockCountingDetails[i].costPrice, 3),
                        stockCountingDetails[i].staffName
                    ]
                );
            }

            row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }

        if (!sumReport && CoreSession.selectedClient == Client.Inventory_Kuwait.valueOf()) {
            let cols = [];
            cols.push('Total');
            cols.push('');
            cols.push('');
            if (!sumReport)
                cols.push('');
            cols.push('');
            cols.push('');
            cols.push('');
            cols.push('');
            cols.push(totalUnitPrice.toFixed(3));
            cols.push('');
            cols.push(totalCostPrice.toFixed(3));
            if (!sumReport)
                cols.push('');
            if (!sumReport)
                cols.push('');

            row = worksheet.addRow(
                cols
            );

            row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
        }


        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'Inventory_StockCounting_' + this.datePipe.transform(new Date(), 'dd/MM/yyyy') + '.xlsx');
        });

    }

    public exportStockCountingDetailsToExcelToyTriangle(stockCountingDetails: StockCountingDetailsModel[]) {

        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Shelf', { properties: { defaultColWidth: 16 } });

        let headerRow: any[] = [];
        headerRow = ['Staff Name', 'Shelf', 'Item Number', 'Description', 'RealQty'];

        let row = worksheet.addRow(headerRow);
        row.font = { name: 'Calibri', family: 4, size: 11, bold: true };
        row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        // row.border = {
        //     top: { style: 'thin', color: { argb: '00000000' } },
        //     left: { style: 'thin', color: { argb: '00000000' } },
        //     bottom: { style: 'thin', color: { argb: '00000000' } },
        //     right: { style: 'thin', color: { argb: '00000000' } }
        // };

        row.height = 35;

        for (let i = 0; i < stockCountingDetails.length; i++) {
            row = worksheet.addRow(
                [
                    stockCountingDetails[i].staffName,
                    stockCountingDetails[i].locationName,
                    stockCountingDetails[i].itemCode,
                    stockCountingDetails[i].itemName,
                    stockCountingDetails[i].itemAvailableStockQuantity,
                ]
            );

            row.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        }


        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, 'Inventory_StockCounting_' + this.datePipe.transform(new Date(), 'dd/MM/yyyy') + '.xlsx');
        });

    }

}

function truncateToDecimalPlaces(originalNumber: number, decimalPlaces: number): number {
    const multiplier = Math.pow(10, decimalPlaces);
    const truncatedNumber = Math.floor(originalNumber * multiplier) / multiplier;
    return truncatedNumber;
}