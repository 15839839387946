import { Component, OnInit } from '@angular/core';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';

@Component({
  selector: 'app-client-operation-history-list',
  templateUrl: './client-operation-history-list.component.html',
  styleUrls: ['./client-operation-history-list.component.css']
})
export class ClientOperationHistoryListComponent implements OnInit {

  dataSource: SharedTableResult = {
    totalItems: 0,
    data: []
  };

  tableData: ITableProperties = {
    pageSize: 100,
    showPaginator: false,
    showSearch: true,
    isOnline: false,
    showSearchBtn: false,
    showNewBtn: false,
    newBtnCaption: 'Desc_New',
    SearchBoxLabel:"Desc_Search",
    rowOperations: [],
    multiSelectionOperations: [],
    columns: [
      {
        title: "DESC_Field_Name",
        key: "fieldName",
        isSortable: false,
        width: "50%"
      },
      {
        title: "DESC_Field_Value",
        key: "fieldValue",
        isSortable: false,
        width: "50%"
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
