export const ConstantMessages = {
  LblErrorCaption: 'Desc_ErrorCaption',
  LblSuccessCaption: 'Desc_SuccessCaption',
  LblWarningCaption: 'Desc_WarningCaption',
  LblDelete: 'Desc_Delete',
  LblCopiedFrom: 'Desc_CopiedFrom',

  MsgErrorHappened: 'Desc_ErrorHappened',
  MsgSavedSuccessfuly: 'Desc_SavedSuccessfully',
  MsgUpdatedSuccessfuly: 'Desc_UpdatedSuccessfuly',
  MsgDeleteConfirmation: 'Desc_ConfirmationDelete',
  MsgConfirmConfirmation: 'Desc_ConfirmConfirmation',
  MsgCancelConfirmation: 'Desc_Cancel_Confirmation',
  MsgDeletedSuccessfully: 'Desc_DeletedSuccessfully',
  MsgNoRowSelected: 'Desc_NoRowSelected',
  MsgInvalidUsernamePassword: 'Desc_InValidUsernameOrPassword',
  MsgSelectDate: "Desc_PleaseSelectDate",
  MsgFillMandatory: 'Desc_FillMandatory',
  MsgUniqueCode: 'Desc_UniqueCode',
  MsgUniqueBarcode: 'Desc_UniqueBarcode',
  MsgMustBeUnique: 'Desc_MustBeUnique',
  MsgUniqueUserName: 'Desc_UniqueUsername',
  MsgEmployeeIsInactive: 'Desc_Employee_Inactive',
  MsgReactivateConfirmation: 'Desc_ReactivateConfirmation',
  MsgDeactivateConfirmation: 'Desc_DeactivateConfirmation',
  LblSelectAllCaption: 'Desc_SelectAll',
  LblUnSelectAllCaption: 'Desc_UnSelectAll',
  LblAdd: 'Desc_Add',
  LblSearchCaption: 'Desc_Search',
  LblCaptionInactive: 'Desc_Inactive',
  MsgSelectStaff: 'Desc_SelectStaff',
  MsgSelectMenus: 'Desc_SelectMenus',
  MsgNoMenusAvailable: 'Desc_NoMenusAvailable',
  MsgEnterSecurityGroupName: 'Desc_EnterSecurityGroupName',
  MsgInsertUserAndPass: 'Desc_InsertUsernameAndPassword',
  MsgFillAtLeastOneLookUpOption: 'Desc_FillAtLeastOneLookUpOption',
  MsgEntryHasEntriesDependOnIt: 'Desc_EntryHasOtherEntriesDependOnIt',
  MsgNoEnoughQuantity: 'Desc_NoEnoughQuantity',
  MsgUserHasNoAccess: 'Desc_UserHasNoAccess',
  MsgInvalidEmailAddress: 'Desc_InvalidEmailAddress',
  MsgLargeFileWarning: 'Desc_LargeFileWarning',
  MsgInvalidNationalId: 'Desc_InvalidNationalId',
  MsgInavlidMobileNumber: 'Desc_InavlidMobileNumber',
};
