import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-organizers-attendance-view',
  templateUrl: './organizers-attendance-view.component.html',
  styleUrls: ['./organizers-attendance-view.component.css']
})
export class OrganizersAttendanceViewComponent implements OnInit {
  @Input() events: Observable<void>;
  @Input() selectedObj: any;
  @Input() isViewMode: boolean = false;
  @Output() onSaveCompleted = new EventEmitter();

  saveBtnSubscription: any;
  form: FormGroup;
  

  constructor() { }

  ngOnInit(): void {
    this.subscribeSaveClick();
  }

  ngOnDestroy(): void {
    this.saveBtnSubscription.unsubscribe();
  }

  //Saving
  subscribeSaveClick() {
    this.saveBtnSubscription = this.events.subscribe(() => {
    });
  }

}