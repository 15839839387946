
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { OperatorModel } from "src/app/shared/model/operator/operator.model";
import { ConstantStorage } from "../constants/constant-stroage";
import { ConstantURLs } from "../constants/constant-URL";
import { CoreSession } from "../core.session";
import * as jwt_decode from "jwt-decode";
import { JwtHelperService } from '@auth0/angular-jwt';
import { PortalMode } from "src/app/shared/enum/portal-mode.enum";


@Injectable()
export class AuthGuard implements CanActivate {
  isMenuAvailable: Boolean;
  currentUser: OperatorModel;
  splittedPath: string[];
  constructor(
    private router: Router,
    private coreSession: CoreSession
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {

    var keepMeLoggedIn: string;

    // this.checkIfMenuAvailable(state, next);
    if (sessionStorage.length > 0) {
      this.currentUser = JSON.parse(sessionStorage.getItem(ConstantStorage.CurrentUser));
      keepMeLoggedIn = sessionStorage.getItem(ConstantStorage.KeepMeLoggedIn);
    } else {
      this.currentUser = JSON.parse(localStorage.getItem(ConstantStorage.CurrentUser));
      keepMeLoggedIn = localStorage.getItem(ConstantStorage.KeepMeLoggedIn);
    }

    if (this.coreSession.selectedPortalMode == PortalMode.Student.valueOf()) {
      this.router.navigate(["/", ConstantURLs.studentRegisterationURL]);
      return false;
    } else if (this.coreSession.selectedPortalMode == PortalMode.DelMonteAuthentic.valueOf() || this.coreSession.selectedPortalMode == PortalMode.DelMonteFake.valueOf()) {
      this.router.navigate(["/", ConstantURLs.homePageURL]);
      return false;
    } else {
      if (
        this.currentUser != null &&
        this.currentUser.isLoggedIn &&
        this.isAuthenticated() && (sessionStorage.length > 0 || keepMeLoggedIn == 'true')

        // && this.isMenuAvailable
      ) {

        this.coreSession.currentUser = this.currentUser;
        this.coreSession.cachedGeneralData = JSON.parse(localStorage.getItem(ConstantStorage.generalData));
        return true;

      } else {

        // if (this.isMenuAvailable) {
        //   sessionStorage.removeItem(ConstantStorage.TokenName);
        //   sessionStorage.removeItem(ConstantStorage.CurrentUser);
        //   sessionStorage.removeItem(ConstantStorage.generalData);
        // } else {
        // }
        localStorage.removeItem(ConstantStorage.TokenName);
        localStorage.removeItem(ConstantStorage.CurrentUser);
        localStorage.removeItem(ConstantStorage.generalData);
        localStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
        localStorage.removeItem(ConstantStorage.LicenseData);

        sessionStorage.removeItem(ConstantStorage.TokenName);
        sessionStorage.removeItem(ConstantStorage.CurrentUser);
        sessionStorage.removeItem(ConstantStorage.generalData);
        sessionStorage.removeItem(ConstantStorage.KeepMeLoggedIn);
        sessionStorage.removeItem(ConstantStorage.LicenseData);

        if (this.coreSession.selectedPortalMode != PortalMode.Student.valueOf())
          this.router.navigate(["/" + ConstantURLs.loginURL]);

        return false;

      }
    }

  }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = sessionStorage.length > 0 ? sessionStorage.getItem(ConstantStorage.TokenName) : localStorage.getItem(ConstantStorage.TokenName);
    return !jwtHelper.isTokenExpired(token);
  }

  getToken(): string {
    return localStorage.getItem(ConstantStorage.TokenName);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  canGoToRoute(user: string, id: string): boolean {
    return true;
  }

  // checkIfMenuAvailable(
  //   state: RouterStateSnapshot,
  //   route: ActivatedRouteSnapshot
  // ) {
  //   this.splittedPath = state.url.split("/");
  //   let originalMenuIndex = -1;
  //   if (
  //     this.splittedPath &&
  //     this.splittedPath.length >= 4 &&
  //     this.splittedPath[1].toLowerCase() === "roi" &&
  //     this.splittedPath[2].toLowerCase() === "stock"
  //   ) {
  //     this.splittedPath = [
  //       "roi",
  //       "stock/" + this.splittedPath[3].toLowerCase(),
  //     ];
  //   }
  //   for (let menuPath of this.splittedPath) {
  //     if (menuPath !== "") {
  //       let menuIndex = this.sessionDataProvider.allMenusAvailable.findIndex(
  //         (x) => x.url === menuPath
  //       );
  //       if (menuIndex > -1) {
  //         originalMenuIndex = menuIndex;
  //       }
  //       if (
  //         menuPath.toLowerCase().trim().includes("home") ||
  //         menuIndex > -1 ||
  //         (this.sessionDataProvider.allMenusAvailable[originalMenuIndex] &&
  //           this.sessionDataProvider.allMenusAvailable[originalMenuIndex]
  //             .addedActionTypes &&
  //           this.sessionDataProvider.allMenusAvailable[
  //             originalMenuIndex
  //           ].addedActionTypes.find((x) => x.url === menuPath)) ||
  //         menuPath === ConstantURLs.settlementURL ||
  //         menuPath.includes(ConstantURLs.checkoutURL) ||
  //         menuPath.includes(ConstantURLs.checkInURL)
  //       ) {
  //         this.navService.ActiveSidebar.next(this.splittedPath[1]);
  //         //console.log(menuPath, "MENU IS AVAILABLE");

  //         this.isMenuAvailable = true;
  //         if (
  //           menuPath.includes("edit") ||
  //           menuPath.includes(ConstantURLs.checkoutURL) ||
  //           menuPath.includes(ConstantURLs.checkInURL) ||
  //           menuPath === ConstantURLs.settlementURL ||
  //           menuPath.includes(ConstantURLs.assignBoxesURL) ||
  //           menuPath.includes(ConstantURLs.releaseBoxesURL)
  //         ) {
  //           return;
  //         }
  //       } else {
  //       //  //console.log(this.sessionDataProvider.allMenusAvailable[originalMenuIndex]);
  //         // //console.log(this.sessionDataProvider.allMenusAvailable[originalMenuIndex].addedActionTypes);
  //         //console.log(menuPath, "MENU IS NOT AVAILABLE1");
  //         this.isMenuAvailable = false;
  //         break;
  //       }
  //     }else{
  //       //console.log(menuPath, "MENU IS NOT AVAILABLE2");
  //     }
  //   }
  //   return this.isMenuAvailable;
  // }
}
