
import { TextLanguage } from "../general/textLanguage.model";

export class SerialModel {
  fromSerial!: string;
  toSerial!: string;
  invoiceId!: string;
  serialStatus!: boolean;
  loggedInUserId: number;
  solutionId: number;
  staffId: number;
  serialStatusId: number;

  constructor() {
    this.fromSerial = '';
    this.toSerial = '';
    this.invoiceId = "-1";
    this.serialStatus = false;
    this.serialStatusId = -1;
    this.staffId = -1;
    this.solutionId = -1;
    this.loggedInUserId = -1;
  }
}
