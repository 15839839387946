import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientModel } from 'src/app/shared/model/client-models/client-model';
import { SharedTableResult } from 'src/app/shared/model/shared-table/shared-table-result.interface';
import { ITableProperties } from 'src/app/shared/model/shared-table/table-properties.interface';

@Component({
  selector: 'app-client-operation-basic-data',
  templateUrl: './client-operation-basic-data.component.html',
  styleUrls: ['./client-operation-basic-data.component.css']
})
export class ClientOperationBasicDataComponent implements OnInit, OnChanges {
  @Input() selectedClientBasicData: ClientModel = new ClientModel();
  form: FormGroup;

  clientName: string = "";
  clientNationalId: string = "";
  clientEmail: string = "";
  clientCode: string = "";
  clientMobile: string = "";
  clientAddress: string = "";
  constructor() { }

  ngOnInit(): void {
    // this.fillClientBasicData();
  }

  ngOnChanges() {    
    if (this.selectedClientBasicData != null) {
      this.clientName = this.selectedClientBasicData.clientName;
      this.clientNationalId = this.selectedClientBasicData.clientNationalId;
      this.clientEmail = this.selectedClientBasicData.email;
      this.clientCode = this.selectedClientBasicData.clientCode;
      this.clientMobile = this.selectedClientBasicData.mobile;
      this.clientAddress = this.selectedClientBasicData.address;
    }
  }


  initForm() {
    let clientId = this.selectedClientBasicData.clientId;
    let clientName = this.selectedClientBasicData.clientName;
    let clientCode = this.selectedClientBasicData.clientCode;
    let clientNationalId = this.selectedClientBasicData.clientNationalId;
    let clientTypeId = this.selectedClientBasicData && this.selectedClientBasicData.clientTypeId && this.selectedClientBasicData.clientTypeId > 0 ? this.selectedClientBasicData.clientTypeId : undefined;
    let mobile = this.selectedClientBasicData.mobile;
    let email = this.selectedClientBasicData.email;
    let address = this.selectedClientBasicData.address;
    let isInactive = this.selectedClientBasicData.inactive;

    this.form = new FormGroup({
      clientId: new FormControl(clientId),
      clientName: new FormControl(clientName, Validators.required),
      clientCode: new FormControl(clientCode, Validators.required),
      clientNationalId: new FormControl(clientNationalId),
      clientTypeId: new FormControl(clientTypeId),
      mobile: new FormControl(mobile, Validators.required),
      email: new FormControl(email),
      address: new FormControl(address),
      isInactive: new FormControl(isInactive),
    });
  }

  //Form Getters
  get clientIdControl() {
    return this.form.get('clientId');
  }

  get clientNameControl() {
    return this.form.get('clientName');
  }

  get clientCodeControl() {
    return this.form.get('clientCode');
  }

  get clientNationalIdControl() {
    return this.form.get('clientNationalId');
  }

  get clientTypeIdControl() {
    return this.form.get('clientTypeId');
  }

  get mobileControl() {
    return this.form.get('mobile');
  }

  get emailAddressControl() {
    return this.form.get('email');
  }

  get addressControl() {
    return this.form.get('address');
  }

  get isInactiveControl() {
    return this.form.get('isInactive');
  }

}
