import { TextLanguage } from "../general/textLanguage.model";

export class AssetsModel {
  assetId: number;
  isParent: boolean;
  parentAssetId: number;
  assetTag: string;
  assetCode: string;
  assetTypeId: number;
  assetStatusId: number;
  assetConditionId: number;
  assetCategoryId: number;
  assetSubCategoryId: number;
  lastLocationId: number;
  lastUserId: number;
  inactive: boolean;

  assetName: string;
  assetType: string;
  assetStatusName: string;
  assetConditionName: string;
  assetCategory: string;
  assetSubCategory: string;
  assetLastLocationName: string;
  assetLastStaffName: string;

  descriptions: TextLanguage[];

  purchaseCost: number;
  sellingCost: number;
  consumptionPeriod: number;
  supplier: string;
  transactionId: string;
  qty: number;

  constructor() {
    this.assetId = -1;
    this.isParent = false;
    this.parentAssetId =-1;
    this.assetTag = '';
    this.assetCode = '';
    this.assetTypeId = -1;
    this.assetStatusId = -1;
    this.assetConditionId = -1;
    this.assetCategoryId = -1;
    this.assetSubCategoryId = -1;
    this.lastLocationId = -1;
    this.lastUserId = -1;
    this.inactive = false;

    this.assetName = '';
    this.assetType = '';
    this.assetStatusName = '';
    this.assetConditionName = '';
    this.assetCategory = '';
    this.assetSubCategory = '';
    this.assetLastLocationName = '';
    this.assetLastStaffName = '';

    this.descriptions = [];

    this.purchaseCost = 0;
    this.sellingCost = 0;
    this.consumptionPeriod = 0;
    this.supplier = '';
    this.qty = 0;
    this.transactionId = '';
  }
}
