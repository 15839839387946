import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { LoginService } from "./content/login/login.service";
import { ConstantStorage } from "./core/constants/constant-stroage";
import { CoreSession } from "./core/core.session";
import { SessionDataProvider } from './core/session-data-provider.service';
import { PortalMode } from "./shared/enum/portal-mode.enum";
import { Solution } from "./shared/enum/solution.enum";
import { ConstantURLs } from "./core/constants/constant-URL";
import { Client } from "./shared/enum/client.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public coreSession: CoreSession,
    private loginService: LoginService,
    private sessionDataProvider: SessionDataProvider,
    private router: Router, private titleService: Title
  ) {
    if (this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
      CoreSession.selectedClient == Client.IEC_Jordan.valueOf()) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.coreSession.selectedPortalMode == PortalMode.TaskManagement.valueOf()) {
            if (this.translate.currentLang.toLocaleLowerCase() == 'ar')
              this.titleService.setTitle("تنظيم المهمات");
            else
              this.titleService.setTitle("Task Management");
          } else if (this.coreSession.selectedPortalMode == PortalMode.Admin.valueOf()) {
            if (this.translate.currentLang.toLocaleLowerCase() == 'ar')
              this.titleService.setTitle("برنامج أنا أشارك");
            else
              this.titleService.setTitle("Ana Usharek");
          } else {
            if (this.translate.currentLang.toLocaleLowerCase() == 'ar')
              this.titleService.setTitle("تسجيل أنا أشارك");
            else
              this.titleService.setTitle("Ana Usharek Registeration");
          }
        }
      });
    }
    this.setSystemLanguages();
  }

  ngOnInit() {
    this.coreSession.selectedLanguageStr = this.translate.currentLang;
    this.translate.addLangs(["en", "ar"]);
    this.translate.setDefaultLang(this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
      CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
      ? "ar" : "en");
    this.OnLanguageChange();
    this.coreSession.direction.next(this.coreSession.selectedLanguageId === 1 ? 'ltr' : 'rtl');
    this.coreSession.changeDir();
  }

  setSystemLanguages() {
    this.translate.addLangs(["en", "ar"]);
    this.translate.setDefaultLang(this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
      CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
      ? "ar" : "en");

    let localStorageLang = "";
    this.coreSession.selectedLanguageId = +localStorage.getItem(
      ConstantStorage.LanguageId
    );
    if (this.coreSession.selectedLanguageId === 1) {
      localStorageLang = "en";
    } else if (this.coreSession.selectedLanguageId == 2) {
      localStorageLang = "ar";
    } else {
      localStorageLang = this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
        CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
        ? 'ar' : "en"; // Default language
    }
    this.translate.use(
      localStorageLang.match(/en|ar/) ? localStorageLang : this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
        CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
        ? 'ar' : "en"
    );
    let allLangs = this.sessionDataProvider.getSessionLanguages();

    let langsCode = allLangs.filter(a => a.active == true).map(a => a.code);

    this.translate.addLangs(langsCode);

    this.translate.setDefaultLang(this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
      CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
      ? 'ar' : langsCode[0]);

    this.coreSession.selectedLanguageId = +localStorage.getItem(ConstantStorage.LanguageId);

    if (this.coreSession.selectedLanguageId) {
      let currentLang = allLangs.find(a => a.languageId == this.coreSession.selectedLanguageId);
      let currentLangCode = currentLang != null ? currentLang.code : langsCode[0];
      this.translate.use(currentLangCode);
    } else {
      this.translate.use(this.coreSession.selectedSolutionId == Solution.IEC.valueOf() &&
        CoreSession.selectedClient == Client.IEC_Jordan.valueOf()
        ? 'ar' : langsCode[0]);
    }
  }

  OnLanguageChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.coreSession.selectedLanguageStr = event.lang;
      if (event.lang == "ar") {
        document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
        document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
        localStorage.setItem(ConstantStorage.LanguageId, "2");
        this.coreSession.selectedLanguageId = 2;
        this.coreSession.isRTL = true;
        this.UpdateServeLanguage();
      } else {
        document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
        document.getElementsByTagName("html")[0].setAttribute("lang", "en");
        localStorage.setItem(ConstantStorage.LanguageId, "1");
        this.coreSession.selectedLanguageId = 1;
        this.coreSession.isRTL = false;
        this.UpdateServeLanguage();
      }
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.coreSession.selectedLanguageStr = event.lang;
      let selectedLanguage = this.sessionDataProvider.getSessionLanguages().filter(a => a.code == event.lang)[0];
      if (selectedLanguage) {
        if (selectedLanguage.rtl) {
          document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
          this.coreSession.isRTL = true;

        } else {
          document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
          this.coreSession.isRTL = false;
        }
        document.getElementsByTagName("html")[0].setAttribute("lang", selectedLanguage.code);
        localStorage.setItem(ConstantStorage.LanguageId, selectedLanguage.languageId);
        this.coreSession.selectedLanguageId = selectedLanguage.languageId;
      }
      this.UpdateServeLanguage();
    });
  }

  UpdateServeLanguage() {
    if (true) {
      // this.loginService.setLanguageId(this.coreSession.selectedLanguageId).subscribe(res => {});
    }
  }
  setSystemLanguages2() {

    let allLangs = this.sessionDataProvider.getSessionLanguages();

    let langsCode = allLangs.filter(a => a.active).map(a => a.code);

    this.translate.addLangs(langsCode);

    this.translate.setDefaultLang(langsCode[0]);


    let localStorageLang = '';
    this.coreSession.selectedLanguageId = +localStorage.getItem(ConstantStorage.LanguageId);

    if (this.coreSession.selectedLanguageId === 1) {
      localStorageLang = "en";
    } else if (this.coreSession.selectedLanguageId == 2) {
      localStorageLang = "ar";
    } else {
      localStorageLang = "en"; // Default language
    }
    this.translate.use(localStorageLang.match(/en|ar/) ? localStorageLang : 'en');
  }

  OnLanguageChange2() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.coreSession.selectedLanguageStr = event.lang;
      if (event.lang == 'ar') {
        document.getElementsByTagName('body')[0].setAttribute('dir', 'rtl');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
        localStorage.setItem(ConstantStorage.LanguageId, "2");
        this.coreSession.selectedLanguageId = 2;
        this.coreSession.isRTL = true;
        this.UpdateServeLanguage();
      }
      else {
        document.getElementsByTagName('body')[0].setAttribute('dir', 'ltr');
        document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
        localStorage.setItem(ConstantStorage.LanguageId, "1");
        this.coreSession.selectedLanguageId = 1;
        this.coreSession.isRTL = false;
        this.UpdateServeLanguage();
      }
    })
  }
}
